import { Button, Modal, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import { useForm } from "react-hook-form";
import { Box } from "@mui/material";
import MuiToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import { useNavigate } from "react-router-dom";
import {
  clinicalCareRadioWorkOrderTests,
  radiologyWorkOrderPrint,
} from "../../../../Investigation/Radiology/Services/WorkOrderViewServices";
import {
  clinicalCarePathWorkOrderTests,
  labReportsCommonPrint,
} from "../../../../Investigation/Pathology/Services/Pathalogy/WorkOrderViewServices";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";
import {
  PrintIcon,
  PrintDisableIcon,
} from "../../../../assets/icons/InvestigationIcon";
import {
  EyeOnIcon,
  EyeOffIcon,
  PrintOnIcon,
} from "../../../../assets/icons/CustomIcons";
import ReportDetailsModal from "../../../../Investigation/Pathology/Components/workOrderView/ReportDetailsModal";
import RadiologyWorkOrderPrint from "../../../../Investigation/Radiology/prints/RadiologyWorkOrderPrint";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import { errorAlert } from "../../../../common/components/Toasts/CustomToasts";
import RadioWorkOrderViewModal from "../../../../Investigation/Radiology/Components/radiologyWorkOrderView/RadioWorkOrderViewModal";
import { getIpdFileCheckListPrint } from "../../../../Investigation/Radiology/Services/WorkOrderViewServices";
import IpdFileCheckListPrint from "../../../prints/IpdFileCheckListPrint";
import { getPhysioListOfTests } from "../../../../Investigation/Physiotheropy/services/PhysioWorkOrderViewServices";
import PhysioNoteModal from "../../../../Investigation/Physiotheropy/Components/physioworkorderview/PhysioNoteModal";
import PathWorkOrderGeneration from "../../../../Investigation/Pathology/Components/PathWorkOrderGeneration";
import RadioWorkOrderGeneration from "../../../../Investigation/Radiology/Components/RadioWorkOrderGeneration";
import PhysioWorkOrderGeneration from "../../../../Investigation/Physiotheropy/Components/PhysioWorkOrderGeneration";

export default function ClinicalInvestigationModal(props) {
  const { userActions } = props;
  const ToggleButton = styled(MuiToggleButton)(({ selectedColor }) => ({
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: selectedColor,
    },
  }));

  const theme = createTheme({
    palette: {
      text: {
        primary: "#c1d9e0",
      },
    },
  });

  const defaultValues = {};

  const {
    control,
    reset,
    register,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const [selected, setSelected] = useState("pathology"); //button toggle
  const [dataResult, setDataResult] = useState([]);
  const [openReportDetailsModal, setOpenReportDetailsModal] = useState(false);
  const [spinner, showSpinner] = useState(false);
  const [openPrintModal, setOpenPrintModal] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [reportPdf, setReportPdf] = React.useState("");
  const [pathSelectedTest, setPathSelectedTest] = useState(null);
  const [printDetails, setPrintDetails] = useState(null);
  const [isPrint, setIsPrint] = useState(false);
  const [printImage, setPrintImage] = React.useState("");
  const [images, setImages] = React.useState([]);
  const [selectedRowIndex, setSelectedRowIndex] = React.useState(null);
  const [openRadiologyModal, setOpenRadiologyModal] = useState(false);
  const [isView, setIsView] = useState(false);
  const [openIpdCheckListPrint, setOpenIpdCheckListPrint] = useState(false);
  const [ipdCheckListData, setIpdCheckListData] = useState(null);
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [viewNoteDetails, setViewNoteDetails] = useState(false);
  const [selectedPhysioTest, setSelectedPhysioTest] = useState(null);
  const [openWOModal, setOpenWOModal] = useState(false);
  const [authorizationArr, setAuthorizationArr] = useState([]);
  const navigate = useNavigate();

  const handleChanger = (e) => {
    setSelected(e.target.value);
  };

  //   let obj = {
  //     doId: row ? row.WorkOrderDoneId : 0,
  //     storeId: row ? row.StoreId : 0,
  //     supplierId: supplierId,
  //   };

  //   getLabList(obj)
  //     .then((response) => response.data)
  //     .then((res) => {
  //         setDataResult(res.result);
  //     });

  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "scroll",
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 20,
    p: 2,
  };

  console.log("getPatientTestsForClinical", props.selectedPatient);

  useEffect(() => {
    if (selected === "pathology") {
      let listObj = {
        admissionId: props.selectedPatient?.AdmissionId,
        opdIpd: 1,
      };
      showSpinner(true);
      clinicalCarePathWorkOrderTests(listObj)
        .then((response) => {
          setDataResult(response.data.result);
          showSpinner(false);
        })
        .catch(() => {
          showSpinner(false);
        });
    } else if (selected === "radiology") {
      let listObj = {
        admissionId: props.selectedPatient?.AdmissionId,
        orderId: null,
        opdIpd: 1,
      };
      showSpinner(true);
      clinicalCareRadioWorkOrderTests(listObj)
        .then((response) => {
          showSpinner(false);
          setDataResult(response.data.result);
        })
        .catch(() => {
          showSpinner(false);
        });
    } else if (selected === "physiotherapy") {
      showSpinner(true);
      getPhysioListOfTests(0, 3, props.selectedPatient?.AdmissionId)
        .then((response) => {
          showSpinner(false);
          setDataResult(response.data.result);
        })
        .catch(() => {
          showSpinner(false);
        });
    }
  }, [selected]);

  const pathRenderActions = (row, index) => {
    return (
      <div className="flex gap-2">
        {row?.FirstLevel === false ? (
          <EyeOffIcon />
        ) : (
          <Tooltip title="View">
            <button
              onClick={() => {
                setOpenReportDetailsModal(true);
                setSelectedRow(row);
                setAuthorizationArr([row]);
                setIsView(true);
                // setRowId(row);
                // setOpenPrintModal(true);
              }}
              disabled={row?.FirstLevel === false}
            >
              <EyeOnIcon />
            </button>
          </Tooltip>
        )}

        {row.FirstLevel === false &&
        row.SecondLevel === false &&
        row.ThirdLevel === false &&
        row?.IsPathologyAuthority === false ? (
          <PrintDisableIcon />
        ) : (
          <Tooltip title="Print">
            <button
              onClick={() => {
                // setSelectedRow(row);
                handleOpenPrintTestModal(row);
              }}
            >
              <PrintIcon />
            </button>
          </Tooltip>
        )}
      </div>
    );
  };
  const radioRenderActions = (row, index) => {
    return (
      <div className="flex gap-2">
        {row?.FirstLevelDescription === "" ? (
          <EyeOffIcon />
        ) : (
          <Tooltip title="View">
            <button
              onClick={() => {
                setOpenRadiologyModal(true);
                setSelectedRow(row);
                setIsView(true);
                // setOpenReportDetailsModal(true);
                // setRowId(row);
                // setOpenPrintModal(true);
              }}
              disabled={row?.FirstLevelDescription === ""}
            >
              <EyeOnIcon />
            </button>
          </Tooltip>
        )}

        {(row?.ThirdLevelDescription === null ||
          row?.ThirdLevelDescription === "") &&
        (row?.SecondLevelDescription === null ||
          row?.SecondLevelDescription === "") &&
        (row?.FirstLevelDescription === null ||
          row?.FirstLevelDescription === "") ? (
          <Tooltip title="Print">
            <button>
              <PrintDisableIcon />
            </button>
          </Tooltip>
        ) : (
          <Tooltip title="Print">
            <button
              onClick={() => {
                getRadiologyPrint(row);
                setSelectedRowIndex(index);
                setSelectedRow(row);
                // setOpenPrintModal(true);
                setOpenBackdrop(true);
              }}
            >
              <PrintIcon />
            </button>
          </Tooltip>
        )}
      </div>
    );
  };

  const physioRenderActions = (row, index) => {
    return (
      <div className="flex gap-2">
        {row["IsNoteExist"] === 1 ? (
          <Tooltip title="View">
            <button
              onClick={() => {
                setOpenNoteModal(true);
                setViewNoteDetails(true);
                setSelectedPhysioTest(row);
              }}
              disabled={row?.FirstLevel === false}
            >
              <EyeOnIcon />
            </button>
          </Tooltip>
        ) : (
          <EyeOffIcon />
        )}
      </div>
    );
  };

  function handleOpenPrintTestModal(row) {
    setOpenBackdrop(true);

    let printArr = [];
    {
      row !== undefined &&
        (printArr = [
          {
            orderDetailsId: row.OrderDtlsId,
            reportId: row.ReportId,
          },
        ]);
    }
    labReportsCommonPrint(printArr)
      .then((response) => {
        // if (response.statusCode === 204) {
        //   warningAlert(response.message);
        // } else if (response.statusCode === 200) {
        //
        // setOpenPrintModal(true);
        // setUrlforPrint(response);
        // }

        if (response.status === 200) {
          setOpenBackdrop(false);

          //Get Reponse JSON
          const blb = new Blob([response.data], { type: "text/plain" });
          const reader = new FileReader();
          // Start reading the blob as text.
          reader.readAsText(blb);
          //Convert Blob Response to JSON String
          reader.addEventListener("loadend", (e) => {
            if (e.target.result.charAt(0) !== "{") {
              setOpenPrintModal(true);
              const file = new Blob([response.data], {
                type: "application/pdf",
              });
              const fileURL = URL.createObjectURL(file);

              setReportPdf(fileURL);
            } else {
              setOpenPrintModal(true);
              setReportPdf("");
            }
          });
        } else if (response.status !== 200) {
          setOpenBackdrop(false);
        }
      })
      .catch((error) => {
        setOpenBackdrop(false);
        errorAlert(error.message);
      });
  }

  const renderImage = () => {
    return <img src={printImage} />;
  };

  useEffect(() => {
    if (selected === "radiology" && dataResult?.length > 0) {
      let imagesArray = [];
      for (let row of dataResult) {
        radiologyWorkOrderPrint(row?.OrderDtlsId)
          .then((response) => response.data)
          .then((res) => {
            let image =
              row?.["Test Name"] === res.result.TestName &&
              res.result?.Radiologist1Sign !== ""
                ? "data:image/png;base64," + res.result?.Radiologist1Sign
                : "";
            imagesArray.push({ testName: res.result.TestName, base64: image });
            setImages(imagesArray);
          });
      }
    }
  }, [dataResult]);

  console.log("images123", images);

  const renderPrint = () => {
    setTimeout(() => {
      setOpenPrintModal(false);
      // setSelectedPatient(null);
    }, 1000);
    return (
      <div className="hidden">
        <RadiologyWorkOrderPrint
          renderImage={renderImage}
          printImage={printImage}
          dataResult={printDetails}
          selectedRow={selectedRow}
          setOpenBackdrop={setOpenBackdrop}
          isPrint={isPrint}
          setIsPrint={setIsPrint}
          images={images}
          selectedRowIndex={selectedRowIndex}
          setSelectedRowIndex={setSelectedRowIndex}
          isWhatsApp={false}
          isEmail={false}
        />
      </div>
    );
  };

  function getRadiologyPrint(row) {
    radiologyWorkOrderPrint(row?.OrderDtlsId)
      .then((response) => response.data)
      .then((res) => {
        setPrintDetails(res.result);
        // setPrintImage("data:image/png;base64," + res.result?.Radiologist1Sign);
        setIsPrint(true);
        setOpenPrintModal(true);
        setOpenBackdrop(false);
      })
      .catch((error) => {
        setOpenBackdrop(false);
      });
  }

  function ipdCheckListPrint() {
    getIpdFileCheckListPrint(props?.selectedPatient?.AdmissionId).then(
      (response) => {
        setIpdCheckListData(response.data.result);
        setOpenIpdCheckListPrint(true);
      }
    );
  }

  const renderIpdCheckListPrint = () => {
    setTimeout(() => {
      setOpenIpdCheckListPrint(false);
    }, 500);

    return (
      <div className="hidden">
        <IpdFileCheckListPrint ipdCheckListData={ipdCheckListData} />
      </div>
    );
  };

  return (
    <div>
      <Modal
        open={props.openLabModal}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] w-[80%]">
          <div className="flex  w-full ">
            <CancelPresentationIconButton
              onClick={() => {
                props.handleCloseLabModal();
              }}
            />
          </div>
          <h1 className="font-semibold text-center">Investigation</h1>

          <div className="bg-gray-100 px-2 mx-3 rounded-md  border border-gray-300 my-2">
            <div className="grid grid-cols-2 lg:grid-cols-3 text-black  text-sm items-center w-full gap-1">
              <div className="flex  items-center  gap-2 w-full font-semibold text-sm">
                <span className="w-24 font-semibold"> Patient Name</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="font-normal">
                    {props.displayInfo?.PatientName ||
                      props.selectedPatient?.PatientName}
                  </span>
                </div>
              </div>
              <div className="flex space-x-12 items-center  gap-2 w-full font-semibold text-sm py-1">
                <span className="font-semibold"> MR No.</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className=" font-normal">
                    {props.displayInfo?.MRNo || props.selectedPatient?.MRNo}
                  </span>
                </div>
              </div>

              <div className="flex  items-center  gap-2 w-full font-semibold text-sm py-1">
                <span className="w-16 font-semibold"> Age</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="font-normal">
                    {props.displayInfo?.AgeYear ||
                      props.selectedPatient?.AgeYear}
                  </span>
                </div>
              </div>

              <div className="flex  items-center  gap-2 w-full font-semibold text-sm py-1">
                <span className="w-24 font-semibold">Department</span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="font-normal">
                    {props.displayInfo?.Department ||
                      props.selectedPatient?.Department}
                  </span>
                </div>
              </div>

              <div className="flex space-x-1 items-center  gap-2 w-full font-semibold text-sm py-1">
                <span className="font-semibold whitespace-nowrap">
                  Doctore Name
                </span>
                <div className="flex space-x-2 items-center">
                  <span>:</span>
                  <span className="font-normal">
                    {props.displayInfo?.DoctorName ||
                      props.selectedPatient?.DoctorName}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="grid justify-center">
              <ThemeProvider theme={theme}>
                <ToggleButtonGroup
                  value={selected}
                  exclusive
                  onChange={handleChanger}
                  sx={{ marginX: "4px" }}
                >
                  <ToggleButton
                    sx={{ height: "30px" }}
                    selectedColor="#073763"
                    checked={selected === "pathology"}
                    value="pathology"
                  >
                    Pathology
                  </ToggleButton>
                  <ToggleButton
                    sx={{ height: "30px" }}
                    selectedColor="#073763"
                    checked={selected === "radiology"}
                    value="radiology"
                  >
                    Radiology
                  </ToggleButton>
                  <ToggleButton
                    sx={{ height: "30px" }}
                    selectedColor="#073763"
                    checked={selected === "physiotherapy"}
                    value="physiotherapy"
                  >
                    Physiotherapy
                  </ToggleButton>
                </ToggleButtonGroup>
              </ThemeProvider>
            </div>

            <div className="flex justify-end space-x-3">
              <button
                title="IPD Check List"
                onClick={() => {
                  ipdCheckListPrint();
                }}
              >
                <PrintOnIcon />
              </button>
              {(selected === "pathology" &&
                userActions?.some(
                  (action) => action?.action === "Create Path WO"
                )) ||
              (selected === "radiology" &&
                userActions?.some(
                  (action) => action?.action === "Create Radio WO"
                )) ||
              (selected === "physiotherapy" &&
                userActions?.some(
                  (action) => action?.action === "Create Physio WO"
                )) ? (
                <CommonButton
                  type="button"
                  label="Create WO"
                  className="bg-customBlue text-white"
                  onClick={() => {
                    setOpenWOModal(true);
                    // if (selected === "pathology") {
                    //   navigate("/investigations/pathologyWorkOrderGeneration", {
                    //     state: { PatientId: props?.PatientId, menuId: 328 },
                    //   });
                    // } else if (selected === "radiology") {
                    //   navigate("/investigations/radiologyWorkOrderGeneration", {
                    //     state: { PatientId: props?.PatientId, menuId: 334 },
                    //   });
                    // } else if (selected === "physiotherapy") {
                    //   navigate(
                    //     "/investigations/PhysiotheropyWorkOrderGeneration",
                    //     {
                    //       state: { PatientId: props?.PatientId, menuId: 338 },
                    //     }
                    //   );
                    // }
                  }}
                />
              ) : null}
            </div>

            {spinner ? (
              <div className="grid justify-center">
                <LoadingSpinner />
              </div>
            ) : (
              <>
                {dataResult !== undefined &&
                dataResult?.length > 0 &&
                spinner === false ? (
                  <CommonDynamicTableNew
                    dataResult={dataResult}
                    highlightRow={false}
                    removeHeaders={[
                      "id",
                      "Quantity",
                      "TechnitionName",
                      "OutSourceSampleReceivedBy",
                      "IsSampleCollected",
                      "IsCompleted",
                      "IsDelivered",
                      "IsPrinted",
                      "First Level",
                      "Second Level",
                      "Third Level",
                      "HasThreeLevels",
                      "IsCancelled",
                      "isPackageService",
                      "IsPackage",
                      "SampleCollectionNO",
                      "ExtAgencyId",
                      "ChargeId",
                      "IsOutSourced",
                      "isconsent",
                      "IsHistopathTest",
                      "ReportId",
                      "OpdIpd",
                      "IsMicro",
                      "IsProcedureEnd",
                      "IsProcedureStart",
                      "FirstLevel",
                      "SecondLevel",
                      "ThirdLevel",
                      "OrderId",
                      "ClinicalNote",
                      "PackageTestId",
                      "OrderDtlsId",
                      "HasWordPrecessor",
                      "HasTemplate",
                      "IsCultureSenTest",
                      "SampleNo",
                      "Id",
                      "Description",
                      "IsPathologyAuthority",
                      "PackageTestName",
                      "site",
                      "IsNoteExist",
                      "TariffServiceId",
                      "Test Code",
                      "Test Cost",
                      "Test Category",
                      "First Level",
                      "Second Level",
                      "Third Level",
                      "IsCompleted",
                      "IsDelivered",
                      "IsPrinted",
                      "HasWordProcessor",
                      "RadiologistId",
                      "FlimId",
                      "NoOfFlims",
                      "MicrobiologistId",
                      "Radiologist1Id",
                      "Radiologist2Id",
                      "ThirdLevelDescription",
                      "TemplateId",
                      "FirstLevelId",
                      "SecondLevelId",
                      "ThirdLevelId",
                      "Number",
                      "IsOutSourced",
                      "AgencyId",
                      "PrintTestName",
                      "isProcedureStart",
                      "IsProcedureEnd",
                      "Opd_Ipd_External",
                      "Opd_Ipd_External_Id",
                      "OrderId",
                      "NonPathTestId",
                      "IsApproved",
                      "ChargeId",
                      "Id",
                      "TariffServiceId",
                      "OrderDtlsId",
                      "DoctorId",
                      "FirstLevel",
                      "SecondLevel",
                      "ThirdLevel",
                      "NoOfContrast",
                      "CriticalFinding",
                      "TimeOfInform",
                      "GenderId",
                      "Gender",
                      "AbnormalId",
                      "Abnormal",
                      "FirstLevelDescription",
                      "SecondLevelDescription",
                      "IsAnc",
                      "PatientId",
                      "filepath",
                      "ishideorder",
                    ]}
                    renderActions={
                      selected === "pathology"
                        ? pathRenderActions
                        : selected === "radiology"
                        ? radioRenderActions
                        : physioRenderActions
                    }
                    tableClass={"h-48"}
                  />
                ) : (
                  <h1 className="flex justify-center my-5 font-bold text-gray-600">
                    No Records Found...
                  </h1>
                )}
              </>
            )}
          </div>
        </Box>
      </Modal>
      <ReportDetailsModal
        openReportDetailsModal={openReportDetailsModal}
        setOpenReportDetailsModal={setOpenReportDetailsModal}
        patientInfo={props.displayInfo || props.selectedPatient}
        checkedArr={[selectedRow?.OrderDtlsId]}
        isView={isView}
        setIsView={setIsView}
        selectedTestId={selectedRow}
        pathSelectedTest={pathSelectedTest}
        setPathSelectedTest={setPathSelectedTest}
        authorizationArr={authorizationArr}
      />
      <RadioWorkOrderViewModal
        open={openRadiologyModal}
        setOpen={setOpenRadiologyModal}
        selectedTestId={selectedRow}
        patientInfo={props.selectedPatient}
        isView={isView}
        setIsView={setIsView}
      />
      <PhysioNoteModal
        selectedPhysioTest={selectedPhysioTest}
        setSelectedPhysioTest={setSelectedPhysioTest}
        viewNoteDetails={viewNoteDetails}
        setViewNoteDetails={setViewNoteDetails}
        openNoteModal={openNoteModal}
        setOpenNoteModal={setOpenNoteModal}
      />
      {selected === "pathology" ? (
        <>
          {openPrintModal ? (
            <Modal
              open={openPrintModal}
              onClose={() => setOpenPrintModal(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  marginX: "auto",
                  position: "absolute",
                  top: "1%",
                  left: "1%",
                  backgroundColor: "white",
                  width: "97%",
                  height: "93%",
                }}
              >
                <div className=" bg-white font-bold flex justify-between px-4">
                  <p className="text-lg">Report Details</p>
                  <Button
                    onClick={() => setOpenPrintModal(false)}
                    sx={{ color: "black", fontWeight: "bold" }}
                  >
                    Close
                  </Button>
                </div>

                {reportPdf !== "" ? (
                  <embed
                    src={reportPdf}
                    frameBorder="0"
                    width="100%"
                    height="100%"
                  />
                ) : (
                  <div>
                    <p>Report is Not Available</p>
                  </div>
                )}
              </Box>
            </Modal>
          ) : null}
        </>
      ) : (
        <> {openPrintModal && renderPrint()}</>
      )}
      {openIpdCheckListPrint && renderIpdCheckListPrint()}
      <Modal open={openWOModal}>
        <Box sx={ModalStyle} className="h-[90%] w-[90%]">
          <div className="flex  w-full ">
            <CancelPresentationIconButton
              onClick={() => {
                setOpenWOModal(false);
              }}
            />
          </div>
          <div className="-mt-6">
            {selected === "pathology" ? (
              <PathWorkOrderGeneration
                PatientId={props?.PatientId}
                menuId={328}
              />
            ) : selected === "radiology" ? (
              <RadioWorkOrderGeneration
                PatientId={props?.PatientId}
                menuId={334}
              />
            ) : selected === "physiotherapy" ? (
              <PhysioWorkOrderGeneration
                PatientId={props?.PatientId}
                menuId={338}
              />
            ) : null}
          </div>
        </Box>
      </Modal>
      <CommonBackDrop openBackdrop={openBackdrop} />
    </div>
  );
}

