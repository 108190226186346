import {
  Modal,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  addDays,
  differenceInMilliseconds,
  format,
  isAfter,
  isPast,
} from "date-fns";
import React from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    background: "#F1F1F1",
    position: "sticky",
    top: "0px",
    left: "0px",
    zIndex: 50,
  },
}));
const StyledTableCellOne = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    background: "#F1F1F1",
    position: "sticky",
    top: "0px",
    left: "104px",
    zIndex: 50,
  },
}));

const ViewAllModal = (props) => {
  const Style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "80%",
    overflow: "scroll",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    border: "1px solid gray",
  };
  // const targetTime = new Date('2024-05-09T14:30:00');

  const targetTime = new Date(`${format(new Date(), "yyyy-MM-dd")}T07:59:59`);

  const hasPassed = isPast(targetTime);

  const defaultValues = {
    fromDate: hasPassed ? new Date() : addDays(new Date(), -1),
    toDate: !hasPassed ? new Date() : addDays(new Date(), 1),
    // fromTime: dayjs('2022-04-17T15:30'),
    toTime: new Date(),
  };

  const {
    formState: { errors },
    control,
    watch,
    setValue,
    register,
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  props.setFromDate(watch("fromDate"));
  props.setToDate(watch("toDate"));

  const intakeOutput = props.intakeOuttakeKeys.intake;
  const outtakeOutput = props.intakeOuttakeKeys.outtake;
  const intakeOuttakeTotal = props.intakeOuttakeKeys.intakeOuttakeTotal;

  const intakeKeys =
    intakeOutput?.length > 0 ? Object.keys(intakeOutput[0]) : [];

  const outtakeKeys =
    outtakeOutput?.length > 0 ? Object.keys(outtakeOutput[0]) : [];

  return (
    <div>
      <div className="w-full grid justify-center items-center rounded lg:px-0">
        <Modal
          open={props.open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={Style} className="h-[80%]  p-4 ">
            <CancelPresentationIconButton
              onClick={() => {
                props.handleClose();
              }}
            />
            <div className="row">
              <h1 className="absolute top-3 text-lg font-semibold whitespace-nowrap">
                ViewAll
              </h1>

              <div>
                <div className="mt-2 bg-white ">
                  <div className="grid grid-cols-2 pt-7">
                    <div className="flex gap-4">
                      <DatePickerFieldNew
                        control={control}
                        name="fromDate"
                        label="From Date"
                        value={new Date()}
                        disablePast={false}
                        disableFuture={false}
                        inputFormat="dd-MM-yyyy"
                      />

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopTimePicker
                          label="From Time"
                          name="fromTime"
                          value={props.fromTime}
                          onChange={(newValue) => {
                            props.setFromTime(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              className="bg-white"
                              fullWidth
                              size="small"
                              {...params}
                              InputLabelProps={{
                                style: {
                                  fontSize: "14px",
                                  position: "absolute",
                                  top: "-2px",
                                },
                              }}
                              sx={{
                                svg: {
                                  color: "#1E3A8A",
                                  height: 22,
                                  width: "100%",
                                  marginRight: "16px",
                                },
                                backgroundColor: "white",
                                overflow: "visible",

                                "& .MuiOutlinedInput-root": {
                                  "& .MuiOutlinedInput-input": {
                                    // border: 0,
                                    fontSize: 14,
                                    height: "18px",
                                    width: "100%",

                                    borderColor: "#ffffff",
                                    overflow: "hidden",
                                  },
                                  "& .MuiFormLabel-root": {
                                    fontSize: "14px",
                                  },
                                },
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                      <p className="font-bold">To</p>
                      <DatePickerFieldNew
                        control={control}
                        name="toDate"
                        label="To Date"
                        value={new Date()}
                        disablePast={false}
                        disableFuture={false}
                        inputFormat="dd-MM-yyyy"
                      />
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopTimePicker
                          label="To Time"
                          name="toTime"
                          value={props.toTime}
                          onChange={(newValue) => {
                            props.setToTime(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              className="bg-white"
                              fullWidth
                              size="small"
                              {...params}
                              InputLabelProps={{
                                style: {
                                  fontSize: "14px",
                                  position: "absolute",
                                  top: "-2px",
                                },
                              }}
                              sx={{
                                svg: {
                                  color: "#1E3A8A",
                                  height: 22,
                                  width: "100%",
                                  marginRight: "16px",
                                },
                                backgroundColor: "white",
                                overflow: "visible",

                                "& .MuiOutlinedInput-root": {
                                  "& .MuiOutlinedInput-input": {
                                    // border: 0,
                                    fontSize: 14,
                                    height: "18px",
                                    width: "100%",

                                    borderColor: "  ",
                                    overflow: "hidden",
                                  },
                                  "& .MuiFormLabel-root": {
                                    fontSize: "14px",
                                  },
                                },
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>

                  <Box sx={{ width: "100%", overflow: "hidden" }}>
                    <Paper sx={{ width: "100%", my: 2, display: "flex" }}>
                      <TableContainer
                        sx={{
                          "&::-webkit-scrollbar": {
                            width: 7,
                            height: 10,
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "white",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "lightBlue",
                            borderRadius: 1,
                          },
                        }}
                        className="rounded border-gray-300 h-80 py-2"
                      >
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{
                                  background: "#F1F1F1",
                                  position: "sticky",
                                  top: "0px",
                                  left: "0px",
                                  zIndex: 50,
                                  width: "10%",
                                  border: 0,
                                  overflow: "hidden",
                                }}
                                className=""
                              ></TableCell>
                              <TableCell
                                sx={{
                                  background: "#F1F1F1",
                                  position: "sticky",
                                  top: "0px",
                                  left: "102px",
                                  zIndex: 50,
                                  width: "10%",
                                  border: 0,
                                  overflow: "hidden",
                                }}
                                className=""
                              ></TableCell>
                              <TableCell
                                sx={{ background: "red", color: "white" }}
                                colSpan={intakeKeys.length}
                              >
                                Input
                              </TableCell>
                              <TableCell
                                sx={{ background: "blue", color: "white" }}
                                colSpan={outtakeKeys.length}
                              >
                                Output
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                sx={{
                                  background: "#F1F1F1",
                                  position: "sticky",
                                  top: "0px",
                                  left: "0px",
                                  zIndex: 50,
                                  overflow: "hidden",
                                }}
                                className=" "
                              >
                                Date
                              </TableCell>
                              <TableCell
                                sx={{
                                  background: "#F1F1F1",
                                  position: "sticky",
                                  top: "0px",
                                  left: "104px",
                                  zIndex: 50,
                                  overflow: "hidden",
                                }}
                                className=" "
                              >
                                Time
                              </TableCell>
                              {intakeKeys.map((key) => (
                                <TableCell
                                  sx={{ whiteSpace: "nowrap" }}
                                  key={key}
                                >
                                  {key}
                                </TableCell>
                              ))}
                              {outtakeKeys.map((key) => (
                                <TableCell
                                  sx={{ whiteSpace: "nowrap" }}
                                  key={key}
                                >
                                  {key}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {intakeOutput?.map((data, index) => (
                              <TableRow key={index}>
                                <StyledTableCell sx={{ whiteSpace: "nowrap" }}>
                                  {data.date}
                                </StyledTableCell>
                                <StyledTableCellOne
                                  sx={{ whiteSpace: "nowrap" }}
                                >
                                  {data.time}
                                </StyledTableCellOne>
                                {intakeKeys.map((key) => (
                                  <TableCell
                                    sx={{ whiteSpace: "nowrap" }}
                                    key={key}
                                  >
                                    {data[key]}
                                  </TableCell>
                                ))}
                                {outtakeKeys?.map((key) => (
                                  <TableCell
                                    sx={{ whiteSpace: "nowrap" }}
                                    key={key}
                                  >
                                    {
                                      props.intakeOuttakeKeys.outtake[index][
                                        key
                                      ]
                                    }
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                        {intakeOutput?.length > 0 ? null : (
                          <div className="flex justify-center text-gray-400 font-semibold mt-9">
                            No Records Found ...
                          </div>
                        )}
                      </TableContainer>
                    </Paper>
                  </Box>
                </div>
              </div>

              <div className="grid md:grid-cols-[8%_1%_18%_8%_1%_18%_8%_1%_18%] lg:grid-cols-[8%_1%_18%_8%_1%_18%_8%_1%_18%] xl:grid-cols-[8%_1%_18%_8%_1%_18%_8%_1%_18%] 2xl:grid-cols-[8%_1%_18%_8%_1%_18%_8%_1%_18%] text-sm text-black whitespace-nowrap">
                <div className="font-semibold">Intake Total</div>
                <div className="font-semibold">:</div>
                <div className="text-gray-700 font-normal">
                  {intakeOuttakeTotal?.["Intake Total"]}
                </div>
                <div className="font-semibold">OutPut Total</div>
                <div className="font-semibold">:</div>
                <div className="text-gray-700 font-normal">
                  {intakeOuttakeTotal?.["Outtake Total"]}
                </div>
                <div className="font-semibold">Difference</div>
                <div className="font-semibold">:</div>
                <div className="text-gray-700 font-normal">
                  {intakeOuttakeTotal?.["Difference"]}
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default ViewAllModal;
