import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { format, isAfter } from "date-fns";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import RadioField from "../../../common/components/FormFields/RadioField";
import SearchBar from "../../../common/components/FormFields/SearchBar";
//import IPIssuePrint from "../../../prints/IPIssuePrint";
import { PrintOnIcon } from "../../../assets/icons/CustomIcons";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import LoadingSpinner from "../../../common/components/loadingspinner/loadingSpinner";
import CommonPrintModal from "../../../common/components/printModal/CommonPrintModal";
import { fetchFloor } from "../../../commonServices/miscellaneousServices/MiscServices";
import {
  approveInPatientReturn,
  fetchInPatientReturnDetailsList,
  fetchInPatientReturnListForApproval,
  fetchInPatientReturnListSearch,
} from "../../../inventory/services/pharmacy/inPatientReturnServices/InPatientReturnServices";
import { fetchPrintInPatientReturn } from "../../services/inPatientReturnServices/InPatientReturnServices";
import NumberOfCopiesModal from "../../../common/components/printModal/NumberOfCopiesModal";
import { blobToBase64 } from "../../../common/components/printModal/BlobToB64";
import { routeToPrinterDevice } from "../../../commonServices/barCodeServices/BarCodeServices";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "97%",
  minHeight: "90%",
  maxHeight: "80%",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  py: 4,
  px: 2,
};

const IssuedStatus = [
  {
    id: 2,
    value: "All",
    label: "All",
  },
  {
    id: 1,
    value: "Approved",
    label: "Approved",
  },
  {
    id: 0,
    value: "Pending",
    label: "Pending",
  },
];
const ReturnTypeArr = [
  {
    id: 0,
    value: "Cash",
    label: "Cash",
  },
  {
    id: 1,
    value: "Credit",
    label: "Credit",
  },
];

export default function InPatientReturnListModal(props) {
  const token = JSON.parse(localStorage.getItem("userInfo"));
  let defaultPrinters = JSON.parse(localStorage.getItem("defaultPrinters"));

  const { open, handleClose, userActions, menuId } = props;

  const [privilege, setPrivilege] = useState("");
  const [openNumberOfCopiesModal, setOpenNumberOfCopiesModal] = useState(false);
  const [printResponse, setPrintResponse] = useState(null);

  //ipd issue details table
  const [ipdIssueTableData, setIpdIssueTableData] = React.useState([]);
  const [floors, setFloors] = useState([]);
  const [floorId, setFloorId] = useState({ id: token.floorId } || null);

  // ipd issue table
  const [ipdListData, setIpdListData] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState();

  //item
  const [admissionId, setAdmissionId] = React.useState(0);
  // show patient data
  const [patientListData, setPatientListData] = React.useState();
  //totaAmount
  const [totaAmount, setTotalAmount] = React.useState(0);
  const [searchString, setSearchString] = React.useState(0);

  //prints
  const [urlforPrint, setUrlforPrint] = useState();
  const [openPrintModal, setOpenPrintModal] = React.useState(false);
  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };

  // Actions Confirmation
  //confirmation modal
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  ///related to table
  const [spinner1, showSpinner1] = useState(false);
  const [spinner2, showSpinner2] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(25);
  const [count, setCount] = useState(null);
  ///
  ///

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(),
    defaultValues: {
      issuedStatus: 0,
      fromDate: new Date(),
      toDate: new Date(),
      floor: { id: token.floorId, label: token?.floor, value: token?.floor },
      //
      returnType: 1,
    },
  });
  const { control, register, watch } = methods;
  //
  useEffect(() => {
    fetchFloor()
      .then((response) => {
        setFloors(response.data.result);
      })
      .catch(() => {});
  }, []);
  ///watch
  var issuedStatusRadio = watch("issuedStatus");
  let FromDate = watch("fromDate");
  let ToDate = watch("toDate");

  let selected_returnType = watch("returnType");

  useEffect(() => {
    if (
      isAfter(FromDate, new Date(1900, 1, 1)) &&
      isAfter(ToDate, new Date(1900, 1, 1))
    ) {
      searchListByPatientData();
    }
  }, [
    FromDate,
    ToDate,
    searchString,
    issuedStatusRadio,
    floors,
    selected_returnType,
  ]);

  //list by patient data
  const searchListByPatientData = (e) => {
    const searchObj = {
      isPending: Number(issuedStatusRadio),
      searchString: e,
      fromDate: format(FromDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      toDate: format(ToDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      floor: floorId?.id || token.floorId,
      //
    };

    setSearchString(e);
    fetchInPatientReturnListSearch(searchObj)
      .then((response) => response.data)
      .then((res) => {
        setPatientListData(res.result);
      });
  };
  useEffect(() => {
    if (
      isAfter(FromDate, new Date(1900, 1, 1)) &&
      isAfter(ToDate, new Date(1900, 1, 1))
    ) {
      populateTable();
    }
  }, [FromDate, ToDate, admissionId, issuedStatusRadio, floorId]);

  const populateTable = (forPagination) => {
    showSpinner1(true);
    const returnListObj = {
      searchString: searchString,
      admissionId: admissionId,
      fromDate: format(FromDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      toDate: format(ToDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
      isPending: Number(issuedStatusRadio),
      floor: floorId?.id || token.floorId,
      cashCredit: Number(selected_returnType),
    };

    fetchInPatientReturnListForApproval(returnListObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setIpdListData((prevData) => [...prevData, ...res.result]);
          setIpdIssueTableData([]);
          setSelectedRow(null);
        } else {
          setIpdListData(res.result);
          setPage(0);
          setIpdIssueTableData([]);
          setSelectedRow(null);
        }
        setCount(res.count);
        showSpinner1(false);
      })
      .catch(() => {
        showSpinner1(false);
      });
  };

  useEffect(() => {
    let totalQuantity = ipdListData.reduce(
      (total, item) =>
        Number(total) + Number(item["Total Amount Payable"] || 0),
      0
    );

    setTotalAmount(totalQuantity);
  }, [ipdListData]);

  //
  const onChangePatientData = (e) => {
    if (e === "" || e === null) {
      setAdmissionId(0);
      setSearchString("");
    } else if (e !== "" || e !== null) {
      setAdmissionId(e.id);
      setSearchString(e.MRNo);
    }
  };

  //ipd issue details table data
  useEffect(() => {
    console.log("selectedRow", selectedRow);

    if (selectedRow) {
      showSpinner2(true);
      console.log("selectedRowaaaaa", selectedRow, selectedRow.IPDReturnId);

      fetchInPatientReturnDetailsList(
        selectedRow.IPDReturnId,
        Number(selected_returnType)
      )
        .then((response) => response.data)
        .then((res) => {
          setIpdIssueTableData(res.result);
          showSpinner2(false);
        })
        .catch(() => {
          showSpinner2(false);
        });
    }
  }, [selectedRow, ipdListData]);

  //////print

  const handleOpenPrintModal = (row) => {
    console.log("row", row, row.IPDIssueId);

    fetchPrintInPatientReturn(row.IPDReturnId, row.IPDIssueId).then(
      (response) => {
        if (defaultPrinters) {
          setOpenNumberOfCopiesModal(true);
          setPrintResponse(response);
        } else {
          setOpenPrintModal(true);
          setUrlforPrint(response);
        }
      }
    );
  };

  const handleSelectedRow = (row) => {
    setSelectedRow(row);
  };

  ////////table related
  const handleAccept = () => {
    const approveObj = {
      ipdReturnId: selectedRow?.IPDReturnId,
      menuId: menuId,
      privilege: privilege,
    };

    approveInPatientReturn(approveObj)
      .then((response) => response.data)
      .then((res) => {
        setOpenConfirmationModal(false);
        populateTable();
      });
  };

  const renderActions = (row, index) => {
    return (
      <div className="flex gap-3">
        <PrintOnIcon
          title={"Print"}
          onClick={() => {
            handleOpenPrintModal(row);
            setSelectedRow(row);
          }}
        />
        {/*userActions.map((actions, i) => (
          <>
            {actions.isAction !== true ? (
              <>
                <div className="flex gap-2 cursor-pointer">
                  <PrintOnIcon
                    title={"Print"}
                    onClick={() => {
                      handleOpenPrintModal(row);
                      setSelectedRow(row);
                    }}
                  />
                  {actions.action === "Print" ? (
                    <Tooltip title={"Print"}>
                      <PrintOnIcon
                        sx={{ color: "#4B5563" }}
                        onClick={() => {
                          handleOpenPrintModal(row);
                          setSelectedRow(row);
                        }}
                      />
                    </Tooltip>
                  ) : null}
                  {actions.action === "Approve" &&
                    (row.IsApproved === true ? (
                      <div className="text-gray-300">
                        <TaskAltOutlinedIcon />
                      </div>
                    ) : (
                      <div className="text-customGreen">
                        <Tooltip title="Approve">
                          <TaskAltOutlinedIcon
                            onClick={() => {
                              setOpenConfirmationModal(true);
                              setSelectedRow(row);
                              setPrivilege(actions.action);
                            }}
                          />
                        </Tooltip>
                      </div>
                    ))}
                </div>
              </>
            ) : null}
          </>
                          ))*/}
      </div>
    );
  };

  //   const renderPrint = (component) => {
  //     setTimeout(() => {
  //       setOpenPrintModal(false);
  //     }, 1000);

  //     return (
  //       <div className="hidden">
  //         <IPIssuePrint
  //           selectedFromDate={FromDate}
  //           selectedToDate={ToDate}
  //           ipdIssueId={selectedRow.IPDIssueId}
  //           Storeld={storeId}
  //         />
  //       </div>
  //     );
  //   };

  const handleDirectPrint = async (data) => {
    let b64 = await blobToBase64(printResponse);

    const obj = [
      {
        printerName: defaultPrinters?.defaultPrinter,
        pdf: b64,
        numberCopies: data.numberOfCopies,
        pageSize: "A5",
      },
    ];

    await routeToPrinterDevice(obj).then(() => {
      setPrintResponse(null);
    });
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
              //   setEdit(false);
              //   reset(defaultValues);
            }}
          />
          <div className=" flex flex-wrap p-2 rounded text-lg  text-black font-semibold">
            Ipd Return List
          </div>
          {/* filters */}
          <div className="grid gap-2 w-full items-center mt-2">
            <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 w-full gap-2 items-center">
              <div className="">
                <SearchBar
                  name="SearchableSelect"
                  placeholder="Search by Patient Name / Mr No."
                  dataArray={patientListData}
                  isSearchable={true}
                  isClearable={true}
                  searchIcon={true}
                  handleInputChange={searchListByPatientData} //searchString
                  onChange={(e) => {
                    onChangePatientData(e);
                  }} // serachId
                  inputRef={{
                    ...register("SearchableSelect", {
                      onChange: (e) => {
                        if (e !== null) {
                          setAdmissionId(e.id);
                        } else {
                          setAdmissionId(0);
                        }
                      },
                    }),
                  }}
                />
              </div>

              <div className="flex space-x-2">
                {/* from Date */}
                <div className="">
                  <DatePickerFieldNew
                    control={control}
                    name="fromDate"
                    label="From Date"
                    value={new Date()}
                    disableFuture={true}
                    disablePast={false}
                    inputFormat="dd-MM-yyyy"
                  />
                </div>

                {/* to Date */}
                <div className="">
                  <DatePickerFieldNew
                    control={control}
                    name="toDate"
                    label="To Date"
                    value={new Date()}
                    disableFuture={true}
                    disablePast={false}
                    inputFormat="dd-MM-yyyy"
                  />
                </div>
              </div>
              <div className="">
                <DropdownField
                  control={control}
                  name="floor"
                  placeholder="Floor"
                  dataArray={floors}
                  isSearchable={false}
                  isClearable={true}
                  inputRef={{
                    ...register("floor", {
                      onChange: (e) => {
                        setFloorId(e.target.value);
                      },
                    }),
                  }}
                />
              </div>

              <div className="">
                <RadioField
                  name="issuedStatus"
                  control={control}
                  dataArray={IssuedStatus}
                />
              </div>

              <div className="flex space-x-2 items-center">
                <span className="text-sm font-semibold">Type :&nbsp;</span>
                <fieldset className="mt-1.5">
                  <RadioField
                    name="returnType"
                    control={control}
                    dataArray={ReturnTypeArr}
                  />
                </fieldset>
              </div>
            </div>
          </div>
          {/* tables */}
          <div className="mt-2">
            <div>
              <span className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
                IPD Return List
              </span>
            </div>
            {spinner1 ? (
              <div className="grid justify-center">
                <LoadingSpinner />
              </div>
            ) : ipdListData.length > 0 ? (
              <>
                <div>
                  <CommonDynamicTablePaginationNew
                    dataResult={ipdListData}
                    populateTable={populateTable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    count={count}
                    renderActions={renderActions}
                    handleSelectedRow={handleSelectedRow}
                    tableClass={"rounded lg:h-52 md:h-72 xl:h-80"}
                    removeHeaders={[
                      "AdmNo",
                      "GenderId",
                      "IPDIssueId",
                      "IndentId",
                      "LoginName",
                      "UserName",
                      "TotalVATAmt",
                    ]}
                  />
                </div>
              </>
            ) : (
              <div className="grid justify-center">Data Not Found</div>
            )}

            <div className="flex justify-between items-center">
              {ipdIssueTableData.length > 0 ? (
                <div className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
                  IPD Return Details
                </div>
              ) : null}

              <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1 justify-end">
                <span className=" text-black font-semibold whitespace-nowrap w-28">
                  Total Amount
                </span>
                <div className="flex space-x-2 items-center">
                  <span>: ₹</span>
                  <span className="text-gray-700 font-normal">
                    {totaAmount.toFixed(2)}
                  </span>
                </div>
              </div>
            </div>

            <div className="">
              {spinner2 ? (
                <div className="grid justify-center">
                  <LoadingSpinner />
                </div>
              ) : ipdIssueTableData.length > 0 ? (
                <CommonDynamicTableNew
                  highlightRow={false}
                  dataResult={ipdIssueTableData}
                  removeHeaders={[
                    "Expr1",
                    "IPDIssueDetailId",
                    "IPDIssueId",
                    "PurchaseRate",
                    "TaxAmount",
                    "TotalForVAT",
                    "VATAmount",
                    "VATInclusive",
                    "VATPercentage",
                  ]}
                  tableClass={"rounded lg:h-52 md:h-72 xl:h-44"}
                />
              ) : null}
            </div>
          </div>
          {/* buttons */}
          <div className="flex gap-3 col-span-3 lg:col-span-1 justify-end ">
            <div>
              <CommonButton
                onClick={handleClose}
                label="Close"
                className="border border-customRed text-customRed"
              />
            </div>
          </div>
        </Box>
      </Modal>
      {/* backdrop 
      <CommonBackDrop openBackdrop={openBackdrop} />
      */}

      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => setOpenConfirmationModal(false)}
        confirmationSubmitFunc={() => handleAccept()}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
      {openNumberOfCopiesModal ? (
        <NumberOfCopiesModal
          open={openNumberOfCopiesModal}
          setOpen={setOpenNumberOfCopiesModal}
          handleSave={handleDirectPrint}
          onClose={() => {
            setOpenNumberOfCopiesModal(false);
          }}
        />
      ) : null}

      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}
      {/*openPrintModal && renderPrint()*/}
    </div>
  );
}

