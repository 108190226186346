import { yupResolver } from "@hookform/resolvers/yup";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { format, isAfter } from "date-fns";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../../common/components/Buttons/CommonButton";
import CommonDynamicTableNew from "../../../../../common/components/CommonTable/CommonDynamicTableNew";
import CommonDynamicTablePaginationNew from "../../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import DatePickerFieldNew from "../../../../../common/components/FormFields/DatePickerFieldNew";
import RadioField from "../../../../../common/components/FormFields/RadioField";
import SearchBar from "../../../../../common/components/FormFields/SearchBar";
import { fetchPatientList } from "../../../../../inventory/services/commonServices/CommonServices";
import {
  fetchIpdIssueDetails,
  fetchItemList,
  fetchItemListTableData,
  fetchPrintInPatientIssue,
} from "../../../../../inventory/services/pharmacy/inPatientIssueServices/InPatientIssueServices";
import IPIssuePrint from "../../../../../nursingStation/prints/IPIssuePrint";
import CommonPrintModal from "../../../../../common/components/printModal/CommonPrintModal";
import { blobToBase64 } from "../../../../../common/components/printModal/BlobToB64";
import { routeToPrinterDevice } from "../../../../../commonServices/barCodeServices/BarCodeServices";
import NumberOfCopiesModal from "../../../../../common/components/printModal/NumberOfCopiesModal";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "97%",
  bgcolor: "background.paper",
  // borderRadius: 2,
  overflowY: "auto",
  border: "none",
  "&:focus": {
    outline: "none",
  },
  padding: 2,
};
const IssuedStatus = [
  {
    id: 2,
    value: "All",
    label: "All",
  },
  {
    id: 1,
    value: "Issued",
    label: "Issued",
  },
  {
    id: 0,
    value: "Pending",
    label: "Pending",
  },
];

const Actions = [
  {
    id: 0,
    action: "printIssueList",
    isAction: true,
  },
];

export default function InPatientListModal(props) {
  const { open, handleClose, patientDetails } = props;

  var token = JSON.parse(localStorage.getItem("userInfo"));
  var storeId = token.cashCounterId;
  let defaultPrinters = JSON.parse(localStorage.getItem("defaultPrinters"));

  //
  const [openNumberOfCopiesModal, setOpenNumberOfCopiesModal] = useState(false);
  const [printResponse, setPrintResponse] = useState(null);
  //ipd issue details table
  const [ipdIssueTableData, setIpdIssueTableData] = React.useState([]);
  // ipd issue table
  const [ipdListData, setIpdListData] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState();
  //
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  //item
  const [itemId, setItemId] = React.useState(0);
  const [admissionId, setAdmissionId] = React.useState(0);
  // show patient data
  const [patientData, setPatientData] = React.useState(patientDetails || null);
  const [patientListData, setPatientListData] = React.useState();
  const [itemListData, setItemListData] = React.useState();
  //totaAmount
  const [totaAmount, setTotalAmount] = React.useState(0);
  const [searchString, setSearchString] = React.useState(0);

  //prints
  const [openPrintModal, setOpenPrintModal] = React.useState(false);
  const [urlforPrint, setUrlforPrint] = useState();

  ///related to table
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(25);
  const [count, setCount] = useState(null);
  ///
  ///

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(),
    defaultValues: {
      isUrgent: 0,
      fromDate: new Date(),
      toDate: new Date(),
      issuedStatus: 2,
    },
  });
  const { control, register, watch } = methods;

  ///watch
  var issuedStatusRadio = watch("issuedStatus");

  useEffect(() => {
    isAfter(selectedFromDate || new Date(), new Date(1900, 1, 1)) &&
      isAfter(selectedToDate || new Date(), new Date(1900, 1, 1)) &&
      getPatientInfoAutoComplete();
  }, [selectedFromDate, selectedToDate]);

  const getPatientInfoAutoComplete = (SearchString) => {
    const autoCopmleteObj = {
      fromDate: format(
        selectedFromDate || new Date(),
        "yyyy-MM-dd'T'HH:mm:ss.000'Z'"
      ),
      toDate: format(
        selectedToDate || new Date(),
        "yyyy-MM-dd'T'HH:mm:ss.000'Z'"
      ),
      // fromStoreId: selectedFromStore,
      indentFrom: "InPatientIssue",
      searchString: SearchString || "",
      toStoreId: token.storeId,
    };
  };

  useEffect(() => {
    isAfter(selectedFromDate || new Date(), new Date(1900, 1, 1)) &&
      isAfter(selectedToDate || new Date(), new Date(1900, 1, 1)) &&
      populateTable();
  }, [
    selectedFromDate,
    selectedToDate,
    searchString,
    admissionId,
    itemId,
    storeId,
    issuedStatusRadio,
  ]);

  const populateTable = (forPagination) => {
    var IpdIssuelistObj = {
      searchString: searchString,
      admissionId: admissionId,
      fromDate: format(
        selectedFromDate || new Date(),
        "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
      ),
      itemId: itemId,
      storeId: token.storeId,
      toDate: format(
        selectedToDate || new Date(),
        "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
      ),
      //
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
      issueStatus: Number(issuedStatusRadio),
    };

    fetchItemListTableData(IpdIssuelistObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setIpdListData((prevData) => [...prevData, ...res.result]);
          setIpdIssueTableData([]);
          setSelectedRow(null);
          setPatientData(null);
        } else {
          setIpdListData(res.result);
          setPage(0);
          setIpdIssueTableData([]);
          setSelectedRow(null);
          setPatientData(null);
        }
        // setUserActions(res.actions);
        setCount(res.count);
      });
  };

  useEffect(() => {
    let totalQuantity = ipdListData.reduce(
      (total, item) => Number(total) + Number(item.TotalAmountPayable || 0),
      0
    );

    setTotalAmount(totalQuantity);
  }, [ipdListData]);

  //list by patient data
  const searchListByPatientData = (e) => {
    setSearchString(e);
    fetchPatientList(e)
      .then((response) => response.data)
      .then((res) => {
        setPatientListData(res.result);
      });
  };
  //
  const onChangePatientData = (e) => {
    if (e === "" || e === null) {
      setAdmissionId(0);
      setPatientData();
      setSearchString("");
    } else if (e !== "" || e !== null) {
      setAdmissionId(e.id);
      setPatientData(e);
      setSearchString(e.MRNo);
    }
  };

  //search item list setPatientData
  const searchItem = (e) => {
    fetchItemList(e)
      .then((response) => response.data)
      .then((res) => {
        setItemListData(res.result);
      });
  };

  //ipd issue details table data
  useEffect(() => {
    if (selectedRow) {
      fetchIpdIssueDetails(selectedRow.IPDIssueId)
        .then((response) => response.data)
        .then((res) => {
          setIpdIssueTableData(res.result);
        });
    }
  }, [selectedRow, ipdListData]);

  //////print

  const handleOpenPrintModal = (row) => {
    // print service
    fetchPrintInPatientIssue(row.IPDIssueId, storeId).then((response) => {
      if (defaultPrinters) {
        setOpenNumberOfCopiesModal(true);
        setPrintResponse(response);
      } else {
        setOpenPrintModal(true);

        setUrlforPrint(response);
      }
    });
  };

  const handleDirectPrint = async (data) => {
    let b64 = await blobToBase64(printResponse);

    const obj = [
      {
        printerName: defaultPrinters?.defaultPrinter,
        pdf: b64,
        numberCopies: data.numberOfCopies,
        pageSize: "A5",
      },
    ];

    await routeToPrinterDevice(obj).then(() => {
      setPrintResponse(null);
    });
  };

  const handleSelectedRow = (row) => {
    setSelectedRow(row);
    setPatientData(row);
  };

  ////////table related
  const renderActions = (row, index) => {
    return (
      <div className="flex gap-3">
        {Actions.map((actions, i) => (
          <>
            {actions.isAction === true ? (
              <>
                <div className="flex gap-2 cursor-pointer">
                  {actions.action === "printIssueList" ? (
                    <Tooltip title={"Print"}>
                      <LocalPrintshopIcon
                        sx={{ color: "#4B5563" }}
                        onClick={() => {
                          handleOpenPrintModal(row);
                          //setOpenPrintModal(true);
                          setSelectedRow(row);
                        }}
                      />
                    </Tooltip>
                  ) : null}
                </div>
              </>
            ) : null}
          </>
        ))}
      </div>
    );
  };

  // const renderPrint = (component) => {
  //   // setTimeout(() => {
  //   //   setOpenPrintModal(false);
  //   // }, 1000);

  //   const handleClose = () => {
  //     console.log("setOpenPrintModal(false)");

  //     setOpenPrintModal(false);

  //   };

  //   return (
  //     <div className="hidden">
  //       <IPIssuePrint
  //         selectedFromDate={selectedFromDate}
  //         selectedToDate={selectedToDate}
  //         ipdIssueId={selectedRow.IPDIssueId}
  //         Storeld={storeId}
  //         handleClose={handleClose}

  //       />
  //     </div>
  //   );
  // };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
              //   setEdit(false);
              //   reset(defaultValues);
            }}
          />
          <div className=" flex flex-wrap rounded text-lg  text-black font-semibold">
            Show Previous Issue
          </div>
          {/* filters */}
          <div className="grid gap-2 w-full items-center mt-2">
            <div className="grid grid-cols-3 lg:grid-cols-12 w-full gap-2 items-center">
              <div className="w-full col-span-3 lg:col-span-4">
                <SearchBar
                  name="SearchableSelect"
                  placeholder="Search by Patient Name / Mr No."
                  dataArray={patientListData}
                  isSearchable={true}
                  isClearable={true}
                  searchIcon={true}
                  handleInputChange={searchListByPatientData} //searchString
                  onChange={(e) => {
                    onChangePatientData(e);
                  }} // serachId
                  inputRef={{
                    ...register("SearchableSelect", {
                      onChange: (e) => {
                        if (e !== null) {
                          setAdmissionId(e.id);
                        } else {
                          setAdmissionId(0);
                        }
                      },
                    }),
                  }}
                />
              </div>

              <div className="grid grid-cols-2 col-span-3 gap-3">
                {/* from Date */}
                <div className="md:col-span-2 lg:col-span-1">
                  <DatePickerFieldNew
                    control={control}
                    name="fromDate"
                    label="From Date"
                    value={new Date()}
                    disableFuture={true}
                    disablePast={false}
                    inputFormat="dd-MM-yyyy"
                    inputRef={{
                      ...register("fromDate", {
                        onChange: (e) => {
                          if (e.target.value !== null) {
                            setSelectedFromDate(e.target.value);
                          }
                        },
                      }),
                    }}
                  />
                </div>

                {/* to Date */}
                <div className="md:col-span-2 lg:col-span-1">
                  <DatePickerFieldNew
                    control={control}
                    name="toDate"
                    label="To Date"
                    value={new Date()}
                    disableFuture={true}
                    disablePast={false}
                    inputFormat="dd-MM-yyyy"
                    inputRef={{
                      ...register("toDate", {
                        onChange: (e) => {
                          if (e.target.value !== null) {
                            setSelectedToDate(e.target.value);
                          }
                        },
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="lg:col-span-2 z-50">
                <SearchBar
                  control={control}
                  name="itemList"
                  label="Item "
                  placeholder="Item "
                  searchIcon={true}
                  isSearchable={true}
                  isClearable={true}
                  dataArray={itemListData}
                  handleInputChange={searchItem}
                  onChange={(e) => {
                    if (e === null) {
                      setItemId(0);
                    } else if (e !== null) {
                      setItemId(e.id);
                    }
                  }}
                />
              </div>

              <div className="flex md:justify-start lg:justify-center col-span-1 lg:col-span-3 ">
                <RadioField
                  label=""
                  name="issuedStatus"
                  control={control}
                  dataArray={IssuedStatus}
                />
              </div>
            </div>
          </div>
          {/* patient Info */}
          <div>
            <fieldset
              className="border border-gray-300 w-full
               text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2 mt-2 "
            >
              <div className="py-2 grid  grid-cols-4 lg:grid-cols-3 2xl:grid-cols-4 gap-2 p-3  ">
                <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                  <span className=" font-semibold w-28">Patient Name</span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {patientData &&
                        (patientData.PatientName || patientData.patientName)}
                    </span>
                  </div>
                </div>
                <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                  <span className=" font-semibold w-28">Age</span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {patientData && patientData.AgeYear}
                    </span>
                  </div>
                </div>
                <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                  <span className=" font-semibold w-28">Gender</span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {patientData &&
                        (patientData.Gender || patientData.gender)}
                    </span>
                  </div>
                </div>

                <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                  <span className=" font-semibold w-28">Bed No.</span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {patientData && patientData.BedNo}
                    </span>
                  </div>
                </div>

                <div className="flex items-center gap-2 w-full lg:col-span-2 md:col-span-3 xl:col-span-1 font-semibold text-sm py-1">
                  <span className=" font-semibold w-28">Ward</span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {patientData && patientData.Ward}
                    </span>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          {/* tables */}
          <div className="mt-2">
            <div>
              <span className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
                IPD Issue List
              </span>
            </div>
            {ipdListData.length > 0 ? (
              <>
                <div>
                  <CommonDynamicTablePaginationNew
                    dataResult={ipdListData}
                    populateTable={populateTable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    count={count}
                    renderActions={renderActions}
                    handleSelectedRow={handleSelectedRow}
                    tableClass={"rounded lg:h-52 md:h-72 xl:h-80"}
                    removeHeaders={[
                      "AdmNo",
                      "GenderId",
                      "IPDIssueId",
                      "IndentId",
                      "LoginName",
                      "UserName",
                      "TotalVATAmt",
                    ]}
                  />
                </div>
              </>
            ) : (
              <div className="grid justify-center my-32">Data Not Found</div>
            )}

            <div className="flex justify-between items-center">
              {ipdIssueTableData.length > 0 ? (
                <div className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
                  IPD Issue Details
                </div>
              ) : null}

              <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1 justify-end">
                <span className=" text-black font-semibold whitespace-nowrap w-28">
                  Total Amount
                </span>
                <div className="flex space-x-2 items-center">
                  <span>: ₹</span>
                  <span className="text-gray-700 font-normal">
                    {totaAmount.toFixed(2)}
                  </span>
                </div>
              </div>
            </div>

            <div className="">
              {ipdIssueTableData.length > 0 ? (
                <CommonDynamicTableNew
                  highlightRow={false}
                  dataResult={ipdIssueTableData}
                  removeHeaders={[
                    "Expr1",
                    "IPDIssueDetailId",
                    "IPDIssueId",
                    "PurchaseRate",
                    "TaxAmount",
                    "TotalForVAT",
                    "VATAmount",
                    "VATInclusive",
                    "VATPercentage",
                  ]}
                  tableClass={"rounded lg:h-52 md:h-72 xl:h-44"}
                />
              ) : null}
            </div>
          </div>
          {/* buttons */}
          <div className="flex gap-3 col-span-3 lg:col-span-1 justify-end ">
            <div>
              <CommonButton
                onClick={handleClose}
                label="Close"
                className="border border-customRed text-customRed"
              />
            </div>
          </div>
        </Box>
      </Modal>

      {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={() => setOpenPrintModal(false)}
          urlforPrint={urlforPrint}
        />
      ) : null}

      {openNumberOfCopiesModal ? (
        <NumberOfCopiesModal
          open={openNumberOfCopiesModal}
          setOpen={setOpenNumberOfCopiesModal}
          handleSave={handleDirectPrint}
          onClose={() => {
            setOpenNumberOfCopiesModal(false);
          }}
        />
      ) : null}

      {/* {openPrintModal && renderPrint()} */}
    </div>
  );
}
