import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import CheckBoxField from "../../common/components/FormFields/CheckBoxField";
import { useForm } from "react-hook-form";

export default function CommonSelectableTable(props) {
  const { control, setValue } = useForm({
    mode: "onChange",
  });
  //state varibale for the table
  const [rowIndex, setRowIndex] = React.useState("");

  const handleClick = (index, row) => {
    setRowIndex(index);
    if (props.component === "main") {
      props.setIndentId(row["IndentId"]);
      props.setStoreId(row["ToStoreId"]);
      props?.handlePatientInfo(
        row?.PatientId,
        row?.AdmissionId?.length > 0 ? 1 : 0
      );
      console.log("row", row);
    }
  };

  const removeHeaders = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };

  //set rows object to table
  const allHeaders = Object.keys(props.data.result[0]);

  const headers = removeHeaders(allHeaders, [
    "Id",
    "DrId",
    "IsUrgent",
    "Remark",
    "Closed",
    "ClosedOnIssue",
    "IsCancelled",
    "IsForcefullyClosed",
    "IsApproved",
    "IsApproved",
    "isprinted",
    "PatientId",
    "Cash_Credit",
  ]);

  const handleSelectItems = (e, row, index) => {
    if (props.component === "child") {
      let dataArray = [...props.selectedItems];
      if (e.target.checked === true) {
        let data = [...props.data.result];
        data[index].isChecked = true;
        let resultObject = { result: data };
        props.setData(resultObject);

        dataArray.push(row);
        props.setSelectedItems(dataArray);
      } else if (e.target.checked === false) {
        let data = [...props.data.result];
        data[index].isChecked = false;
        let resultObject = { result: data };
        props.setData(resultObject);

        let filtered = dataArray.filter((item) => item !== row);
        props.setSelectedItems(filtered);
      }
    }
  };
  //table start
  return (
    <>
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%" }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: "8px",
                  height: "8px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "lightBlue",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#1E3A8A",
                },
              }}
              className="rounded h-44 2xl:h-60"
            >
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {props.component === "child" ? (
                      <TableCell
                        className="whitespace-nowrap"
                        style={{ background: "#F1F1F1" }}
                      >
                        <span className="text-gray-600 font-bold">Select</span>
                      </TableCell>
                    ) : (
                      ""
                    )}
                    {headers.map((header, index) => (
                      <TableCell
                        className="whitespace-nowrap"
                        key={index}
                        style={{ background: "#F1F1F1" }}
                      >
                        <span className="text-gray-600 font-bold">
                          {header}
                        </span>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.data.result.map((row, index) => {
                    setValue(`select${index}`, row?.isChecked);
                    return (
                      <TableRow
                        hover
                        key={index.id}
                        tabIndex={-1}
                        onClick={() => {
                          handleClick(index, row);
                        }}
                        style={{
                          backgroundColor: row.IsUrgent
                            ? "#f87171"
                            : rowIndex === index && props.component === "main"
                            ? "#ffb84d"
                            : "",
                        }}
                      >
                        {props.component === "child" ? (
                          <TableCell>
                            <fieldset
                              onChange={(e) => {
                                handleSelectItems(e, row, index);
                              }}
                            >
                              <CheckBoxField
                                style={{ padding: 0 }}
                                control={control}
                                name={`select${index}`}
                              />
                            </fieldset>
                          </TableCell>
                        ) : (
                          ""
                        )}
                        {headers &&
                          headers.map((header, i) => (
                            <TableCell
                              className="whitespace-nowrap"
                              key={row.id}
                            >
                              {row[header]}
                            </TableCell>
                          ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>
    </>
  );
}

