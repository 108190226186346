import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { IconButton, Modal, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useForm } from "react-hook-form";
import UTIF from "utif";
import CancelPresentationIconButton from "../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import {
  errorAlert,
  successAlert,
} from "../../common/components/Toasts/CustomToasts";
import loadingImage from "../../taskManager/components/patientClearence/loadingImage.png";
import { getOldTiffClearanceDocuments } from "../../taskManager/services/patientClearenceServices/PatientClearenceServices";
import { UploadFileIcon } from "../Assets/UploadFileIcon";
import {
  getSelectedGroups,
  uploadDocuments,
} from "../services/PatientEngagementServices";
import { PatientEngagementContext } from "./PatientEngagement";

export default function CommonTable(props) {
  const {
    setDocumentsArray,
    preDetailsId,
    PreviousDetails,
    setSavedGroupDetails,
    patientPreDetails,
  } = React.useContext(PatientEngagementContext);
  const [openView, setOpenView] = React.useState(false);
  const [documentData, setDocumentData] = React.useState([]);
  const [documents, setDocuments] = React.useState([]);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const { register, control } = useForm();
  const [selectedHeight, setSelectedHeight] = React.useState("90%");
  const [selectedWidth, setSelectedWidth] = React.useState("60%");
  const [openUploadConfirmation, setOpenUploadConfirmation] = React.useState(
    false
  );
  const [testingImage, setTestingImage] = React.useState([]);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [viewDocuments, setViewDocuments] = React.useState([]);

  const [documentDimention, setDocumentDimention] = React.useState(1);
  const [loadingSpinner, setLoadingSpinner] = React.useState(false);

  React.useEffect(() => {}, [documents]);
  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    height: selectedHeight,
    width: selectedWidth,
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 20,
    overflowY: "scroll",
    overflowX: "scroll",
    p: 3,
  };

  React.useEffect(() => {
    if (documentDimention === 0) {
      setDocumentDimention(1);
    }
  }, [documentDimention]);

  const handleCloseUploadConfirmation = () => {
    if (openUploadConfirmation === true) {
      setOpenUploadConfirmation(false);
    }
  };

  const handleCloseChild = () => {
    if (openView === true) {
      setOpenView(false);
      setDocumentDimention(1);
    }
  };

  const handleHeightAndWidth = () => {
    if (selectedHeight === "90%" && selectedWidth === "60%") {
      setSelectedHeight("100%");
      setSelectedWidth("100%");
    } else if (selectedHeight === "100%" && selectedWidth === "100%") {
      setSelectedHeight("90%");
      setSelectedWidth("60%");
    }
  };

  const handleDocumentChange = (e, engId) => {
    let arr = e.target.files;
    let documentsArr = [];
    for (let obj of arr) {
      let object = {};

      object.documentName = obj.name;
      object.ptEngId = engId;
      object.mrno = props?.mrno;

      let reader = new FileReader();
      reader.readAsDataURL(obj);
      reader.onload = function () {
        let string = reader.result.toString().slice(22);
        object.documentBase64 = string;
      };
      documentsArr.push(object);
      setDocumentsArray(documentsArr);
      setDocuments(documentsArr);
    }
  };

  const handleGetDocuments = (data) => {
    if (data) {
      let temp = [...documentData];
      for (let obj of data) {
        let object = {};
        object.fileType = "ptEngDoc";
        // to create the array of required file paths from received filePaths Object
        object.fileName = obj
          .toString()
          .replaceAll("//", ",")
          .replaceAll("\\", ",")
          .split(",");
        temp.push(object);
        setDocumentData(temp);
      }
    }
  };

  const handleFileUpload = () => {
    setOpenBackdrop(true);
    handleCloseUploadConfirmation();
    uploadDocuments(documents)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setOpenUploadConfirmation(false);
        PreviousDetails(preDetailsId);
        setOpenBackdrop(false);
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  };

  React.useEffect(() => {
    console.log("patientPreDetails",patientPreDetails);
    if (patientPreDetails.length>0) {
      getSelectedGroups(patientPreDetails[0]?.ID)
        .then((response) => response.data)
        .then((res) => {
          setSavedGroupDetails(res.result);
        });
    }
  }, [patientPreDetails]);

  const handleZoomContent = () => {
    if (documentDimention > 0) {
      setDocumentDimention(documentDimention + 1);
    }
  };

  const handleRevertContent = () => {
    if (documentDimention > 0) {
      setDocumentDimention(documentDimention - 1);
    }
  };

  React.useEffect(() => {
    let imageArray = [];
    if (documentData?.length > 0) {
      documentData.forEach((imagePath, index) => {
        getOldTiffClearanceDocuments(
          imagePath?.fileName?.[0],
          imagePath?.fileName?.[1],
          imagePath?.fileName?.[2],
          imagePath?.fileName?.[3]
        )
          .then((response) => response.data)
          .then((res) => {
            var apiResponseImageData = res;
      
            var ifds = UTIF.decode(apiResponseImageData);
            ifds.map(function (ifd, index) {
              UTIF.decodeImage(apiResponseImageData, ifd);
              var rgba = UTIF.toRGBA8(ifd);
              var canvas = document.createElement("canvas");
              canvas.width = ifd?.width;
              canvas.height = ifd?.height;
              var ctx = canvas.getContext("2d");
              var img = ctx.createImageData(ifd.width, ifd.height);
              img.data.set(rgba);
              ctx.putImageData(img, 0, 0);
              let image = canvas.toDataURL();
              imageArray.push(image);
              setViewDocuments(imageArray);

              document
                .getElementById(`tiff-inner-container${index}`)
                .appendChild(canvas);
              return canvas;
            });
          });
      });
    }
  }, [documentData]);

  return (
    <>
      {documentData.map((item, index) => {
        return (
          <canvas
            id={`tiff-inner-container${index}`}
            className="h-[300px] w-[400px] hidden"
          />
        );
      })}
      <div className="grid w-auto">
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Paper sx={{ width: "100%", my: 0 }}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  width: 7,
                  height: 10,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#7393B3",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "lightBlue",
                },
              }}
              className="rounded h-36 2xl:h-40"
            >
              <Table
                size="small"
                stickyHeader
                aria-label="sticky table"
                sx={{
                  border: 1,
                  borderColor: "#e0e0e0",
                  // paddingY: "scroll",
                }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        paddingY: 0.5,
                        backgroundColor: "#F1F1F1",
                      },
                    }}
                  >
                    <TableCell>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        ID
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Date
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Time
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Patient Type
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Added By
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-gray-600 font-bold whitespace-nowrap">
                        Document
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.data
                    ? props.data.map((row, index) => {
                        return (
                          <TableRow
                            key={index}
                            sx={{
                              "& td": {
                                paddingY: 0.5,
                              },
                            }}
                            style={{
                              backgroundColor:
                                selectedIndex === index ? "#FFC44B" : "",
                              height: selectedIndex === index ? "32px" : "",
                            }}
                            onClick={() => {
                              setSelectedIndex(index);
                            }}
                          >
                            <TableCell>{row.ID}</TableCell>
                            <TableCell>{row.Date}</TableCell>
                            <TableCell>{row.Time}</TableCell>
                            <TableCell>{row.PatientType}</TableCell>
                            <TableCell>{row.AddedBy}</TableCell>

                            <TableCell>
                              {row.documentSource?.length > 0 ? (
                                <div>
                                  <CommonButton
                                    className="h-7 rounded px-2 text-white bg-sky-500 w-[92px]"
                                    onClick={() => {
                                      setOpenView(true);
                                      handleGetDocuments(row.documentSource);
                                    }}
                                    type="button"
                                    label="View Docs"
                                  />
                                </div>
                              ) : (
                                <div className="flex gap-2 items-center">
                                  <input
                                    type="file"
                                    placeholder="Choose File"
                                    name={`upload${index}`}
                                    {...register(`upload${index}`)}
                                    control={control}
                                    multiple
                                    onChange={(e) => {
                                      handleDocumentChange(e, row.ID);
                                    }}
                                  />
                                  {documents.length > 0 ? (
                                    <Tooltip title="Upload Docs">
                                      <CommonButton
                                        label={<UploadFileIcon />}
                                        onClick={() => {
                                          setOpenUploadConfirmation(true);
                                        }}
                                        type="button"
                                      />
                                    </Tooltip>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : ""}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </div>
      <Modal
        open={openView}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <CancelPresentationIconButton
            onClick={() => {
              handleCloseChild();
              setDocumentData([]);
            }}
          />
          <div>
            <div className="flex justify-between">
              <div className="text-lg font-semibold my-4">Documents :</div>
              <div className="flex gap-2 items-center">
                <Tooltip title="Zoom In">
                  <span className="border border-gray-400 rounded p-1 bg-gray-200 ">
                    <IconButton
                      className="px-5  h-5  w-6 text-black "
                      onClick={handleZoomContent}
                    >
                      <ZoomInIcon sx={{ color: "#070708" }} />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Zoom Out">
                  <span className="border border-gray-400 rounded p-1 bg-gray-200 ">
                    <IconButton
                      className="px-5  h-5  w-6 text-black "
                      onClick={handleRevertContent}
                    >
                      <ZoomOutIcon sx={{ color: "#070708" }} />
                    </IconButton>
                  </span>
                </Tooltip>
                <CommonButton
                  label="Reset"
                  className="px-2 bg-white border border-customRed text-customRed rounded h-9"
                  type="button"
                  onClick={() => {
                    setDocumentDimention(1);
                  }}
                />
              </div>
            </div>

            <div
              className="flex gap-2  border border-gray-300 rounded overflow-x-scroll my-5 p-2"
              onClick={handleHeightAndWidth}
            >
              {documentData.map((documents, index) => {
                let documentId = document.getElementById(
                  `clearanceDocument${index}`
                );
                if (documentId) {
                  if (viewDocuments[index]) {
                    documentId.src = viewDocuments[index];
                  } else {
                    documentId.src = loadingImage;
                  }
                }
                return (
                  <div
                    className=""
                    // onClick={() => openViewer(index)}
                  >
                    <img
                      id={`clearanceDocument${index}`}
                      className="object-contain h-full w-full"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </Box>
      </Modal>

      <ConfirmationModal
        confirmationOpen={openUploadConfirmation}
        confirmationHandleClose={handleCloseUploadConfirmation}
        confirmationSubmitFunc={handleFileUpload}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to upload this documents ?"
        confirmationButtonMsg="Upload"
      />
      <CommonBackDrop openBackdrop={openBackdrop} />
    </>
  );
}
