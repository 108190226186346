import authHeader from "../../../../authentication/authservices/auth-header";
import apiClient from "../../../../http-common";

//drug name table
export const fetchDrugNameTable = (drugListObj) => {
  return apiClient.post(
    `/inventory/inPatientIssue/itemDetailsSearch`,
    drugListObj,
    {
      headers: authHeader(),
    }
  );
};

//Alternate drug name table
export const fetchAlternateDrugNameTable = (altDrugObj) => {
  return apiClient.post(
    `/inventory/inPatientIssue/itemDetailListByGenericId`,
    altDrugObj,
    {
      headers: authHeader(),
    }
  );
};

//Item Kit List
export const fetchKitList = (searchString) => {
  return apiClient.get(
    `/inventory/inPatientIssue/itemKitList/${searchString}`,
    {
      headers: authHeader(),
    }
  );
};

//Item patient List for listing page
export const fetchPatientList = (searchString) => {
  //
  return apiClient.get(`/patientInfo/admissionAutoComplete/${searchString}`, {
    headers: authHeader(),
  });
};

//Item patient Details
export const fetchPatientDetails = (patientId) => {
  //
  return apiClient.get(`/patientInfo/transfer/${patientId}`, {
    headers: authHeader(),
  });
};

//Item List for listing page
export const fetchItemList = (searchItem) => {
  //
  return apiClient.get(`/inventory/inPatientIssue/itemList/${searchItem}`, {
    headers: authHeader(),
  });
};

//Item List for listing page
export const fetchIpdIssueDetails = (issueId) => {
  //
  return apiClient.get(`/issueToDepartment/issueToDetails/${issueId}`, {
    headers: authHeader(),
  });
};

//Item Table List for listing page
export const fetchItemListTableData = (IpdIssuelistObj) => {
  return apiClient.post(`/issueToDepartment/issueToList`, IpdIssuelistObj, {
    headers: authHeader(),
  });
};

// //fetch Store Name
// export const fetchStore = (cashCounterId) => {
//
//   return apiClient.get(`/inventory/inPatientIssue/storeName/${cashCounterId}`);
// };

//fetch Store Name
export const fetchBatchNumber = (BatchSelObj) => {
  return apiClient.post(
    `/inventory/inPatientIssue/itemBatchNumber`,
    BatchSelObj,
    {
      headers: authHeader(),
    }
  );
};
//post finalobj
export const postIssueToDeptData = (finalObj) => {
  return apiClient.post(`/issueToDepartment/saveIssueToDepartment`, finalObj, {
    headers: authHeader(),
  });
};

//get indent 1
export const fetchIndentList = (indentObj) => {
  return apiClient.post(`/issueToDepartment/indentList`, indentObj, {
    headers: authHeader(),
  });
};

//get indent items 2
export const fetchIndentItems = (indentId) => {
  return apiClient.get(`/issueToDepartment/indentFromIndentList/${indentId}`, {
    headers: authHeader(),
  });
};

//patient info against selected indent
export const fetchSelectedIndentPatientInfo = (patientId) => {
  return apiClient.get(`/patientInfo/transfer/${patientId}`, {
    headers: authHeader(),
  });
};

export const fetchItemKitData = (indentId, storeId) => {
  return apiClient.get(`/patientIndent/kitItems/${indentId}/${storeId}`, {
    headers: authHeader(),
  });
};
//prints
export const fetchPrintIssueToDept = (issueId) => {
  return apiClient.get(`/reports/inventory/issueToDepartment/${issueId}`, {
    responseType: "blob",
  });
};
