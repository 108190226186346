import React from "react";

//import from react hook form library.
import { useForm } from "react-hook-form";

// importing the table to display drug list.
import DrugList from "./common/DrugList";

//importing the table to display the prescriptions ; along with the prices of the drugs.
import PrescriptionTable from "./common/PrescriptionTable";

//importing the table to display the alternative drug list.
import AlternativeDrugList from "./common/AlternativeDrugList";

//importing the common/components
import InputField from "../../common/components/FormFields/InputField";
import { errorAlert } from "../../common/components/Toasts/CustomToasts";

//importing the services that are to be called
// import {
//   searchDrugs,
//   searchAlternativeDrugs,
//   getBatchNumberForDrug,
//   getItemKitDropdown,
//   getKitItemsById,
// } from "../pharmacyServices/pharmaServices";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import CommonButton from "../../common/components/Buttons/CommonButton";
import CheckBoxField from "../../common/components/FormFields/CheckBoxField";
import SearchDropdown from "../../common/components/FormFields/searchDropdown";
import {
  getAlternativeDrugList,
  getDruglist,
  getItemBatchNumber,
  getItemKitDropdown,
  getKitItemsById,
} from "../PharmacyServices";
import { ClearButton } from "../counterSale/Assets/ClearButton";
import BatchInfoModal from "./BatchInfoModal";
import InputFieldForSearch from "./InputFieldForSearch";

function DrugSearch(props) {
  const defaultValues = {
    typeDrugName: "",
    quantity: "",
    kitQuantity: null,
    itemKit: null,
    gstPercent: "",
    gstAmount: "",
    isItemKit: false,
  };

  React.useEffect(() => {
    setValue("isItemKit", false);
  }, [props.resetStates]);

  //importing the functions from react hook form
  const { control, watch, setValue, register, reset, getValues } = useForm({
    mode: "onChange",
    defaultValues,
  });

  // The typeDrugName variable is used to store the values typed in the typeDrugName input field
  let typeDrugName = watch("typeDrugName");

  //display the drug names and the alternative drug names ; if and only if displayTable variable's value is true
  let displayTable = true;

  //display the drug names and the alternative drug names ; if and only if displayTable variable's value is true
  if (typeDrugName === "") {
    //do not display the tables
    displayTable = false;
  } else {
    //display the tables
    displayTable = true;
  }

  //The state variable to store the arrow key name.
  const [arrowKeyName, setArrowKeyName] = React.useState("");

  //The state variable to populate the PrescriptionTable component
  const [medicineData, setMedicineData] = React.useState({
    result: [],
  });

  //The state variable to store the drug information coming from the DrugList.js file and AlternativeDrugList.js file.
  const [selectedRow, setSelectedRow] = React.useState("");

  const [selected, setSelected] = React.useState([]);

  //set the result of the drug search in the state variable called data
  const [data, setData] = React.useState({ result: [] });

  //set the result of the drug search in the state variable called dataResult
  const [dataResult, setDataResult] = React.useState([]);

  //set the result of the alternative drug search in the state variable called dataTest
  const [dataTest, setDataTest] = React.useState({ result: [] });

  //set the result of the alternative drug search in the state variable called dataResultTest
  const [dataResultTest, setDataResultTest] = React.useState([]);

  //The state variable to store the genericId of the drug name.
  //genericId is used to get the list of alternativeDrug names.
  const [genericId, setGenericId] = React.useState(null);

  const [openBatchInfoModal, setOpenBatchInfoModal] = React.useState(false);
  const [itemId, setItemId] = React.useState(null);
  const [drugBatchInfo, setDrugBatchInfo] = React.useState(null);
  const [drugItemInfo, setDrugItemInfo] = React.useState(null);
  const [requiredRow, setRequiredRow] = React.useState("");
  const [isShrinked, setIsShrinked] = React.useState(false);
  const [isHidden, setIsHidden] = React.useState("");
  const [isFocused, setIsFocused] = React.useState(false);
  const [isFocusedBatchModal, setIsFocusedBatchModal] = React.useState(false);
  const [enteredQuantity, setEnteredQuantity] = React.useState(null);
  const [taxableAmount, setTaxableAmount] = React.useState(null);
  const [itemKitOptions, setItemKitOptions] = React.useState([]);
  const [kitId, setKitId] = React.useState(null);
  const [itemsInKit, setItemsInKit] = React.useState([]);
  let loginObj = JSON.parse(localStorage.getItem("userInfo"));

  let quantityOfKitItems = watch("kitQuantity");

  const handleKitSearch = (searchString) => {
    getItemKitDropdown(searchString)
      .then((response) => response.data)
      .then((res) => {
        setItemKitOptions(res.result);
      });
  };

  const DrugListTableElement = React.useRef();

  //use ref hook to shift the focus on AlternativeDrugListTable
  const AlternativeDrugListElement = React.useRef();

  //This function is called whenever there is a focus on DrugListTable.
  //The focus comes onto the DrugList table ; whenever any string is typed in the input field "typeDrugName"
  //The focus comes onto the DrugList table ; whenever the "Left arrow key" is pressed.
  const focusDrugTable = () => {
    if (DrugListTableElement.current) {
      DrugListTableElement.current.focus();
      setIsDrugListFocused(true);
    }
  };

  //This function is called whenever there is a focus on AlternativeDrugList table.
  //The focus comes onto the AlternativeDrugList table ; whenever the "Right arrow key" is pressed.
  const focusAlternativeDrugTable = () => {
    if (AlternativeDrugListElement.current) {
      AlternativeDrugListElement.current.focus();
      setIsDrugListFocused(false);
    }
  };

  React.useEffect(() => {
    if (props.patientInfo === null) {
      setMedicineData({ result: [] });
      setValue("typeDrugName", "");
      setValue("itemBatchNo", "");
      setValue("itemQty", "");
      setValue("discountPercentage", "");
      setValue("discountAmt", "");
      setValue("netAmt", "");
      setIsFocused(false);
      setIsShrinked(false);
      setValue("isItemKit", false);
    }
  }, [props.patientInfo]);

  const clearPriscriptionData = () => {
    setMedicineData({ result: [] });
  };

  React.useEffect(() => {
    if (props.resetQtyFlag === 0) {
      reset();
      setMedicineData({ result: [] });
      setValue("typeDrugName", "");
      setValue("itemBatchNo", "");
      setValue("itemQty", "");
      setValue("discountPercentage", "");
      setValue("discountAmt", "");
      setValue("netAmt", "");
      setIsFocused(false);
      setIsShrinked(false);
    }
  }, [props.resetQtyFlag]);

  const batchReference = React.useRef();
  const quantityReference = React.useRef();
  const searchRef = React.useRef();
  const [AmountValue, setAmountValue] = React.useState(null);
  const [discountPercent, setDiscountPercent] = React.useState(null);
  const [discAmount, setDiscAmount] = React.useState(null);
  const [netAmtVAl, setNetAmtVal] = React.useState(null);
  const [cgstAmount, setCgstAmount] = React.useState(null);
  const [sgstAmount, setSgstAmount] = React.useState(null);
  const [searchFocus, setSearchFocus] = React.useState(false);
  const [showPreQty, setShowPreQty] = React.useState(false);
  const [alternateTableList, setAlternateTableList] = React.useState([]);
  const [finalGstAmount, setFinalGstAmount] = React.useState(null);
  const [quantityError, setQuantityError] = React.useState(false);
  const [isDrugListFocused, setIsDrugListFocused] = React.useState(false);
  const [selectedDrugRowID, setSelectedDrugRowID] = React.useState(0);
  const [selectedAltDrugRowID, setSelectedAltDrugRowID] = React.useState(0);

  React.useEffect(() => {
    setMedicineData({ result: alternateTableList });
  }, [alternateTableList]);

  // let loginObj = JSON.parse(localStorage.getItem("loggedUser"));

  let unitId = loginObj && loginObj.loginUnitId;

  let storeId = loginObj && loginObj.storeId;

  let getItemBatchNo = watch("itemBatchNo");

  React.useEffect(() => {
    if (getItemBatchNo) {
      setValue("itemQty", 1);
    }
  }, [getItemBatchNo]);

  React.useEffect(() => {
    let storeId = loginObj?.storeId;
    if (kitId !== null) {
      getKitItemsById(kitId, storeId)
        .then((response) => response.data)
        .then((res) => {
          setItemsInKit(res.result);
        });
    }
  }, [kitId]);

  function findNextTabStop(el) {
    var universe = document.querySelectorAll(
      "input, button, select, textarea, a[href]"
    );
    var list = Array.prototype.filter.call(universe, function (item) {
      return item.tabIndex >= "0";
    });
    var index = list.indexOf(el);
    return list[index + 2] || list[0];
  }

  //useEffect to make the entry of data into the prescriptionTable , and to avoid the duplicate entry.
  //The data coming from the DrugList.js file and AlternativeDrugList.js file is stored in the state variable selectedeRow
  //selectedRow is in the dependency array of the below useEffect.
  React.useEffect(() => {
    let obj = {
      pharmacyItem: selectedRow?.["Drug"],
      code: selectedRow?.["Code"],
    };
    setDrugItemInfo(obj);

    let amountVal = Number(selectedRow?.MRP) / Number(selectedRow?.Conversion);
    let gstPercent = Number(amountVal) * Number(selectedRow?.VATPercentage);
    let gstAmount = Number(gstPercent / 100).toFixed(2);
    setFinalGstAmount(gstAmount);

    if (selectedRow?.["Drug"]) {
      setValue("typeDrugName", selectedRow?.["Drug"]);
      setIsHidden("hidden");
      // batchReference.current.focus();
      var nextEl = findNextTabStop(document.activeElement);
      nextEl.focus();
    }

    if (selectedRow?.["Drug"] !== "") {
      setItemId(selectedRow?.Id);
      setDataResult([]);
      setDataTest({ result: [] });
    }
  }, [selectedRow]);

  React.useEffect(() => {
    if (requiredRow !== "" && selectedRow.BatchesRequired === true) {
      setIsFocused(true);
      setIsShrinked(true);
      setValue("itemBatchNo", requiredRow?.["Batch Code"]);
      quantityReference.current.focus();
    }
    if (selectedRow?.concessionper) {
      setValue(
        "discountPercentage",
        !!loginObj?.isDiscountApplicable ? selectedRow?.concessionper : 0
      );
      setDiscountPercent(
        Number(
          !!loginObj?.isDiscountApplicable ? selectedRow?.concessionper : 0
        )
      );
    } else {
      setValue("discountPercentage", 0);
      setDiscountPercent(Number(0));
    }

    let amountVal = 0;
    if (requiredRow?.Conversion !== 0) {
      amountVal = Number(requiredRow?.UnitMRP);
      setAmountValue(Number(amountVal).toFixed(2));
    } else if (requiredRow?.Conversion === 0) {
      amountVal = Number(requiredRow?.UnitMRP);
      setAmountValue(Number(amountVal).toFixed(2));
    }

    let discAmount;
    if (!!loginObj?.isDiscountApplicable && selectedRow?.concessionper) {
      discAmount =
        (Number(amountVal) * Number(selectedRow?.concessionper)) / 100;
    } else {
      discAmount = 0;
    }

    setValue("discountAmt", discAmount.toFixed(2));
    setDiscAmount(discAmount.toFixed(2));

    let netAmount = Number(amountVal) - Number(discAmount);
    setValue("netAmt", netAmount.toFixed(2));
    setNetAmtVal(netAmount.toFixed(2));

    let gstPercent = Number(amountVal) * Number(requiredRow?.VATPer);
    let gstAmount = Number(gstPercent / 100).toFixed(2);
    setFinalGstAmount(gstAmount);
    setValue("gstAmount", gstAmount);

    setValue("gstPercent", Number(requiredRow?.VATPer).toFixed(2));
    setTaxableAmount(Number(requiredRow?.mrpExcludeGst));

    let cgstPercentageVal = requiredRow?.cgstPercentage;
    let sgstPercentageVal = requiredRow?.sgstPercentage;
    let cgstAmount = (amountVal * (cgstPercentageVal / 100)).toFixed(2);
    setCgstAmount(Number(cgstAmount));
    let sgstAmount = (amountVal * (sgstPercentageVal / 100)).toFixed(2);
    setSgstAmount(Number(sgstAmount));
  }, [requiredRow]);

  //The useEffect to populate the medicineData state variable ; if the patient has the already existing prescription data.
  React.useEffect(() => {
    setMedicineData({ result: props.prescriptionData });
  }, [props.prescriptionData]);

  //The useEffect to empty the medicineData state variable ; if the patient type is switched among OPD , IPD , Counter.
  React.useEffect(() => {
    if (
      props.selectedPatientType === "OPD" ||
      props.selectedPatientType === "IPD" ||
      props.selectedPatientType === "Counter"
    ) {
      setMedicineData({ result: [] });
      setValue("typeDrugName", "");
      setValue("itemBatchNo", "");
      setValue("itemQty", "");
      setValue("discountPercentage", "");
      setValue("discountAmt", "");
      setValue("netAmt", "");
      setAmountValue(null);
      setDiscountPercent(null);
      setDiscAmount(null);
      setTaxableAmount(null);
      setEnteredQuantity(null);
      setIsFocused(false);
      setIsShrinked(false);
    }
  }, [props.selectedPatientType]);

  //The useEffect to search the alternative drug names on the basis of genericId.
  React.useEffect(() => {
    if (genericId !== null) {
      let obj = {
        deptStore: 1,
        deptStoreId: loginObj?.storeId,
        genericId: genericId,
        isConsignment: 0,
        itemCode: "%",
        itemName: "%",
        searchString: "",
      };
      getAlternativeDrugList(obj).then((response) => {
        //set the result of the alternative drug search in the state variable called dataTest
        setDataTest({ result: response.data.result });
        //set the result of the alternative drug search in the state variable called dataResultTest
        //Why two state variables for the same purpose ; needs to be investigated.
        setDataResultTest(response.data.result);
      });
    }
  }, [genericId]);

  //The useEffect that is supposed to do three things.
  //When the right arrow key is pressed ; shift the focus on Alternative Drug List table.
  //When the left arrow key is pressed ; shift the focus on Drug List table.
  //When the enter key is pressed ; clear the input field registered by the name "typeDrugName"

  React.useEffect(() => {
    if (arrowKeyName === "Right Arrow Key") {
      focusAlternativeDrugTable();
    } else if (arrowKeyName === "Left Arrow Key") {
      focusDrugTable();
    } else if (arrowKeyName === "Enter Key Pressed") {
    }
  }, [arrowKeyName]);

  const handleOpen = () => {
    setOpenBatchInfoModal(true);
  };

  const handleClose = () => {
    if (openBatchInfoModal === true) {
      setOpenBatchInfoModal(false);
    }
  };

  const handleQuantityChange = (e) => {
    if (
      selectedRow.BatchesRequired === true &&
      e.target.value > requiredRow["Balance Qty"]
    ) {
      setQuantityError(true);
    } else if (
      selectedRow.BatchesRequired === false &&
      e.target.value > selectedRow.BalanceQty
    ) {
      setQuantityError(true);
    } else {
      setQuantityError(false);
    }
    setEnteredQuantity(e.target.value);

    if (selectedRow?.BatchesRequired === true) {
      let amount = Number(requiredRow?.UnitMRP);

      let amountVal = Number(amount) * Number(e.target.value);
      // let discountedAmount=Number(selectedRow?.MRP*selectedRow.concessionper)/100

      setAmountValue(Number(amountVal).toFixed(2));

      let discAmount;
      if (!!loginObj?.isDiscountApplicable && selectedRow?.concessionper) {
        setValue("discountPercentage", selectedRow?.concessionper);
        setDiscountPercent(Number(selectedRow?.concessionper));
        discAmount =
          (Number(amountVal) * Number(selectedRow?.concessionper)) / 100;
      } else {
        setValue("discountPercentage", 0);
        setDiscountPercent(Number(0));
        discAmount = (Number(amountVal) * Number(0)) / 100;
      }

      setDiscAmount(discAmount.toFixed(2));

      setValue("discountAmt", discAmount.toFixed(2));

      let netAmount = Number(amountVal) - Number(discAmount);

      setValue("netAmt", netAmount.toFixed(2));
      setNetAmtVal(netAmount.toFixed(2));

      let discountedAmount =
        Number(selectedRow.MRP) -
        Number(
          selectedRow.MRP *
            (!!loginObj?.isDiscountApplicable ? selectedRow.concessionper : 0)
        ) /
          100;

      let Amount = Number(discountedAmount * e.target.value);
      let gstAmount = Number(Amount * selectedRow.VATPercentage) / 100;
      setFinalGstAmount(gstAmount);

      let gstPercent =
        Number(requiredRow?.sgstPercentage) +
        Number(requiredRow?.cgstPercentage);
      let divider = gstPercent + 100;
      let divideVal = divider / 100;

      let taxableAmountVal = (
        Number(e.target.value) * Number(requiredRow?.mrpExcludeGst)
      ).toFixed(2);
      setTaxableAmount(Number(taxableAmountVal).toFixed(2));

      let cgstPercentageVal = requiredRow?.cgstPercentage;
      let sgstPercentageVal = requiredRow?.sgstPercentage;
      let cgstAmount = (amountVal * (cgstPercentageVal / 100)).toFixed(2);
      setCgstAmount(Number(cgstAmount));
      let sgstAmount = (amountVal * (sgstPercentageVal / 100)).toFixed(2);
      setSgstAmount(Number(sgstAmount));
    } else if (selectedRow?.BatchesRequired === false) {
      let discountedAmount =
        Number(selectedRow.MRP) -
        Number(
          selectedRow.MRP *
            (!!loginObj?.isDiscountApplicable ? selectedRow.concessionper : 0)
        ) /
          100;
      let Amount = Number(discountedAmount * e.target.value);
      let gstAmount = Number(Amount * selectedRow.VATPercentage) / 100;
      setFinalGstAmount(gstAmount);
      let amount = Number(selectedRow?.MRP);

      let amountVal = Number(amount) * Number(e.target.value);

      setAmountValue(Number(amountVal).toFixed(2));

      let discAmount;
      if (!!loginObj?.isDiscountApplicable && selectedRow?.concessionper) {
        setValue("discountPercentage", selectedRow?.concessionper);
        setDiscountPercent(Number(selectedRow?.concessionper));
        discAmount =
          (Number(amountVal) * Number(selectedRow?.concessionper)) / 100;
      } else {
        setValue("discountPercentage", 0);
        setDiscountPercent(Number(0));
        discAmount = (Number(amountVal) * Number(0)) / 100;
      }

      setDiscAmount(discAmount.toFixed(2));

      setValue("discountAmt", discAmount.toFixed(2));

      let netAmount = Number(amountVal) - Number(discAmount);

      setValue("netAmt", netAmount.toFixed(2));
      setNetAmtVal(netAmount.toFixed(2));

      let gstPercent =
        Number(selectedRow?.sgstPercentage) +
        Number(selectedRow?.cgstPercentage);
      let divider = gstPercent + 100;
      let divideVal = divider / 100;

      let taxableAmountVal = (
        Number(e.target.value) * Number(selectedRow?.mrpExcludeGst)
      ).toFixed(2);
      setTaxableAmount(Number(taxableAmountVal).toFixed(2));

      let cgstPercentageVal = selectedRow?.cgstPercentage;
      let sgstPercentageVal = selectedRow?.sgstPercentage;
      let cgstAmount = (amountVal * (cgstPercentageVal / 100)).toFixed(2);
      setCgstAmount(Number(cgstAmount));
      let sgstAmount = (amountVal * (sgstPercentageVal / 100)).toFixed(2);
      setSgstAmount(Number(sgstAmount));
    }
  };

  const handleDiscount = (e) => {
    let amountVal = requiredRow.mrp;
    setAmountValue(Number(amountVal).toFixed(2));
    let discAmount = (Number(amountVal) * Number(e.target.value)) / 100;
    setDiscountPercent(Number(e.target.value));

    setValue("discountAmt", discAmount.toFixed(2));
    setDiscAmount(discAmount.toFixed(2));

    let netAmount = Number(amountVal) - Number(discAmount);
    setValue("netAmt", netAmount.toFixed(2));
    setNetAmtVal(netAmount.toFixed(2));

    let gstPercent =
      Number(requiredRow.sgstPercentage) + Number(requiredRow.cgstPercentage);
    let divider = gstPercent + 100;
    let divideVal = divider / 100;

    let taxableAmountVal = (
      Number(enteredQuantity) * Number(requiredRow.mrpExcludeGst)
    ).toFixed(2);
    setTaxableAmount(Number(taxableAmountVal).toFixed(2));

    let cgstPercentageVal = requiredRow.cgstPercentage;
    let sgstPercentageVal = requiredRow.sgstPercentage;
    let cgstAmount = (amountVal * (cgstPercentageVal / 100)).toFixed(2);
    setCgstAmount(Number(cgstAmount));
    let sgstAmount = (amountVal * (sgstPercentageVal / 100)).toFixed(2);
    setSgstAmount(Number(sgstAmount));
  };

  React.useEffect(() => {
    if (
      props.selectedPatientType === "Counter" &&
      props.counterInfo.firstName === "" &&
      props.counterInfo.lastName === "" &&
      props.counterInfo.doctorName === null &&
      props.counterInfo.address === ""
    ) {
      setSelectedRow("");
    }
  }, [props.counterInfo]);

  function findNext() {
    var universe = document.querySelectorAll("input");
    var list = Array.prototype.filter.call(universe, function (item) {
      return item.tabIndex >= "0";
    });
    if (props.selectedPatientType !== "Counter") {
      var index = 4;
      if (list[index + 1]?.name === "typeDrugName") {
        return list[index + 1].focus();
      } else {
        return list[index + 2].focus();
      }
    } else if (
      props.selectedPatientType === "Counter" &&
      props.counterFocus > 0 &&
      props.isValidForCounter === true
    ) {
      var index = 8;
      if (list[index + 1]?.name === "typeDrugName") {
        return list[index + 1].focus();
      } else {
        return list[index + 2].focus();
      }
    }
  }

  function findAddButton(el) {
    var universe = document.querySelectorAll(
      "input, button, select, textarea, a[href]"
    );
    var list = Array.prototype.filter.call(universe, function (item) {
      return item.tabIndex >= "0";
    });
    var index = list.indexOf(el);
    return list[index + 4] || list[0];
  }

  React.useEffect(() => {
    if (props.counterFocus > 0 && props.isValidForCounter === true) {
      setSearchFocus(true);
      findNext();
    } else if (
      props.isValidForCounter === false &&
      props.selectedPatientType === "Counter"
    ) {
    }
  }, [props.counterFocus]);

  React.useEffect(() => {
    if (
      props.mobileNo?.length !== 10 &&
      props.selectedPatientType === "Counter"
    ) {
      setSearchFocus(false);
    }
  }, [props.mobileNo]);

  React.useEffect(() => {
    if (
      (props.patientInfo !== null && props.selectedPatientType === "IPD") ||
      props.selectedPatientType === "OPD"
    ) {
      setSearchFocus(true);
      findNext();
    }
    if (
      props.selectedPatientType === "IPD" &&
      props.patientInfo !== null &&
      props.patientInfo.Discharged === true
    ) {
      errorAlert("Patient Already Discharged..!");
    }
  }, [props.patientInfo]);

  const mergeArray = (arr) => {
    return arr.reduce((acc, val) => {
      const ind = acc.findIndex((item) => item.id === val.id);
      if (ind !== -1) {
        acc[ind]["quantity"] = acc[ind]["quantity"] + val["quantity"];
        acc[ind]["amount"] = acc[ind]["amount"] + val["amount"];
        acc[ind]["discountAmount"] =
          acc[ind]["discountAmount"] + val["discountAmount"];
        acc[ind]["netAmount"] = acc[ind]["netAmount"] + val["netAmount"];
        acc[ind]["saleQuantity"] = acc[ind]["saleQuantity"];
        acc[ind]["gstAmount"] = acc[ind]["gstAmount"] + val["gstAmount"];
      } else {
        acc.push(val);
      }
      return acc;
    }, []);
  };

  const functionSetvalue = () => {
    setValue("typeDrugName", "");
  };

  React.useEffect(() => {
    if (props?.isValidForCounter === true) {
      // findNext();
    }
  }, [props.isValidForCounter]);

  const handleAddPrescriptions = () => {
    findNext();
    let getItemQty = watch("itemQty");
    if (
      selectedRow !== "" &&
      selectedRow.BatchesRequired === true &&
      getItemQty > 0 &&
      getItemQty <= requiredRow["Balance Qty"]
    ) {
      //make the required object to be pushed
      let expiry = requiredRow["Batch Exp Date"];
      let requiredObj = {
        id: selectedRow.id,
        bins: selectedRow.Bins,
        code: selectedRow["Code"],
        pharmacyItem: selectedRow["Drug"],
        schedule: selectedRow.Frequency,
        BatchesRequired: selectedRow.BatchesRequired,
        batchNo: requiredRow["Batch Code"],
        balanceQuantity: Number(requiredRow["Balance Qty"]),
        expiryDate: expiry,
        mrp: requiredRow.UnitMRP,
        sgstPercentage: Number(requiredRow.sgstPercentage),
        cgstPercentage: Number(requiredRow.cgstPercentage),
        rackName: selectedRow.Rack,
        shelfName: selectedRow.Shelf,
        rack: { id: selectedRow.rackid },
        shelf: { id: selectedRow.shelfid },
        purchaseRate: requiredRow["Unit Purchase Rate"],
        landedRate: requiredRow["Unit Landed Rate"],
        quantity: Number(getItemQty),
        amount: Number(AmountValue),
        discountPercentage: Number(discountPercent),
        discountAmount: Number(discAmount),
        netAmount: Number(netAmtVAl),
        mrpExcludeGst: Number(requiredRow.mrpExcludeGst),
        cgstAmount: Number(cgstAmount),
        sgstAmount: Number(sgstAmount),
        taxableAmount: Number(taxableAmount),
        prescribeQuantity: 0,
        saleQuantity: Number(getItemQty),
        itemMaster: { id: selectedRow.id },
        genericId: selectedRow.ItemGenericId,
        gstPercent: requiredRow.VATPer,
        gstAmount: finalGstAmount,
        conversion: requiredRow.Conversion,
        IsConsignment: requiredRow.IsConsignment,
      };

      //deep cloning the state variable medicineData into the variable medicineDataClone.
      let medicineDataClone = structuredClone(medicineData);

      medicineDataClone.result.push(requiredObj);

      let keySimilar = "id";
      let mergeVal = "quantity";
      medicineDataClone.result = mergeArray(medicineDataClone.result);
      //set the value of state variable medicineData.
      setMedicineData(medicineDataClone);
      setValue("typeDrugName", "");
      setValue("itemBatchNo", "");
      setValue("itemQty", "");
      setValue("discountPercentage", "");
      setValue("discountAmt", "");
      setValue("netAmt", "");
      setValue("gstPercent", "");
      setValue("gstAmount", "");
      setAmountValue(null);
      setDiscountPercent(null);
      setDiscAmount(null);
      setTaxableAmount(null);
      setEnteredQuantity(null);
      setIsFocused(false);
      setIsShrinked(false);
      setSearchFocus(true);
      setSelectedRow("");
    } else if (
      selectedRow.BatchesRequired === false &&
      getItemQty > 0 &&
      getItemQty <= selectedRow?.BalanceQty
    ) {
      let expiry = requiredRow["Batch Exp Date"];
      let requiredObj = {
        id: selectedRow.id,
        bins: selectedRow.Bins,
        code: selectedRow["Code"],
        pharmacyItem: selectedRow["Drug"],
        schedule: selectedRow.Frequency,
        BatchesRequired: selectedRow.BatchesRequired,
        batchNo: "",
        balanceQuantity: Number(selectedRow?.BalanceQty),
        expiryDate: "",
        mrp: selectedRow.MRP,
        sgstPercentage: Number(selectedRow?.sgstPercentage),
        cgstPercentage: Number(selectedRow?.cgstPercentage),
        rackName: selectedRow.Rack,
        shelfName: selectedRow.Shelf,
        rack: { id: selectedRow.rackid },
        shelf: { id: selectedRow.shelfid },
        purchaseRate: selectedRow?.PurchaseRate,
        landedRate: selectedRow?.["Unit Landed Rate"],
        quantity: Number(getItemQty),
        amount: Number(AmountValue),
        discountPercentage: Number(discountPercent),
        discountAmount: Number(discAmount),
        netAmount: Number(netAmtVAl),
        mrpExcludeGst: Number(selectedRow?.mrpExcludeGst),
        cgstAmount: Number(cgstAmount),
        sgstAmount: Number(sgstAmount),
        taxableAmount: Number(taxableAmount),
        prescribeQuantity: 0,
        saleQuantity: Number(getItemQty),
        itemMaster: { id: selectedRow.id },
        genericId: selectedRow.ItemGenericId,
        gstPercent: selectedRow?.VATPercentage,
        gstAmount: finalGstAmount,
        conversion: selectedRow?.Conversion,
        IsConsignment: selectedRow?.IsConsignment,
      };

      //deep cloning the state variable medicineData into the variable medicineDataClone.
      let medicineDataClone = structuredClone(medicineData);

      medicineDataClone.result.push(requiredObj);

      let keySimilar = "id";
      let mergeVal = "quantity";
      medicineDataClone.result = mergeArray(medicineDataClone.result);
      //set the value of state variable medicineData.
      setMedicineData(medicineDataClone);
      setValue("typeDrugName", "");
      setValue("itemBatchNo", "");
      setValue("itemQty", "");
      setValue("discountPercentage", "");
      setValue("discountAmt", "");
      setValue("netAmt", "");
      setValue("gstPercent", "");
      setValue("gstAmount", "");
      setAmountValue(null);
      setDiscountPercent(null);
      setDiscAmount(null);
      setTaxableAmount(null);
      setEnteredQuantity(null);
      setIsFocused(false);
      setIsShrinked(false);
      setSearchFocus(true);
      setSelectedRow("");
    } else if (
      getItemQty > selectedRow.BalanceQty ||
      getItemQty > requiredRow["Balance Qty"]
    ) {
      errorAlert("Quantity is Greater Than Balance Quantity.");
    }
  };

  let itemKitWatch = watch("itemKit");

  React.useEffect(() => {
    if (itemKitWatch !== null) {
      setKitId(itemKitWatch?.id);
      setValue("kitQuantity", 1);
    }
  }, [itemKitWatch]);

  const handleAddKitItems = () => {
    if (kitId === null) {
      errorAlert("Please Select Item Kit.");
    }
    if (watch(`itemKit`) !== null) {
      if (itemsInKit !== null && quantityOfKitItems !== "") {
        let kitItems = [...itemsInKit];
        let medicineDataClone = structuredClone(medicineData);
        let requiredArr = [];
        for (let obj of kitItems) {
          let kitItemsObj = {};
          kitItemsObj.id = obj.Id;
          kitItemsObj.code = obj.Code;
          kitItemsObj.pharmacyItem = obj.Drug;
          kitItemsObj.balanceQuantity = obj.BalanceQty;
          kitItemsObj.quantity = Number(quantityOfKitItems);
          kitItemsObj.BatchesRequired = obj.BatchesRequired;

          requiredArr.push(kitItemsObj);

          setShowPreQty(true);
          //if the searchResult is some object ; then show the warning alert "Drug Name Already Exists".
          //When the searchResult is undefined ; then push the object into the medicineDataClone.result array.

          setValue("kitQuantity", "");
          setValue("itemKit", "");
          setItemsInKit([]);
          setKitId(null);

          //set the value of state variable medicineData.
        }
        let keySimilar = "id";
        let mergeVal = "quantity";
        medicineDataClone.result = [
          ...medicineDataClone.result,
          ...requiredArr,
        ];
        medicineDataClone.result = mergeArray(medicineDataClone.result);
        setMedicineData(medicineDataClone);
      } else {
        errorAlert("Kit Is Empty.");
      }
    }
  };

  React.useEffect(() => {
    setValue("kitQuantity", "");
    setValue("itemKit", null);
  }, [props.selectedPatientType]);

  let itemKitEnable = watch("isItemKit");

  const handleRemoveItem = () => {
    setValue("typeDrugName", "");
    setSelectedRow("");
    setRequiredRow("");
    setValue("itemBatchNo", "");
    setValue("itemQty", "");
    setValue("discountPercentage", "");
    setValue("discountAmt", "");
    setValue("netAmt", "");
    setValue("gstPercent", "");
    setValue("gstAmount", "");
    setSearchFocus(true);
    findNext();
  };

  let itemQuantityWatch = watch("itemQty");
  const loggedUser = JSON.parse(localStorage.getItem("userInfo"));

  //////
  const handleBatchSelection = (e) => {
    console.log("aaa", e);

    if (itemId !== null) {
      let isConsignment;
      if (selectedRow.isConsignment === true) {
        isConsignment = 1;
      } else {
        isConsignment = 0;
      }
      //get the batch information from itemId
      let obj = {
        expiryDate: new Date(),
        isConsignment: isConsignment,
        itemId: selectedRow.id,
        storeId: loginObj.storeId,
      };
      getItemBatchNumber(obj).then((response) => {
        // if (response.data.result.length > 0) {
          //The information stored in the below state variable is displayed in the
          //BatchInfoTable component.
          setDrugBatchInfo(response.data);
          // if (e.target.value === "") {
          !isFocusedBatchModal && setOpenBatchInfoModal(true);
          // }
        // } else if (response.data.result.length === 0) {
        //   //If the batch information is not available for the given drug ; then show the error message.
        //   errorAlert("Stock Unavailable.");
        //   setValue("typeDrugName", "");
        //   findNext();
        // }
      });
    }
  };

  ///

  console.log("isFocused", isFocusedBatchModal);

  return (
    <div className="mt-2 w-full  " id="drugSearch">
      <form
        className="w-full"
        onSubmit={(e) => {
          e.preventDefault();
          // handleSubmit(onSubmitDataHandler);
        }}
      >
        <div className="flex  items-center w-full">
          <div className="lg:col-span-2">
            <div className="flex gap-2 items-center w-full">
              <span className="text-base font-semibold whitespace-nowrap">
                Item Detail:
              </span>

              <div className="z-10 pr-1 w-80">
                <InputFieldForSearch
                  autoComplete="off"
                  name="typeDrugName"
                  variant="outlined"
                  label="Search Item"
                  control={control}
                  focused={searchFocus}
                  disabled={
                    (props.patientInfo === null &&
                      props.selectedPatientType === "IPD") ||
                    (props.patientInfo === null &&
                      props.selectedPatientType === "OPD") ||
                    (props.selectedPatientType === "IPD" &&
                      props.patientInfo.Discharged === true) ||
                    (props.selectedPatientType === "Counter" &&
                      props.isValidForCounter === false)
                      ? true
                      : null
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {selectedRow !== "" && getValues("typeDrugName") ? (
                          <IconButton onClick={handleRemoveItem}>
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        ) : (
                          ""
                        )}
                      </InputAdornment>
                    ),
                  }}
                  inputRef={{
                    ...register(`typeDrugName`, {
                      onChange: (e) => {
                        setSelectedRow("");
                        setRequiredRow("");
                        setDataResult([]);
                        setIsHidden("");
                        let obj = {
                          deptStore: 1,
                          deptStoreId: loginObj.storeId,
                          isConsignment: 0,
                          itemCode: "%",
                          itemName: "%",
                          searchString: e.target.value,
                        };
                        if (e.target.value !== "") {
                          getDruglist(obj).then((response) => {
                            if (response.data.result?.length > 0) {
                              setData({ result: response.data.result });
                              setDataResult(response.data.result);
                            }
                            // else {
                            //   setData({ result: [] });
                            //   setDataResult(response.data.result);
                            // }
                            // setGenericId(response.data.result[0].genericId);
                          });
                        }
                      },
                    }),
                  }}
                />
              </div>
              {selectedRow?.BatchesRequired === true ? (
                <div className="w-32 bg-white">
                  <TextField
                    disabled={typeDrugName === "" ? true : false}
                    inputRef={batchReference}
                    InputLabelProps={{
                      shrink: isShrinked === true ? true : false,
                    }}
                    size="small"
                    control={control}
                    name="itemBatchNo"
                    {...register("itemBatchNo")}
                    label="Batch No."
                    placeholder="Batch No."
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setIsFocusedBatchModal(false)
                        handleBatchSelection(e);
                      }
                    }}
                    onFocusCapture={(e) => {
                      if (e.target.value === "") {
                        handleBatchSelection(e);

                        // if (itemId !== null) {
                        //   let isConsignment;
                        //   if (selectedRow.isConsignment === true) {
                        //     isConsignment = 1;
                        //   } else {
                        //     isConsignment = 0;
                        //   }
                        //   //get the batch information from itemId
                        //   let obj = {
                        //     expiryDate: new Date(),
                        //     isConsignment: isConsignment,
                        //     itemId: selectedRow.id,
                        //     storeId: loginObj.storeId,
                        //   };
                        //   getItemBatchNumber(obj).then((response) => {
                        //     if (response.data.result.length > 0) {
                        //       //The information stored in the below state variable is displayed in the
                        //       //BatchInfoTable component.
                        //       setDrugBatchInfo(response.data);
                        //       if (e.target.value === "") {
                        //         setOpenBatchInfoModal(true);
                        //       }
                        //     } else if (response.data.result.length === 0) {
                        //       //If the batch information is not available for the given drug ; then show the error message.
                        //       errorAlert("Stock Unavailable.");
                        //       setValue("typeDrugName", "");
                        //       findNext();
                        //     }
                        //   });
                        // }
                      }
                    }}
                  />
                </div>
              ) : (
                ""
              )}

              <div className="w-24 bg-white">
                <TextField
                  type="number"
                  disabled={
                    typeDrugName === "" || requiredRow["Balance Qty"] === 0
                      ? true
                      : false
                  }
                  InputLabelProps={{
                    shrink: isFocused === true ? true : false,
                  }}
                  inputRef={quantityReference}
                  size="small"
                  {...register("itemQty")}
                  // sx={{
                  //   input: {
                  //     color: quantityError ? "#d32f2f" : "#212529",
                  //     borderColor:quantityError ? "#d32f2f" : "#212529"
                  //   },
                  //   label: {
                  //     color: quantityError ? "#d32f2f" : "#212529",
                  //   },
                  // }}
                  error={quantityError ? true : false}
                  onChange={(e) => {
                    handleQuantityChange(e);
                    if (e.target.value !== "") {
                      setIsFocused(true);
                    } else {
                      setIsFocused(false);
                    }
                  }}
                  onFocusCapture={(e) => {
                    setIsFocusedBatchModal(false);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      // var nextEl = findAddButton(document.activeElement);
                      // nextEl.focus();
                      handleAddPrescriptions();
                    }
                  }}
                  control={control}
                  name="itemQty"
                  label="Qty."
                  placeholder="Qty."
                />
              </div>
              <div className="w-24 bg-white">
                <TextField
                  size="small"
                  type="number"
                  name="discountPercentage"
                  disabled={
                    (props.patientInfo !== null &&
                      props.selectedPatientType === "OPD" &&
                      loggedUser?.storeName === "OUTPATIENT PHARMACY STORE") ||
                    (loggedUser?.storeName.toLowerCase() ===
                      "central pharmacy" &&
                      props.patientInfo?.isEmployee === 1 &&
                      props.patientInfo !== null)
                      ? false
                      : true
                  }
                  InputLabelProps={{
                    shrink: isFocused === true ? true : false,
                  }}
                  variant="outlined"
                  label="Disc.(%)"
                  {...register("discountPercentage")}
                  onChange={(e) => {
                    handleDiscount(e);
                  }}
                  control={control}
                />
              </div>
              <div className="w-24 bg-white">
                <TextField
                  size="small"
                  disabled
                  type="number"
                  name="discountAmt"
                  InputLabelProps={{
                    shrink: isFocused === true ? true : false,
                  }}
                  variant="outlined"
                  label="Disc. Amt."
                  {...register("discountAmt")}
                  control={control}
                />
              </div>

              <div className="w-24 bg-white">
                <TextField
                  InputLabelProps={{
                    shrink: isFocused === true ? true : false,
                  }}
                  size="small"
                  type="number"
                  name="netAmt"
                  variant="outlined"
                  label="Net Amt."
                  {...register("netAmt")}
                  disabled
                  control={control}
                />
              </div>
              <CommonButton
                type="button"
                onClick={handleAddPrescriptions}
                label="Add"
                className="h-9 px-3 text-base font-medium  bg-green-600 text-white rounded  overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out"
              />
              <div className="whitespace-nowrap">
                <CheckBoxField
                  control={control}
                  name="isItemKit"
                  label="Add Kit"
                />
              </div>
              <Tooltip title="Clear Druglist">
                <div className="">
                  <CommonButton
                    type="button"
                    onClick={clearPriscriptionData}
                    className="h-9"
                    label={<ClearButton />}
                  />
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
        {itemKitEnable === true ? (
          <div className="lg:col-span-2 my-4">
            <div className="flex gap-2 items-center">
              <span className="text-base font-semibold whitespace-nowrap">
                Item kit:
              </span>
              <div className="w-96 ml-7">
                <SearchDropdown
                  control={control}
                  handleInputChange={handleKitSearch}
                  name="itemKit"
                  dataArray={itemKitOptions}
                  isDisabled={
                    (props.patientInfo === null &&
                      props.selectedPatientType === "OPD") ||
                    (props.patientInfo === null &&
                      props.selectedPatientType === "IPD")
                      ? true
                      : null
                  }
                  placeholder="Item Kit"
                  isClearable={true}
                  isMulti={false}
                  isSearchable={true}
                />
              </div>

              <div className="w-36">
                <InputField
                  name="kitQuantity"
                  type="number"
                  variant="outlined"
                  label="Qty"
                  control={control}
                  disabled={props.patientInfo === null ? true : null}
                />
              </div>
              <CommonButton
                type="button"
                onClick={handleAddKitItems}
                label="Add"
                className="h-9 px-3 text-base font-medium  bg-green-600 text-white rounded  overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out"
              />
            </div>
          </div>
        ) : (
          ""
        )}

        <div>
          {displayTable ? (
            <div
              className={`grid gap-y-2 mt-2 lg:gap-y-2 xl:grid-cols-2  lg:flex lg:space-x-3 overflow-hidden`}
            >
              <div className="">
                <h1 className={`text-md  font-semibold ${isHidden}`}>
                  Drug List
                </h1>
                <div className=" " ref={DrugListTableElement}>
                  {/* The DrugList Component would be rendered ; if and only if the
                  below two conditions are satisfied */}
                  {data.result.length > 0 && dataResult.length > 0 ? (
                    <DrugList
                      dataResult={dataResult}
                      setDataResult={setDataResult}
                      setSelectedRow={setSelectedRow}
                      data={data}
                      selected={selected}
                      setSelected={setSelected}
                      setArrowKeyName={setArrowKeyName}
                      arrowKeyName={arrowKeyName}
                      setGenericId={setGenericId}
                      isDruglistFocused={isDrugListFocused}
                    />
                  ) : null}
                </div>
              </div>
              <div className=" rounded-md ">
                {dataResultTest?.length > 0 ? (
                  <h1 className={`text-md  font-semibold pl-2 ${isHidden}`}>
                    Alternative Drug List
                  </h1>
                ) : (
                  ""
                )}

                <div className="" ref={AlternativeDrugListElement}>
                  {dataTest.result.length > 0 ? (
                    <AlternativeDrugList
                      dataResult={dataResultTest}
                      setDataResult={setDataResultTest}
                      setSelectedRow={setSelectedRow}
                      data={dataTest}
                      setArrowKeyName={setArrowKeyName}
                      arrowKeyName={arrowKeyName}
                      isDruglistFocused={isDrugListFocused}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div className=" items-center  mt-1">
          <PrescriptionTable
            setIsValidQuantity={props.setIsValidQuantity}
            showPreQty={showPreQty}
            data={medicineData}
            setData={setMedicineData}
            setAlternateTableList={setAlternateTableList}
            setDiscountPercent={props.setDiscountPercent}
            setDiscountAmount={props.setDiscountAmount}
            discountPercent={props.discountPercent}
            discountAmount={props.discountAmount}
            setCalculatedFormData={props.setCalculatedFormData}
            setFinalPrescriptions={props.setFinalPrescriptions}
            selectedPatientType={props.selectedPatientType}
            setPrescriptionLength={props.setPrescriptionLength}
            patientInfo={props.patientInfo}
            employeeDetails={props.employeeDetails}
          />
        </div>
      </form>
      {openBatchInfoModal &&
      drugBatchInfo !== null &&
        drugBatchInfo.hasOwnProperty("result")
        // && drugBatchInfo.result.length > 0
        ? (
        <BatchInfoModal
          selectedRow={requiredRow}
          setSelectedRow={setRequiredRow}
          drugBatchInfo={drugBatchInfo}
          setDrugBatchInfo={setDrugBatchInfo}
          open={openBatchInfoModal}
          setOpen={setOpenBatchInfoModal}
          handleOpen={handleOpen}
          handleClose={handleClose}
          drugItemInfo={drugItemInfo}
          findNext={findNext}
          functionSetvalue={functionSetvalue}
          setIsFocusedBatchModal={setIsFocusedBatchModal}
        />
      ) : null}
    </div>
  );
}

export default DrugSearch;
