import { Box, Checkbox, Modal } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTablePaginationNew from "../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import RadioField from "../../../../common/components/FormFields/RadioField";
import {
  errorAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import {
  getIndentDetailsForPOMultiple,
  getIndentForPOMultiple,
} from "../../../services/generalStore/pomultipleServices/POMultipleServices";
import { fetchStore } from "../../../services/purchase/EnquiryServices";
import MultipleItemDetails from "./MultipleItemDetails";
import { fetchApplicableStores } from "../../../../commonServices/miscellaneousServices/MiscServices";

const verification = [
  {
    id: "Pending",
    value: "Pending",
    label: "Pending",
  },
  {
    id: "Close",
    value: "Close",
    label: "Close",
  },
];
const isUrgent = [
  {
    id: "All",
    value: "All",
    label: "All",
  },
  {
    id: "Yes",
    value: "Yes",
    label: "Yes",
  },
  {
    id: "No",
    value: "No",
    label: "No",
  },
];

export default function POMultipleIndentModal(props) {
  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "98%",
    overflowY: "scroll",
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 20,
    p: 2,
  };

  const defaultValues = {
    verification: "Pending",
    isUrgent: "All",
    fromDate: new Date(),
    toDate: new Date(),
    storeName: null,
  };

  const {
    setValue,
    control,
    handleSubmit,
    reset,
    register,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  //1st Table
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = useState();
  const [dataPOIndentList, setDataPOIndentList] = React.useState({
    result: [],
  });
  const [dataResult, setDataResult] = React.useState([]);
  ///1st Table

  const [store, setStore] = React.useState([]);
  const [storeId, setStoreId] = React.useState(null);
  const [storeName, setStoreName] = React.useState([]);
  const [dataPOItemList, setDataPOItemList] = React.useState([]);
  const [selectedDataPOItemList, setSelectedDataPOItemList] = React.useState(
    []
  ); //2nd table getting selected indent details and pass to 1st table

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  var storeID = userInfo.storeId;
  let toStoreId = userInfo?.storeId;
  let fromDate = watch("fromDate");
  let toDate = watch("toDate");

  let verificationVal = watch("verification");
  let isUrgentVal = watch("isUrgent");

  React.useEffect(() => {
    fetchApplicableStores(storeID)
      .then((response) => response.data)
      .then((res) => {
        setStore(res.result);
      });
  }, []);

  var isClose;
  if (verificationVal === "Pending") {
    isClose = false;
  } else if (verificationVal === "Close") {
    isClose = true;
  }

  var Urgent;
  if (isUrgentVal === "All") {
    Urgent = 2;
  } else if (isUrgentVal === "Yes") {
    Urgent = 0;
  } else if (isUrgentVal === "No") {
    Urgent = 1;
  }

  const filterData = () => {
    populateTable();
  };

  let defaultParams = {
    // fromDate: format(selectedFromDate, "yyyy-MM-dd'T'hh:mm:ss.000"),
    fromDate: fromDate,
    fromStoreId: storeId,
    isClosed: isClose,
    isUrgent: Urgent,
    page: page,
    size: rowsPerPage,
    // toDate: format(selectedToDate, "yyyy-MM-dd'T'hh:mm:ss.000"),
    toDate: toDate,
    toStoreId: toStoreId,
  };

  const populateTable = () => {
    getIndentForPOMultiple(defaultParams)
      .then((response) => {
        setCount(response.data.count);
        return response.data;
      })
      .then((res) => {
        setDataPOIndentList(res);
        setDataResult(res.result);
      })
      .catch((error) => {
        errorAlert(error.message);
      });
  };

  //Table
  const renderActions = (row, index) => {
    return (
      <div className="flex gap-3">
        <div className="flex pt-1 justify-center gap-2 cursor-pointer">
          <>
            <Checkbox
              size="small"
              className="w-3 h-3 border border-gray-600 rounded bg-transparent"
              type="checkbox"
              color="primary"
              name={`checkbox${index}`}
              onChange={(e) => {
                handleClick(e, row, index);
              }}
            />
          </>
        </div>
      </div>
    );
  };

  //Multiselect checkbox
  const handleClick = (e, row, index) => {
    let reqArr = [...dataPOItemList];

    if (e.target.checked === true) {
      getIndentDetailsForPOMultiple(row.IndentId)
        .then((response) => {
          return response.data;
        })
        .then((res) => {
          for (let obj of res.result) {
            reqArr.push(obj);
          }

          setDataPOItemList(reqArr);
        });
    } else if (e.target.checked === false) {
      let filter = reqArr.filter((item) => item.IndentId !== row.IndentId);
      setDataPOItemList(filter);
    }
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[80%]">
          <CancelPresentationIconButton
            onClick={() => {
              props.handleClose();
              setDataPOItemList([]);
              setDataPOIndentList([]);
              reset(defaultValues);
            }}
          />
          <div className="row">
            <fieldset className="border border-gray-300 text-left lg:px-4 py-2 rounded mt-1 lg:m-2 ">
              <legend className="px-2 ml-2 lg:ml-0 font-bold text-gray-800">
                Search Indent (Multiple)
              </legend>
              <form className="w-full md:px-3 lg:px-0 pt-2">
                <div className="grid grid-cols-4 gap-2 pr-2">
                  <div className="flex gap-3">
                    <DatePickerFieldNew
                      control={control}
                      name="fromDate"
                      label="From Date"
                      value={new Date()}
                      onChange={(newValue) => {
                        setValue("fromDate", newValue);
                      }}
                      disableFuture={true}
                      inputFormat="dd-MM-yyyy"
                    />
                    <DatePickerFieldNew
                      control={control}
                      name="toDate"
                      label="To Date"
                      value={new Date()}
                      onChange={(newValue) => {
                        setValue("toDate", newValue);
                      }}
                      disableFuture={true}
                      inputFormat="dd-MM-yyyy"
                    />
                  </div>
                  <div className="w-full whitespace-nowrap">
                    <DropdownField
                      control={control}
                      error={errors.storeName}
                      name="storeName"
                      placeholder="Store Name"
                      dataArray={store}
                      isClearable={true}
                      isMulti={true}
                      inputRef={{
                        ...register("storeName", {
                          onChange: (e) => {
                            let storeName = e.target.value;
                            let arr = [];
                            for (let obj of storeName) {
                              arr.push(obj.id);
                            }

                            setStoreId(arr.map(String));
                          },
                        }),
                      }}
                    />
                  </div>

                  <div className="flex col-span-2 gap-2">
                    <div className="flex whitespace-nowrap items-center space-x-2">
                      <label className="font-bold">Status:</label>

                      <div className="pt-1">
                        <RadioField
                          label=""
                          name="verification"
                          control={control}
                          dataArray={verification}
                        />
                      </div>
                    </div>
                    <div className="flex gap-2">
                      <div className=" flex whitespace-nowrap  items-center space-x-2">
                        <label className="font-bold">Is Urgent:</label>

                        <div className="pt-1">
                          <RadioField
                            label=""
                            name="isUrgent"
                            control={control}
                            dataArray={isUrgent}
                          />
                        </div>
                      </div>

                      <CommonButton
                        type="button"
                        searchIcon={true}
                        onClick={filterData}
                        className="bg-customBlue text-white"
                      />
                    </div>
                  </div>
                </div>
              </form>

              <div className="w-full">
                {/* <h1>Indent List</h1> */}

                {dataPOIndentList.hasOwnProperty("result") &&
                dataPOIndentList.result.length > 0 ? (
                  <CommonDynamicTablePaginationNew
                    data={dataPOIndentList}
                    dataResult={dataResult}
                    setDataResult={setDataResult}
                    actionsRequired={true}
                    populateTable={populateTable}
                    tableApiFunc={getIndentForPOMultiple}
                    defaultParams={defaultParams}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    count={count}
                    //Function to Show lineItem
                    renderActions={renderActions}
                    highlightRow={false}
                    tableClass={"rounded lg:h-52 border"}
                    removeHeaders={["id"]}
                    tableHeading={"Indent List"}
                  />
                ) : (
                  <h3 className="flex justify-center my-20 font-bold text-gray-600">
                    No Records Found...
                  </h3>
                )}
              </div>

              <div className="w-full">
                <h1 className="font-bold">Item Details</h1>
                {dataPOItemList.length > 0 ? (
                  <MultipleItemDetails
                    data={dataPOItemList}
                    selectedDataPOItemList={selectedDataPOItemList}
                    setSelectedDataPOItemList={setSelectedDataPOItemList}
                  />
                ) : (
                  <h3 className="flex justify-center my-20 font-bold text-gray-600">
                    No Records Found...
                  </h3>
                )}
              </div>

              {/* {dataPOItemList.length > 0 ? ( */}
              <div className="flex justify-end gap-2">
                <CommonButton
                  type="button"
                  className="border border-customRed text-customRed"
                  label="Reset"
                  onClick={() => {
                    reset(defaultValues);
                    setDataPOIndentList([]);
                    //indent modal send table
                    setDataPOItemList([]); //store data
                    setSelectedDataPOItemList([]); //selected dataPOItemList
                  }}
                />
                {selectedDataPOItemList.length > 0 ? (
                  <div className="grid justify-end">
                    <CommonButton
                      type="button"
                      className="bg-customGreen text-white"
                      label="Ok"
                      onClick={() => {
                        if (dataPOItemList.length !== 0) {
                          props.handleClose();
                          props.setMultiIndentAgainstItem(
                            selectedDataPOItemList
                          );
                          reset(defaultValues);
                          setDataPOItemList([]);
                          setDataPOIndentList([]);
                        } else if (dataPOItemList.length === 0) {
                          warningAlert("Please Select Atleast One Item...!");
                        }
                      }}
                    />
                  </div>
                ) : null}
              </div>
              {/* ) : null} */}
            </fieldset>
          </div>
        </Box>
      </Modal>
    </>
  );
}
