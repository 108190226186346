import { Box, Checkbox } from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { format, subDays } from "date-fns";
import * as React from "react";
import { useEffect } from "react";
import { EditIcon } from "../../../../assets/CommonAssets";
import IVFluidEditModal from "../IVFluidEditModal";
import { useState } from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: theme.palette.primary.main,
  whiteSpace: "nowrap",
  color: theme.palette.primary.contrastText,
  letterSpacing: 0,
}));

const IVFlowTable = (props) => {
  let localStorageValue = JSON.parse(localStorage.getItem("userInfo"));
  const [openIVFluidEditModal, setOpenIvFluidEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleCheckboxChange = (row, event, fieldName) => {
    props.setIvId(row?.ivID);
    props.setSelectedFieldName(fieldName);
    if (event.target.checked === true) {
      props.setOpenConfirmationModal(true);
    }
    props.setStatus(event.target.checked);
  };

  useEffect(() => {
    console.log("props.data", props.data);
  }, [props.data]);

  let ivHoldDisabledValue = props.userActions.some(
    (action) => action?.action === "Iv Hold" && action.isAction
  );
  let ivStopDisabledValue = props.userActions.some(
    (action) => action?.action === "Iv Stop" && action.isAction
  );
  let ivCancelDisabledValue = props.userActions.some(
    (action) => action?.action === "Iv Cancel" && action.isAction
  );
  let ivStartDisabledValue = props.userActions.some(
    (action) => action?.action === "Iv Start" && action.isAction
  );
  let ivEndDisabledValue = props.userActions.some(
    (action) => action?.action === "Iv End" && action.isAction
  );
  console.log("ivHoldDisabledValue", props);

  return (
    <div className="grid w-auto">
      <Box sx={{ width: "100%", overflow: "hidden" }}>
        <Paper sx={{ width: "100%", my: 1 }}>
          <TableContainer
            sx={{
              "&::-webkit-scrollbar": {
                width: 7,
                height: 10,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#7393B3",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "lightBlue",
              },
            }}
            className="rounded h-[450px] 2xl:h-[450px] border"
          >
            <Table size="small" stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow className="tracking-normal">
                  <StyledTableCell>Actions</StyledTableCell>
                  <StyledTableCell>Date</StyledTableCell>
                  <StyledTableCell>IV Fluid</StyledTableCell>
                  <StyledTableCell>
                    Intravenous Infusion Details
                  </StyledTableCell>
                  <StyledTableCell>Dose</StyledTableCell>
                  <StyledTableCell>Volume</StyledTableCell>
                  <StyledTableCell>Rate of Infusion</StyledTableCell>
                  <StyledTableCell>Doctor</StyledTableCell>
                  <StyledTableCell>Hold</StyledTableCell>
                  <StyledTableCell>Stop</StyledTableCell>
                  <StyledTableCell>Cancel</StyledTableCell>
                  <StyledTableCell>Start</StyledTableCell>
                  <StyledTableCell>Start By</StyledTableCell>
                  <StyledTableCell>End</StyledTableCell>
                  <StyledTableCell>Stopped By</StyledTableCell>
                  <StyledTableCell>Hold By</StyledTableCell>
                  <StyledTableCell>Start Time</StyledTableCell>
                  <StyledTableCell>End Time</StyledTableCell>
                  <StyledTableCell>Remark</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.data?.map((row, index) => {
                  const currentDate = new Date(
                    localStorageValue.currentDateTime
                  );

                  const formattedNextDayDate = format(
                    currentDate,
                    "dd/MM/yyyy"
                  );
                  const prevDay = subDays(currentDate, 1);
                  const formattedPrevDayDate = format(prevDay, "dd/MM/yyyy");

                  // Determine if row.date is today or yesterday
                  const isCurrentDay = formattedNextDayDate === row.date;
                  const isPrevDay = formattedPrevDayDate === row.date;
                  const shouldDisableCheckbox = !(isCurrentDay || isPrevDay);

                  return (
                    <TableRow
                      key={row.ivId}
                      rowSpan={row.intravenousInfusionDetailsDtoList?.length}
                      className="tracking-normal"
                    >
                      <TableCell>
                        <button
                          type="button"
                          onClick={() => {
                            setOpenIvFluidEditModal(true);
                            setSelectedRow(row)
                          }}
                        >
                          <EditIcon />
                        </button>
                      </TableCell>

                      <TableCell className="whitespace-nowrap border-l-2 border-r-2 ">
                        {row.date}
                      </TableCell>
                      <TableCell>{row.ivFluid}</TableCell>
                      <TableCell className="border-l-2 ">
                        {row.intravenousInfusionDetailsDtoList?.map((item) => {
                          return (
                            <p className="whitespace-nowrap">
                              {item.drugAdded}
                            </p>
                          );
                        })}
                      </TableCell>
                      <TableCell className="border-l-2 ">
                        {row.intravenousInfusionDetailsDtoList?.map((item) => {
                          return (
                            <p className="whitespace-nowrap">
                              {item.doseOfAdded}
                            </p>
                          );
                        })}
                      </TableCell>
                      <TableCell className="border-l-2 ">{row.vol}</TableCell>
                      <TableCell className="border-l-2 ">
                        {row.rateOfInfusion}
                      </TableCell>
                      <TableCell className="whitespace-nowrap border-l-2">
                        {row.doctor}
                      </TableCell>
                      <TableCell className="border-l-2 " padding="checkbox">
                        <Checkbox
                          disabled={
                            row.stop === true || row.end
                              ? true
                              : shouldDisableCheckbox === true &&
                                ivHoldDisabledValue === true
                              ? true
                              : false
                          }
                          checked={row.hold}
                          name={`hold${index}`}
                          onChange={(event) =>
                            handleCheckboxChange(row, event, "hold")
                          }
                        />
                      </TableCell>
                      <TableCell className="border-l-2 " padding="checkbox">
                        <Checkbox
                          checked={row.stop}
                          disabled={
                            row.hold || row.end
                              ? true
                              : false ||
                                (shouldDisableCheckbox && ivStopDisabledValue)
                          }
                          name={`stop${index}`}
                          onChange={(event) =>
                            handleCheckboxChange(row, event, "stop")
                          }
                        />
                      </TableCell>
                      <TableCell className="border-l-2 " padding="checkbox">
                        <Checkbox
                          checked={row.cancel}
                          // disabled={ivCancelDisabledValue && disabled}
                          disabled={
                            row.stop === true || row.end
                              ? true
                              : false ||
                                (ivCancelDisabledValue &&
                                  row.hold === false &&
                                  shouldDisableCheckbox === false)
                              ? false
                              : true
                          }
                          name={`cancel${index}`}
                          onChange={(event) =>
                            handleCheckboxChange(row, event, "cancel")
                          }
                        />
                      </TableCell>
                      <TableCell className="border-l-2 " padding="checkbox">
                        <Checkbox
                          checked={row.start}
                          // disabled={ivStartDisabledValue && disabled}
                          disabled={
                            row.stop || row.end
                              ? true
                              : false ||
                                (ivStartDisabledValue &&
                                  shouldDisableCheckbox === false)
                              ? false
                              : true
                          }
                          name={`start${index}`}
                          onChange={(event) =>
                            handleCheckboxChange(row, event, "start")
                          }
                        />
                      </TableCell>

                      <TableCell className="border-l-2 whitespace-nowrap">
                        {row.startBy}
                      </TableCell>
                      <TableCell className="border-l-2 " padding="checkbox">
                        <Checkbox
                          checked={row.end}
                          disabled={
                            row.hold || row.stop
                              ? true
                              : ivEndDisabledValue && shouldDisableCheckbox
                          }
                          name={`end${index}`}
                          onChange={(event) =>
                            handleCheckboxChange(row, event, "end")
                          }
                        />
                      </TableCell>
                      <TableCell className="border-l-2 whitespace-nowrap">
                        {row.stoppedBy}
                      </TableCell>
                      <TableCell className="border-l-2 whitespace-nowrap">
                        {row.holdBy}
                      </TableCell>
                      <TableCell className="whitespace-nowrap border-l-2">
                        {row.startTime}
                      </TableCell>
                      <TableCell className="whitespace-nowrap border-l-2">
                        {row.endTime}
                      </TableCell>
                      <TableCell className="border-l-2 ">
                        {row.remark}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      {openIVFluidEditModal && (
        <IVFluidEditModal
          open={openIVFluidEditModal}
          handleClose={() => setOpenIvFluidEditModal(false)}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          userActions={props.userActions}
          menuId={props.menuId}
          privilege={props.privilege}
          setOpenLoader={props.setOpenLoader}
          populateTable={props.populateTable}
        />
      )}
    </div>
  );
};

export default IVFlowTable;
