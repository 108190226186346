import { Box, Modal } from "@mui/material";
import React from "react";
import BloodIssueNote from "../../../../bloodBank/components/bloodIssueNote/BloodIssueNote";
import BloodRequisitionNote from "../../../../bloodBank/components/bloodRequisitionNote/BloodRequisitionNote";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import PatientIndent from "../../../../inventory/components/common/patientIndent/PatientIndent";
import CarePlan from "../../../../ipd/components/carePlan/CarePlan";
import DischargeSummaryTabWise from "../../../../ipd/components/dischargeSummary/dischargeSummaryTabwise/DischargeSummaryTabWise";
import DrugAdministratorOrder from "../../../../ipd/components/drugadministratororder/DrugAdministratorOrder";
import NarcoticDrug from "../../../../ipd/components/narcoticdrugForm/NarcoticDrug";
import PatientTransfer from "../../../../ipd/components/patientTransfer/PatientTransfer";
import { restrictSamePatientToMultiUserActivity } from "../../../../login/services/LoginServices.js";
import ComplaintManagement from "../../../../taskManager/components/ComplaintManagement/ComplaintManagement";
import PushTool from "../../../../taskManager/components/pushTool/PushTool";
import DivaTool from "../../divatool/DivaTool";
import DrugAdministrationChart from "../../drugadministratorchart/DrugAdministrationChart";
import InPatientIssue from "../../inPatientIssue/InPatientIssue";
import InPatientReturn from "../../inPatientReturn/InPatientReturn";
import SampleCollectionForColourTube from "../../sampleCollectionForColourTube/SampleCollectionForColourTube";
import PendingAntibioticForm from "../../../../taskManager/components/pendingAntibioticForm/PendingAntibioticForm";
import OtConsent from "../../../../operationTheater/components/otConsent/OtConsent ";
import EditToDoTaskModal from "./EditToTaskModal";
import TentativeDischargeModal from "../../../../ipd/components/dischargeSummary/dischargeSummaryTabwise/TentativeDischargeModal.js";

const DivatoolModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  height: "auto",
  maxHeight: "98%",
  overflowY: "auto",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  p: 1,
};

export default function ClinicalIconModal(props) {
  const { setOpen, privilege } = props;
  let token = JSON.parse(localStorage.getItem("userInfo"));

  const checkSimilarTransaction = (isInsert, patientDtls) => {
    let obj = {
      id: {
        documentId: 0,
        functionalityId: props?.menuId,

        opdIpd: 1,
        opdIpdId: (!!patientDtls && patientDtls.admissionId) || 0,
        patientId: (!!patientDtls && patientDtls.PatientId) || 0,
        userId: token?.userId,
      },
      isInsert: isInsert,
    };

    restrictSamePatientToMultiUserActivity(obj)
      .then((response) => response.data)
      .then((res) => {});
  };

  return (
    <div>
      <Modal open={props}>
        <Box sx={DivatoolModalStyle}>
          <div
            onClick={() => {
              setOpen(false);
              privilege === "Discharge Summary" &&
                checkSimilarTransaction(false, props);
            }}
          >
            <CancelPresentationIconButton />
          </div>

          <div className="-mt-10">
            {privilege === "In Patient Issue" ? (
              <InPatientIssue {...props} patientInfo={props.patientInfo} />
            ) : privilege === "In Patient Return" ? (
              <InPatientReturn {...props} />
            ) : privilege === "Patient Indent" ? (
              <PatientIndent {...props} />
            ) : privilege === "Sample Collection For Colour Tube" ? (
              <SampleCollectionForColourTube {...props} opdIpd={1} />
            ) : privilege === "Discharge Summary" ? (
              <DischargeSummaryTabWise {...props} />
            ) : privilege === "Care Plan" ? (
              <CarePlan {...props} />
            ) : privilege === "DIVA Tool Score" ? (
              <DivaTool {...props} />
            ) : privilege === "Drug Administrator Order" ? (
              <DrugAdministratorOrder {...props} />
            ) : privilege === "Drug Administrator Chart(Inventory)" ? (
              <DrugAdministrationChart {...props} />
            ) : privilege === "Push Tool" ? (
              <PushTool {...props} />
            ) : privilege === "Patient Transfer" ? (
              <PatientTransfer {...props} />
            ) : privilege === "Complaint Management" ? (
              <ComplaintManagement {...props} />
            ) : privilege === "Blood Requisition Note" ? (
              <BloodRequisitionNote {...props} />
            ) : privilege === "Blood Issue Note" ? (
              <BloodIssueNote {...props} />
            ) : privilege === "Narcotic Drug Form" ? (
              <NarcoticDrug {...props} />
            ) : privilege === "Pending Antibiotic Form" ? (
              <PendingAntibioticForm {...props} />
            ) : privilege === "Add Consent" ? (
              <OtConsent {...props} />
            ) : privilege === "SubmitToDo" ? (
              <EditToDoTaskModal {...props} /> //new
            ) : null}
          </div>
        </Box>
      </Modal>
    </div>
  );
}

