// /api/returnFromDepartment/issuedItemList

import authHeader from "../../../../authentication/authservices/auth-header";
import apiClient from "../../../../http-common";

/// return post

export const postReturnFromDeptData = (finalObj) => {
  return apiClient.post(
    `/returnFromDepartment/saveReturnFromDepartment`,
    finalObj,
    {
      headers: authHeader(),
    }
  );
};
/// return indent list 1

export const fetchReturnDeptIndentList = (indentObj) => {
  return apiClient.post(`/returnFromDepartment/issuedItemList`, indentObj, {
    headers: authHeader(),
  });
};

//get indent items 2
export const fetchReturnDeptIndent = (issueId) => {
  return apiClient.get(`/returnFromDepartment/itemList/${issueId}`, {
    headers: authHeader(),
  });
};

//Item  Return Items
export const fetchItemDetails = (admissionId, ipdIssueId) => {
  //
  return apiClient.get(
    `/inPatientReturn/itemDetailsList/${admissionId}/${ipdIssueId}`,
    {
      headers: authHeader(),
    }
  );
};

export const fetchPrintInDepartmentReturn = (returnFromdeptId) => {
  return apiClient.get(
    `/reports/inventory/returnFromDepartment/${returnFromdeptId}`,
    {
      responseType: "blob",
      headers: authHeader(),
    }
  );
};
