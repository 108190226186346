import React, { useEffect, useState } from "react";
import { InamdarHospiLogo } from "../../assets/printAssets/InmadarHospiLogo";
import { fetchLogFilePrint } from "../services/drugAdministrationChartService/DrugAdministartionChartService";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";
import { useRef } from "react";

export default function DrugAdmChartLogFilePrint({ AdmissionId }) {
  const printRef = useRef(null);
  const [dataResult, setDataResult] = useState();

  useEffect(() => {
    fetchLogFilePrint(AdmissionId)
      .then((response) => response.data)
      .then((res) => {
        setDataResult(res.result);
      });
  }, [AdmissionId]);

  useEffect(() => {
    dataResult && printRef && GeneratePrint(printRef);
  }, [dataResult]);
console.log("dataResult",dataResult);
  return (
    <table className="w-full" ref={printRef}>
      <thead
        className=" w-full"
        style={{
          "-fs-table-paginate": "paginate",
        }}
      >
        <div className=" my-2">
          <div className=" my-2 grid grid-cols-[15%_5%_80%] text-[11px] ">
            <div className=" font-semibold">MRNo</div>
            <div>:</div>
            <div>{dataResult?.MRNo}</div>
            <div className=" font-semibold">Patient Name</div>
            <div>:</div>
            <div>{dataResult?.PatientName}</div>
            <div className=" font-semibold">Bed No</div>
            <div>:</div>
            <div>{dataResult?.BedNo}</div>
          </div>
        </div>

        <div className=" p-1  grid grid-cols-[10%_10%_25%_10%_10%_10%_10%_15%] text-[11px] border-t-[1px] border-b-[1px] border-black font-semibold ">
          <div>Update Data</div>
          <div>Time</div>
          <div>Drug Name</div>
          <div>Duration</div>
          <div>Dose</div>
          <div>Frequency</div>
          <div>Route</div>
          <div>Update By</div>
        </div>
      </thead>
      <tbody className="w-full">
        <div className=" mt-1">
          {dataResult?.DrugList?.map((data) => {
            return (
              <div className=" grid grid-cols-[10%_10%_25%_10%_10%_10%_10%_15%] text-[11px]">
                <div>{data?.UpdateDate}</div>
                <div>{data?.UpdatedTime}</div>
                <div>{data?.DrugName}</div>
                <div>{data?.Duration}</div>
                <div>{data?.Dose}</div>
                <div>{data?.Frequency}</div>
                <div>{data?.Route}</div>
                <div>{data?.UpdatedBy}</div>
              </div>
            );
          })}
        </div>
      </tbody>
    </table>
  );
}
