import React from "react";
import InputField from "../../common/components/FormFields/InputField";
import DrugSearch from "../drugitemsearch/DrugSearch";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../common/components/Toasts/CustomToasts";
import CounterForm from "./CounterForm";
import IpdForm from "./IpdForm";
import OpdForm from "./OpdForm";
// import IndentModal from "./IndentModal";
import { useLocation } from "react-router-dom";
import CommonButton from "../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import DropdownField from "../../common/components/FormFields/DropdownField";
import SearchDropdown from "../../common/components/FormFields/searchDropdown.jsx";
import { fetchUserActionsByMenuId } from "../../commonServices/functionalityServices/FunctionalityServices";
import CommonPrintModal from "../CommonPrintModal";
import {
  getDoctorsDropdownForCounter,
  getEmployeeDetails,
  getPatientInfoByPatientId,
  getPatientSearchAutocomplete,
  printCounterSales,
  saveCounterSales,
} from "../PharmacyServices";
import SalesModal from "../counterSalesReturn/SalesModal";
import IndentModal from "./IndentModal";
import { format } from "date-fns";
import { routeToPrinterDevice } from "../../commonServices/barCodeServices/BarCodeServices.js";
import { blobToBase64 } from "../../common/components/printModal/BlobToB64.js";

export default function CounterSales() {
  let loginObj = JSON.parse(localStorage.getItem("userInfo"));
  let printerSettings = JSON.parse(localStorage.getItem("defaultPrinters"));

  

  const schema = yup.object().shape({
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    doctorName: yup.object().nullable().required(),
    address: yup.string().required("Required"),
    mobileNo: yup
      .string()
      .required()
      .max(10)
      .min(10)
      .matches(/^[0-9]/),
  });

  const defaultValues = {
    firstName: "",
    lastName: "",
    doctorName: null,
    address: "",
    mobileNo: "",
    searchPatient: null,
  };

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });
  const {
    control,
    formState: { errors },
    register,
    getValues,
    reset,
    setValue,
    handleSubmit,
    watch,
    trigger,
  } = methods;

  // State variable and useEffect used for radio buttons
  const [selectedPatientType, setSelectedPatientType] = React.useState(null);
  const [isValidQuantity, setIsValidQuantity] = React.useState(true);

  //state variable to populate the
  const [patientOptions, setPatientOptions] = React.useState([]);

  //state variable to store the patched patient information
  const [patientInfo, setPatientInfo] = React.useState(null);

  //state variable to store the patient's prescription data
  const [prescriptionData, setPrescriptionData] = React.useState([]);

  //state variable to store the patient's payment info modes
  const [paymentInfoList, setPaymentInfoList] = React.useState(null);

  //state variable to store the discount percentage in the opd / ipd form
  const [discountPercent, setDiscountPercent] = React.useState(null);
  const [discountAmount, setDiscountAmount] = React.useState(null);

  //The total calculation of all the rows in the prescription table is stored in the state variable calculatedFormData
  const [calculatedFormData, setCalculatedFormData] = React.useState(null);

  //The final array of prescriptions to be posted in the post service
  const [finalPrescriptions, setFinalPrescriptions] = React.useState(null);

  //The state variable to store the opd / ipd / counter form data
  const [finalFormData, setFinalFormData] = React.useState(null);

  const [billPdf, setBillPdf] = React.useState("");

  const [doctorOptions, setDoctorOptions] = React.useState([]);

  const [prescriptionLength, setPrescriptionLength] = React.useState(0);

  const [proceedButtonStatus, setProceedButtonStatus] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [openDraftBill, setOpenDraftBill] = React.useState(false);
  const [resetQtyFlag, setResetQtyFlag] = React.useState(1);
  const [openSearchBillsModal, setOpenSearchBillsModal] = React.useState(false);
  const [employeeDetails, setEmployeeDetails] = React.useState(null);
  const [isValidForCounter, setIsValidForCounter] = React.useState(true);
  const [counterError, setCounterError] = React.useState(false);
  const [resetStates, setResetStates] = React.useState(0);
  const [addedMedicines, setAddedMedicines] = React.useState([]);
  const [openSaveChild, setOpenSaveChild] = React.useState(false);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [postObject, setPostObject] = React.useState({});
  const [openCommonPrintModal, setOpenCommonPrintModal] = React.useState(false);
  const [userActions, setUserActions] = React.useState([]);
  const [privilege, setPrivilege] = React.useState("Create");
  const [counterFocus, setCounterFocus] = React.useState(0);
  const [printBase64, setPrintBase64] = React.useState("");

  let location = useLocation();

  React.useEffect(() => {
    fetchUserActionsByMenuId(location?.state?.menuId)
      .then((response) => response.data)
      .then((res) => {
        setUserActions(res.result);
      });
  }, []);

  const handleClosePrintModal = () => {
    if (openCommonPrintModal === true) {
      setOpenCommonPrintModal(false);
    }
  };

  const handleCloseSaveChild = () => {
    if (openSaveChild === true) {
      setOpenSaveChild(false);
    }
  };

  React.useEffect(() => {
    let searchString = "%20";
    getDoctorsDropdownForCounter(searchString)
      .then((response) => response.data)
      .then((res) => {
        setDoctorOptions(res.result);
      });
  }, []);

  let serachePatientVal = watch("searchPatient");

  const handlePatientInfo = (patientId, opdIpd) => {
    getPatientInfoByPatientId(patientId, opdIpd, loginObj?.storeId)
      .then((response) => response.data)
      .then((res) => {
        setPatientInfo(res.result);
      });
  };

  React.useEffect(() => {
    if (serachePatientVal !== null) {
      let opdIpd;
      if (selectedPatientType === "OPD") {
        opdIpd = 0;
      } else if (selectedPatientType === "IPD") {
        opdIpd = 1;
      }

      let patientId = serachePatientVal?.id;
      // getPatientInfoByPatientId(patientId, opdIpd, loginObj?.storeId)
      //   .then((response) => response.data)
      //   .then((res) => {
      //     setPatientInfo(res.result);
      //   });

      handlePatientInfo(patientId, opdIpd);
    } else {
      setPatientInfo(null);
    }
  }, [serachePatientVal]);

  const handleOpenSearchBills = () => {
    setOpenSearchBillsModal(true);
  };

  const handleCloseSearchBills = () => {
    setOpenSearchBillsModal(false);
  };

  //function to open the indent modal
  function handleOpen() {
    setOpen(true);
  }

  //function to close the indent modal
  function handleClose() {
    setOpen(false);
  }

  //function to open the DraftBillModal
  function handleOpenDraftBill() {
    setOpenDraftBill(true);
  }

  //function to close the DraftBillModal
  function handleCloseDraftBill() {
    setOpenDraftBill(false);
  }

  React.useEffect(() => {
    let obj = { name: "" };
    if (selectedPatientType === "Counter") {
      setPatientInfo(obj);
    }
  }, [selectedPatientType]);

  const changeHandler = (e) => {
    if (
      e.target.value === "OPD" ||
      e.target.value === "IPD" ||
      e.target.value === "Counter"
    ) {
      setPatientInfo(null);
      setValue("searchPatient", null);
      setSelectedPatientType(e.target.value);
    }
  };

  React.useEffect(() => {
    if (patientInfo === null) {
      setResetQtyFlag(1);
      setProceedButtonStatus(false);
    }
  }, [patientInfo]);

  const resetFunction = () => {
    reset();
  };

  //This function is props to the searchDropdown component
  const handleChange = (autoSearchString) => {
    if (autoSearchString !== "" && selectedPatientType !== null) {
      let opdIpd;
      if (selectedPatientType === "OPD") {
        opdIpd = 0;
      } else if (selectedPatientType === "IPD") {
        opdIpd = 1;
      }
      getPatientSearchAutocomplete(autoSearchString, opdIpd)
        .then((response) => response.data)
        .then((res) => {
          setPatientOptions(res.result);
        });
    }
  };

  //To get Patient Information for "Counter" of counterSales
  let firstName = watch("firstName");
  let lastName = watch("lastName");
  let doctorName = watch("doctorName");
  let address = watch("address");
  let mobileNo = watch("mobileNo");

  let counterInfo = {
    firstName: firstName,
    lastName: lastName,
    doctorName: doctorName,
    address: address,
    mobileNo: mobileNo,
  };

  React.useEffect(() => {
    if (mobileNo?.length === 10) {
      setCounterFocus(2);
    } else {
      setCounterFocus(0);
    }
  }, [mobileNo]);

  React.useEffect(() => {
    setValue("address", "");
    setValue("mobileNo", "");
    setValue("doctorName", null);
    setValue("firstName", "");
    setValue("lastName", "");
  }, [selectedPatientType]);

  React.useEffect(() => {
    if (
      firstName !== "" ||
      (firstName !== undefined && lastName !== "") ||
      lastName !== undefined
    ) {
      setIsValidForCounter(true);
    } else {
      setIsValidForCounter(false);
    }
  }, [firstName, lastName, doctorName, address, mobileNo]);

  const triggerFunction = () => {
    trigger();
  };

  //useEffect to select the OPD radio button ; before the component gets mounted
  React.useEffect(() => {
    setSelectedPatientType("IPD");
  }, []);

  React.useEffect(() => {
    if (patientInfo?.isEmployee === 1) {
      let employeeId = patientInfo.EmpId;
      let departmentId = 0;
      if (Number(employeeId) > 0) {
        getEmployeeDetails(employeeId, departmentId)
          .then((response) => response.data)
          .then((res) => {
            setEmployeeDetails(res.result);
          });
      }
    }
  }, [patientInfo]);

  React.useEffect(() => {
    if (counterError === true) {
      trigger(["firstName", "lastName"]);
    }
  }, [counterError]);

  React.useEffect(() => {
    if (finalPrescriptions?.result?.length > 0) {
      let precsriptions = [...finalPrescriptions?.result];
      let requiredArr = [];
      for (let obj of precsriptions) {
        if (obj.quantity > 0) {
          let medicineObj = {};
          let dateString;
          if (obj.expiryDate !== "") {
            dateString =
              obj.expiryDate
                ?.toString()
                ?.split("/")
                ?.reverse()
                ?.join("/")
                ?.replaceAll("/", "-") + "T00:00:00.000Z";
          } else {
            dateString = "1900-01-01T00:00:00.000Z";
          }

          medicineObj.amount = obj.amount;
          medicineObj.batchCode = obj.batchNo;
          medicineObj.concession = obj.discountAmount;
          medicineObj.concessionPercentage = obj.discountPercentage;
          medicineObj.conversion = obj.conversion;
          medicineObj.discount = obj.discountAmount;
          medicineObj.discountPercentage = obj.discountPercentage;
          medicineObj.expiryDate = dateString;
          medicineObj.isConsignment = obj.IsConsignment;
          medicineObj.issueQty = obj.quantity;
          medicineObj.itemId = obj.id;
          medicineObj.kitId = 0;
          medicineObj.kitItemQty = 0;
          medicineObj.kitQt = 0;
          medicineObj.mrp = obj.mrp;
          medicineObj.otherTaxAmount = 0;
          medicineObj.prescriptionId = 0;
          medicineObj.purchaseRate = obj.purchaseRate ? obj.purchaseRate : 0;
          medicineObj.qtyAvlForReturn = 0;
          medicineObj.sellingPrice = obj.netAmount;
          medicineObj.staffFree = 0;
          medicineObj.staffFreePercentage = 0;
          medicineObj.taxAmount = 0;
          medicineObj.totalAmount = obj.netAmount;
          medicineObj.totalForVat = 0;
          medicineObj.unitLandedRate = obj.landedRate ? obj.landedRate : 0;
          medicineObj.vatAmount = Number(obj.gstAmount);
          medicineObj.vatInclusive = 0;
          medicineObj.vatPercentage = Number(obj.gstPercent);
          requiredArr.push(medicineObj);
          setAddedMedicines(requiredArr);

          setIsValidQuantity(true);
        } else {
          setIsValidQuantity(false);
        }
      }
    }
  }, [finalPrescriptions]);

  const saveCounterSalesPureCash = (amountPayable) => {
    let loginObj = JSON.parse(localStorage.getItem("userInfo"));

    let admissionId;
    let opdIpd;
    if (selectedPatientType === "OPD") {
      admissionId = patientInfo?.VisitId;
      opdIpd = 0;
    } else if (selectedPatientType === "IPD") {
      admissionId = patientInfo?.AdmissionId;
      opdIpd = 1;
    } else if (selectedPatientType === "Counter") {
      admissionId = 0;
      opdIpd = 2;
    }

    let paymentInfoArr = [
      {
        paymentType: {
          id: 4,
          label: "CASH",
          value: "CASH",
        },
        amount: Number(amountPayable.totalPaidAmount),
        referrenceNo: "",
        remark: "",
      },
    ];

    let companyId;
    if (patientInfo?.CompanyId > 0) {
      companyId = patientInfo?.CompanyId;
    } else {
      companyId = 0;
    }

    let finalObj = {
      privilege: privilege,
      addedBy: loginObj?.userId,
      areaId: 0,
      balanceAmountNonSelf: 0,
      balanceAmountSelf: 0,
      cashCounterId: loginObj?.cashCounterId,
      cash_Credit_Staff: 0,
      chargeId: 0,
      cityId: 0,
      companyId: companyId,
      computerName: "",
      concessionAmount: 0,
      counterSaleDetailsDto: addedMedicines,
      cpOrMrp: 0,
      crAmount: 0,
      discountAmount: Number(amountPayable.totalDiscountAmount),
      doctorId: 0,
      doctorName: patientInfo?.doctor,
      empId: 0,
      isCancelled: true,
      isConsignment: true,
      isCpBill: true,
      isFree: true,
      isOld: true,
      isPrinted: true,
      mrno: Number(patientInfo?.mrNo) > 0 ? Number(patientInfo?.mrNo) : 0,
      opdIpd: opdIpd,
      opdIpdExternalId:
        Number(patientInfo?.VisitId) > 0
          ? patientInfo?.VisitId
          : Number(patientInfo?.AdmissionId) > 0
          ? patientInfo?.AdmissionId
          : 0,
      patientType: 0,
      paymentForPharmacyDto: {
        addedBy: 0,
        admissionId: admissionId,
        advanceNo: "",
        advanceRefund: 0,
        advanceUsed: 0,
        billAmount: 0,
        billBalanceAmount: 0,
        billId: 0,
        cancellationDate: "2023-04-14T13:23:52.209Z",
        cancellationReason: "",
        cancellationTime: "2023-04-14T13:23:52.209Z",
        cashCounterId: 0,
        cashPayAmount: 0,
        ccBankId: 0,
        ccNumber: "",
        ccPayAmount: 0,
        ccValidityDate: "2023-04-14T13:23:52.209Z",
        chequeAuthorisedBy: 0,
        chequeBankId: 0,
        chequeCleared: true,
        chequeDate: "2023-04-14T13:23:52.209Z",
        chequeNumber: "",
        chequePayAmount: 0,
        companyId: 0,
        creditAuthorisedBy: 0,
        dcBankId: 0,
        dcNumber: "",
        dcPayAmount: 0,
        dcValidityDate: "2023-04-14T13:23:52.209Z",
        ddBankId: 0,
        ddDate: "2023-04-14T13:23:52.209Z",
        ddNumber: "",
        ddPayAmount: 0,
        isBadDebit: true,
        isCancelled: true,
        isCash: true,
        isPrinted: true,
        narration: "",
        opdIpd: opdIpd,
        opdIpdExternalId:
          Number(patientInfo?.VisitId) > 0
            ? patientInfo?.VisitId
            : Number(patientInfo?.AdmissionId) > 0
            ? patientInfo?.AdmissionId
            : 0,
        patientAdvanceAmount: 0,
        patientAdvanceId: 0,
        patientAdvanceUsed: 0,
        isAdvanceConsume: false,
        patientId:
          Number(patientInfo?.PatientId) > 0
            ? Number(patientInfo?.PatientId)
            : 0,
        paymentInfoList: paymentInfoArr,
        paymentType: 0,
        refundAmount: 0,
        refundId: 0,
        staffFreeAmount: 0,
        tdsPercent: 0,
        totalAmount: 0,
        trustAdvanceAmount: 0,
        trustAdvanceUsed: 0,
        trustAdvancedId: 0,
        trustId: 0,
        updatedBy: 0,
        upiPayAmount: 0,
        upiRefNo: "",
      },
      percentage: 0,
      reasonOfFree: 0,
      // remark: remarkString,
      roundingAmount: Number(amountPayable.totalRoundingAmount),
      serviceChargeAmount: 0,
      serviceCharges: 0,
      settled: true,
      staffFree: 0,
      storeId: loginObj?.storeId,
      totalAmount: Number(amountPayable.totalAmount),
      totalAmountPayable: Number(amountPayable.totalPaidAmount),
      totalOtherTaxAmount: 0,
      totalTaxAmount: 0,
      totalVatAmount: Number(amountPayable.totalGSTAmount),
      menuId: location?.state?.menuId,
    };

    if (selectedPatientType === "Counter") {
      finalObj.patientName = firstName + " " + lastName;
      finalObj.otherDoctorName = doctorName?.label;
      finalObj.patientAddress = address;
      finalObj.mobileNo = mobileNo;
      finalObj.menuId = location?.state?.menuId;
    } else {
      finalObj.patientName = patientInfo.patientName;
      finalObj.menuId = location?.state?.menuId;
      finalObj.otherDoctorName = "";
      finalObj.patientAddress = "";
      finalObj.mobileNo = "";
    }
    console.log(
      "The final post object for counter sales is :",
      finalObj,
      patientInfo
    );

    setPostObject(finalObj);

    if (addedMedicines.length > 0 && isValidQuantity === true) {
      setOpenSaveChild(true);
    } else if (!isValidQuantity) {
      warningAlert("Quantity Can't Be Zero.");
    }
  };

  const handleDirectPrint = async (printResponse) => {
    let b64 = await blobToBase64(printResponse);

    const obj = [
      {
        printerName: printerSettings?.defaultPrinter,
        pdf: b64,
        numberCopies: Number(printerSettings?.printPages),
        pageSize: "A5",
      },
    ];

    await routeToPrinterDevice(obj).then(() => {
      successAlert("Record Printed !");
    }).catch(() => {
      errorAlert("Error while printing !")
    })
  };

  function openPrintWindow(billId) {
    if (billId) {
      let requiredBillId = billId;

      printCounterSales(requiredBillId).then((response) => {
        if (Number(printerSettings?.printPages) > 0) {
          handleDirectPrint(response)
        } else {
          if (response.status === 200) {
            //Get Reponse JSON
            const blb = new Blob([response.data], { type: "text/plain" });
            const reader = new FileReader();
            // Start reading the blob as text.
            reader.readAsText(blb);
            //Convert Blob Response to JSON String
            reader.addEventListener("loadend", (e) => {
              if (e.target.result.charAt(0) !== "{") {
                setOpenCommonPrintModal(true);
                const file = new Blob([response.data], {
                  type: "application/pdf",
                });
                const fileURL = URL.createObjectURL(file);

                setBillPdf(fileURL);
                setValue("searchPatient", null);
                setResetQtyFlag(0);
              } else {
                setBillPdf("");
                setValue("searchPatient", null);
                setResetQtyFlag(0);
              }
            });
          }
        }
      });
    }
  }

  const postCounterSales = () => {
    handleCloseSaveChild();
    setOpenBackdrop(true);
    saveCounterSales(postObject)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        let requiredBillId = res.result;
        openPrintWindow(requiredBillId);
        resetFunction();
        setResetQtyFlag(0);
        setPatientInfo(null);
        setOpenBackdrop(false);
        setPrescriptionData([]);
      })
      .catch((error) => {
        errorAlert(error.message || error.response.data.message);
        setOpenBackdrop(false);
      });
  };

  React.useEffect(() => {
    if (employeeDetails !== null) {
      let availableAmount =
        Number(employeeDetails.PharmacyAmount) -
        Number(employeeDetails.PharmacyHMSAmt);
      if (
        Number(calculatedFormData?.totalDiscountAmount).toFixed() >
        Number(availableAmount)
      ) {
        warningAlert("Discount Exeeds Pharmacy Amount Avaiable .");
      }
    }
  }, [employeeDetails, calculatedFormData?.totalDiscountAmount]);

  return (
    <FormProvider {...methods}>
      <div className=" mt-16 lg:mt-14 w-full overflow-hidden" id="drugSearch">
        <div>
          <span className="flex justify-center text-base xl:text-lg font-semibold whitespace-nowrap">
            Counter Sales
          </span>
        </div>
        <div className="grid grid-cols-3 lg:flex gap-x-2 w-full py-2">
          <div className=" flex items-center md:space-x-3 w-full col-span-3">
            <div className="pl-2 flex space-x-2 items-center">
              <h1 className=" texl-lg whitespace-nowrap text-gray-600 font-medium ">
                Type :
              </h1>
              <input
                className=" h-4 w-4 ml-5 md:ml-0 "
                type="radio"
                value="OPD"
                id="OPD"
                checked={selectedPatientType === "OPD"}
                name="OPD"
                onChange={changeHandler}
              />

              <label htmlFor="OPD" className="ml-2">
                OPD
              </label>
              <input
                className=" h-4 w-4 ml-5 md:ml-0 "
                type="radio"
                value="IPD"
                id="IPD"
                checked={selectedPatientType === "IPD"}
                name="IPD"
                onChange={changeHandler}
              />

              <label htmlFor="IPD" className="ml-2">
                IPD
              </label>
              <input
                className=" h-4 w-4 ml-3"
                type="radio"
                name="Counter"
                value="Counter"
                id="Counter"
                checked={selectedPatientType === "Counter"}
                onChange={changeHandler}
              />
              <label htmlFor="courses" className="ml-2">
                Counter
              </label>
            </div>
            <div className="flex w-full lg:px-2 col-span-3 lg:col-span-2 z-50 py-2">
              <div className="flex gap-2 lg:px-2 w-[75%] z-50">
                {selectedPatientType !== "Counter" ? (
                  <SearchDropdown
                    control={control}
                    name="searchPatient"
                    dataArray={patientOptions}
                    searchIcon={true}
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Search By Patient Name/Indent No."
                    handleInputChange={handleChange}
                  />
                ) : null}
              </div>
              {selectedPatientType === "IPD" ? (
                <div className="">
                  <button
                    className="whitespace-nowrap bg-customBlue text-white rounded px-3 h-9 "
                    type="button"
                    onClick={() => {
                      handleOpen();

                      // if (patientInfo !== null) {
                      //   handleOpen();
                      // } else {
                      //   warningAlert("Please select the patient.");
                      // }
                    }}
                  >
                    Get Indent
                  </button>
                </div>
              ) : null}
              <div className="ml-2 whitespace-nowrap">
                <CommonButton
                  className="px-2 rounded h-9 bg-customBlue text-white"
                  label="Search Bills"
                  type="button"
                  onClick={handleOpenSearchBills}
                />
              </div>
            </div>
          </div>
        </div>

        {/* OPD Patient information is */}
        {selectedPatientType === "OPD" &&
        selectedPatientType !== null &&
        patientInfo !== null ? (
          <fieldset className="border bg-gray-100 border-gray-300 text-left w-full rounded-md mb-2 px-2 ">
            <div
              className={`py-2 grid grid-cols-2 lg:${
                patientInfo.EmpId !== null ? `grid-cols-4` : `grid-cols-3`
              } gap-2`}
            >
              <div className="border-r-2 border-gray-600">
                <div className="flex items-center space-x-3 ">
                  <h1 className="font-semibold text-sm">Patient Name</h1>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-500 font-normal">
                      {patientInfo !== null
                        ? patientInfo?.patientName
                        : "-----"}
                    </span>
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <h1 className="font-semibold text-sm">Doctor Name</h1>
                  <div className="flex space-x-2 items-center pl-0.5">
                    <span>:</span>
                    <span className="text-gray-500 font-normal">
                      {patientInfo !== null ? patientInfo?.doctor : "-----"}
                    </span>
                  </div>
                </div>
              </div>

              <div className="lg:border-r-2 border-gray-600">
                <div className="flex items-center space-x-2 ">
                  <h1 className="font-semibold text-sm">Gender</h1>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-500 font-normal">
                      {patientInfo !== null ? patientInfo.gender : "-----"}
                    </span>
                  </div>
                </div>
                <div className="flex items-center space-x-8">
                  <h1 className="font-semibold text-sm">Age</h1>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-500 font-normal">
                      {patientInfo !== null ? patientInfo.AgeYear : "-----"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="md:col-span-2 lg:col-span-1">
                <div className="flex items-center space-x-[73px] lg:space-x-3 ">
                  <h1 className="font-semibold text-sm">MRNO.</h1>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-500 font-normal">
                      {patientInfo !== null ? patientInfo.mrNo : "-----"}
                    </span>
                  </div>
                </div>
              </div>
              {patientInfo.EmpId !== null ? (
                <div className="md:col-span-2 lg:col-span-1 border-l-2 border-gray-600 pl-2">
                  <div className="flex items-center  lg:space-x-3 ">
                    <h1 className="font-semibold text-sm lg:w-[40px]">Code</h1>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-500 font-normal">
                        {employeeDetails !== null
                          ? employeeDetails.Code
                          : "-----"}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center lg:space-x-3 ">
                    <h1 className="font-semibold text-sm lg:w-[40px]">Limit</h1>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-500 font-normal">
                        {employeeDetails !== null
                          ? employeeDetails.PharmacyAmount
                          : "-----"}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center lg:space-x-3 ">
                    <h1 className="font-semibold text-sm lg:w-[40px]">Used</h1>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-500 font-normal">
                        {employeeDetails !== null
                          ? employeeDetails.PharmacyHMSAmt
                          : "-----"}
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </fieldset>
        ) : null}

        {/* IPD Patient Information is */}
        {selectedPatientType === "IPD" &&
        selectedPatientType !== null &&
        patientInfo !== null ? (
          <fieldset className="border bg-gray-100 border-gray-300 text-left w-full rounded-md mb-2 px-2">
            <div
              className={`py-2 grid grid-cols-2 lg:${
                patientInfo.EmpId !== null ? `grid-cols-4` : `grid-cols-3`
              } gap-2`}
            >
              <div className="border-r-2 border-gray-600">
                <div className="flex items-center space-x-3 ">
                  <h1 className="font-semibold text-sm">Patient Name</h1>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-500 font-normal">
                      {patientInfo !== null ? patientInfo.patientName : "-----"}
                    </span>
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <h1 className="font-semibold text-sm">Doctor Name</h1>
                  <div className="flex space-x-2 items-center pl-0.5">
                    <span>:</span>
                    <span className="text-gray-500 font-normal">
                      {patientInfo !== null ? patientInfo.doctor : "-----"}
                    </span>
                  </div>
                </div>
              </div>

              <div className="lg:border-r-2 border-gray-600">
                <div className="flex items-center space-x-2 ">
                  <h1 className="font-semibold text-sm">Gender</h1>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-500 font-normal">
                      {patientInfo !== null ? patientInfo.gender : "-----"}
                    </span>
                  </div>
                </div>
                <div className="flex items-center space-x-8">
                  <h1 className="font-semibold text-sm">Age</h1>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-500 font-normal">
                      {patientInfo !== null ? patientInfo.AgeYear : "-----"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="md:col-span-2 lg:col-span-1">
                <div className="flex items-center  lg:space-x-3 ">
                  <h1 className="font-semibold text-sm lg:w-[119px]">MRNO.</h1>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-500 font-normal">
                      {patientInfo !== null ? patientInfo.mrNo : "-----"}
                    </span>
                  </div>
                </div>
                <div className="flex items-center lg:space-x-3 ">
                  <h1 className="font-semibold text-sm lg:w-[119px]">
                    Patient Category
                  </h1>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-500 font-normal">
                      {patientInfo !== null
                        ? patientInfo.PatientCategory
                        : "-----"}
                    </span>
                  </div>
                </div>
              </div>
              {patientInfo.EmpId !== null ? (
                <div className="md:col-span-2 lg:col-span-1 border-l-2 border-gray-600 pl-2">
                  <div className="flex items-center  lg:space-x-3 ">
                    <h1 className="font-semibold text-sm lg:w-[40px]">Code</h1>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-500 font-normal">
                        {employeeDetails !== null
                          ? employeeDetails.Code
                          : "-----"}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center lg:space-x-3 ">
                    <h1 className="font-semibold text-sm lg:w-[40px]">Limit</h1>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-500 font-normal">
                        {employeeDetails !== null
                          ? employeeDetails.PharmacyAmount
                          : "-----"}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center lg:space-x-3 ">
                    <h1 className="font-semibold text-sm lg:w-[40px]">Used</h1>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-500 font-normal">
                        {employeeDetails !== null
                          ? employeeDetails.PharmacyHMSAmt
                          : "-----"}
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </fieldset>
        ) : null}

        {/* Counter Information is */}
        {selectedPatientType === "Counter" ? (
          <div>
            <div className="grid grid-cols-3 gap-2 my-2">
              <InputField
                name="firstName"
                variant="outlined"
                label="First Name*"
                error={errors.firstName}
                control={control}
                inputProps={{ style: { textTransform: "capitalize" } }}
              />
              <InputField
                name="lastName"
                type="text"
                variant="outlined"
                label="Last Name*"
                error={errors.lastName}
                control={control}
                inputProps={{ style: { textTransform: "capitalize" } }}
              />
              <div className="w-full">
                {/* doctorName input field */}
                <DropdownField
                  defaultValue={null}
                  control={control}
                  isSearchable={true}
                  error={errors.doctorName}
                  name="doctorName"
                  placeholder="Doctor Name*"
                  dataArray={doctorOptions}
                />
              </div>
              <InputField
                name="address"
                variant="outlined"
                label="Address*"
                error={errors.address}
                control={control}
                inputProps={{ style: { textTransform: "capitalize" } }}
              />

              <div className="w-full">
                <InputField
                  name="mobileNo"
                  variant="outlined"
                  label="Mobile No.*"
                  control={control}
                  error={errors?.mobileNo}
                  inputProps={{ style: { textTransform: "capitalize" } }}
                />
              </div>
            </div>
          </div>
        ) : null}

        <IndentModal
          open={open}
          close={handleClose}
          admissionId={patientInfo?.AdmissionId}
          patientInfo={patientInfo}
          setData={setPrescriptionData}
          handlePatientInfo={handlePatientInfo}
        />

        {/* The component which searches the drug information and populates in the prescription table */}
        <DrugSearch
          isValidQuantity={isValidQuantity}
          setIsValidQuantity={setIsValidQuantity}
          counterInfo={counterInfo}
          prescriptionData={prescriptionData}
          setPrescriptionData={setPrescriptionData}
          setDiscountPercent={setDiscountPercent}
          discountPercent={discountPercent}
          setDiscountAmount={setDiscountAmount}
          discountAmount={discountAmount}
          patientInfo={patientInfo}
          setCalculatedFormData={setCalculatedFormData}
          setFinalPrescriptions={setFinalPrescriptions}
          selectedPatientType={selectedPatientType}
          setPrescriptionLength={setPrescriptionLength}
          resetQtyFlag={resetQtyFlag}
          resetStates={resetStates}
          triggerFunction={triggerFunction}
          isValidForCounter={isValidForCounter}
          counterFocus={counterFocus}
          mobileNo={mobileNo}
          employeeDetails={employeeDetails}
        />

        {/* Display the OPD form ; when the OPD radio button is selected */}
        {selectedPatientType === "OPD" ? (
          <OpdForm
            saveCounterSalesPureCash={saveCounterSalesPureCash}
            isValidQuantity={isValidQuantity}
            setIsValidQuantity={setIsValidQuantity}
            setResetQtyFlag={setResetQtyFlag}
            setDiscountPercent={setDiscountPercent}
            discountPercent={discountPercent}
            setDiscountAmount={setDiscountAmount}
            discountAmount={discountAmount}
            calculatedFormData={calculatedFormData}
            patientInfo={patientInfo}
            setPatientInfo={setPatientInfo}
            setFinalFormData={setFinalFormData}
            setPaymentInfoList={setPaymentInfoList}
            selectedPatientType={selectedPatientType}
            setValue={setValue}
            prescriptionLength={prescriptionLength}
            setProceedButtonStatus={setProceedButtonStatus}
            proceedButtonStatus={proceedButtonStatus}
            finalFormData={finalFormData}
            finalPrescriptions={finalPrescriptions}
            paymentInfoList={paymentInfoList}
            openPrintWindow={openPrintWindow}
            resetFunction={resetFunction}
            setResetStates={setResetStates}
            resetStates={resetStates}
            setOpenBackdrop={setOpenBackdrop}
            userActions={userActions}
            setPrivilege={setPrivilege}
            privilege={privilege}
            menuId={location?.state?.menuId}
            employeeDetails={employeeDetails}
          />
        ) : null}

        {/* Display the IPD form ; when the IPD radio button is selected */}
        {selectedPatientType === "IPD" ? (
          <IpdForm
            saveCounterSalesPureCash={saveCounterSalesPureCash}
            isValidQuantity={isValidQuantity}
            setIsValidQuantity={setIsValidQuantity}
            setResetQtyFlag={setResetQtyFlag}
            setDiscountPercent={setDiscountPercent}
            discountPercent={discountPercent}
            setDiscountAmount={setDiscountAmount}
            discountAmount={discountAmount}
            calculatedFormData={calculatedFormData}
            setPatientInfo={setPatientInfo}
            setFinalFormData={setFinalFormData}
            setPaymentInfoList={setPaymentInfoList}
            selectedPatientType={selectedPatientType}
            setValue={setValue}
            prescriptionLength={prescriptionLength}
            setProceedButtonStatus={setProceedButtonStatus}
            proceedButtonStatus={proceedButtonStatus}
            patientInfo={patientInfo}
            finalFormData={finalFormData}
            finalPrescriptions={finalPrescriptions}
            paymentInfoList={paymentInfoList}
            openPrintWindow={openPrintWindow}
            resetFunction={resetFunction}
            setResetStates={setResetStates}
            resetStates={resetStates}
            setOpenBackdrop={setOpenBackdrop}
            userActions={userActions}
            setPrivilege={setPrivilege}
            privilege={privilege}
            menuId={location?.state?.menuId}
            employeeDetails={employeeDetails}
          />
        ) : null}

        {selectedPatientType === "Counter" ? (
          <CounterForm
            saveCounterSalesPureCash={saveCounterSalesPureCash}
            setCounterError={setCounterError}
            isValidQuantity={isValidQuantity}
            setIsValidQuantity={setIsValidQuantity}
            resetFunction={resetFunction}
            setResetQtyFlag={setResetQtyFlag}
            resetQtyFlag={resetQtyFlag}
            setDiscountPercent={setDiscountPercent}
            discountPercent={discountPercent}
            setDiscountAmount={setDiscountAmount}
            discountAmount={discountAmount}
            calculatedFormData={calculatedFormData}
            setPatientInfo={setPatientInfo}
            patientInfo={patientInfo}
            setFinalFormData={setFinalFormData}
            setPaymentInfoList={setPaymentInfoList}
            selectedPatientType={selectedPatientType}
            setValue={setValue}
            defaultValues={defaultValues}
            reset={reset}
            prescriptionLength={prescriptionLength}
            setProceedButtonStatus={setProceedButtonStatus}
            proceedButtonStatus={proceedButtonStatus}
            finalFormData={finalFormData}
            finalPrescriptions={finalPrescriptions}
            paymentInfoList={paymentInfoList}
            openPrintWindow={openPrintWindow}
            counterInfo={counterInfo}
            isValidForCounter={isValidForCounter}
            setResetStates={setResetStates}
            resetStates={resetStates}
            triggerFunction={triggerFunction}
            setOpenBackdrop={setOpenBackdrop}
            userActions={userActions}
            setPrivilege={setPrivilege}
            privilege={privilege}
            menuId={location?.state?.menuId}
            employeeDetails={employeeDetails}
          />
        ) : null}

        <ConfirmationModal
          confirmationOpen={openSaveChild}
          confirmationHandleClose={handleCloseSaveChild}
          confirmationSubmitFunc={postCounterSales}
          confirmationLabel="Confirmation "
          confirmationMsg="Save Payment Details?"
          confirmationButtonMsg="Save Payment"
        />

        <CommonBackDrop openBackdrop={openBackdrop} />
        <CommonPrintModal
          open={openCommonPrintModal}
          close={handleClosePrintModal}
          document={billPdf}
        />

        {/* The modal to display the pdf */}
        {/*  */}
      </div>

      <SalesModal
        open={openSearchBillsModal}
        close={handleCloseSearchBills}
        openPrintWindow={openPrintWindow}
        setReturnItems={"hello"}
        returnItems={"hello"}
        setPatientName={"hello"}
        setRetSalesId={"hello"}
        resetCount={"hello"}
        setResetCount={"hello"}
        component={"counter"}
        menuId={location?.state?.menuId + 1}
        privilege="View"
      />
    </FormProvider>
  );
}

