import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import PatientdetailsTab from "./PatientdetailsTab";
import OperativeNotes from "./OperativeNotes";
import FollowUpInstructionTab from "./FollowUpInstructionTab";
import GynacologyDtlsTab from "./GynacologyDtlsTab";
import PediatricDetails from "./PediatricDetails";
import DeathSummary from "./DeathSummary";
import { DischargeSummaryContext } from "./DischargeSummaryTabWise";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import HistorySheetTab from "./HistorySheetTab";
import NeonatalModal from "./NeonatalModal";

///
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

///
const DischargeSummaryTabs = () => {
  const {
    patientData,
    deathSummary,
    tabValue,
    setTabValue,
    patchData,
  } = useContext(DischargeSummaryContext);

  const [expandPanal1, setExpandPanal1] = useState(true);
  const [expandPanal2, setExpandPanal2] = useState(true);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChangePanal1 = () => {
    setExpandPanal1(!expandPanal1);
  };
  const handleChangePanal2 = () => {
    setExpandPanal2(!expandPanal2);
  };

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            backgroundColor: "#dfe6e9",
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons
          >
            <Tab
              label="Patient Details"
              disabled={patientData !== "" ? false : true}
              {...a11yProps(0)}
            />
            <Tab
              label="History Sheet"
              disabled={patientData !== "" ? false : true}
              {...a11yProps(1)}
            />
            <Tab
              label="Opr/Treatment"
              disabled={patientData !== "" ? false : true}
              {...a11yProps(2)}
            />
            <Tab
              label="Follow-up Instruction"
              disabled={patientData !== "" ? false : true}
              {...a11yProps(3)}
            />
            <Tab
              label="Gynecology Details"
              disabled={patientData?.gender === "Female" ? false : true}
              disa
              {...a11yProps(4)}
            />
            <Tab
              label="Pediatric Details"
              disabled={
                patientData?.gender === "Female" || patientData?.age <= 14
                  ? false
                  : true
              }
              {...a11yProps(5)}
            />

            <Tab
              label="Neonatal Details"
              disabled={patientData?.isNeonatalCase ? false : true}
              {...a11yProps(6)}
            />

            <Tab
              label="Death Summary"
              disabled={deathSummary && patientData !== "" ? false : true}
              {...a11yProps(7)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={tabValue} index={0}>
          <fieldset
            disabled={!!patchData && patchData.Finalize === 1 ? true : false}
          >
            <PatientdetailsTab />
          </fieldset>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <fieldset
            disabled={!!patchData && patchData.Finalize === 1 ? true : false}
          >
            {" "}
            <HistorySheetTab />
          </fieldset>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={2}>
          {/* <OperativeNotes /> */}
          {/* <PediatricDetails /> */}

          <div className="pt-3">
            <div>
              <Accordion
                expanded={expandPanal2}
                onChange={handleChangePanal2}
                elevation={7}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5bh-content"
                  id="panel5bh-header"
                  sx={{
                    "&.Mui-expanded": {
                      marginBottom: "0.3rem",
                      minHeight: "40px",
                    },
                    "& .MuiAccordionSummary-content.Mui-expanded": {
                      margin: 0,
                    },
                    backgroundColor: "#CCE4FB",
                  }}
                >
                  <p className="font-bold tracking-wide text-sm font-Poppins">
                    Operative / Treatment
                  </p>
                </AccordionSummary>
                <AccordionDetails>
                  <fieldset
                    disabled={
                      !!patchData && patchData.Finalize === 1 ? true : false
                    }
                  >
                    {" "}
                    <OperativeNotes />
                  </fieldset>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={3}>
          <fieldset
            disabled={!!patchData && patchData.Finalize === 1 ? true : false}
          >
            {" "}
            <FollowUpInstructionTab />
          </fieldset>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={4}>
          <fieldset
            disabled={!!patchData && patchData.Finalize === 1 ? true : false}
          >
            <GynacologyDtlsTab />
          </fieldset>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={5}>
          {/* <PediatricDetails /> */}

          <div className="pt-3">
            <div>
              <Accordion
                expanded={expandPanal2}
                onChange={handleChangePanal2}
                elevation={7}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5bh-content"
                  id="panel5bh-header"
                  sx={{
                    "&.Mui-expanded": {
                      marginBottom: "0.3rem",
                      minHeight: "40px",
                    },
                    "& .MuiAccordionSummary-content.Mui-expanded": {
                      margin: 0,
                    },
                    backgroundColor: "#CCE4FB",
                  }}
                >
                  <p className="font-bold tracking-wide text-sm font-Poppins">
                    Pediatric Details
                  </p>
                </AccordionSummary>
                <AccordionDetails>
                  {/* Pediatric Details*/}

                  <fieldset
                    disabled={
                      !!patchData && patchData.Finalize === 1 ? true : false
                    }
                  >
                    <PediatricDetails />
                  </fieldset>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={6}>
          {/* <PediatricDetails /> */}

          <div className="pt-3">
            <div>
              <Accordion
                expanded={expandPanal2}
                onChange={handleChangePanal2}
                elevation={7}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5bh-content"
                  id="panel5bh-header"
                  sx={{
                    "&.Mui-expanded": {
                      marginBottom: "0.3rem",
                      minHeight: "40px",
                    },
                    "& .MuiAccordionSummary-content.Mui-expanded": {
                      margin: 0,
                    },
                    backgroundColor: "#CCE4FB",
                  }}
                >
                  <p className="font-bold tracking-wide text-sm font-Poppins">
                    Neonatal Details
                  </p>
                </AccordionSummary>
                <AccordionDetails>
                  {/* Pediatric Details*/}

                  <fieldset
                    disabled={
                      !!patchData && patchData.Finalize === 1 ? true : false
                    }
                  >
                    <NeonatalModal />
                  </fieldset>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={7}>
          {/* <DeathSummary /> */}

          <div className="pt-3">
            <div>
              <Accordion
                expanded={expandPanal1}
                onChange={handleChangePanal1}
                elevation={7}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5bh-content"
                  id="panel5bh-header"
                  sx={{
                    "&.Mui-expanded": {
                      marginBottom: "0.3rem",
                      minHeight: "40px",
                    },
                    "& .MuiAccordionSummary-content.Mui-expanded": {
                      margin: 0,
                    },

                    backgroundColor: "#FFF2D2",
                  }}
                >
                  <p className="font-bold tracking-wide text-sm font-Poppins">
                    Death Summary
                  </p>
                </AccordionSummary>
                <AccordionDetails>
                  {/* Death Summary */}
                  <fieldset
                    disabled={
                      !!patchData && patchData.Finalize === 1 ? true : false
                    }
                  >
                    {" "}
                    <DeathSummary />
                  </fieldset>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </CustomTabPanel>
      </Box>
    </div>
  );
};

export default DischargeSummaryTabs;
