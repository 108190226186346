// import React from "react";
// import { FormControl, FormHelperText, TextField } from "@mui/material";
// import { Controller } from "react-hook-form";
// import "./style.css";
// const InputEnter = ({
//   sx,
//   variant,
//   defaultValue,
//   inputProps,
//   type,
//   disabled,
//   inputRef,
//   name,
//   label,
//   error,
//   control,
//   dontCapitalize,
//   onKeyPress,
//   onFocus,
//   onKeyDown,
//   onChange,
//   onBlur,
//   ...rest
// }) => {
//   return (
//     <FormControl fullWidth size="small" sx={sx}>
//       <Controller
//         render={({ field }) => (
//           <TextField
//             className="h-10 bg-white "
//             inputRef={inputRef}
//             ref={null}
//             onKeyPress={onKeyPress}
//             onKeyDown={onKeyDown}
//             onFocus={onFocus} // onFocus,
//             onBlur={onBlur} // onBlur,
//             onChange={onChange}
//             inputProps={
//               dontCapitalize
//                 ? inputProps
//                 : (inputProps, { style: { textTransform: "capitalize" } })
//             }
//             type={type}
//             disabled={disabled}
//             error={!!error?.message}
//             variant={variant}
//             label={label}
//             placeholder={label}
//             name={name}
//             fullWidth
//             {...field}
//             {...rest}
//             size="small"
//           />
//         )}
//         name={name}
//         control={control}
//         defaultValue={defaultValue}
//       />
//       {/* <FormHelperText style={{ color: "#d32f2f" }}>
//         {error?.message}
//       </FormHelperText> */}
//     </FormControl>
//   );
// };

// export default InputEnter;
import React from "react";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

import { fontSize } from "@mui/system";
const InputField = ({
  sx,
  variant,
  defaultValue,
  inputProps,
  type,
  disabled,
  inputRef,
  name,
  label,
  error,
  control,
  dontCapitalize,
  color,
  onChange,
  onKeyDown,
}) => {
  return (
    <FormControl fullWidth size="small" sx={sx}>
      <Controller
        render={({ field }) => (
          <TextField
            className="h-[35px] text-[14px] bg-white"
            inputRef={inputRef}
            onKeyDown={onKeyDown}
            ref={null}
            inputProps={
              dontCapitalize
                ? inputProps
                : (inputProps,
                  {
                    style: {
                      textTransform: "capitalize",
                      fontSize: "14px",
                      height: "18.5px",
                    },
                  })
            }
            sx={{
              "& .MuiFormLabel-root": {
                fontSize: "14px",
              },
            }}
            onWheel={(e) => {
              if (type == "number") {
                e.target.blur();
              }
            }}
            type={type}
            disabled={disabled}
            error={!!error?.message}
            color={color}
            variant={variant}
            label={label}
            placeholder={label}
            name={name}
            fullWidth
            {...field}
            size="small"

          />
        )}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
      {/* <FormHelperText style={{ color: "#d32f2f" }}>
        {error?.message}
      </FormHelperText> */}
    </FormControl>
  );
};

export default InputField;
