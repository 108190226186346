import { TextField } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";

import AddNewRetrivedDrug from "./AddNewRetrivedDrug";

const OperativeNotes = () => {
 

  const { register } = useFormContext();

  return (
    <div className="gap-3 grid lg:grid-cols-2">
      <div className="">
        {/* //operativeNotes.// */}
        <div className="">
          {/* //Other Diseases.// */}

          <TextField
            id="outlined-multiline-static"
            name="operativeNotes"
            {...register("operativeNotes")}
            label="Operative Notes"
            multiline
            InputLabelProps={{ shrink: true }}
            rows={18}
            fullWidth
          />
        </div>
      </div>
      <div className=" ">
        <div>
          <AddNewRetrivedDrug />
        </div>
      </div>
    </div>
  );
};

export default OperativeNotes;
