import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import useGeneratePdf from "../../../common/components/printModal/GeneratePdfHook";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import {
  fetchFolderStructureForHrDms,
  fetchHrmsDoc,
  uploadHrdmsDocument,
} from "../../services/hrDmsServices/HrDmsServices";
import { Button, Tooltip } from "@mui/material";

const HrDms = () => {
  let location = useLocation();
  //
  let token = JSON.parse(localStorage.getItem("userInfo"));
  let userId = token.userId;
  const methods = useForm({
    mode: "onChange",
    // resolver: yupResolver(schema),
    defaultValues: {
      uploadFileDailyTips: null,
      //
    },
  });

  const { control, register, getValues } = methods;

  const [folderStructure, setFolderStructure] = useState([]);
  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const [docToView, setDocToView] = useState(null);
  const fileURL = useGeneratePdf(docToView);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [base64String, setBase64String] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [openNodes, setOpenNodes] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = useState("");
  const [isFoldersVisible, setIsFoldersVisible] = useState(true);

  const toggleSectionVisibility = () => {
    setIsFoldersVisible(!isFoldersVisible);
  };

  useEffect(() => {
    fetchFolderStructureForHrDms()
      .then((response) => response.data)
      .then((res) => {
        setFolderStructure(res.result);
      });

    /////get User Action
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  const TreeNode = ({ node }) => {
    const isOpen = openNodes.includes(node.folderId);

    const getHrmsDoc = (folderId) => {
      setSelectedFolderId(folderId);

      folderId &&
        fetchHrmsDoc(folderId)
          .then((response) => {
            setDocToView(response);
          })
          .catch((res) => {
            errorAlert(res.message);
            setDocToView(null);
          });
    };

    const handleToggle = (folderId) => {
      if (openNodes.includes(folderId)) {
        setOpenNodes(openNodes.filter((id) => id !== folderId));
      } else {
        setOpenNodes([...openNodes, folderId]);
        if (node.children && !node.children.length > 0) {
          getHrmsDoc(folderId);
        }
      }
    };

    const handleSelect = (folderId) => {
      setSelectedNodes([folderId]);
    };

    const getRandomColorClass = () => {
      const colors = [
        "text-red-500",
        "text-blue-500",
        "text-green-500",
        "text-yellow-500",
        "text-indigo-500",
        "text-purple-500",
        "text-pink-500",
      ];
      const randomIndex = Math.floor(Math.random() * colors.length);
      return colors[randomIndex];
    };

    const nodeStyle =
      node.folderId === selectedFolderId ? "bg-slate-200 text-white" : "";

    return (
      <div
        className={`border-l-2 border-dashed border-slate-600 left-[25%] ml-2 ${nodeStyle}`}
      >
        <div
          onClick={(e) => {
            handleToggle(node.folderId);
            e.preventDefault();
          }}
          onDoubleClick={(e) => {
            handleSelect(node.folderId);
            e.preventDefault();
          }}
        >
          <div className="grid grid-cols-12">
            <p className="col-span-1 lg:text-xs 2xl:text-lg text-nowrap">
              ----
            </p>
            <div className={"col-span-11 flex"}>
              <div className="-mt-1">
                {node.children.length > 0 ? (
                  isOpen ? (
                    <FolderOpenIcon className={``} fontSize="small" />
                  ) : (
                    <FolderIcon className={``} fontSize="small" />
                  )
                ) : (
                  <DescriptionOutlinedIcon className={``} fontSize="small" />
                )}
              </div>
              <p
                className={`${getRandomColorClass()} ml-2 text-sm align-text-bottom hover:bg-slate-600 hover:text-white ${nodeStyle}`}
              >
                {node.folderName}
              </p>
            </div>
          </div>
        </div>

        {isOpen && node.children && (
          <div style={{ marginLeft: "20px" }}>
            {node.children.map((child) => (
              <TreeNode key={child.id} node={child} />
            ))}
          </div>
        )}
      </div>
    );
  };

  const TreeView = ({ data }) => {
    return (
      <div className={"cursor-pointer bg-white"}>
        {data.map((node) => (
          <TreeNode key={node.id} node={node} />
        ))}
      </div>
    );
  };

  const handleFileChange = (e) => {
    const files = e.target.files;

    if (files && files.length > 0) {
      const file = files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        const imageName = file.name;
        setBase64String(base64String);
        setImageName(imageName);
      };

      reader.readAsDataURL(file);
    }
  };

  const onsubmit = () => {
    if (!!selectedFolderId) {
      if (!!base64String) {
        setOpenConfirmationModal(true);
      } else {
        warningAlert("Please select a Document");
      }
    } else {
      warningAlert("Please select a folder location");
    }
  };

  const handleFormSubmit = () => {
    setOpenConfirmationModal(false);
    setOpenBackdrop(true);

    const finalObj = {
      documentBase64: base64String,
      documentName: imageName,
      folderId: selectedFolderId,
      menuId: location?.state?.menuId,
      privilege: privilege,
    };

    uploadHrdmsDocument(finalObj)
      .then((response) => response.data)
      .then((res) => {
        if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenBackdrop(false);
          methods.reset();
          setSelectedFolderId(null);
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        setOpenConfirmationModal(false);
        setOpenBackdrop(false);
      });
  };

  return (
    <>
      <div className="mt-12 mb-2">
        <div className="grid grid-cols-12">
          <div className="mb-2">
            <Tooltip
              title={!isFoldersVisible ? "Show Folders" : "Hide Folders"}
            >
              <Button
                onClick={() => {
                  toggleSectionVisibility();
                }}
              >
                {!isFoldersVisible ? <MenuIcon /> : <MenuOpenIcon />}
              </Button>
            </Tooltip>
          </div>
          <div className="text-center text-black font-bold text-xl mt-2 col-span-11">
            Document Management System
          </div>
        </div>

        <div className=" py-2 grid md:grid-cols-2 lg:grid-cols-4 gap-3">
          {isFoldersVisible && (
            <div className="">
              <div className="lg:h-[350px] xl:h-[450px]  2xl:h-[601px] overflow-y-scroll">
                <TreeView data={folderStructure} />
              </div>
              <fieldset className="border border-gray-300 col-span-3 w-full text-left lg:px-1 md:px-5 rounded bg-gray-100">
                <div className="grid gap-3 grid-cols-7 my-2">
                  <legend className="text-md font-semibold text-gray-900 px-2 col-span-7">
                    Upload New Document
                  </legend>
                  <div className="col-span-7 pl-1">
                    <input
                      type="file"
                      accept="application/pdf, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.presentation"
                      {...register("uploadFileDailyTips")}
                      onChange={handleFileChange}
                      name="uploadFileDailyTips"
                    />
                  </div>

                  <div className="col-span-7 flex gap-3 justify-end">
                    {userActions.map((obj) => (
                      <>
                        {!obj.isAction && obj.action === "Create" ? (
                          <>
                            <div>
                              <CommonButton
                                label={"Reset"}
                                className={
                                  "border border-customRed text-customRed"
                                }
                                onClick={() => methods.reset()}
                              />
                            </div>
                            <div
                              onClick={() => {
                                setPrivilege(obj.action);
                              }}
                            >
                              <CommonButton
                                label="Add"
                                className="bg-customGreen text-white"
                                onClick={() => onsubmit()}
                              />
                            </div>
                          </>
                        ) : null}
                      </>
                    ))}
                  </div>
                </div>
              </fieldset>
              <hr className="my-2" />
            </div>
          )}

          <div
            className={`${
              isFoldersVisible ? "col-span-3" : "col-span-4"
            } border border-gray-500 lg:h-[350px] xl:h-[580px]  2xl:h-[740px]`}
          >
            <iframe width="100%" height="100%" src={fileURL} target="_blank" />
          </div>
        </div>
      </div>

      <CommonBackDrop openBackdrop={openBackdrop} />
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => {
          setOpenConfirmationModal(false);
        }}
        confirmationSubmitFunc={() => handleFormSubmit()}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </>
  );
};

export default HrDms;
