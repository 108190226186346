import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTablePagination from "../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import RadioField from "../../../../common/components/FormFields/RadioField";
import SearchBar from "../../../../common/components/FormFields/SearchBar";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import {
  autoSerachPatient,
  colourTubeTemperature,
  fetchSampleCollectionBarcode,
  getSampleCategoryList,
  getUnitList,
  saveColorTubeTemperature,
  saveOutSourceSample,
  saveSampleCollection,
  searchPatientList,
  searchPatientTestList,
} from "../../Services/SampleCollectionServices";
import { getPreviousSampleNoList } from "../../Services/WorkOrderGenerationServices";
import SampleCollectionTestTable from "../../common/SampleCollectionTestTable";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";
import CommonPrintModal from "../../../../common/components/printModal/CommonPrintModal";
import NumberOfCopiesModal from "../../../../common/components/printModal/NumberOfCopiesModal";
import { blobToBase64 } from "../../../../common/components/printModal/BlobToB64";
import { routeToPrinterDevice } from "../../../../commonServices/barCodeServices/BarCodeServices";
import {
  BarcodeIcon,
  BarcodeOnIcon,
} from "../../../../assets/icons/CustomIcons";

const SampleCollection = (props) => {
  const { updateComponent } = props;
  let defaultPrinters = JSON.parse(localStorage.getItem("defaultPrinters"));
  //

  const location = useLocation();
  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = React.useState("");
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [sampleCategory, setSampleCategory] = useState();
  const [unit, setUnit] = useState();
  const [searchString, setSearchString] = useState("");
  const [options, setOptions] = React.useState([]);
  const [data, setData] = React.useState({ result: [], actions: [] });
  const [dataResult, setDataResult] = React.useState([]);
  const [testData, setTestData] = React.useState([]);
  const [testDataResult, setTestDataResult] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = useState();
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedTest, setSelectedTest] = useState(null);
  const [finalData, setFinalData] = React.useState();
  const [isOutSourceData, setIsOutsourceData] = React.useState();
  const [temperatureTubeData, setTemperatureTubeData] = React.useState();
  const [openPost, setOpenPost] = React.useState(false);
  const [openTemperatureModal, setOpenTemperatureModal] = React.useState();
  const [openSampleNoMpdal, setOpenSampleNoModal] = useState(false);
  const [rowIndex, setRowIndex] = React.useState();
  const [previousSampleNo, setPreviousSampleNo] = useState(null);
  const [previosSampleNoList, setPreviosSampleNoList] = useState([]);
  const [temperatureTubeList, setTemperatureTubeList] = useState([]);
  const [orderDtlsIdArray, setOrderDtlsIdArray] = useState([]);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [testLoadingSpinner, setTestLoadingSpinner] = useState(false);
  const [urlforPrint, setUrlforPrint] = React.useState();
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [openNumberOfCopiesModal, setOpenNumberOfCopiesModal] = useState(false);
  const [barcodeResponse, setBarcodeResponse] = useState(null);

  const handleClosePost = () => {
    setOpenPost(false);
  };

  console.log("selectedRow123", selectedRow);
  let defaultUnit = {
    id: 2,
    label: "IMH(FATIMA NAGAR)",
    value: "IMH(FATIMA NAGAR)",
  };
  const defaultValues = {
    patientType: 4,
    sampleType: 0,
    searchBar: "",
    fromDate: new Date(),
    toDate: new Date(),
    category: null,
    testType: null,
    unit: defaultUnit,
  };

  const schema = yup.object().shape({});
  const {
    control,
    reset,
    handleSubmit,
    watch,
    setValue,
    getValues,
    register,
    setError,
    clearErrors,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });
  let getFromExistSample = watch("fromExistSample");
  console.log("fromExistSample", getFromExistSample);
  const patientType = [
    {
      id: 4,
      value: "All",
      label: "All",
    },
    {
      id: 0,
      value: "OPD",
      label: "OPD",
    },
    {
      id: 1,
      value: "IPD",
      label: "IPD",
    },
  ];

  const sampleType = [
    {
      id: 2,
      value: "All",
      label: "All",
    },
    {
      id: 1,
      value: "Collected",
      label: "Collected",
    },
    {
      id: 0,
      value: "Not-Collected",
      label: "Not-Collected",
    },
  ];

  const testType = [
    {
      id: 0,
      value: "Normal",
      label: "Normal",
    },
    {
      id: 1,
      value: "Retest",
      label: "Retest",
    },
    {
      id: 2,
      value: "Dummy",
      label: "Dummy",
    },
    {
      id: 3,
      value: "All",
      label: "All",
    },
  ];

  let getCategory = watch("category");
  let getPatientType = watch("patientType");
  let getSampleType = watch("sampleType");
  let getTestType = watch("testType");
  let getUnitId = watch("unit");
  let getFromDate = watch("fromDate");
  let getToDate = watch("toDate");

  function handleClick(row, index) {
    setRowIndex(index);
  }

  // const populateTable = (forPaginantion) => {
  //   const defaultParams = {
  //     category: 0,
  //     fromDate: getFromDate,
  //     opdIpd: 4,
  //     orderId: null,
  //     sampleType: 2,
  //     searchString: searchString,
  //     testType: 3,
  //     toDate: getToDate,
  //     unitId: 2,
  //     page: !forPaginantion ? 0 : page,
  //     size: rowsPerPage,
  //     menuId: location?.state?.menuId,
  //   };
  //   searchPatientList(defaultParams)
  //     .then((response) => response.data)
  //     .then((res)=>{
  //       if(forPaginantion){
  //         setDataResult((prevData) => [...prevData,...res.result]);
  //       } else {
  //         setDataResult(res.result);
  //         setPage(0);
  //       }
  //        setCount(res.count);
  //        setUserActions(res.actions);
  //     })
  //     // .then((res) => {
  //     //   setData(res);
  //     //   setCount(res.count);
  //     //   setDataResult(res.result);
  //     //   setUserActions(res.actions);
  //     // });
  // };

  console.log("dataResult123", dataResult);

  const populateTable = (forPaginantion) => {
    let searchObj = {};
    let requiredObj = {};
    if (getCategory) {
      requiredObj.catId = getCategory ? getCategory.id : 0;
    }
    if (getTestType) {
      requiredObj.testId = getTestType ? getTestType.id : 0;
    }
    if (getUnitId) {
      requiredObj.unitId = getUnitId ? getUnitId.id : 0;
    }

    searchObj.category = requiredObj.catId ? requiredObj.catId : 0;
    searchObj.fromDate = getFromDate;
    searchObj.opdIpd = Number(getPatientType);
    searchObj.orderId = searchString ? searchString?.id : null;
    searchObj.sampleType = Number(getSampleType);
    searchObj.searchString = searchString ? searchString?.label : "";
    searchObj.testType = requiredObj.testId ? requiredObj.testId : 0;
    searchObj.toDate = getToDate;
    searchObj.unitId = requiredObj.unitId ? requiredObj.unitId : 0;
    searchObj.page = !forPaginantion ? 0 : page;
    searchObj.size = rowsPerPage;
    searchObj.menuId = location?.state?.menuId;
    setLoadingSpinner(true);
    searchPatientList(searchObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPaginantion) {
          setDataResult((prevData) => [...prevData, ...res.result]);
          setLoadingSpinner(false);
        } else {
          setDataResult(res.result);
          setPage(0);
          setLoadingSpinner(false);
        }
        setCount(res.count);
        setUserActions(res.actions);
      });
  };

  const handleChange = (autoSearchString) => {
    let searchObj = {
      fromDate: getFromDate,
      searchString: autoSearchString,
      toDate: getToDate,
    };
    if (autoSearchString !== "") {
      autoSerachPatient(searchObj)
        .then((response) => response.data)
        .then((res) => {
          console.log("searchlist", res.result);
          setOptions(res.result);
        });
    }
  };

  const autoSelectedValue = (value) => {
    console.log("value123", value);
    if (value === null) {
      setSearchString("");
    } else {
      setSearchString(value);
    }
  };

  function getSampleCategory() {
    getSampleCategoryList()
      .then((response) => response.data)
      .then((res) => {
        setSampleCategory(res.result);
      });
  }

  function getUnit() {
    getUnitList()
      .then((response) => response.data)
      .then((res) => {
        setUnit(res.result);
      });
  }

  useEffect(() => {
    let orderDtlsArr = [];
    for (let i = 0; i < testData.length; i++) {
      if (testData[i].checked === true) {
        orderDtlsArr.push(testData[i].OrderDtlsId);
      }
      console.log("orderDtlsArr", orderDtlsArr);
    }

    if (orderDtlsIdArray.length > 0) {
      colourTubeTemperature(orderDtlsArr).then((response) => {
        console.log(response.data.result);
        setTemperatureTubeList(response.data.result);
      });
    }
  }, [orderDtlsIdArray]);

  console.log("temperatureTubeList123", temperatureTubeList);

  useEffect(() => {
    getSampleCategory();
    getUnit();
    populateTable();
  }, []);

  useEffect(() => {
    let requiredObj = {};
    if (getCategory) {
      requiredObj.catId = getCategory.id;
    }

    if (selectedRow) {
      let testObj = {
        categoryId: requiredObj.catId,
        isAuthority: 0,
        orderId: selectedRow.OrderId,
        status: 0,
      };
      setTestLoadingSpinner(true);
      searchPatientTestList(testObj)
        .then((response) => response.data)
        .then((res) => {
          setTestData(res.result);
          setTestDataResult(res);
          setTestLoadingSpinner(false);
        });
    }
    setValue("fromExistSample", selectedRow?.existsample);
  }, [selectedRow]);

  console.log("orderDtlsIdArray123", orderDtlsIdArray);

  const onSubmitData = (data) => {
    let findSampleNo = testData?.find((test) => test.SampleNo !== 0);
    console.log("datadata123", privilege);
    let siteInfoRequestDtoList = [];
    let isOutSourceArr = [];
    let temp = [...testData];
    console.log("postObj123", temp);

    let requiredObj = {};
    requiredObj.menuId = location.state.menuId;
    requiredObj.privilege = data ? data : privilege;
    requiredObj.addWithExistingSampleNo = getFromExistSample;
    requiredObj.orderId = selectedRow?.OrderId;
    requiredObj.sampleNo = findSampleNo
      ? findSampleNo?.SampleNo
      : previousSampleNo
      ? previousSampleNo
      : 0;
    requiredObj.siteInfoRequestDtoList = siteInfoRequestDtoList;

    for (let i = 0; i < temp.length; i++) {
      if (temp[i].checked === true) {
        siteInfoRequestDtoList.push({
          orderDtlsId: testData[i].OrderDtlsId,
          site: testData[i].site,
        });
      }
      let isOutSourceObj = {};
      if (temp[i].IsOutSourced === true) {
        isOutSourceObj.agencyId = temp[i].ExtAgencyId;
        isOutSourceObj.collectingBy = temp[i].OutSourceSampleReceivedBy;
        isOutSourceObj.isOutSource = temp[i].IsOutSourced;
        isOutSourceObj.orderDtlsId = temp[i].OrderDtlsId;
        isOutSourceObj.quantity = Number(temp[i].Quantity);
        isOutSourceObj.technicianName = temp[i].TechnitionName;
        isOutSourceArr.push(isOutSourceObj);
      }
      console.log("isOutSourceArr123", isOutSourceArr);
      setIsOutsourceData(isOutSourceArr);
    }

    let colorTubeArray = [];
    let getUserId = JSON.parse(localStorage.getItem("userInfo"));
    for (let obj of temperatureTubeList) {
      console.log("objobj123", obj);
      let colorTubeObj = {
        atFloorCollectBy: 0,
        atLabCollectBy: getUserId.userId,
        collectAtFloor_DateTime: "1900-01-01T00:00:00.000Z",
        collectAtLab_DateTime: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
        isCollectFromLab: true,
        colorTubeId: obj.id,
        orderID: selectedRow?.OrderId,
        temperatureCollectAtFloor: "",
        temperatureCollectAtLab: obj.testLimit,
      };
      colorTubeArray.push(colorTubeObj);
      console.log("colorTubeObj987", colorTubeArray);

      setTemperatureTubeData(colorTubeArray);
    }

    console.log("requiredObj987", requiredObj);
    setFinalData(requiredObj);
  };

  function postData() {
    setOpenBackdrop(true);
    handleClosePost();
    saveSampleCollection(finalData)
      .then((response) => response.data)
      .then((res) => {
        populateTable();
        setData([]);
        setTestDataResult([]);
        reset(defaultValues);
        successAlert(res.message);
        handleClosePost();
        setOpenBackdrop(false);
        handleOpenPrintModal("barcode");
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
        handleClosePost();
        setOpenBackdrop(false);
      });
    if (isOutSourceData.length > 0) {
      saveOutSourceSample(isOutSourceData)
        .then((response) => response.data)
        .then((res) => {
          successAlert(res.message);
        })
        .catch((error) => {
          errorAlert(error.response.data.message || error.message);
        });
    }
    if (temperatureTubeData.length > 0) {
      saveColorTubeTemperature(temperatureTubeData)
        .then((response) => response.data)
        .then((res) => {
          successAlert(res.message);
        })
        .catch((error) => {
          errorAlert(error.response.data.message || error.message);
        });
    }

    setOpenPost(false);
  }

  useEffect(() => {
    if (selectedRow !== null) {
      getPreviousSampleNoList(selectedRow?.Opd_Ipd_External_Id).then(
        (response) => {
          console.log("responseData123", response.data.result);
          setPreviosSampleNoList(response.data.result);
        }
      );
    }
  }, [selectedRow]);

  console.log("testData123", testData);

  function onSaveCheckError(data) {
    let error = testData?.some((arr) => arr.error !== false);
    console.log("errorrrrrrr", error);
    if (
      error === false &&
      getFromExistSample === false &&
      temperatureTubeList?.length > 0
    ) {
      setOpenTemperatureModal(true);
    } else if (error === false && getFromExistSample === true) {
      setOpenSampleNoModal(true);
    } else if (
      error === false &&
      getFromExistSample === false &&
      temperatureTubeList?.length === 0
    ) {
      onSubmitData(data);
      setOpenPost(true);
    } else if (error === true) {
      warningAlert("Please Enter Site Information");
      setOpenPost(false);
    }
  }

  const handleSelectedRow = (row, index) => {
    setSelectedRow(row);
  };

  const rowBackgroundColor1 = (row, index) => {
    // if (row.Count === 1) {
    //   return "#9CEF95";
    // }
    if (row.Urgent === 1) {
      return "#FF9D9D";
    }
  };

  const handleDirectPrint = async (data) => {
    let b64 = await blobToBase64(barcodeResponse);
    const obj = [
      {
        printerName: defaultPrinters?.barCodePrinter,
        pdf: b64,
        numberCopies: data.numberOfCopies,
        pageSize: defaultPrinters?.barcodeSize,
      },
    ];
    await routeToPrinterDevice(obj).then(() => {
      setBarcodeResponse(null);
    });
  };

  const handleOpenPrintModal = (type, row) => {
    if (type === "barcode") {
      fetchSampleCollectionBarcode(
        row ? row?.OrderId : selectedRow?.OrderId
      ).then((response) => {
        if (defaultPrinters) {
          setOpenNumberOfCopiesModal(true);
          setBarcodeResponse(response);
        } else {
          setUrlforPrint(response);
          setOpenPrintModal(true);
        }
      });
    }
  };

  const renderActions = (row, index) => {
    return (
      <div>
        <button
          type="button"
          onClick={() => {
            handleOpenPrintModal("barcode", row);
          }}
        >
          <BarcodeIcon />
        </button>
      </div>
    );
  };

  useEffect(() => {
    if (updateComponent) {
      setValue("fromDate", new Date());
      setValue("toDate", new Date());
      populateTable();
    }
  }, [updateComponent]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitData)}>
        <div className="mt-12 space-y-2">
          <div className="text-center">
            <label className="text-lg font-bold">Sample Collection</label>
          </div>
          <div className="grid grid-cols-3 lg:grid-cols-5 xl:grid-cols-7 gap-2">
            <div className="col-span-2 lg:col-span-2 xl:col-span-2 z-50">
              <SearchBar
                control={control}
                name="searchBar"
                placeholder="Search by Patient Name / MRNo / Sample No"
                searchIcon={true}
                isSearchable={true}
                isClearable={true}
                dataArray={options}
                handleInputChange={handleChange}
                onChange={autoSelectedValue}
              />
            </div>
            <div>
              <DatePickerFieldNew
                control={control}
                name="fromDate"
                label="From Date"
                value={new Date()}
                onChange={(newValue) => {
                  setValue("fromDate", newValue);
                }}
                disableFuture={true}
                inputFormat="dd-MM-yyyy"
              />
            </div>
            <div>
              <DatePickerFieldNew
                control={control}
                name="toDate"
                label="To Date"
                value={new Date()}
                onChange={(newValue) => {
                  setValue("toDate", newValue);
                }}
                disableFuture={true}
                inputFormat="dd-MM-yyyy"
              />
            </div>

            <div>
              <DropdownField
                control={control}
                name="category"
                placeholder="Category"
                dataArray={sampleCategory}
                isSearchable={true}
                {...register("category")}
              />
            </div>
            <div>
              <DropdownField
                control={control}
                name="testType"
                placeholder="Test Type"
                dataArray={testType}
                isSearchable={true}
              />
            </div>
            <div>
              <DropdownField
                name="unit"
                control={control}
                placeholder="Unit"
                dataArray={unit}
                isSearchable={true}
              />
            </div>
            <div className="col-span-2 flex items-center gap-2">
              <label className="font-semibold text-sm">
                Patient Type :&nbsp;
              </label>
              <div className="flex items-center mt-1.5 flex-nowrap">
                <RadioField
                  control={control}
                  name="patientType"
                  dataArray={patientType}
                />
              </div>
            </div>
            <div className="col-span-3 lg:col-span-4 flex gap-2 items-center">
              <label className="font-semibold text-sm">
                Sample Type :&nbsp;
              </label>
              <div className="items-center mt-2 flex-nowrap">
                <RadioField
                  className="mx-2"
                  control={control}
                  name="sampleType"
                  dataArray={sampleType}
                />
              </div>
              <div className="flex xl:justify-end">
                <CommonButton
                  type="button"
                  className="bg-customBlue text-white"
                  searchIcon={true}
                  onClick={() => {
                    setPage(0);
                    populateTable();
                    setTestData([]);
                  }}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="flex justify-between">
              <div className="font-semibold text-lg">List Of Orders</div>
              <div className="flex gap-4">
                <div className="flex gap-1 items-center">
                  <div className="h-4 w-4 bg-[#FF9D9D]"></div>
                  <label className="font-semibold text-sm">Urgent</label>
                </div>
                {/*
               <div className="flex gap-1 items-center">
                  <div className="h-4 w-4 bg-[#9CEF95]"></div>
                  <label className="font-semibold text-sm">Completed</label>
                </div>
                */}
              </div>
            </div>
            {!loadingSpinner ? (
              <>
                {dataResult !== undefined && dataResult.length > 0 ? (
                  <CommonDynamicTablePagination
                    dataResult={dataResult}
                    populateTable={populateTable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    handleSelectedRow={handleSelectedRow}
                    count={count}
                    tableClass="h-52"
                    rowBackgroundColor={rowBackgroundColor1}
                    renderActions={renderActions}
                    removeHeaders={[
                      "Opd_Ipd_External_Id",
                      "Opd_Ipd_External",
                      "IsApproved",
                      "IsDelivered",
                      "IsCompleted",
                      "IsPrinted",
                      "IsOrderGenerated",
                      "PatientId",
                      "OpdIpd",
                      "Urgent",
                      "existsample",
                      "colortubesample",
                    ]}
                  />
                ) : (
                  <div className="justify-center items-center font-semibold md:h-56 2xl:h-72 w-full flex border-2 border-gray-300 rounded">
                    No Records Found...
                  </div>
                )}
              </>
            ) : (
              <div className="justify-center items-center font-semibold md:h-56 2xl:h-72 w-full flex border-2 border-gray-300 rounded">
                <LoadingSpinner />
              </div>
            )}
          </div>
          <div>
            <div className="flex justify-between">
              <label className="text-lg font-bold mx-2">Tests</label>
              <fieldset className="flex justify-end">
                <CheckBoxField
                  control={control}
                  name="fromExistSample"
                  label="Add With Existing Sample"
                  defaultValue={selectedRow?.existsample}
                  disabled={previosSampleNoList?.length > 0 ? false : true}
                />
              </fieldset>
            </div>
            {!testLoadingSpinner ? (
              <>
                {testDataResult.hasOwnProperty("result") &&
                testDataResult?.result.length > 0 ? (
                  <SampleCollectionTestTable
                    testData={testData}
                    setTestData={setTestData}
                    selectedRow={selectedRow}
                    setTestDataResult={setTestDataResult}
                    setSelectedTest={setSelectedTest}
                    setOrderDtlsIdArray={setOrderDtlsIdArray}
                  />
                ) : (
                  <div className="justify-center items-center font-semibold md:h-56 2xl:h-72 w-full flex border-2 border-gray-300 rounded">
                    No Records Found...
                  </div>
                )}
              </>
            ) : (
              <div className="justify-center items-center font-semibold md:h-56 2xl:h-72 w-full flex border-2 border-gray-300 rounded">
                <LoadingSpinner />
              </div>
            )}
          </div>
          {userActions.map((action, i) => (
            <>
              {action.action === "Create" && action.isAction === false ? (
                <div className="flex gap-3 justify-end">
                  <CommonButton
                    type="button"
                    label="Reset"
                    className="border border-customRed text-customRed"
                    onClick={() => {
                      populateTable();
                      reset(defaultValues);
                      setData([]);
                      setTestDataResult([]);
                    }}
                  />
                  {orderDtlsIdArray?.length > 0 ? (
                    <CommonButton
                      type="button"
                      label="Save"
                      className="bg-customGreen text-white"
                      onClick={() => {
                        setPrivilege(action.action);
                        onSaveCheckError(action.action);
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </>
          ))}
        </div>
        {temperatureTubeList?.length > 0 && getFromExistSample !== true ? (
          <Modal open={openTemperatureModal}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                boxShadow: 20,
                width: "30%",
                maxHeight: "50%",
                p: 3,
                overflowY: "scroll",
              }}
            >
              <CancelPresentationIconButton
                onClick={() => {
                  setOpenTemperatureModal(false);
                  // setPreviousSampleNo(null);
                  setValue("addSample", false);
                }}
              />
              <div className="my-3">
                <div className="grid w-auto">
                  <Box sx={{ width: "100%", overflow: "hidden" }}>
                    <Paper sx={{ width: "100%" }}>
                      <TableContainer>
                        <Table
                          size="small"
                          stickyHeader
                          aria-label="sticky table"
                          sx={{
                            border: 1,
                            borderColor: "#e0e0e0",
                            paddingY: "scroll",
                          }}
                        >
                          <TableHead>
                            <TableRow
                              sx={{
                                "& th": {
                                  paddingY: 0.1,
                                  backgroundColor: "#F1F1F1",
                                },
                              }}
                            >
                              <>
                                <TableCell>Tube Color</TableCell>
                                <TableCell>Temperature</TableCell>
                              </>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {temperatureTubeList?.map((row, index) => {
                              console.log("rowrowrowtube", row);
                              return (
                                <TableRow
                                  key={index}
                                  onClick={() => {
                                    // setPreviousSampleNo(row.SampleNo);
                                  }}
                                >
                                  <TableCell>{row.colourTube}</TableCell>
                                  <TableCell>
                                    <div className="w-28">
                                      <InputField
                                        control={control}
                                        name={`temperature${index}`}
                                        label="Temperature"
                                        defaultValue={row.testLimit}
                                        inputRef={{
                                          ...register(`temperature${index}`, {
                                            onChange: (e) => {
                                              let dataArray = [
                                                ...temperatureTubeList,
                                              ];
                                              if (e.target.value) {
                                                dataArray[index].testLimit =
                                                  e.target.value;
                                                setTemperatureTubeList(
                                                  dataArray
                                                );
                                                console.log(
                                                  "dataArray123",
                                                  dataArray
                                                );
                                              }
                                            },
                                          }),
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </Box>
                  <div className="flex justify-center mt-4">
                    <CommonButton
                      type="button"
                      label="Save"
                      className="bg-customGreen text-white"
                      onClick={() => {
                        setOpenTemperatureModal(false);
                        onSubmitData();
                        setOpenPost(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
        ) : (
          ""
        )}

        {getFromExistSample === true ? (
          <Modal open={openSampleNoMpdal}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                boxShadow: 20,
                width: "60%",
                height: "40%",
                p: 3,
                overflowY: "scroll",
              }}
            >
              <CancelPresentationIconButton
                onClick={() => {
                  setOpenSampleNoModal(false);
                  setPreviousSampleNo(null);
                  setValue("addSample", false);
                }}
              />
              <div className="my-3">
                {previosSampleNoList?.length > 0 ? (
                  <div className="grid w-auto">
                    <Box sx={{ width: "100%", overflow: "hidden" }}>
                      <Paper sx={{ width: "100%" }}>
                        <TableContainer>
                          <Table
                            size="small"
                            stickyHeader
                            aria-label="sticky table"
                            sx={{
                              border: 1,
                              borderColor: "#e0e0e0",

                              paddingY: "scroll",
                            }}
                          >
                            <TableHead>
                              <TableRow
                                sx={{
                                  "& th": {
                                    paddingY: 0.5,
                                    backgroundColor: "#F1F1F1",
                                  },
                                }}
                              >
                                <>
                                  <TableCell>Sample No.</TableCell>
                                  <TableCell>Order No.</TableCell>
                                  <TableCell>Date</TableCell>
                                </>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {previosSampleNoList.map((row, index) => {
                                console.log("rowrowrow", row);
                                return (
                                  <TableRow
                                    key={index}
                                    onClick={() => {
                                      handleClick(row, index);
                                      setPreviousSampleNo(row.SampleNo);
                                    }}
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        rowIndex === index ? "#FFC44B" : "",
                                    }}
                                  >
                                    <TableCell>{row.SampleNo}</TableCell>
                                    <TableCell>{row.OrderNo}</TableCell>
                                    <TableCell>{row.Date}</TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </Box>
                    <div className="flex justify-end my-2">
                      <CommonButton
                        type="button"
                        label="OK"
                        className="bg-customGreen text-white"
                        onClick={() => {
                          onSubmitData();
                          setOpenPost(true);
                        }}
                        disabled={previousSampleNo === null}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="justify-center items-center font-semibold md:h-56 2xl:h-72 w-full flex border-2 border-gray-300 rounded">
                    No Records Found...
                  </div>
                )}
              </div>
            </Box>
          </Modal>
        ) : (
          ""
        )}
        {openPrintModal ? (
          <CommonPrintModal
            open={openPrintModal}
            setOpen={setOpenPrintModal}
            handleOpen={() => {
              setOpenPrintModal(true);
            }}
            handleClose={() => {
              setOpenPrintModal(false);
            }}
            urlforPrint={urlforPrint}
          />
        ) : null}

        {openNumberOfCopiesModal ? (
          <NumberOfCopiesModal
            open={openNumberOfCopiesModal}
            setOpen={setOpenNumberOfCopiesModal}
            handleSave={handleDirectPrint}
            onClose={() => {
              setOpenNumberOfCopiesModal(false);
            }}
          />
        ) : null}
        <CommonBackDrop openBackdrop={openBackdrop} />
        <ConfirmationModal
          confirmationOpen={openPost}
          confirmationHandleClose={handleClosePost}
          confirmationSubmitFunc={postData}
          confirmationLabel="Confirmation"
          confirmationMsg="Are you sure want to add this Record ?"
          confirmationButtonMsg="Add"
        />
      </form>
    </>
  );
};

export default SampleCollection;
