import React, { useEffect, useRef } from "react";
import { fetchPrescriptionPrint } from "../services/drugAdministrationChartService/DrugAdministartionChartService";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";
import { useState } from "react";
import { InamdarHospiLogo } from "../../assets/printAssets/InmadarHospiLogo";

export default function PriscriptionPrint({ obj }) {
  const printRef = useRef(null);
  const [printDataResult, setPrintDataResult] = useState(null);

  useEffect(() => {
    fetchPrescriptionPrint(obj).then((response) => {
      setPrintDataResult(response?.data?.result);
      console.log(response?.data?.result);
    });
  }, []);

  useEffect(() => {
    if (!!printDataResult && printRef.current) {
      GeneratePrint(printRef);
    }
  }, [printDataResult]);

  console.log("printDataResult", printDataResult);
  return (
    <div ref={printRef} className="text-[11px]">
      {!!printDataResult && (
        <div className="">
          {/* header */}
          <table>
            <thead>
              <tr className="text-left">
                <div className="print-header">
                  <div className=" grid grid-cols-1 ">
                    <div className="mr-2 ">
                      <h6 className="font-semibold text-[15px]  text-center">
                        Inamdar Multispeciality Hospital
                      </h6>
                      <p className=" text-[11px]  text-center">
                        Sr. No 15, Fatimanagar, Behind Kpct Mall Pune - 411 040.
                      </p>
                      <h6 className="font-semibold text-[11px]  text-center">
                        Phone No : 020-66812222/44
                      </h6>
                      <div className=" flex justify-end gap-2 -mt-[50px]">
                        <InamdarHospiLogo />
                      </div>
                    </div>
                  </div>
                  <div className="text-center border-t border-black">
                    <h1 className="font-semibold text-[13px]">Prescription</h1>
                  </div>
                  <div>
                    <div className="border-b-[1px] p-1 border-t-[1px] border-black grid grid-cols-[16%_1%_33%_16%_1%_33%]">
                      <div className="font-semibold">MRNo</div>
                      <div className="font-semibold">:</div>
                      <div>{printDataResult?.MRNo}</div>
                      <div className="font-semibold">Age</div>
                      <div className="font-semibold">:</div>
                      <div>{printDataResult?.AgeYear}</div>
                      <div className="font-semibold">Patient Name</div>
                      <div className="font-semibold">:</div>
                      <div>{printDataResult?.PatientName}</div>
                      <div className="font-semibold">Height</div>
                      <div className="font-semibold">:</div>
                      <div>{printDataResult?.Height}</div>
                      <div className="font-semibold">Bed No</div>
                      <div className="font-semibold">:</div>
                      <div>{printDataResult?.BedNo}</div>
                      <div className="font-semibold">Weight</div>
                      <div className="font-semibold">:</div>
                      <div>{printDataResult?.Weight}</div>
                      <div className="font-semibold">Doctor Name</div>
                      <div className="font-semibold">:</div>
                      <div>{printDataResult?.DoctorName}</div>
                      <div className="font-semibold">Admission Date</div>
                      <div className="font-semibold">:</div>
                      <div>{printDataResult?.AdmissionDate}</div>
                    </div>
                  </div>
                </div>
              </tr>
            </thead>
            <tbody className="border-b border-black">
              {/* page content */}
              <div className="w-full overflow-x-auto ">
                <table className="w-[100%] border-gray-200">
                  <thead className="border-b border-black">
                    <tr className="grid grid-cols-12 text-left gap-x-0">
                      <th className="col-span-1">Date Of Start</th>
                      <th className="col-span-4 ml-1">Drug Name</th>
                      <th className="col-span-1">Dose</th>
                      <th className="col-span-2">Route</th>
                      <th className="col-span-2">Frequency</th>
                      <th className="col-span-2">Instruction</th>
                    </tr>
                  </thead>
                  <tbody className="border-b border-black">
                    {printDataResult?.prescriptionDetails?.map((med, index) => (
                      <tr
                        key={index}
                        className={`grid grid-cols-12 text-left gap-x-0 ${
                          med?.IsOutsideMedication && "text-blue-300"
                        }`}
                      >
                        <td className=" py-1 whitespace-pre-line overflow-ellipsis col-span-1">
                          {med?.["Date Of Start"]}
                        </td>
                        <td className=" py-1 whitespace-pre-line overflow-ellipsis col-span-4 ml-1">
                          <div className="flex justify-between gap-1">
                            <div> {med?.["Drug Name"]}</div>
                            {med?.IsHoldDrugAdmin && (
                              <div className="font-bold  w-4 border border-black rounded-full text-center text-black mr-2">
                                H
                              </div>
                            )}
                          </div>
                        </td>
                        <td className=" py-1 whitespace-pre-line overflow-ellipsis col-span-1">
                          {med?.Dose}
                        </td>
                        <td className=" py-1 whitespace-pre-line overflow-ellipsis col-span-2">
                          {med?.Route}
                        </td>
                        <td className=" py-1 whitespace-pre-line overflow-ellipsis col-span-2 flex  items-center gap-4 ">
                          <div>{med?.Frequency}</div>
                          <div>{med?.IsOutsideMedication ? "(O.M.)" : ""}</div>
                        </td>
                        <td className="py-1 whitespace-pre-wrap overflow-ellipsis pr-2 col-span-2">
                          {med?.Instruction}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="border-y border-black my-3 text-center font-semibold text-[13px]">
                INTRAVENOUS INFUSION THERAPY CHART
              </div>
              <div className="w-full overflow-x-auto">
                <table className="w-full border-gray-200">
                  <thead className="border-b border-black">
                    <tr className="grid grid-cols-12 text-left">
                      <th className="col-span-1">Date</th>
                      <th className="col-span-1">Time</th>
                      <th className="col-span-2">IV Fluid</th>
                      <th className="col-span-1">IV Vol</th>
                      <th className="col-span-1">Rate Of Infusion</th>
                      <th className="col-span-1">Drug Added</th>
                      <th className="col-span-1">Dose Of Added Drug</th>
                      <th className="col-span-2">Doctor Name</th>
                      <th className="col-span-1">Started By</th>
                      <th className="col-span-1">Ended By</th>
                    </tr>
                  </thead>
                  <tbody className="border-b border-black">
                    {printDataResult?.ivFluidDetails?.length > 0 ? (
                      printDataResult?.ivFluidDetails?.map(
                        (infusion, index) => (
                          <tr
                            key={index}
                            className="grid grid-cols-12 text-left"
                          >
                            <td className="col-span-1 py-1 whitespace-pre-line overflow-ellipsis">
                              {infusion?.Date}
                            </td>
                            <td className="col-span-1 py-1 whitespace-pre-line overflow-ellipsis">
                              {infusion?.Time}
                            </td>
                            <td className="col-span-2 py-1 whitespace-pre-line">
                              {infusion?.IVFluid}
                            </td>
                            <td className="col-span-1 py-1 whitespace-pre-line overflow-ellipsis">
                              {infusion?.IVVol}
                            </td>
                            <td className="col-span-1 py-1 whitespace-pre-line overflow-ellipsis">
                              {infusion?.["Rate Of Infusion"]}
                            </td>
                            <td className="col-span-1 py-1 whitespace-pre-line overflow-ellipsis">
                              {infusion?.["Drug Added"]}
                            </td>

                            <td className="col-span-1 py-1 whitespace-pre-line overflow-ellipsis">
                              {infusion?.["Dose Of Added Drug"]}
                            </td>
                            <td className="col-span-2 py-1 whitespace-pre-line overflow-ellipsis">
                              {infusion?.["Doctor Name"]}
                            </td>
                            <td className="col-span-1 py-1 whitespace-pre-line overflow-ellipsis">
                              {infusion?.["StartedBy"]}
                            </td>
                            <td className="col-span-1 py-1 whitespace-pre-line overflow-ellipsis">
                              {infusion?.["EndedBy"]}
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <div className="py-5 grid gap-5">
                        <div className="border-dashed border-b border-black "></div>
                        <div className="border-dashed border-b border-black "></div>
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            </tbody>
          </table>

          <div className="grid  grid-cols-3 mt-10 gap-3">
            <div className="grid gap-2">
              <div className="font-bold"> Doctor Name</div>
              <div>{printDataResult.DoctorName}</div>
            </div>
            <div className="grid gap-2 text-center">
              <div className="font-bold"> R.M.O Name</div>
              <div>{printDataResult?.RmoName}</div>
            </div>
            <div className="text-center">
              <div className="font-bold"> sign</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
