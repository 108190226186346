import * as React from "react";

import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonDynamicTablePaginationNew from "../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import {
  approveInPatientReturn,
  fetchInPatientReturnDetailsList,
  fetchInPatientReturnListForApproval,
  fetchInPatientReturnListSearch,
} from "../../../services/pharmacy/inPatientReturnServices/InPatientReturnServices";
import { format, isAfter } from "date-fns";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import DatePickerFieldNew from "../../../../common/components/FormFields/DatePickerFieldNew";
import RadioField from "../../../../common/components/FormFields/RadioField";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import { Tooltip } from "@mui/material";
import { PrintIcon } from "../../../../assets/icons/InvestigationIcon";
import IpdCreditReturnVoucherPrint from "../../../prints/pharmacyPrints/IpdCreditReturnVoucherPrint";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";

const IssuedStatusArr = [
  {
    id: 2,
    value: "All",
    label: "All",
  },
  {
    id: 1,
    value: "Approved",
    label: "Approved",
  },
  {
    id: 0,
    value: "Pending",
    label: "Pending",
  },
];

const ReturnTypeArr = [
  {
    id: 0,
    value: "Cash",
    label: "Cash",
  },
  {
    id: 1,
    value: "Credit",
    label: "Credit",
  },
];

export default function InPatientReturnApproval(props) {
  let token = JSON.parse(localStorage.getItem("userInfo"));
  let location = useLocation();
  //
  const [userActions, setUserActions] = useState([]);

  const [privilege, setPrivilege] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);

  //ipd issue details table
  const [ipdIssueTableData, setIpdIssueTableData] = React.useState([]);
  const [errorToTblInput, setErrorToTblInput] = useState(false);

  // ipd issue table
  const [ipdListData, setIpdListData] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState();

  //item
  const [admissionId, setAdmissionId] = React.useState(0);
  // show patient data
  const [patientListData, setPatientListData] = React.useState();
  //totaAmount
  const [totaAmount, setTotalAmount] = useState(0);
  const [searchString, setSearchString] = useState();

  //prints
  const [urlforPrint, setUrlforPrint] = useState();
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const handleClosePrintModal = () => {
    setOpenPrintModal(false);
  };

  // Actions Confirmation
  //confirmation modal
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  ///related to table
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(25);
  const [count, setCount] = useState(null);
  ///
  ///

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(),
    defaultValues: {
      issuedStatus: 0,
      fromDate: new Date(),
      toDate: new Date(),
      returnType: 1,
    },
  });
  const { control, watch, register, setValue, reset } = methods;

  ///watch
  var issuedStatusRadio = watch("issuedStatus");
  let FromDate = watch("fromDate");
  let ToDate = watch("toDate");
  let selected_returnType = watch("returnType");

  React.useEffect(() => {
    if (
      isAfter(FromDate || new Date(), new Date(1900, 1, 1)) &&
      isAfter(ToDate || new Date(), new Date(1900, 1, 1))
    ) {
      searchListByPatientData();
    }
  }, [FromDate, ToDate, searchString]);

  //list by patient data
  const searchListByPatientData = (e) => {
    const searchObj = {
      isPending: Number(issuedStatusRadio),
      searchString: e,
      toDate: format(ToDate || new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      fromDate: format(FromDate || new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      cashCredit: Number(selected_returnType),
      //
    };

    setSearchString(e);
    fetchInPatientReturnListSearch(searchObj)
      .then((response) => response.data)
      .then((res) => {
        setPatientListData(res.result);
      });
  };
  useEffect(() => {
    if (
      isAfter(FromDate || new Date(), new Date(1900, 1, 1)) &&
      isAfter(ToDate || new Date(), new Date(1900, 1, 1))
    ) {
      populateTable();
      setShowSpinner(true);
    }
  }, [FromDate, ToDate, admissionId, issuedStatusRadio, selected_returnType]);
  console.log("showSpinner", showSpinner);
  const populateTable = (forPagination) => {
    const returnListObj = {
      searchString: searchString,
      admissionId: admissionId,
      toDate: format(ToDate || new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      fromDate: format(FromDate || new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      page: !forPagination ? 0 : page,
      size: rowsPerPage,
      isPending: Number(issuedStatusRadio),
      cashCredit: Number(selected_returnType),
    };

    fetchInPatientReturnListForApproval(returnListObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setIpdListData((prevData) => [...prevData, ...res.result]);
          setIpdIssueTableData([]);
          setSelectedRow(null);
        } else {
          setIpdListData(res.result);
          setPage(0);
          setIpdIssueTableData([]);
          setSelectedRow(null);
        }
        setCount(res.count);
        setShowSpinner(false);
      });
  };

  useEffect(() => {
    let totalQuantity = ipdListData.reduce(
      (total, item) =>
        Number(total) + Number(item["Total Amount Payable"] || 0),
      0
    );

    setTotalAmount(totalQuantity);
  }, [ipdListData]);

  //
  const onChangePatientData = (e) => {
    if (e === "" || e === null) {
      setAdmissionId(0);
      setSearchString("");
    } else if (e !== "" || e !== null) {
      setAdmissionId(e.id);
      setSearchString(e.MRNo);
    }
  };

  //ipd issue details table data
  useEffect(() => {
    if (selectedRow) {
      fetchInPatientReturnDetailsList(
        selectedRow.IPDReturnId,
        Number(selected_returnType)
      )
        .then((response) => response.data)
        .then((res) => {
          let updatedResult = res.result.map((item, index) => ({
            ...item,
            checkReturnQty: item["Avl Qty for Approval"],
          }));
          setIpdIssueTableData(updatedResult);
        });
    }
  }, [selectedRow, ipdListData]);

  //////print

  // const handleOpenPrintModal = (row) => {
  //   // print service
  //   fetchPrintInPatientIssue(row.IPDIssueId, storeId).then((response) => {
  //     setOpenPrintModal(true);

  //     setUrlforPrint(response);
  //   });
  // };

  const handleSelectedRow = (row) => {
    setSelectedRow(row);
  };

  ////////table related
  const handleAccept = () => {
    setOpenBackdrop(true);
    setOpenConfirmationModal(false);
    const approveObj = {
      ipdReturnId: selectedRow?.IPDReturnId,
      menuId: location?.state?.menuId,
      privilege: privilege,
      storeId: selectedRow?.StoreId,
      isCashCredit: Number(selected_returnType),
      poApproveQtyDetailsList: ipdIssueTableData.map((item) => {
        return {
          approveQty: item["Avl Qty for Approval"],
          ipdReturnDetailsId: item.IPDReturnDetailId,
          returnQty: item["Return Qty"],
          batchCode: item["Batch Code"],
          itemId: item?.ItemId,
        };
      }),
    };

    approveInPatientReturn(approveObj)
      .then((response) => response.data)
      .then((res) => {
        populateTable();
        successAlert(res.message);
        setOpenBackdrop(false);
      })
      .catch((res) => {
        errorAlert(res?.response?.data?.message || res?.message);
        setOpenBackdrop(false);
      });
  };
  const renderInput = (row, index, header) => {
    const handleReturnQuantityChange = (index, newValue) => {
      const updatedList = [...ipdIssueTableData];

      let error = Number(newValue) > updatedList[index]["checkReturnQty"];

      updatedList[index]["Avl Qty for Approval"] = Number(newValue);
      updatedList[index]["Total Amount"] =
        Number(newValue) * Number(updatedList[index]["Unit MRP"]);
      updatedList[index]["errorReturnQty"] = error;
      setErrorToTblInput(error);

      setIpdIssueTableData(updatedList);
    };

    return (
      <>
        {header === "Avl Qty for Approval" && Number(issuedStatusRadio) < 1 ? (
          <span>
            <input
              className={
                row.errorReturnQty !== true
                  ? "border border-gray-500 rounded w-24 px-2 focus:outline-none focus:border-2 focus:border-blue-500"
                  : "border-2 border-red-500 rounded w-24 px-2 focus:outline-none focus:border-red-500"
              }
              onChange={(e) =>
                handleReturnQuantityChange(index, e.target.value)
              }
              value={row["Avl Qty for Approval"]}
              type="number"
              min="0"
              onWheel={(event) => event.currentTarget.blur()}
              onKeyDown={(e) => {
                if (
                  e?.key === "-" ||
                  e?.key === "+" ||
                  e?.code === "ArrowUp" ||
                  e?.code === "ArrowDown" ||
                  e?.code === "NumpadDecimal"
                ) {
                  e.preventDefault();
                }
              }}
              name={`Quantity${[index]}`}
            />
          </span>
        ) : (
          row[header]
        )}
      </>
    );
  };

  const renderPrint = (component) => {
    setTimeout(() => {
      setOpenPrintModal(false);
    }, 1000);

    return (
      <div className="hidden">
        <IpdCreditReturnVoucherPrint ipdReturnId={selectedRow?.IPDReturnId} />
      </div>
    );
  };

  const renderActions = (row, index) => {
    return (
      <Tooltip title="Print">
        <button
          type="button"
          onClick={() => {
            setOpenPrintModal(true);
          }}
        >
          <PrintIcon />
        </button>
      </Tooltip>
    );
  };
  return (
    <div className="mt-16">
      {/* heading */}
      <div className="text-center text-black font-bold text-xl ">
        In Patient Return Approval
      </div>
      {/* filters */}
      <div className="grid gap-2 w-full items-center mt-2">
        <div className="grid grid-cols-2 lg:grid-cols-4 w-full gap-2 items-center">
          <div className="">
            <SearchDropdown
              control={control}
              placeholder="Search By Patient Name / MR.No./Mob No."
              dataArray={patientListData}
              name="inPatientReturnSearch"
              searchIcon={true}
              isClearable={true}
              handleInputChange={searchListByPatientData}
              //isDisabled={props?.admissionId ? true : false}
              inputRef={{
                ...register("inPatientReturnSearch", {
                  onChange: (e) => {
                    if (e.target.value !== null) {
                      onChangePatientData(e.target.value.id);
                      setAdmissionId(e.target.value.id);
                    } else {
                      setAdmissionId(0);
                    }
                  },
                }),
              }}
            />
          </div>

          <div className="flex space-x-2">
            <div className="">
              <DatePickerFieldNew
                control={control}
                name="fromDate"
                label="From Date"
                value={new Date()}
                disableFuture={true}
                disablePast={false}
                inputFormat="dd-MM-yyyy"
              />
            </div>
            <div className="">
              <DatePickerFieldNew
                control={control}
                name="toDate"
                label="To Date"
                value={new Date()}
                disableFuture={true}
                disablePast={false}
                inputFormat="dd-MM-yyyy"
              />
            </div>
          </div>
          <div className="">
            <RadioField
              label=""
              name="issuedStatus"
              control={control}
              dataArray={IssuedStatusArr}
            />
          </div>
          <div className="flex space-x-2 items-center">
            <span className="text-sm font-semibold">Type :&nbsp;</span>
            <fieldset className="mt-1.5">
              <RadioField
                name="returnType"
                control={control}
                dataArray={ReturnTypeArr}
              />
            </fieldset>
          </div>
        </div>
      </div>
      {/* tables */}
      <div className="mt-2">
        <div>
          <span className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
            IPD Return List
          </span>
        </div>
        {showSpinner ? (
          <div className="flex justify-center mt-20">
            <LoadingSpinner />
          </div>
        ) : ipdListData.length > 0 ? (
          <>
            <div>
              <CommonDynamicTablePaginationNew
                dataResult={ipdListData}
                populateTable={populateTable}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                count={count}
                renderActions={renderActions}
                handleSelectedRow={handleSelectedRow}
                tableClass={"rounded lg:h-52 md:h-72 xl:h-80"}
                removeHeaders={[
                  "AdmNo",
                  "GenderId",
                  "IPDIssueId",
                  "IndentId",
                  "LoginName",
                  "UserName",
                  "TotalVATAmt",
                ]}
              />
            </div>
          </>
        ) : (
          <div className="grid justify-center">Data Not Found</div>
        )}

        <div className="flex justify-between items-center">
          {ipdIssueTableData.length > 0 ? (
            <div className="mt-2 text-gray-700 font-semibold whitespace-nowrap">
              IPD Return Details
            </div>
          ) : null}

          {ipdListData.length > 0 && (
            <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1 justify-end">
              <span className=" text-black font-semibold whitespace-nowrap w-28">
                Total Amount
              </span>
              <div className="flex space-x-2 items-center">
                <span>: ₹</span>
                <span className="text-gray-700 font-normal">
                  {totaAmount.toFixed(2)}
                </span>
              </div>
            </div>
          )}
        </div>

        <div className="">
          {ipdIssueTableData.length > 0 ? (
            <CommonDynamicTableNew
              highlightRow={false}
              dataResult={ipdIssueTableData}
              editableColumns={["Avl Qty for Approval"]}
              renderInput={renderInput}
              removeHeaders={[
                "Expr1",
                "IPDIssueDetailId",
                "IPDIssueId",
                "PurchaseRate",
                "TaxAmount",
                "TotalForVAT",
                "VATAmount",
                "VATInclusive",
                "VATPercentage",
              ]}
              tableClass={"rounded lg:h-52 md:h-72 xl:h-44"}
            />
          ) : null}
        </div>
      </div>
      {ipdIssueTableData.length > 0 && (
        <div className="flex gap-3 col-span-3 lg:col-span-1 justify-end">
          <div>
            <CommonButton
              label="Reset"
              // onClick={onSubmit}
              className=" border border-customRed text-customRed"
            />
          </div>

          <div>
            <CommonButton
              label="Approve"
              disabled={Number(issuedStatusRadio) > 0 ? true : false}
              onClick={() => {
                let validQty = ipdIssueTableData?.some(
                  (item) =>
                    item.errorReturnQty === "" || item.errorReturnQty === true
                );
                !validQty
                  ? setOpenConfirmationModal(true)
                  : warningAlert("Please Enter Valid Quantity...");
              }}
              className="bg-customGreen text-white"
            />
          </div>
        </div>
      )}
      <CommonBackDrop openBackdrop={openBackdrop} />
      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => setOpenConfirmationModal(false)}
        confirmationSubmitFunc={() => handleAccept()}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
      {openPrintModal && renderPrint()}
    </div>
  );
}

