import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
// import { searchAlternativeDrugs } from "../../pharmacyServices/pharmaServices";
import GenericDrugList from "./GenericDrugList";
import { getAlternativeDrugList } from "../../PharmacyServices";

export default function GenericDrugModal(props) {
  const {
    setSelectedGenericDrug,
    rowToReplace,
    indexToReplace,
    setAlternateTableList,
    data,
    selectedGenericDrug,
  } = props;

  let genericId = rowToReplace?.genericId;

  const [dataResult, setDataResult] = React.useState([]);
  const [dataTest, setDataTest] = React.useState({ result: [] });
  const loggedUser = JSON.parse(localStorage.getItem("userInfo"));

  React.useEffect(() => {
    let searchObject = {
      deptStore: 1,
      deptStoreId: loggedUser?.storeId,
      genericId: genericId,
      isConsignment: 0,
      itemCode: "%",
      itemName: "%",
      searchString: "",
    };
    getAlternativeDrugList(searchObject)
      .then((response) => response.data)
      .then((res) => {
        setDataResult(res.result);
        setDataTest({ result: res.result });
      })
      .catch((error) => {});
  }, [genericId]);

  React.useEffect(() => {
    if (selectedGenericDrug !== "") {
      setDataResult([]);
      setDataTest({ result: [] });
    }
  }, [selectedGenericDrug]);

  return (
    <div>
      <Modal
        open={props.open}
        // onClose={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #848211",
            boxShadow: 24,
            px: 4,
            pb: 4,
            width: "70%",
          }}
        >
          <CancelPresentationIconButton
            onClick={() => {
              props.close();
            }}
            style={{
              paddingLeft: 10,
            }}
          />
          <div className="grid mt-5">
            <div>
              <label className="text-lg font-semibold whitespace-nowrap">
                Alternate drugs based on the selected drug
              </label>
            </div>
          </div>
          <div className="my-5 overflow-x-scroll">
            {dataTest.result.length > 0 ? (
              <GenericDrugList
                from="alternateDrugModal"
                setSelectedGenericDrug={props.setSelectedGenericDrug}
                dataResult={dataResult}
                data={dataTest}
              />
            ) : null}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
