import axios from "axios";
import authHeader from "../../../authentication/authservices/auth-header";
import apiClient, { printersApi } from "../../../http-common";

//autoComplete
export const fetchPatientListForClearanceSearch = (searchObj) => {
  return apiClient.post(
    `/patientClearance/patientListForClearanceSearch
    `,
    searchObj,
    {
      headers: authHeader(),
    }
  );
};

//Clearance list
export const fetchClearenceTableData = (clearenceObj) => {
  return apiClient.post(
    `/patientClearance/patientClearanceList`,
    clearenceObj,
    {
      headers: authHeader(),
    }
  );
};

//department
export const fetchClearenceDepartment = () => {
  return apiClient.get(`/patientClearance/department`, {
    headers: authHeader(),
  });
};

//check list
export const fetchCheckList = () => {
  return apiClient.get(`/patientClearance/getCheckList`, {
    headers: authHeader(),
  });
};

//CheckList Details past
export const fetchCheckListDetails = (admissionId) => {
  return apiClient.get(`/patientClearance/getCheckListDetails/${admissionId}`, {
    headers: authHeader(),
  });
};

export const MultipartApi = axios.create({
  baseURL: apiClient,
  headers: {
    "Content-Type": "application/json",
  },
});

//Save Patient Clearance
export const postPatientClearence = (finalObj) => {
  console.log("finalObj",finalObj);
  return apiClient.post(`/patientClearance/savePatientClearance`, finalObj, {
    headers: authHeader(),
    maxBodyLength: Infinity,
    maxContentLength: Infinity,
    "Content-Type": "application/json",
  });
};

//save Checklist
export const postsavedCheckList = (checklistObj) => {
  return apiClient.post(`/patientClearance/saveCheckList`, checklistObj, {
    headers: authHeader(),
  });
};

//Bill Status Info
export const getBillStatusInfo = (admissionId) => {
  return apiClient.get(`/patientClearance/getBillStatusInfo/${admissionId}`, {
    headers: authHeader(),
  });
};

//scanned images

export const fetchScannedDocument = () => {
  return printersApi.get(
    `/ScannerDevices/getScannedDocument`,
    {
      headers: authHeader(),
      responseType: "arraybuffer",
    }
  );
};

export const fetchDeleteScannedDocument = () => {
  return axios.get(
    `http://localhost:6004/api/ScannerDevices/deleteScannedDocument`,
    {
      headers: authHeader(),
    }
  );
};

//

export const fetchPatientClearanceDocumentPath = (admissionId) => {
  return apiClient.get(
    `/patientClearance/getPatientClearanceDocument/${admissionId}`,
    {
      headers: authHeader(),
    }
  );
};

export const fetchPatientClearanceDocument = (
  fileType,
  path1,
  path2,
  fileName
) => {
  return apiClient.get(
    `/getDocument/${fileType}/${path1}/${path2}/${fileName}`,
    {
      headers: authHeader(),
    }
  );
};

//get service tiff clearance documents

export const getOldTiffClearanceDocuments = (
  fileType,
  path1,
  path2,
  fileName
) => {
  return apiClient.get(
    `/getDocument/${fileType}/${path1}/${path2}/${fileName}`,
    {
      headers: authHeader(),
      responseType: "blob",
    }
  );
};

export const cancelPatientClearance = (
 cancelObj
) => {
  return apiClient.post(
    `/patientClearance/cancelPatientClearance`,cancelObj,
    {
      headers: authHeader(),
    }
  );
};
