import * as React from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import CreateableSelect from "../../../../common/components/FormFields/CreateableSelect";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import {
  fetchDrugDose,
  fetchDrugInstructions,
  fetchDrugName,
  fetchDrugRoute,
  fetchRetriveDrug,
} from "../../../services/dischargeSummary/DischargeSummaryServices";
import { DischargeSummaryContext } from "./DischargeSummaryTabWise";
import { DeleteOnIcon, EditOnIcon } from "../../../../assets/icons/CustomIcons";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import {
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Tooltip } from "@mui/material";
let medicationHeaders = [
  {
    "Item Name": "",
    Dose: "",
    Route: "",
    Frequency: "",
    Instructions: "",
  },
];

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  maxHeight: "65%",
  // maxHeight: "80%",
  overflowY: "auto",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  py: 2,
  px: 2,
  "&:focus": {
    outline: "none",
  },
};
const schema = yup.object().shape({
  // drugName
  drugName: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Required"),
      label: yup.string().required("Required"),
    })
    .required("Required"),

  // dosage
  dosage: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Required"),
      label: yup.string().required("Required"),
    })
    .required("Required"),

  // instructions
  // instructions: yup
  //   .object()
  //   .nullable()
  //   .shape({
  //     value: yup.string().required("Required"),
  //     label: yup.string().required("Required"),
  //   })
  //   .required("Required"),

  // route
  route: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Required"),
      label: yup.string().required("Required"),
    })
    .required("Required"),

  // duration
  duration: yup.string().nullable().required("Required"),
});
export default function AddNewRetrivedDrug(props) {
  const inputRefs = React.useRef([]);
  //
  const {
    admissionId,
    setAllMedicationData,
    allMedicationData,
    retrivedDrugData,
    setRetrivedDrugData,
  } = useContext(DischargeSummaryContext);

  const [serviceheaders, setServiceHeaders] = React.useState([
    { "Drug Name": "", Dose: "", Frequency: "", Route: "" },
  ]);

  //

  const [drugName, setDrugName] = useState();
  const [drugDose, setDrugDose] = useState();
  const [drugInstruction, setDrugInstrucForm] = useState();
  const [drugRoute, setDrugRoute] = useState();
  const [editIndex, setEditIndex] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);

  const [drugSearchString, setDrugSearchString] = useState();

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      // drugType: "",
      drugName: "",
      dosage: "",
      duration: "",
      genericName: "",
      quantity: "",
      refill: "",
      form: "",
      instructions: "",
      route: "",
      remark: "",
      startDate: "",
      isCurrentMedicine: false,
    },
  });
  const {
    formState: { errors },
    control,
    register,
  } = methods;

  const onsubmit = (data) => {
    let itemObj = {
      "Drug Name": data.drugName.label,
      Dose: data.duration,
      Frequency: data.dosage.label,
      Route: data.route.label,
    };

    let arr = [...retrivedDrugData];

    if (editIndex !== null) {
      let medicationId = arr[editIndex].medicationId;
      itemObj.medicationId = medicationId;

      arr[editIndex] = itemObj;
      setEditIndex(null);
      setIsUpdate(false);
    } else {
      // Add new item
      arr.push(itemObj);
    }

    setRetrivedDrugData(arr);
    methods.reset();
  };

  useEffect(() => {
    getDrugDose();
    getDrugName();
    getDrugInstruction();
    getDrugRoute();
  }, [drugSearchString]);

  //   DrugName
  const getDrugName = () => {
    fetchDrugName(drugSearchString)
      .then((response) => response.data)
      .then((res) => {
        setDrugName(res.result);
      });
  };
  // drugDose
  const getDrugDose = () => {
    fetchDrugDose()
      .then((response) => {
        //
        setDrugDose(response.data.result);
      })
      .catch((response) => {
        //
      });
  };

  //   drugInstruction
  const getDrugInstruction = () => {
    fetchDrugInstructions()
      .then((response) => response.data)
      .then((res) => {
        //
        setDrugInstrucForm(res.result);
      });
  };

  //   drugRoute
  const getDrugRoute = () => {
    fetchDrugRoute()
      .then((response) => response.data)
      .then((res) => {
        //
        setDrugRoute(res.result);
      });
  };

  ////focus input on enter
  const handleInputFocus = (event, index) => {
    let nextIndex;
    if (index === 4) {
      nextIndex = 0;
    } else {
      nextIndex = index + 1;
    }

    inputRefs.current[nextIndex].focus();
  };
  /////
  const handleRetriveDrug = () => {
    if (admissionId !== undefined) {
      fetchRetriveDrug(admissionId)
        .then((response) => response.data)
        .then((res) => {
          let updated = res.result.map((item) => ({
            "Drug Name": item?.drugname,
            Dose: item?.dose,
            Frequency: item?.frequency,
            Route: item?.route,
          }));
          setRetrivedDrugData(updated);
        });
    }
  };

  const handleSelectedMedication = () => {
    setIsUpdate(false);

    const patchedMedication = !!allMedicationData
      ? allMedicationData?.filter((item) => {
          return item.medicationId && item.medicationId > 0;
        })
      : [];

    let selectedRetrivedDrugData = retrivedDrugData.filter(
      (item) => item.isChecked
    );

    let missingDose = selectedRetrivedDrugData?.some(
      (item) => item?.Dose === ""
    );


    if (!missingDose) {

      const updateSelectedRetrivedDrugData = selectedRetrivedDrugData.map(
        ({ "Drug Name": drugName, Frequency, Route, Dose }) => ({
          itemName: drugName,
          dose: Dose,
          route: Route,
          frequency: Frequency,
          instructions: "",
          medicationId: 0,
          isDelete: false,
        })
      );

      setAllMedicationData([
        ...patchedMedication,
        ...updateSelectedRetrivedDrugData,
      ]);



      updateSelectedRetrivedDrugData?.length > 0 &&
        successAlert("Medicines added as discharge medicines.");
    } else {
      warningAlert("Kindly add correct dose for all selected drugs");
    }

    // setRetrivedDrugData([]);
  };

  const renderInput = (row) => {
    return (
      <>
        {row?.Dose === "" ? (
          <div className="border border-dashed border-customRed "> </div>
        ) : (
          <div>{row?.Dose}</div>
        )}
      </>
    );
  };

  const renderActions = (row, index) => {
    const handleEditRow = (row, rowIndex) => {
      setIsUpdate(true);
      methods.setValue("drugName", {
        value: row?.["Drug Name"],
        label: row?.["Drug Name"],
      });
      methods.setValue("dosage", {
        value: row.Frequency,
        label: row.Frequency,
      });
      methods.setValue("duration", row.Dose);
      methods.setValue("route", { value: row.Route, label: row.Route });

      setEditIndex(rowIndex);
    };

    const handleDeleteRow = (row, index) => {
      let temp = [...retrivedDrugData];
      let updated = temp.filter((item, id) => index !== id);
      setRetrivedDrugData(updated);
    };

    return (
      <>
        <div>
          {setRetrivedDrugData.length > 0 ? (
            <>
              <EditOnIcon
                title={"Edit"}
                onClick={() => handleEditRow(row, index)}
              />
              <DeleteOnIcon
                title={"Delete"}
                onClick={() => handleDeleteRow(row, index)}
              />
            </>
          ) : null}
        </div>
      </>
    );
  };


  return (
    <div>
      <form>
        <div>
          <div className="flex justify-between">
            <div className="text-black font-bold text-lg pb-3">
              Prescription
            </div>
            <div className="flex gap-1">
              {!isUpdate && (
                <Tooltip title="Add">
                  <AddCircleIcon
                    sx={{ color: "#1e3a8a" }}
                    onClick={() => {
                      setIsUpdate(true);
                    }}
                  />
                </Tooltip>
              )}
              {isUpdate && (
                <Tooltip title="Hide Form">
                  <CancelOutlinedIcon
                    sx={{ color: "#B91C1C" }}
                    onClick={() => {
                      setIsUpdate(false);
                    }}
                  />{" "}
                </Tooltip>
              )}
            </div>
          </div>
          {isUpdate && (
            <div className="grid md:grid-cols-6 lg:grid-cols-3 gap-3">
              {/* drugName */}
              <div className="md:col-span-3 lg:col-span-1">
                <CreateableSelect
                  name="drugName"
                  placeholder="Search Item"
                  dataArray={drugName}
                  onInputChange={(e) => {
                    setDrugSearchString(e);
                  }}
                  menuPlacement={"bottom"}
                  searchIcon={true}
                  error={errors.drugName}
                  control={control}
                  isClearable={true}
                  //
                  referance={(inputRef) => {
                    if (inputRef) {
                      inputRefs.current.push(inputRef);
                    }
                  }}
                  inputRef={{
                    ...register("drugName", {
                      onChange: (e) => {
                        if (e.target.value !== null) {
                          handleInputFocus(e, 0);
                        }
                      },
                    }),
                  }}
                />
              </div>

              {/* dosage */}
              <div className="md:col-span-3 lg:col-span-1">
                <DropdownField
                  control={control}
                  dataArray={drugDose}
                  name="dosage"
                  menuPlacement={"bottom"}
                  placeholder="Frequency *"
                  error={errors.dosage}
                  referance={(inputRef) => {
                    if (inputRef) {
                      inputRefs.current.push(inputRef);
                    }
                  }}
                  inputRef={{
                    ...register("dosage", {
                      onChange: (e) => {
                        handleInputFocus(e, 1);
                      },
                    }),
                  }}
                />
              </div>

              {/* duration/frequncy */}
              <div className="md:col-span-3 lg:col-span-1">
                <InputField
                  name="duration"
                  variant="outlined"
                  label="Dosage * "
                  error={errors.duration}
                  control={control}
                  // disabled={isDisabled}
                  inputProps={{ maxLength: 100 }}
                  //

                  inputRef={(inputRef) => {
                    if (inputRef) {
                      inputRefs.current.push(inputRef);
                    }
                  }}
                  onKeyDown={(e) => e.key === "Enter" && handleInputFocus(e, 2)}
                />
              </div>

              {/* route */}
              <div className="md:col-span-3 lg:col-span-1">
                <DropdownField
                  control={control}
                  error={errors.route}
                  name="route"
                  placeholder="Route*"
                  dataArray={drugRoute}
                  isSearchable={false}
                  menuPlacement={"bottom"}
                  //
                  referance={(inputRef) => {
                    if (inputRef) {
                      inputRefs.current.push(inputRef);
                    }
                  }}
                  inputRef={{
                    ...register("route", {
                      onChange: (e) => {
                        if (e.target.value !== null) {
                          handleInputFocus(e, 3);
                        }
                      },
                    }),
                  }}
                />
              </div>

              <div className="flex justify-end lg:col-span-2 md:col-span-1">
                <div className="flex gap-3">
                  <CommonButton
                    label={"Reset"}
                    className="text-customRed border border-customRed"
                    onClick={() => methods.reset()}
                  />
                  <CommonButton
                    label={editIndex !== null ? "Update" : "Add"}
                    className="bg-customGreen text-white"
                    referance={(inputRef) => {
                      if (inputRef) {
                        inputRefs.current.push(inputRef);
                      }
                    }}
                    onClick={methods.handleSubmit(onsubmit)}
                    onKeyDown={(e) => {
                      handleInputFocus(e, 4);

                      methods.handleSubmit(onsubmit);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          <div>
            <CommonDynamicTableNew
              dataResult={
                retrivedDrugData?.length > 0 ? retrivedDrugData : serviceheaders
              }
              setDataResult={setRetrivedDrugData}
              enableSelectAll={retrivedDrugData?.length > 0 ? true : false}
              enableSwapRow={retrivedDrugData?.length > 0 ? true : false}
              highlightRow={false}
              tableClass={" h-80"}
              renderActions={
                retrivedDrugData?.length > 0 ? renderActions : false
              }
              removeHeaders={["isChecked", "medicationId"]}
              editableColumns={["Dose"]}
              renderInput={renderInput}
            />
          </div>
          <div className=" flex items-center gap-3 mt-3">
            <div>
              <CommonButton
                label="Retrieve Drugs"
                onClick={handleRetriveDrug}
                className="bg-customBlue text-white"
              />
            </div>
            <div>
              <CommonButton
                label="Add As Discharge Medication"
                className="bg-customGreen text-white"
                onClick={() => {
                  handleSelectedMedication();
                }}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

