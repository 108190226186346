//import from the react library.
import * as React from "react";

//importing useReducer hook from the react library.
import { useReducer } from "react";

//imports from the material ui (mui) library.
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

//useKeyPress hook to detect the happening of the below key press events.
//arrowUpPressed , arrowDownPressed , arrowRightPressed,
//arrowLeftPressed , enterPressed

// const initialState = { selectedIndex: 0 };

// const reducer = (state, action) => {
//   let list
//   switch (action.type) {
//     case "arrowUp":
//       return {
//         selectedIndex:
//           state.selectedIndex !== 0 ? state.selectedIndex - 1 : list.length - 1,
//       };
//     case "arrowDown":
//       return {
//         selectedIndex:
//           state.selectedIndex !== list.length - 1 ? state.selectedIndex + 1 : 0,
//       };
//     case "select":
//       return { selectedIndex: action.payload };
//     default:
//       throw new Error();
//   }
// };

export default function DrugList(props) {
  const useKeyPress = (targetKey) => {
    const [keyPressed, setKeyPressed] = React.useState(false);

    React.useEffect(() => {
      const downHandler = ({ key }) => {
        if (key === targetKey) {
          setKeyPressed(true);
        }
      };
      const upHandler = ({ key }) => {
        if (key === targetKey) {
          setKeyPressed(false);
        }
      };

      window.addEventListener("keydown", downHandler);
      window.addEventListener("keyup", upHandler);

      return () => {
        window.removeEventListener("keydown", downHandler);
        window.removeEventListener("keyup", upHandler);
      };
    }, [targetKey]);

    return keyPressed;
  };
  // const [state, dispatch] = useReducer(reducer, initialState);

  //state variable to store the rowIndex ; that is to be highlighted.
  const [rowIndex, setRowIndex] = React.useState(null);

  //state variable to store the row object upon the press of arrow keys. state variable to store the row object upon clicking.
  const [selectedObj, setSelectedObj] = React.useState(null);

  //taking the props coming from the Drug Search component into the DrugList component by appropriate names.
  const {
    dataResult,
    selected,
    setSelected,
    arrowKeyName,
    setArrowKeyName,
  } = props;

  //event listener function to detect the click of the TableRow.
  const handleClickRow = (index, row) => {
    //DrugSearch components's state variable to store the genericId of the drug into the state variable called genericId.
    props.setGenericId(row.ItemGenericId);

    //storing the index of the row that was clicked ; into the state variable called rowIndex.
    setRowIndex(index);
  };

  //event listener function to detect the click of the TableRow.
  const handleClick = (row, id, index) => {
    setArrowKeyName("Left Arrow Key");
    setSelectedObj(row);
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    newSelected = newSelected.concat(id);
    if (selectedIndex === 0) {
      newSelected = [];
      setSelected(null);
    }
    setSelected(newSelected);
  };

  //the function to remove the headers that are not required.
  const removeHeaders = (headers, fieldToRemove) => {
    return headers.filter((v) => {
      return !fieldToRemove.includes(v);
    });
  };

  //set rows object to table
  const allHeaders = Object.keys(props.data.result[0]);

  const headers = removeHeaders(allHeaders, [
    "Value",
    "genericId",
    "Id",
    "BatchesRequired",
    "ItemCategory",
    "",
    "VATInClusive",
    "ConcessionApp",
    "FreeApplicable",
    "Onco",
    "IsConsignment",
    "OncoDist",
    "ItemType",
    "PurchaseRate",
    "ItemGenericId",
    "rackid",
    "shelfid",
    "VATPercentage",
  ]);

  const isSelected = (name) => selected.indexOf(name) !== -1;

  //key press up down
  const arrowUpPressed = useKeyPress("ArrowUp");
  const arrowDownPressed = useKeyPress("ArrowDown");
  const arrowRightPressed = useKeyPress("ArrowRight");
  const arrowLeftPressed = useKeyPress("ArrowLeft");
  const enterPressed = useKeyPress("Enter");

  React.useEffect(() => {
    if (arrowRightPressed === true) {
      setArrowKeyName("Right Arrow Key");
      setRowIndex(-1);
      setSelectedObj(null);
    }
  }, [arrowRightPressed]);

  React.useEffect(() => {
    if (enterPressed === true) {
      setArrowKeyName("Enter Key Pressed");
      if (selectedObj !== null) {
        props.setSelectedRow(selectedObj);
      }
    }
  }, [enterPressed, selectedObj]);

  React.useEffect(() => {
    if (arrowUpPressed) {
      let rIndex = rowIndex;

      //decrement index by 1
      if (arrowKeyName === "Left Arrow Key" || arrowKeyName === "") {
        if (rowIndex !== 0) {
          rIndex = rIndex - 1;
          setSelectedObj(dataResult[rIndex]);
          let requiredGenericId = dataResult[rIndex].ItemGenericId;
          props.setGenericId(requiredGenericId);
          setRowIndex(rIndex);
        }
      }
    }
  }, [arrowUpPressed]);

  React.useEffect(() => {
    if (arrowDownPressed) {
      let rIndex = rowIndex;
      //increment index by 1 when the value of rowIndex is not equal to the value of last index of dataResult array
      if (arrowKeyName === "Left Arrow Key" || arrowKeyName === "") {
        if (rowIndex !== dataResult.length - 1) {
          rIndex = rIndex + 1;
          setSelectedObj(dataResult[rIndex]);
          let requiredGenericId = dataResult[rIndex].ItemGenericId;
          props.setGenericId(requiredGenericId);
          setRowIndex(rIndex);
        }
      }
    }
  }, [arrowDownPressed]);

  React.useEffect(() => {
    if (arrowKeyName === "Right Arrow Key") {
      setRowIndex(-1);
      setSelectedObj(null);
    } else if (arrowKeyName === "Left Arrow Key" && selectedObj === null) {
      setRowIndex(0);
      setSelectedObj(dataResult[0]);
    }
  }, [arrowKeyName]);

  React.useEffect(() => {
    props.setGenericId(dataResult[0].ItemGenericId);
    setRowIndex(0);
    setSelectedObj(dataResult[0]);
    setArrowKeyName("Left Arrow Key");
  }, []);

  //table start

  const tableRef = React.useRef(null);

  React.useEffect(() => {
    const handleKeyDown = (event) => {
      const table = tableRef.current;
      if (table) {
        const currentScrollTop = table.scrollTop;
        if (event.key === "ArrowUp") {
          table.scrollTo({ top: currentScrollTop - 30, behavior: "smooth" });
        } else if (event.key === "ArrowDown") {
          table.scrollTo({ top: currentScrollTop + 30, behavior: "smooth" });
        }
      }
    };
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className="grid ">
      <Box sx={{ width: "700px" }}>
        <Paper>
          <TableContainer
            ref={tableRef}
            sx={{
              "&::-webkit-scrollbar": {
                width: 7,
                height: 5,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#7393B3",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "lightBlue",
              },
            }}
            className="rounded h-60 2xl:h-80 overflow-scroll"
          >
            <Table
              component={Paper}
              Table
              size="small"
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead>
                <TableRow
                  sx={{
                    "& th": {
                      paddingY: 0.5,
                      backgroundColor: "#F1F1F1",
                    },
                  }}
                >
                  {headers.map((header, index) => (
                    <TableCell className="whitespace-nowrap">
                      <span className="text-gray-600 font-bold">{header}</span>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataResult.map((row, index, i) => {
                  {
                    const isItemSelected = isSelected(row.Id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        onClick={(event) => {
                          handleClick(event, row, row.Id);
                          handleClickRow(index, row);
                          // dispatch({ type: "select", payload: i });
                        }}
                        onKeyPress={(event) => {
                          event.preventDefault();
                          if (event.key === "Enter") {
                            setSelectedObj(row);
                          }
                        }}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        // aria-pressed={i === state.selectedIndex}
                        style={{
                          backgroundColor: rowIndex === index ? "#FFC44B" : "",
                        }}
                      >
                        {headers &&
                          headers.map((header, i) => (
                            <TableCell className="whitespace-nowrap">
                              {row[header]}
                            </TableCell>
                          ))}
                      </TableRow>
                    );
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {/* //table end */}
        </Paper>
      </Box>
    </div>
  );
}
