// import React from 'react'

import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { postMyVacantBeds } from "../../../commonServices/bedServices/BedServices";
import {
  fetchAdmissionType,
  fetchBedCategory,
  fetchWard,
} from "../../../commonServices/miscellaneousServices/MiscServices";
import { saveAllocateSecondaryBed } from "../../services/bedAllowcationServices/BedAllowcationServices";
import ConfirmationModal from "../../../common/components/ConfirmationModal";

export const Schema = yup.object().shape({
  BedCategory: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Please Select Category"),
      label: yup.string().required("Please Select Category"),
    })
    .required("Required"),

  ChargesPerBedCategory: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Please Select Category"),
      label: yup.string().required("Please Select Category"),
    })
    .required("Required"),
});

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { sm: "70%", lg: "60%" },
  // minWidth:"25%",
  // height: "30%",
  bgcolor: "background.paper",
  borderRadius: 2,
  border: "none",
  "&:focus": {
    outline: "none",
  },
};

//

export default function SecBedAddModal(props) {
  const {
    open,
    handleClose,
    menuId,functionalityId,
    privilege,
    getAllocatedSecondaryBed,
    AdmissionId,
    bedDetails,
    getBedListData,
    isEditBed,
    selectedRow,
  } = props;

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [selectedBedDetails, setSelectedBedDetails] = useState(null);
  const [admissionType, setAdmissionType] = useState();
  const [bedCategory, setBedCategory] = useState();
  const [selectedBedCategory, setSelectedBedCategory] = useState(0);
  // const [chargesPerBedCategory, setChargesPerBedCategory] = useState();
  const [ward, setWard] = useState();
  const [selectedWard, setSelectedWard] = useState(0);

  const [vacantBedDetails, setVacantBedDetails] = useState([]);
  //

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const handleConfirmationClose = () => setOpenConfirmationModal(false);
  //
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(Schema),
    defaultValues: {
      BedCategory: null,
      ChargesPerBedCategory: null,
      ChargesPerRemark: "",
    },
  });
  const {
    control,
    setValue,
    register,
    formState: { errors },
  } = methods;

  let token = JSON.parse(localStorage.getItem("userInfo"));
  let unitID = token.unitId;

  const BedObj = {
    categoryId: selectedBedCategory,
    unitId: unitID,
    wardId: selectedWard,
  };

  useEffect(() => {
    getVacantBeds();
  }, [selectedBedCategory, unitID, selectedWard]);

  useEffect(() => {
    getAdmissionType();
    getBedCategory();
    //  getChargesPerBedCategory();
    getWard();
  }, []);

  const getAdmissionType = () => {
    fetchAdmissionType()
      .then((response) => response.data)
      .then((res) => {
        setAdmissionType(res.result);
      });
  };
  const getBedCategory = () => {
    fetchBedCategory()
      .then((response) => response.data)
      .then((res) => {
        setBedCategory(res.result);
      });
  };

  //   const getChargesPerBedCategory = () => {
  //     fetchChargesPerBedCategory()
  //       .then((response) => response.data)
  //       .then((res) => {
  //         setChargesPerBedCategory(res.result);
  //       });
  //   };

  const getWard = () => {
    fetchWard()
      .then((response) => response.data)
      .then((res) => {
        setWard(res.result);
      });
  };
  const getVacantBeds = () => {
    postMyVacantBeds(BedObj)
      .then((response) => response.data)
      .then((res) => {
        setVacantBedDetails(res.result);
      })
      .catch(() => {});
  };

  const onSubmit = (data) => {
    selectedBedDetails !== null
      ? setOpenConfirmationModal(true)
      : warningAlert("Please Select Bed...");
  };
  const handleFormSubmit = () => {
    setOpenConfirmationModal(false);
    setOpenBackdrop(true);

    const allocatedBedObj = {
      admissionId: AdmissionId || 0,
      bedId: selectedBedDetails?.BedId || selectedRow?.BedId,
      menuId: menuId,
      privilege: privilege,
      bedAllocationId: selectedRow?.bedAllocationId || 0,
      functionalityId:functionalityId
    };

    saveAllocateSecondaryBed(allocatedBedObj)
      .then((response) => response.data)
      .then((res) => {
        successAlert(res.message);
        setOpenBackdrop(false);
        handleClose();
        getAllocatedSecondaryBed();
        getBedListData();
        setSelectedBedDetails(null);
      })
      .catch((res) => {
        errorAlert(res?.response?.data?.message || res.message);
        setOpenBackdrop(false);
        handleClose();
      });
  };

  useEffect(() => {
    selectedRow?.bedAllocationId && handleSelectedRow(selectedRow);
  }, []);

  const handleSelectedRow = (row) => {
    setSelectedBedDetails(row);

    setValue(
      "BedCategory",
      {
        id: row?.CategoryId,
        label: row?.Category || (row && row["Bed Category"]),
        value: row?.Category || (row && row["Bed Category"]),
      },
      { shouldValidate: true }
    );
    setValue(
      "ChargesPerBedCategory",
      {
        id: row?.CategoryId,
        label: row?.Category,
        value: row?.Category,
      },
      { shouldValidate: true }
    );
    setValue(
      "ward",
      {
        id: row?.WadId,
        label: row?.Ward,
        value: row?.Ward,
      },
      { shouldValidate: true }
    );
  };
  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle} className="max-h-[88%] xl:max-h-[100%]">
          <CancelPresentationIconButton
            onClick={() => {
              handleClose();
              //   setEdit(false);
              //   reset(defaultValues);
            }}
          />
          <form>
            <div className="mx-3 mb-3 mt-2 items-center align-middle">
              <div className=" p-2 rounded  text-black font-semibold whitespace-nowrap">
                Add Secondary Bed
              </div>

              <hr className="border mx-1  border-blue-900" />

              <div className="grid border border-gray-300 bg-gray-100 px-3 rounded mt-2">
                <div className="grid grid-cols-2  gap-2 py-2">
                  <div className="grid gap-2 border-r-2 border-slate-500 my-1 ">
                    {/* Patient Name , UHID */}
                    <div className="flex gap-2 ">
                      <h1 className="text-black font-semibold flex space-x-1">
                        <span className="text-sm">Patient Name </span>
                        <span className=""> :</span>
                      </h1>

                      <h1 className="font-normal">
                        {bedDetails?.ptInfo &&
                          bedDetails?.ptInfo["Patient Name"]}
                      </h1>
                    </div>
                    {/* Gender */}
                    <div className="flex gap-2 ">
                      <h1 className="text-black font-semibold flex space-x-5 lg:space-x-4">
                        <span className="text-sm">Gender</span>
                        <span className="lg:pl-7 md:pl-6">:</span>
                      </h1>

                      <h1 className="font-normal">
                        {bedDetails?.ptInfo && bedDetails?.ptInfo["Gender"]}
                      </h1>
                    </div>
                  </div>

                  {/* MRNo */}
                  <div className="grid gap-2  pl-4 border-slate-500 my-1 ">
                    <div className="flex gap-2 ">
                      <h1 className="text-black font-semibold flex space-x-16 lg:space-x-16">
                        <span className="text-sm">MRNo</span>
                        <span className="">:</span>
                      </h1>
                      <h1 className="font-normal">
                        {bedDetails?.ptInfo && bedDetails?.ptInfo["MRNo"]}
                      </h1>
                    </div>
                    {/* age */}
                    <div className="flex gap-2 ">
                      <h1 className="text-black font-semibold flex space-x-16 lg:space-x-16">
                        <span className="text-sm">Age</span>
                        <span className="md:pl-4">:</span>
                      </h1>
                      <h1 className="font-normal">
                        {bedDetails?.ptInfo && bedDetails?.ptInfo["Age"]}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-3">
                <div className="lg:grid lg:grid-cols-1 md:grid-cols-1 lg:gap-3 md:gap-3 mt-2 lg:col-span-3 md:col-span-5">
                  {/* //Admission Type*/}
                  <div className="md:mb-3 lg:mb-0">
                    <DropdownField
                      control={control}
                      error={errors.admissionType}
                      name="admissionType"
                      label="admissionType"
                      dataArray={admissionType}
                      isSearchable={false}
                      isClearable={false}
                      placeholder="Admission Type *"
                    />
                  </div>
                  {/* //Bed Category*/}
                  <div className="md:mb-3 lg:mb-0">
                    <DropdownField
                      control={control}
                      error={errors.BedCategory}
                      name="BedCategory"
                      label="Bed Category"
                      dataArray={bedCategory}
                      isSearchable={false}
                      // isClearable={true}
                      isDisabled={props.isForEdit}
                      placeholder="Bed Category *"
                      inputRef={{
                        ...register("BedCategory", {
                          onChange: (e) => {
                            setSelectedBedCategory(e.target.value.id);
                          },
                        }),
                      }}
                    />
                  </div>
                  {/* //Ward */}
                  <div className="md:mb-3 lg:mb-0">
                    <DropdownField
                      control={control}
                      error={errors.ward}
                      name="ward"
                      label="Ward"
                      dataArray={ward}
                      isSearchable={false}
                      isDisabled={props.isForEdit}
                      // isClearable={true}
                      placeholder="Ward *"
                      inputRef={{
                        ...register("ward", {
                          onChange: (e) => {
                            setSelectedWard(e.target.value.id);
                          },
                        }),
                      }}
                    />
                  </div>

                  {/* //BedNumber */}
                  <div className="md:mb-3 lg:mb-0 flex gap-3">
                    <p className="font-bold"> Bed Number </p>
                    {selectedBedDetails !== null ? (
                      <p>
                        :{" "}
                        {selectedBedDetails?.BedNo ||
                          selectedBedDetails?.Bed ||
                          (selectedRow && selectedRow["Bed No"])}
                      </p>
                    ) : (
                      <p>Choose Bed</p>
                    )}
                  </div>
                </div>

                <div className="lg:col-span-9 md:col-span-7 ">
                  {vacantBedDetails.length > 0 &&
                  selectedBedCategory !== null &&
                  !props.isForEdit ? (
                    <CommonDynamicTableNew
                      dataResult={vacantBedDetails}
                      handleSelectedRow={handleSelectedRow}
                      tableClass="h-52"
                      removeHeaders={[
                        "CategoryId",
                        "RoomId",
                        "Sex",
                        "WadId",
                        "BedId",
                        "Code",
                      ]}
                    />
                  ) : (
                    <p className="text-center items-center">
                      Please Select Bed Category
                    </p>
                  )}
                </div>
              </div>
              <div className="flex justify-end my-2 gap-3">
                <CommonButton
                  label="Close"
                  onClick={() => {
                    handleClose();
                  }}
                  className="border border-customRed text-customRed "
                />
                <CommonButton
                  label="save"
                  onClick={() => {
                    onSubmit();
                  }}
                  className="bg-customGreen text-white"
                />
              </div>
            </div>
          </form>
          {openBackdrop && <CommonBackDrop openBackdrop={openBackdrop} />}

        </Box>
      </Modal>
      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={handleConfirmationClose}
        confirmationSubmitFunc={() => handleFormSubmit()}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </div>
  );
}
