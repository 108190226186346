import { Slider } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { DeleteOnIcon } from "../../../../assets/icons/CustomIcons";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTableNew from "../../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";
import {
  deleteAlert,
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import PrintPainAssesUseRowClick from "../../../prints/PrintPainAssesUseRowClick";
import {
  addNewPatientPainScore,
  deletePainAssessment,
  getAllPainAssessment,
} from "../../../services/cliinicalChartServices/painAssesmentService/PainAssesmentService";
import PainAssessmentScale from "./assets/PainAssessmentScale.svg";
import DialyWeight from "./DailyWeight";
import InitialNursingModal from "./InitialNursingModal";
import MedicationErrorRepModal from "./medicationError/MedicationErrorRepModal";
import PhlebitiesScoreModal from "./PhlebitiesScoreModal";

function valueText(value) {
  return `${value}°C`;
}
const CustomSlider = styled(Slider)({
  color: "#52af77",
  height: 8,
  padding: 7,

  "& .MuiSlider-mark": {
    border: "none",
    color: "gray",
    height: 7,
    // marginLeft: "2px",
  },
  "& .MuiSlider-rail": {
    border: "none",
    backgroundImage:
      "linear-gradient(.25turn,  lightblue, green, greenyellow, yellow, orange, red)",
  },
  "& .MuiSlider-track": {
    border: "none",
    backgroundImage:
      "linear-gradient(.25turn,  lightblue, green, greenyellow, yellow, orange, red)",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid blue",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "black",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});
const sliderMarks = [
  {
    value: 0,
    scaledValue: 0,
  },
  {
    value: 1,
    scaledValue: 1,
  },
  {
    value: 2,
    scaledValue: 2,
  },
  {
    value: 3,
    scaledValue: 3,
  },
  {
    value: 4,
    scaledValue: 4,
  },
  {
    value: 5,
    scaledValue: 5,
  },
  {
    value: 6,
    scaledValue: 6,
  },
  {
    value: 7,
    scaledValue: 7,
  },
  {
    value: 8,
    scaledValue: 8,
  },
  {
    value: 9,
    scaledValue: 9,
  },
  {
    value: 10,
    scaledValue: 10,
  },
];

export default function PainAssessment(props) {
  const { dataResultAssessment, setDataResultAssessment } = props;

  const defaultValues = {
    painScore: "",
  };

  const methods = useForm({
    mode: "onChange",
    defaultValues,
  });
  const { control, handleSubmit, register } = methods;

  const [finalData, setFinalData] = React.useState();
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  // const [dataResult, setDataResult] = useState([]);
  const [spinner, showSpinner] = useState(false);
  const [openMedicationErrorModal, setOpenMedicationErrorModal] =
    React.useState(false);
  const handleOpenMedicationErrorModal = () =>
    setOpenMedicationErrorModal(true);
  const handleCloseMedicationErrorModal = () =>
    setOpenMedicationErrorModal(false);
  const [openPhlebitiesModal, setOpenPhlebitiesModal] = React.useState(false);
  const handleOpenPhlebitiesModal = () => setOpenPhlebitiesModal(true);
  const handleClosePhlebitiesModal = () => setOpenPhlebitiesModal(false);
  const [openInitialNursingModal, setOpenInitialNursingModal] =
    React.useState(false);
  const handleOpenInitialNursing = () => setOpenInitialNursingModal(true);
  const handleCloseInitialNursing = () => setOpenInitialNursingModal(false);
  let selectedPainScore = 0;
  const [openPost, setOpenPost] = React.useState(false);
  const handleClosePost = () => {
    if (openPost) {
      setOpenPost(false);
    }
  };
  const [privilege, setPrivilege] = useState(null);
  const [deleteObj, setDeleteObj] = React.useState(null);
  const [deleteconfirm, setDeleteconfirm] = useState(false);
  const handleOpenDeleteConfirm = () => setDeleteconfirm(true);
  const handleCloseDeleteConfirm = () => setDeleteconfirm(false);

  let userInfoString = localStorage.getItem("userInfo");
  let userInfoObj = JSON.parse(userInfoString);
  let addedBy = userInfoObj?.userId;

  //Get Pain Assessment Table List
  console.log("props.displayInfo", props.displayInfo);

  useEffect(() => {
    if (props.displayInfo?.AdmissionId || props?.admissionId) {
      populateTable();
    }
  }, [props.displayInfo?.AdmissionId || props?.admissionId]);

  const populateTable = () => {
    if (props.displayInfo?.AdmissionId !== undefined || props?.admissionId) {
      showSpinner(true);
      getAllPainAssessment(props.displayInfo?.AdmissionId || props?.admissionId)
        .then((response) => {
          return response.data;
        })
        .then((res) => {
          showSpinner(false);
          // setDataResult(res.result);
          setDataResultAssessment(res.result);
        })
        .catch((error) => {});
    }
  };

  function postPainAssessment(obj) {
    addNewPatientPainScore(obj)
      .then((response) => {
        if (
          (response.data.statusCode === 200 &&
            props.displayInfo?.AdmissionId) ||
          props.admissionId
        ) {
          successAlert(response.data.message);
          setOpenBackdrop(false);
          props.setSliderReset(null);
          populateTable(props.displayInfo?.AdmissionId);
        } else if (!props.displayInfo?.AdmissionId || props.admissionId) {
          warningAlert("Please Select Patient...!");
          props.setSliderReset(null);
        }
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
        setOpenBackdrop(false);
        handleClosePost();
      });
  }

  let getpatientAdmId = props.displayInfo?.AdmissionId;
  let getipdAdmissionId = props.admissionId;
  let admissionId;
  if (getpatientAdmId) {
    admissionId = getpatientAdmId;
  } else if (getipdAdmissionId) {
    admissionId = getipdAdmissionId;
  }

  const onSubmitDataHandler = (data) => {
    if (getpatientAdmId !== undefined || getipdAdmissionId !== null) {
      setFinalData(data);
      setOpenPost(true);
    }
  };

  function addRecord(data) {
    setOpenPost(false);
    setOpenBackdrop(true);
    let painScorePostObj = {
      addedBy: addedBy,
      admissionId: admissionId,
      painAssessmentScore: 0,
      menuId: props.menuId,
      privilege: privilege,
    };

    painScorePostObj.painAssessmentScore = props.sliderReset || 0;

    postPainAssessment(painScorePostObj);
  }

  //Patch Painst Score
  useEffect(() => {
    if (props?.selectedAssesmentListRow !== null) {
      props.setSliderReset(props.selectedAssesmentListRow["Pain Assessment"]);
    }
  }, [props.selectedAssesmentListRow]);

  const handleSelectedRow = (row, index) => {
    props.setSelectedAssesmentListRow(row);
  };

  function deleteRecord() {
    if (deleteObj) {
      setOpenBackdrop(true);

      deletePainAssessment(deleteObj.Id, props.menuId, deleteObj.action)
        .then((response) => {
          if (response.data.statusCode === 200) {
            deleteAlert(response.data.message);
            setOpenBackdrop(false);
            handleCloseDeleteConfirm();
            populateTable();
          }
        })
        .catch((error) => {
          errorAlert(error.message);
          handleCloseDeleteConfirm();
        });
    }
  }

  //Print
  const [openPrintModal, setOpenPrintModal] = React.useState(false);
  const [painAssesPrintId, setPainAssesPrintId] = React.useState(null);
  const renderPrint = (component) => {
    setTimeout(() => {
      setOpenPrintModal(false);
    }, 1000);

    return (
      <div className="hidden">
        <PrintPainAssesUseRowClick
          admissionId={
            props?.admissionId || props?.patientInformation?.AdmissionId
          }
          painAssesPrintId={painAssesPrintId}
        />
      </div>
    );
  };

  const renderActions = (row, index) => {
    return (
      <div className="flex gap-3">
        {props.userActions.map((obj) => (
          <>
            {obj.isAction === true &&
            obj.action === "Delete" &&
            !props.selectedPatient?.AllowToGO ? (
              <>
                <DeleteOnIcon
                  title="Delete"
                  onClick={() => {
                    let deleteObjVal = {};

                    deleteObjVal.Id = row.id;

                    deleteObjVal.action = obj.action;

                    setDeleteObj(deleteObjVal);

                    //open the delete confirmation modal
                    handleOpenDeleteConfirm();
                  }}
                />
              </>
            ) : null}

            {/*  {!obj.isAction === true && obj.action === "Print" ? (
              <Tooltip title="Print">
                <button
                  type="button"
                  onClick={() => {
                    
                    setOpenPrintModal(true);
                    setPainAssesPrintId(row.id);
                  }}
                >
                  <PrintIcon />
                </button>
              </Tooltip>
                ) : null} */}
          </>
        ))}
      </div>
    );
  };

  console.log(
    " props",
    props.patientInformation?.BedReleased,
    props.selectedPatient?.BedReleased
  );

  return (
    <div className="w-full">
      <form
        className="grid grid-cols-1 w-full gap-x-2 "
        // onSubmit={handleSubmit(onSubmitDataHandler)}
      >
        <div className="grid grid-cols-3 gap-4 w-full ">
          <div className="col-span-3 lg:col-span-2 w-full ">
            <h1 className="my-1 text-sm font-semibold pt-2 whitespace-nowrap">
              Assessment List
            </h1>

            {spinner ? (
              <div className="grid justify-center">
                <LoadingSpinner />
              </div>
            ) : null}

            <div className="pt-2">
              {dataResultAssessment.length > 0 && spinner === false ? (
                <CommonDynamicTableNew
                  dataResult={dataResultAssessment}
                  tableClass={"rounded w-44 h-44"}
                  handleSelectedRow={handleSelectedRow}
                  highlightRow={true}
                  removeHeaders={["id", "admissionid", "Date", "Time"]}
                  renderActions={renderActions}
                />
              ) : (
                <h1 className="flex justify-center my-5 font-bold text-gray-600">
                  No Records Found...
                </h1>
              )}
            </div>
          </div>
          <div className="w-full md:py-2 lg:py-0 hidden lg:block">
            <DialyWeight
              admissionId={
                !!props?.admissionId
                  ? props?.admissionId
                  : props?.displayInfo?.AdmissionId
              }
              displayInfo={props.displayInfo}
              Actions={props.Actions}
              openBackdrop={openBackdrop}
              setOpenBackdrop={setOpenBackdrop}
              userActions={props.userActions}
              menuId={props.menuId}
              privilege={props.privilege}
              setPrivilege={props.setPrivilege}
              patientInformation={props.patientInformation}
              selectedPatient={props.selectedPatient}
            />
          </div>
        </div>
        <div className="mt-2 rounded-md border p-3 w-full bg-white">
          <div className="flex justify-center items-center w-full pb-2">
            <div className="text-sm font-semibold whitespace-nowrap ">
              <h1>Patient Pain Assessment</h1>
            </div>
          </div>
          <div className="flex space-x-2 w-full">
            <div className="flex flex-col mt-6 xl:mt-8 space-y-9 lg:space-y-7 xl:space-y-4  2xl:space-y-8 xl:pt-2 2xl:pt-12 w-[25%] xl:w-[20%] text-sm text-gray-600">
              <h1 className="">Verbal Descriptor Scale</h1>
              <h1 className="">Wong-Baker Facial Grimace Scale</h1>
              <h1 className="">Activity Tolerance Scale</h1>
            </div>
            <div className="w-[97%]">
              <div className="w-full">
                <img className="w-full" src={PainAssessmentScale} />
              </div>
              <div className="flex w-full justify-start mt-2">
                <div className="w-[92%] xl:w-[90%] 2xl:w-[91%] ml-3 lg:ml-5 2xl:ml-9">
                  <CustomSlider
                    className="painScore"
                    control={control}
                    {...register("painScore")}
                    name="painScore"
                    onChange={(value) => {
                      if (
                        props.displayInfo !== null ||
                        props.admissionId !== undefined
                      ) {
                        selectedPainScore = value?.target?.value;
                        props.setSliderReset(value?.target?.value);
                      } else {
                        warningAlert("Please Select Patient...!");
                      }
                    }}
                    aria-label="Custom marks"
                    value={props.sliderReset !== null ? props?.sliderReset : 0}
                    getAriaValueText={valueText}
                    min={0}
                    step={1}
                    max={10}
                    valueLabelDisplay="auto"
                    aria-labelledby="non-linear-slider"
                    style={{ height: "14px" }}
                    marks={sliderMarks}
                  />
                </div>
              </div>
              {props.patientInformation?.BedReleased &&
              props.selectedPatient?.BedReleased ? null : (
                <div className="flex justify-end space-x-4 mt-2 ">
                  {props.userActions.map((obj) => (
                    <>
                      {!obj.isAction && obj.action === "Create" ? (
                        <div
                          onClick={() => {
                            setPrivilege(obj?.action);
                          }}
                        >
                          {props.displayInfo !== null ||
                          props.admissionId !== undefined ? (
                            <CommonButton
                              type="button"
                              className="bg-customGreen text-white"
                              label="Save"
                              onClick={methods.handleSubmit(
                                onSubmitDataHandler
                              )}
                            />
                          ) : null}
                        </div>
                      ) : null}
                    </>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="w-full md:py-2 lg:py-0 lg:hidden">
          <DialyWeight
            admissionId={props.admissionId}
            displayInfo={props.displayInfo}
            openBackdrop={openBackdrop}
            setOpenBackdrop={setOpenBackdrop}
            userActions={props.userActions}
            menuId={props.menuId}
            privilege={props.privilege}
            setPrivilege={props.setPrivilege}
            patientInformation={props.patientInformation}
            selectedPatient={props.selectedPatient}
          />
        </div>

        <div className="flex space-x-2 justify-end mt-2 ">
          <CommonButton
            type="button"
            className="bg-[#C3FDB8] text-black"
            label="Report Medication Error"
            onClick={() => {
              if (!!props.displayInfo || !!props.admissionId) {
                handleOpenMedicationErrorModal();
              } else {
                warningAlert("Please Select Patient...!");
              }
            }}
          />
          <CommonButton
            type="button"
            className="bg-[#CFECEC] text-black"
            label="Phlebitis"
            onClick={() => {
              if (!!props.displayInfo || !!props.admissionId) {
                handleOpenPhlebitiesModal();
              } else {
                warningAlert("Please Select Patient...!");
              }
            }}
          />
          <CommonButton
            type="button"
            className="bg-[#E9CFEC] text-black"
            label="Initial Nursing Assessment"
            onClick={() => {
              if (!!props.displayInfo || !!props.admissionId) {
                handleOpenInitialNursing();
              } else {
                warningAlert("Please Select Patient...!");
              }
            }}
          />
        </div>
      </form>

      <CommonBackDrop openBackdrop={openBackdrop} />

      {openMedicationErrorModal ? (
        <MedicationErrorRepModal
          displayInfo={props.displayInfo}
          admissionId={props.admissionId}
          patientInformation={props.patientInformation}
          open={openMedicationErrorModal}
          setOpen={setOpenMedicationErrorModal}
          handleOpen={handleOpenMedicationErrorModal}
          handleClose={handleCloseMedicationErrorModal}
          selectedPatient={props.selectedPatient}
          userActions={props.userActions}
          menuId={props.menuId}
          privilege={props.privilege}
          setPrivilege={props.setPrivilege}
        />
      ) : null}
      {openPhlebitiesModal ? (
        <PhlebitiesScoreModal
          patientInformation={props.patientInformation}
          open={openPhlebitiesModal}
          setOpen={setOpenPhlebitiesModal}
          handleOpen={handleOpenPhlebitiesModal}
          handleClose={handleClosePhlebitiesModal}
          displayInfo={props.displayInfo}
          admissionId={props.admissionId}
          selectedPatient={props.selectedPatient}
          userActions={props.userActions}
          menuId={props.menuId}
          privilege={props.privilege}
          setPrivilege={props.setPrivilege}
        />
      ) : null}

      {openInitialNursingModal ? (
        <InitialNursingModal
          open={openInitialNursingModal}
          patientInformation={props.patientInformation}
          admissionId={props.admissionId}
          setOpen={setOpenInitialNursingModal}
          handleOpen={handleOpenInitialNursing}
          handleClose={handleCloseInitialNursing}
          selectedPatient={props.selectedPatient}
        />
      ) : null}

      <ConfirmationModal
        confirmationOpen={openPost}
        confirmationHandleClose={handleClosePost}
        confirmationSubmitFunc={addRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to add this record ?"
        confirmationButtonMsg="Add"
      />

      <ConfirmationModal
        confirmationOpen={deleteconfirm}
        confirmationHandleClose={handleCloseDeleteConfirm}
        confirmationSubmitFunc={deleteRecord}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure want to delete this record ?"
        confirmationButtonMsg="Delete"
      />

      {openPrintModal && renderPrint()}
    </div>
  );
}
