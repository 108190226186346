import authHeader from "../../../authentication/authservices/auth-header";
import apiClient from "../../../http-common";

//////all services are imported from inventory_pharmacy in patient services file

// i.e. src/inventory/services/pharmacy/inPatientReturnServices/InPatientReturnServices

export const fetchPrintInPatientReturn = (ipdReturnId, ipdIssueId) => {
  return apiClient.get(
    `/reports/nursing/inPatientIssueReturn/${ipdReturnId}/${ipdIssueId}`,
    {
      responseType: "blob",
      headers: authHeader(),
    }
  );
};
