import React, { useEffect, useRef } from "react";
import { InamdarHospiLogo } from "../../../assets/printAssets/InmadarHospiLogo";
import { GeneratePrint } from "../../../common/components/printModal/GeneratePrint";

export default function ConsentPrintPreview(props) {
  let printRef = useRef(null);
  const { handleClose, patientInfo, templateContent, TemplateName } = props;

  const DynamicHTMLRenderer = ({ htmlContent }) => {
    return (
      <div
        // className="whitespace-pre-wrap break-words"
        className="break-words"
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />
    );
  };

  useEffect(() => {
    templateContent !== null && printRef && GeneratePrint(printRef);
  }, [templateContent]);
  return (
    <div ref={printRef}>
      <table className="w-full">
        <thead
          style={{
            "-fs-table-paginate": "paginate",
          }}
        >
          <div>
            <div className=" justify-end flex ">
              <div>
                <InamdarHospiLogo />
              </div>
            </div>
            <div className=" border-t-[1px] font-semibold text-[15px] border-black text-center">
              OT Conset
            </div>
            <div className=" grid grid-cols-[20%_5%_25%_20%_5%_25%] border-b-[1px] border-t-[1px] p-1 border-black ">
              <div className=" text-[13px] font-semibold  ">PatientName</div>
              <div className=" text-[13px] ">:</div>
              <div className=" text-[13px] whitespace-nowrap ">
                {patientInfo?.patientName}
              </div>
              <div className=" text-[13px] font-semibold ">MR No</div>
              <div className=" text-[13px] ">:</div>
              <div className=" text-[13px] ">{patientInfo?.mrNo}</div>
              <div className=" text-[13px] font-semibold ">Gender</div>
              <div className=" text-[13px] ">:</div>
              <div className=" text-[13px] ">{patientInfo?.gender}</div>
              <div className=" text-[13px] font-semibold ">Consent Date</div>
              <div className=" text-[13px] ">:</div>
              <div className=" text-[13px] ">{patientInfo?.ConsentDate}</div>
              <div className=" text-[13px]  font-semibold">Admission Date</div>
              <div className=" text-[13px] ">:</div>
              <div className=" text-[13px] ">
                {patientInfo?.admVisitPatchDate}
              </div>
              <div className=" text-[13px] font-semibold ">Adviced By</div>
              <div className=" text-[13px] ">:</div>
              <div className=" text-[13px] ">
                {patientInfo?.doctorName ? patientInfo?.doctorName : "-"}
              </div>
              <div className=" text-[13px] font-semibold ">Age</div>
              <div className=" text-[13px] ">:</div>
              <div className=" text-[13px] ">{patientInfo?.ageYear}</div>
              <div className=" text-[13px] font-semibold ">DOB</div>
              <div className=" text-[13px] ">:</div>
              <div className=" text-[13px] ">{patientInfo?.dob}</div>
            </div>
            <div className=" border-b-[1px] font-semibold p-1 text-[13px] border-black text-center">
              {TemplateName?.label}
            </div>
          </div>
        </thead>
        <tbody>
          <tr>
            {/*<div
              className="pt-5 text-wrap flex flex-wrap  "
              // dangerouslySetInnerHTML={{ __html: data?.TempalteDescription }}
            >
              <h1> {data?.TempalteDescription}</h1>
        </div>*/}
            <DynamicHTMLRenderer htmlContent={templateContent} />
          </tr>
        </tbody>
      </table>
    </div>
  );
}
