import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box,
  Checkbox,
  IconButton,
  Modal,
  TextField,
  Tooltip,
} from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DetailedReportIcon,
  EditIcon,
  EmailDisableIcon,
  EmailEnableIcon,
} from "../../../../assets/CommonAssets";
import {
  DisablePrintIcon,
  EyeOffIcon,
  EyeOnIcon,
  ScheduleDisableIcon,
  ScheduleEnableIcon,
  WhatsAppDisableIcon,
  WhatsAppEnableIcon,
} from "../../../../assets/icons/CustomIcons";
import {
  DisableANCSchedulerIcon,
  EnableANCSchedulerIcon,
  PrintIcon,
} from "../../../../assets/icons/InvestigationIcon";
import QueueManagement from "../../../../assets/icons/QueueManagement.svg";
import RadioNABHLogo from "../../../../assets/icons/RadioNABHLogo";
import { InamdarHospiLogo } from "../../../../assets/printAssets/InmadarHospiLogo";
import CancelPresentationIconButton from "../../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTable from "../../../../common/components/CommonTable/CommonDynamicTable";
import CommonDynamicTablePaginationNew from "../../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import { UseCreatePdfBase64 } from "../../../../common/components/Custom Hooks/UseCreatePdfBase64";
import CheckBoxField from "../../../../common/components/FormFields/CheckBoxField";
import DatePickerField from "../../../../common/components/FormFields/DatePickerField";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import InputField from "../../../../common/components/FormFields/InputField";
import RadioField from "../../../../common/components/FormFields/RadioField";
import SearchDropdown from "../../../../common/components/FormFields/searchDropdown";
import { ModalStyle } from "../../../../common/components/ModalStyle";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../../common/components/Toasts/CustomToasts";
import LoadingSpinner from "../../../../common/components/loadingspinner/loadingSpinner";
import { fetchUserActionsByMenuId } from "../../../../commonServices/functionalityServices/FunctionalityServices";
import AppointmentPage from "../../../../opd/components/AppointmentBooking/AppointmentPage";
import LIMSHistoryModal from "../../../common/LIMSHistoryModal";
import {
  cancelOrder,
  cancelTest,
  fetchCategoryForRadiology,
  fetchRadiologyPatientOptions,
  fetchUnitOptions,
  generateRadiologyReport,
  getImage,
  getListOfTests,
  getPatientInfo,
  getTestReportDetails,
  getWorkOrderViewListing,
  radiologyWorkOrderPrint,
  sendReportOnEmail,
  sendReportOnWhatsapp,
  updateProcedureStatus,
} from "../../Services/WorkOrderViewServices";
import RadiologyWorkOrderPrint from "../../prints/RadiologyWorkOrderPrint";
import ANCSchedulerModal from "./ANCSchedulerModal";
import RadioWorkOrderViewModal from "./RadioWorkOrderViewModal";
import RadiologyQueueModal from "./RadiologyQueueModal";
import UploadFileViewModal from "./UploadFileViewModal";
const patientType = [
  {
    id: 4,
    label: "All",
    value: "All",
  },
  {
    id: 1,
    label: "IPD",
    value: "IPD",
  },
  {
    id: 0,
    label: "OPD",
    value: "OPD",
  },
];
const status = [
  {
    id: 2,
    label: "All",
    value: "All",
  },
  {
    id: 1,
    label: "Verified",
    value: "Verified",
  },
  {
    id: 0,
    label: "Non-Verified",
    value: "Non-Verified",
  },
];

const testTypeOptions = [
  {
    id: 0,
    label: "Normal",
    value: "Normal",
  },
  {
    id: 1,
    label: "Retest",
    value: "Retest",
  },
  {
    id: 2,
    label: "Dummy",
    value: "Dummy",
  },
  {
    id: 3,
    label: "All",
    value: "All",
  },
];

const testActions = [
  {
    id: 0,
    action: "print",
    isAction: true,
  },
  {
    id: 0,
    action: "cancel",
    isAction: true,
  },
  {
    id: 3,
    action: "select",
    isAction: true,
  },
];

const Actions = [
  {
    id: 0,
    action: "print",
    isAction: true,
  },
  {
    id: 1,
    action: "cancel",
    isAction: true,
  },
  {
    id: 2,
    action: "add",
    isAction: false,
  },
  {
    id: 3,
    action: "procedure",
    isAction: false,
  },
  {
    id: 4,
    action: "schedule",
    isAction: false,
  },
];

function RadiologyWorkOrderView(props) {
  const { updateComponent } = props;
  const defaultUnit = {
    id: 2,
    label: "IMH(FATIMA NAGAR)",
    value: "IMH(FATIMA NAGAR)",
  };
  const location = useLocation();
  const [userActions, setUserActions] = useState([]);
  let navigate = useNavigate();
  const bgGreen = "bg-green-600";
  const bgGray = "bg-gray-400";
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [pdfOpenBackdrop, setPdfOpenBackdrop] = React.useState(false);
  const [messageOpenBackdrop, setMessageOpenBackdrop] = React.useState(false);
  // const [selectedFromDate, setSelectedFromDate] = React.useState(null);
  // const [selectedToDate, setSelectedToDate] = React.useState(null);
  const [selectedRow1, setSelectedRow1] = React.useState(null);
  // const [selectedTestId, setSelectedTestId] = React.useState(null);
  const [categoryOptions, setCategoryOptions] = React.useState([]);
  const [unitOptions, setUnitOptions] = React.useState([]);
  const [searchString, setSearchString] = React.useState("");
  const [data, setData] = React.useState({ result: [], actions: [] });
  const [dataResult, setDataResult] = React.useState([]);
  const [count, setCount] = useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [testsArray, setTestsArray] = React.useState([]);
  const [selectedTestId, setSelectedTestId] = React.useState(null);
  const [patientInfo, setPatientInfo] = React.useState(null);
  const [forStatus, setForStatus] = useState();
  const [reportDetails, setReportDetails] = useState();
  const [procedureStart, setProcedureStart] = useState(false);
  const [procedureEnd, setProcedureEnd] = useState(false);
  const [procedureConfirmation, setProcedureConfirmation] = useState(false);
  const [procedureStatusCode, setProcedureStatusCode] = useState(false);
  const [openQueueModal, setOpenQueueModal] = useState(false);
  const [cancelTestConfirmation, setCancelTestConfirmation] = useState(false);
  const [cancelOrderConfirmation, setCancelOrderConfirmation] = useState(false);
  const [openPrintModal, setOpenPrintModal] = React.useState(false);
  const [urlforPrint, setUrlforPrint] = useState();
  const [checkBoxIndex, setCheckBoxIndex] = React.useState(null);
  const [privilege, setPrivilege] = React.useState("");
  const [searchOptions, setSearchOptions] = React.useState([]);
  const [openGenerateReport, setOpenGenerateReport] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [testLoadingSpinner, setTestLoadingSpinner] = useState(false);
  const [messageConfirmation, setMessageConfirmation] = useState(false);
  const [appointmentModalFlag, setAppointmentModaFlag] = useState(false);
  const [navigateData, setNavigateData] = useState(null);
  const [printDetails, setPrintDetails] = useState(null);
  const [isPrint, setIsPrint] = useState(false);
  const [printImage, setPrintImage] = React.useState("");
  const [images, setImages] = React.useState([]);
  const [selectedRowIndex, setSelectedRowIndex] = React.useState(null);
  const [isWhatsApp, setIsWhatsApp] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [openANCSchedulerModal, setOpenANCSchedulerModal] = useState(false);
  const [isView, setIsView] = useState(false);
  const [viewPdfSrc, setViewPdfSrc] = useState(null);
  const [openViewPdf, setOpenViewPdf] = useState(false);
  const [openLIMSHistoryModal, setOpenLIMSHistoryModal] = useState(false);
  const [radioHistoryData, setRadioHistoryData] = useState([]);
  const [LIMSType, setLIMSType] = useState("");
  const [saveData, setSaveData] = useState(false);
  const [selectedWorkOrderIndex, setSelectedWorkOrderIndex] = useState(null);
  const [listingObject, setListingObject] = useState({
    mrno: null,
    categoryId: 0,
    fromDate: null,
    isVerify: 2,
    patientType: Number(4),
    searchString: "",
    testType: 3,
    toDate: null,
    unitId: defaultUnit,
    page: page,
    size: rowsPerPage,
    menuId: location?.state?.menuId,
    searchId: null,
  });

  // const handleClosePrintModal = () => {
  //   setOpenPrintModal(false);
  // };

  const handleProcedureStartClose = () => {
    setProcedureStart(false);
  };

  const handleProcedureEndClose = () => {
    setProcedureEnd(false);
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const defaultValues = {
    patientType: 4,
    status: 2,
    unit: defaultUnit,
    category: 0,
    testType: 3,
    fromDate: new Date(),
    toDate: new Date(),
    searchBar: "",
    provisionalRemark: "",
    mrno: null,
  };
  const { control, watch, reset, getValues, register, setValue } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const getCategory = watch("category");
  const getTestType = watch("testType");
  const getUnit = watch("unit");
  const getStatus = Number(watch("status"));
  const getPatientType = watch("patientType");
  const selectedFromDate = watch("fromDate");
  const selectedToDate = watch("toDate");
  const getMrno = Number(watch("mrno"));
  let getSearchPatient = watch("searchBar");
  // const getFromDate = selectedFromDate
  //   .toLocaleDateString()
  //   .replaceAll("/", "-");
  // const getToDate = selectedToDate.toLocaleDateString().replaceAll("/", "-");

  const onInputChange = (searchstring) => {
    //
    setSearchString(searchstring);
  };

  // const filterData = () => {
  //   let requiredObj = {};

  //   // if (getCategory) {
  //   //   requiredObj.categoryId = getCategory.id;
  //   // }
  //   if (getTestType) {
  //     requiredObj.testType = getTestType.id;
  //   }
  //   if (getUnit) {
  //     requiredObj.unitId = getUnit.id;
  //   }
  //   requiredObj.categoryId = getCategory ? getCategory.id : 0;
  //   requiredObj.testType = getTestType ? getTestType.id : 3;
  //   requiredObj.isVerify = getStatus ? getStatus : 2;
  //   requiredObj.patientType = getPatientType ? getPatientType : 4;
  //   requiredObj.unitId = getUnit ? getUnit.id : defaultUnit;
  //   requiredObj.searchString = searchString;
  //   requiredObj.fromDate = selectedFromDate;
  //   requiredObj.toDate = selectedToDate;
  //   requiredObj.page = page;
  //   requiredObj.size = rowsPerPage;

  //   getWorkOrderViewListing(requiredObj)
  //     .then((response) => response.data)
  //     .then((res) => {
  //       setData(res);
  //       setCount(res.count);
  //       setDataResult(res.result);
  //
  //     });
  //   setTestsArray([]);
  //   setSelectedRow1(false);
  //
  // };

  useEffect(() => {
    if (page !== 0) {
      setTestsArray([]);
    }
  }, [page]);

  useEffect(() => {
    fetchWorkOrderViewList();
    getCategoryOptions();
    getUnitOptions();
  }, [getSearchPatient]);

  useEffect(() => {
    if (selectedRow1 !== null) {
      listOfTests();
      setCheckBoxIndex();
    }
  }, [selectedRow1]);

  const getCategoryOptions = () => {
    fetchCategoryForRadiology()
      .then((response) => response.data)
      .then((res) => {
        setCategoryOptions(res.result);
      });
  };

  const getUnitOptions = () => {
    fetchUnitOptions()
      .then((response) => response.data)
      .then((res) => {
        setUnitOptions(res.result);
      });
  };

  const defaultParams = {
    mrno: getMrno ? getMrno : null,
    categoryId: getCategory ? getCategory?.id : 0,
    fromDate: selectedFromDate,
    isVerify: getStatus,
    patientType: Number(getPatientType ? getPatientType : 4),
    searchString: searchString ? searchString : "",
    testType: getTestType ? getTestType.id : 3,
    toDate: selectedToDate,
    unitId: getUnit ? getUnit.id : defaultUnit,
    page: page,
    size: rowsPerPage,
    menuId: location?.state?.menuId,
    searchId: getSearchPatient ? getSearchPatient?.id : null,
  };

  const handleChange = (string) => {
    let searchObj = {
      searchString: string,
      patientType: getPatientType,
      fromDate: selectedFromDate,
      toDate: selectedToDate,
    };
    if (string !== "") {
      fetchRadiologyPatientOptions(searchObj)
        .then((response) => response.data)
        .then((res) => {
          setSearchOptions(res.result);
        });
    }
  };

  function fetchWorkOrderViewList() {
    setLoadingSpinner(true);
    getWorkOrderViewListing(defaultParams)
      .then((response) => response.data)
      .then((res) => {
        setData(res);
        setCount(res.result?.length);
        setDataResult(res.result);
        // setUserActions(res.actions);
        setLoadingSpinner(false);
      });
  }

  console.log("selectedTestId123", selectedTestId, selectedTestId);

  const listOfTests = () => {
    const listOfTestsObj = {
      admissionId: null,
      isVerify: getStatus,
      orderId: selectedRow1?.OrderId,
    };
    setTestLoadingSpinner(true);
    getListOfTests(listOfTestsObj)
      .then((response) => response.data)
      .then((res) => {
        setTestsArray(res.result);
        let filter = res.result.filter(
          (arr) => arr.OrderDtlsId === selectedTestId?.OrderDtlsId
        );
        setSelectedTestId(filter?.length > 0 ? filter[0] : null);
        setTestLoadingSpinner(false);
      });
  };

  const fetchPatientInfo = (row) => {
    getPatientInfo(row?.OrderDtlsId, row?.Opd_Ipd_External)
      .then((response) => response.data)
      .then((res) => {
        setPatientInfo(res.result);
      });
  };

  function startProcedure() {
    let status = 1;
    if (forStatus === "Start Procedure") {
      status = 1;
    } else if (forStatus === "Cancel Procedure") {
      status = 0;
    } else if (forStatus === "End Procedure") {
      status = 2;
      setForStatus();
    }
    updateProcedureStatus(selectedTestId?.OrderDtlsId, status).then(
      (response) => {
        setProcedureStatusCode(response.statusCode);
        listOfTests();
        setProcedureConfirmation(false);
      }
    );
    // setSelectedTestId();
  }

  function forCancelOrder() {
    cancelOrder(selectedRow1?.OrderId, location?.state?.menuId, privilege)
      .then((response) => {
        successAlert(response.data.message);
        setCancelOrderConfirmation(false);
        fetchWorkOrderViewList();
        listOfTests();
        setPage(0);
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
      });
  }

  const renderTableHeader = () => {
    return (
      <div className="flex w-full">
        <div className="font-semibold text-lg w-max">List Of Orders</div>
      </div>
    );
  };
  const RenderActions1 = (row, index) => {
    setValue(
      `workOrderCheck${index}`,
      selectedWorkOrderIndex === index ? true : false
    );
    return (
      <div className="flex">
        {userActions.map((actions, i) => (
          <>
            {actions.isAction === true ? (
              <>
                <div className="flex gap-2 cursor-pointer">
                  {actions.action === "Cancel" ? (
                    <>
                      {row.Opd_Ipd_External === 1 ? (
                        <Tooltip title="Cancel Order">
                          <IconButton
                            onClick={() => {
                              setSelectedRow1(row);
                              setCancelOrderConfirmation(true);
                              setPrivilege(actions.action);
                            }}
                          >
                            <HighlightOffIcon
                              sx={{ color: "#FF0000", height: "20px" }}
                            />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip>
                          <IconButton sx={{ cursor: "default" }}>
                            <HighlightOffIcon
                              sx={{
                                color: "#4B5563",
                                height: "20px",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : null}
          </>
        ))}
        <fieldset
          className="mx-2"
          onChange={(e) => {
            console.log("checkOrder", e.target.checked);
            if (e.target.checked === true) {
              setValue("provisionalRemark", row?.ProvisionalDiagnosis);
              setSelectedRow1(row);
              setImages([]);
              setSelectedWorkOrderIndex(index);
              setPatientInfo(null);
            }
          }}
        >
          <CheckBoxField control={control} name={`workOrderCheck${index}`} />
        </fieldset>
      </div>
    );
  };

  const rowBackgroundColor1 = (row, index) => {
    if (row?.resultDetails !== null) {
      if (
        (row?.resultDetails?.FirstLevelId !== null &&
          row?.resultDetails?.FirstLevelId > 0) ||
        (row?.resultDetails?.SecondLevelId !== null &&
          row?.resultDetails?.SecondLevelId > 0) ||
        (row?.resultDetails?.ThirdLevelId !== null &&
          row?.resultDetails?.ThirdLevelId > 0)
      ) {
        return "#9CEF95";
      }
    }
  };

  function forCancelTest() {
    cancelTest(selectedTestId?.OrderDtlsId, location?.state?.menuId, privilege)
      .then((response) => {
        successAlert(response.data.message);
        setCancelTestConfirmation(false);
        listOfTests();
      })
      .catch((error) => {
        errorAlert(error.response.data.message || error.message);
      });
  }

  // function handleOpenPrintModal(row) {
  //   setPdfOpenBackdrop(true);
  //   radiologyTestPrint(row.OrderDtlsId)
  //     .then((response) => {
  //       setPdfOpenBackdrop(false);
  //       setOpenPrintModal(true);
  //       setUrlforPrint(response);
  //     })
  //     .catch((error) => {
  //       errorAlert(error.message);
  //       setPdfOpenBackdrop(false);
  //     });
  // }

  useEffect(() => {
    for (let i = 0; i < testActions.length; i++) {
      if (testActions[i].action === "select") {
        setValue(`selectOptions${i}`, false);
      }
    }
  }, [testsArray]);

  function viewUploadFile(row) {
    let splitPath = row?.filepath?.split("/");

    let imageType =
      splitPath[splitPath?.length - 1]?.split(".")?.[1] === "pdf" ||
      splitPath[splitPath?.length - 1]?.split(".")?.[1] === undefined
        ? "pdf"
        : "image";
    console.log("splitPath123", splitPath, imageType);

    // ${baseUrl}/file${reportDetails?.FilePath}
    getImage(splitPath, imageType).then((response) => {
      console.log("response123", response);
      // if (imageType === "pdf") {
      //Get Reponse JSON
      const blb = new Blob([response.data], { type: "text/plain" });
      const reader = new FileReader();
      // Start reading the blob as text.
      reader.readAsText(blb);
      //Convert Blob Response to JSON String
      reader.addEventListener("loadend", (e) => {
        if (e.target.result.charAt(0) !== "{") {
          const file = new Blob([response.data], {
            type: "application/pdf",
          });
          const fileURL = URL.createObjectURL(file);
          console.log("fileURL", fileURL);
          let fileObject = {
            type: imageType,
            src: fileURL,
          };
          setViewPdfSrc(fileObject);
          setOpenViewPdf(true);
        }
      });
      // } else {
      // }
    });
  }

  const RenderActions2 = (row, index) => {
    return (
      <div className="flex items-center">
        <div className="mr-2 flex items-center">
          {userActions?.map((actions) => (
            <>
              {actions.isAction === false && actions.action === "Create" && (
                <button
                  type="button"
                  onClick={() => {
                    setCheckBoxIndex(index);
                    setSelectedTestId(row);
                    if (row && row !== null && row?.isProcedureStart === true) {
                      handleOpen();
                      fetchPatientInfo(row);
                    } else if (row !== null && row?.isProcedureStart === null) {
                      warningAlert("Please Start Procedure...!");
                    } else if (row === null) {
                      warningAlert("Please Select Procedure...!");
                    }
                  }}
                >
                  <EditIcon />
                </button>
              )}
            </>
          ))}
        </div>
        {userActions.map((actions, i) => (
          <>
            {actions.isAction === true && (
              <div className="flex items-center cursor-pointer">
                <div className="mt-1">
                  {actions.action === "Print" && (
                    <>
                      {row?.filepath?.includes(".pdf") ? (
                        <button type="button" disabled>
                          <DisablePrintIcon />
                        </button>
                      ) : (
                        <>
                          {(row?.ThirdLevelDescription !== null &&
                            row?.ThirdLevelDescription !== "") ||
                          (row?.SecondLevelDescription !== null &&
                            row?.SecondLevelDescription !== "") ||
                          (row?.FirstLevelDescription !== null &&
                            row?.FirstLevelDescription !== "") ? (
                            <Tooltip title="Print">
                              <button
                                type="button"
                                onClick={() => {
                                  getPrintDetails(row);
                                  setSelectedRowIndex(index);
                                  setIsWhatsApp(false);
                                  setIsEmail(false);
                                  setOpenBackdrop(true);
                                  setCheckBoxIndex(index);
                                  // setIsPrint(true);
                                }}
                              >
                                <PrintIcon />
                              </button>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Print">
                              <button type="button" disabled>
                                <DisablePrintIcon />
                              </button>
                            </Tooltip>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
                <div>
                  {actions.action === "Cancel" && (
                    <>
                      {row.Opd_Ipd_External === 1 ? (
                        <Tooltip title="Cancel Order">
                          <IconButton
                            onClick={() => {
                              setSelectedTestId(row);
                              setCancelTestConfirmation(true);
                              setPrivilege(actions.action);
                              setCheckBoxIndex(index);
                            }}
                          >
                            <HighlightOffIcon
                              sx={{ color: "#FF0000", height: "20px" }}
                            />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip>
                          <IconButton sx={{ cursor: "default" }}>
                            <HighlightOffIcon
                              sx={{
                                color: "#4B5563",
                                height: "20px",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  )}
                </div>
                <div className="mt-2">
                  {actions.action === "Send On Whatsapp" && (
                    <>
                      {row?.IsCompleted === true ? (
                        <Tooltip title="Send Report">
                          <button
                            type="button"
                            onClick={() => {
                              setSelectedTestId(row);
                              getPrintDetails(row);
                              setSelectedRowIndex(index);
                              setMessageConfirmation(true);
                              setIsWhatsApp(true);
                              setCheckBoxIndex(index);
                            }}
                          >
                            <WhatsAppEnableIcon />
                          </button>
                        </Tooltip>
                      ) : (
                        <Tooltip title="">
                          <button type="button">
                            <WhatsAppDisableIcon />
                          </button>
                        </Tooltip>
                      )}
                    </>
                  )}
                </div>
                <div>
                  {actions.action === "SendOnEmail" && (
                    <>
                      {row?.IsCompleted === true ? (
                        <Tooltip title="Send Email">
                          <button
                            type="button"
                            onClick={() => {
                              setIsEmail(true);
                              setIsWhatsApp(false);
                              getPrintDetails(row);
                              setSelectedRowIndex(index);
                              setMessageConfirmation(true);
                              setCheckBoxIndex(index);
                            }}
                          >
                            <EmailEnableIcon />
                          </button>
                        </Tooltip>
                      ) : (
                        <button type="button">
                          <EmailDisableIcon />
                        </button>
                      )}
                    </>
                  )}
                </div>
                <div className="mt-1 mr-2">
                  {actions.action === "Schedule" && (
                    <>
                      {(row?.ThirdLevelDescription !== null &&
                        row?.ThirdLevelDescription !== "") ||
                      (row?.SecondLevelDescription !== null &&
                        row?.SecondLevelDescription !== "") ||
                      (row?.FirstLevelDescription !== null &&
                        row?.FirstLevelDescription !== "") ? (
                        <button type="button" disabled>
                          <ScheduleDisableIcon />
                        </button>
                      ) : (
                        <Tooltip title="Schedule">
                          <button
                            type="button"
                            onClick={() => {
                              setCheckBoxIndex(index);
                              setAppointmentModaFlag(true);
                              let navigateObj = {
                                patientId: selectedRow1?.PatientId,
                                radiologyRow: row,
                                menuId: actions.menuId,
                              };
                              setNavigateData(navigateObj);
                              // navigate("/opd/phoneAppointments", {
                              //   state: {
                              //     patientId: selectedRow1?.PatientId,
                              //     radiologyRow: row,
                              //     menuId: actions.menuId,
                              //   },
                              // });
                            }}
                          >
                            <ScheduleEnableIcon />
                          </button>
                        </Tooltip>
                      )}
                    </>
                  )}
                </div>
                <div>
                  {actions.action === "ANC Scheduler" && (
                    <div className="mt-1">
                      {selectedRow1?.Gender === "Female" &&
                      row.IsAnc === true ? (
                        <Tooltip title="ANC Scheduler">
                          <button
                            type="button"
                            onClick={() => {
                              setOpenANCSchedulerModal(true);
                              setSelectedTestId(row);
                              setPrivilege(actions.action);
                              setCheckBoxIndex(index);
                            }}
                          >
                            <EnableANCSchedulerIcon />
                          </button>
                        </Tooltip>
                      ) : (
                        <div>
                          <DisableANCSchedulerIcon />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        ))}
        <div className="mx-1">
          <>
            {row?.IsCompleted === true &&
            row?.IsProcedureEnd === true &&
            !row?.filepath?.includes("null") ? (
              <Tooltip title="View PDF">
                <button
                  type="button"
                  onClick={() => {
                    // setOpenANCSchedulerModal(true);
                    setSelectedTestId(row);
                    viewUploadFile(row);
                    setCheckBoxIndex(index);
                  }}
                >
                  <EyeOnIcon />
                </button>
              </Tooltip>
            ) : (
              <div>
                <EyeOffIcon />
              </div>
            )}
          </>
        </div>
        <>
          <Checkbox
            control={control}
            checked={checkBoxIndex === index ? true : false}
            value={index}
            name={`selectOptions${row.PackageTestName}`}
            {...register(`selectOptions${row.PackageTestName}`)}
            size="small"
            onChange={(e) => {
              setCheckBoxIndex(index);
              setSelectedTestId(row);
            }}
          />
        </>
      </div>
    );
  };

  const rowBackgroundColor2 = (row, index) => {
    if (
      (row?.ThirdLevelDescription !== null &&
        row?.ThirdLevelDescription !== "") ||
      (row?.SecondLevelDescription !== null &&
        row?.SecondLevelDescription !== "") ||
      (row?.FirstLevelDescription !== null && row?.FirstLevelDescription !== "")
    ) {
      return "#9CEF95";
    }
  };

  useEffect(() => {
    for (let i = 0; i < testActions.length; i++) {
      setValue(`selectOptions${i}`, false);
    }
  }, [selectedRow1]);

  const handleSelectedRow1 = (row, index) => {
    // setValue("provisionalRemark", row?.ProvisionalDiagnosis);
    // setSelectedRow1(row);
    // setImages([]);
  };
  const handleSelectedRow2 = (row, index) => {
    setSelectedTestId(row);
  };

  function generateRadioReport() {
    generateRadiologyReport(selectedTestId?.OrderDtlsId).then((response) => {
      successAlert(response.data.message);
      setOpenGenerateReport(false);
    });
  }

  // function sendMessage() {
  //   setOpenBackdrop(true);
  //   sendReportOnWhatsapp(selectedTestId?.OrderDtlsId).then((response) => {
  //     successAlert(response.data.message);
  //     setMessageConfirmation(false);
  //     setOpenBackdrop(false);
  //   });
  // }

  function arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  function sendToEmailPrint() {
    setOpenBackdrop(true);
    let pdfContent = document.getElementById("radiologyPrint");
    //
    UseCreatePdfBase64(pdfContent).then((response) => {
      if (response !== "") {
        // setPdfBase64(response.split(",")[1]);
        sendReportOnEmail({
          orderDtlsId: selectedTestId?.OrderDtlsId,
          base64: response.split(",")[1],
          functionalityId: userActions?.filter(
            (action) => action.action === "SendOnEmail"
          )[0]?.functionalityId,
          opdIpd: selectedTestId?.Opd_Ipd_External,
          opdIpdId: selectedTestId?.Opd_Ipd_External_Id,
        })
          .then((response) => {
            successAlert(response.data.message);
            setMessageConfirmation(false);
            setOpenBackdrop(false);
          })
          .catch(() => {
            setMessageConfirmation(false);
            setOpenBackdrop(false);
          });
      }
    });
  }

  function whatsAppPrint() {
    if (selectedTestId?.filepath?.includes(".pdf")) {
      let splitPath = selectedTestId?.filepath?.split("/");
      let imageType = splitPath[splitPath?.length - 1]?.split(".")?.[1];
      // ${baseUrl}/file${reportDetails?.FilePath}
      getImage(splitPath).then((response) => {
        if (imageType === "pdf") {
          let newPdfString = arrayBufferToBase64(response.data);
          setOpenBackdrop(true);
          sendReportOnWhatsapp({
            orderDtlsId: selectedTestId?.OrderDtlsId,
            base64: newPdfString,
            functionalityId: userActions?.filter(
              (action) => action.action === "Send On Whatsapp"
            )[0]?.functionalityId,
            opdIpd: selectedTestId?.Opd_Ipd_External,
            opdIpdId: selectedTestId?.Opd_Ipd_External_Id,
          })
            .then((response) => {
              successAlert(response.data.message);
              setMessageConfirmation(false);
              setOpenBackdrop(false);
            })
            .catch(() => {
              setMessageConfirmation(false);
              setOpenBackdrop(false);
            });
        }
      });
    } else {
      setOpenBackdrop(true);
      let pdfContent = document.getElementById("radiologyPrint");
      //
      UseCreatePdfBase64(pdfContent).then((response) => {
        if (response !== "") {
          // setPdfBase64(response.split(",")[1]);
          sendReportOnWhatsapp({
            orderDtlsId: selectedTestId?.OrderDtlsId,
            base64: response.split(",")[1],
            functionalityId: userActions?.filter(
              (action) => action.action === "Send On Whatsapp"
            )[0]?.functionalityId,
            opdIpd: selectedTestId?.Opd_Ipd_External,
            opdIpdId: selectedTestId?.Opd_Ipd_External_Id,
          })
            .then((response) => {
              successAlert(response.data.message);
              setMessageConfirmation(false);
              setOpenBackdrop(false);
            })
            .catch(() => {
              setMessageConfirmation(false);
              setOpenBackdrop(false);
            });
        }
      });
    }
  }

  const renderImage = () => {
    return <img src={printImage} />;
  };

  React.useEffect(() => {
    let imagesArray = [];
    for (let row of testsArray) {
      radiologyWorkOrderPrint(row?.OrderDtlsId)
        .then((response) => response.data)
        .then((res) => {
          let image =
            row?.["Test Name"] === res.result.TestName &&
            res.result?.Radiologist1Sign !== ""
              ? "data:image/png;base64," + res.result?.Radiologist1Sign
              : "";
          imagesArray.push({ testName: res.result.TestName, base64: image });
          setImages(imagesArray);
        });
    }
  }, [testsArray]);

  console.log("images123", images);

  const renderPrint = () => {
    setTimeout(() => {
      setOpenPrintModal(false);
      // setSelectedPatient(null);
    }, 2000);

    return (
      <div className="">
        <RadiologyWorkOrderPrint
          renderImage={renderImage}
          printImage={printImage}
          dataResult={printDetails}
          selectedRow={selectedTestId}
          setOpenBackdrop={setOpenBackdrop}
          isPrint={isPrint}
          setIsPrint={setIsPrint}
          images={images}
          selectedRowIndex={selectedRowIndex}
          setSelectedRowIndex={setSelectedRowIndex}
          isWhatsApp={isWhatsApp}
          isEmail={isEmail}
        />
      </div>
    );
  };

  function getPrintDetails(row) {
    radiologyWorkOrderPrint(row?.OrderDtlsId)
      .then((response) => response.data)
      .then((res) => {
        setPrintDetails(res.result);
        // setPrintImage("data:image/png;base64," + res.result?.Radiologist1Sign);
        setIsPrint(true);
        setOpenPrintModal(true);
        setOpenBackdrop(false);
      })
      .catch((error) => {
        setOpenBackdrop(false);
      });
  }

  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });
  }, []);

  function viewLIMSHistory() {
    getTestReportDetails({
      opdIpdExternal: selectedRow1?.Opd_Ipd_External,
      opdIpdExternalId: selectedRow1?.Opd_Ipd_External_Id,
      patientId: selectedRow1?.PatientId,
    }).then((response) => {
      let newArray = [];
      response.data.result?.map((val) => {
        newArray.push({
          testName: val?.testName,
          testId: val?.testId,
          Tests: JSON.parse(val?.Tests),
        });
      });
      setRadioHistoryData(newArray);
    });
  }

  useMemo(() => {
    fetchWorkOrderViewList();
  }, [
    listingObject.mrno,
    listingObject.categoryId,
    listingObject.fromDate,
    listingObject.isVerify,
    listingObject.patientType,
    listingObject.searchString,
    listingObject.testType,
    listingObject.toDate,
    listingObject.unitId,
    listingObject.page,
    listingObject.size,
    listingObject.menuId,
    listingObject.searchId,
  ]);

  useEffect(() => {
    if (updateComponent === true) {
      console.log("updateComponent123", updateComponent);
      setValue("fromDate", new Date());
      setValue("toDate", new Date());
      fetchWorkOrderViewList();
    }
  }, [updateComponent]);

  console.log("radioHistoryData", radioHistoryData);
  return (
    <form className="space-y-2">
      <div className="mt-12">
        <div className="text-xl font-semibold flex justify-center">
          Work Order View
        </div>
        <div className="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-2 my-2">
          <div className="flex space-x-2 col-span-3 lg:col-span-3">
            <div className="w-80">
              <InputField control={control} name="mrno" label="MR No." />
            </div>
            <div className="w-full">
              <SearchDropdown
                control={control}
                name="searchBar"
                placeholder="Search By Patient Name/Mr.No."
                isSearchable={true}
                isClearable={true}
                searchIcon={true}
                handleInputChange={handleChange}
                dataArray={searchOptions}
              />
            </div>
          </div>
          <div>
            <DatePickerField
              control={control}
              name="fromDate"
              label="From Date"
              value={new Date()}
              onChange={(newValue) => {
                setValue("fromDate", newValue);
              }}
              disableFuture={true}
              inputFormat="DD-MM-YYYY"
            />
          </div>
          <div>
            <DatePickerField
              control={control}
              name="toDate"
              label="To Date"
              value={new Date()}
              onChange={(newValue) => {
                setValue("toDate", newValue);
              }}
              disableFuture={true}
              inputFormat="DD-MM-YYYY"
            />
          </div>
          <div>
            <DropdownField
              control={control} // startProcedure(e);
              name="testType"
              label="Test Type"
              placeholder="Test Type"
              dataArray={testTypeOptions}
              isSearchable={true}
            />
          </div>
          <div>
            <DropdownField
              isSearchable={true}
              control={control}
              name="category"
              label="Category"
              placeholder="Category"
              dataArray={categoryOptions}
            />
          </div>
          <div>
            <DropdownField
              isSearchable={true}
              control={control}
              name="unit"
              label="Unit"
              placeholder="Unit"
              dataArray={unitOptions}
            />
          </div>
          <fieldset className="flex gap-1 justify-center items-center bg-white border rounded px-2 -mt-2">
            <legend className="font-semibold text-xs px-2">Patient Type</legend>
            <div className="-my-2">
              <RadioField
                control={control}
                name="patientType"
                dataArray={patientType}
              />
            </div>
          </fieldset>
          <fieldset className="flex gap-1 justify-center items-center bg-white border rounded px-2 -mt-2 col-span-2">
            <legend className="font-semibold text-xs px-2">Status</legend>
            <div className="-my-2">
              <RadioField control={control} name="status" dataArray={status} />
            </div>
          </fieldset>
          <div className="flex flex-wrap gap-2">
            <div className="mx-2">
              <div className="flex gap-1 justify-end">
                <CommonButton
                  type="button"
                  label="Reset"
                  className="border border-customRed text-customRed"
                  onClick={() => {
                    reset();
                    setValue("fromDateCalender", new Date());
                    setValue("toDateCalender", new Date());
                  }}
                />
                <CommonButton
                  type="button"
                  className="bg-customBlue text-white"
                  searchIcon={true}
                  onClick={() => {
                    // fetchWorkOrderViewList();
                    setListingObject((listingObject) => ({
                      listingObject,
                      mrno: getMrno ? getMrno : null,
                      categoryId: getCategory ? getCategory?.id : 0,
                      fromDate: selectedFromDate,
                      isVerify: getStatus,
                      patientType: Number(getPatientType ? getPatientType : 4),
                      searchString: searchString ? searchString : "",
                      testType: getTestType ? getTestType.id : 3,
                      toDate: selectedToDate,
                      unitId: getUnit ? getUnit.id : defaultUnit,
                      page: page,
                      size: rowsPerPage,
                      menuId: location?.state?.menuId,
                      searchId: getSearchPatient ? getSearchPatient?.id : null,
                    }));
                    setTestsArray([]);
                  }}
                />
              </div>
            </div>
            {userActions.map((action, i) => (
              <>
                {action.action === "Queue" && action.isAction === false ? (
                  <div
                    className="cursor-pointer items-center flex"
                    onClick={() => {
                      setOpenQueueModal(true);
                    }}
                  >
                    <img src={QueueManagement} />
                  </div>
                ) : (
                  ""
                )}
              </>
            ))}
            <div className="flex space-x-2 items-center justify-end">
              <Tooltip title="Reports History">
                <button
                  type="button"
                  onClick={() => {
                    if (selectedRow1 !== null) {
                      setLIMSType("Radiology");
                      viewLIMSHistory();
                      setOpenLIMSHistoryModal(true);
                    } else {
                      warningAlert("Select Order...!");
                    }
                  }}
                >
                  <DetailedReportIcon />
                </button>
              </Tooltip>
              {userActions.map((action, i) => (
                <>
                  {action.isAction === false && action.action === "Schedule" ? (
                    <div className="flex gap-2">
                      <CommonButton
                        type="button"
                        label="Schedule"
                        className="bg-customGreen text-white"
                        onClick={() => {
                          navigate("/opd/phoneAppointments", {
                            state: { selectedRow1, menuId: action.menuId },
                          });
                        }}
                      />
                    </div>
                  ) : null}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
      <hr className="w-full mt-2 border-t-2 border-gary-900" />
      <div className="">
        {!loadingSpinner ? (
          <>
            {dataResult !== undefined && dataResult?.length > 0 ? (
              <CommonDynamicTablePaginationNew
                data={data}
                dataResult={dataResult}
                setDataResult={setDataResult}
                handleSelectedRow={handleSelectedRow1}
                page={page}
                setPage={setPage}
                rowsPerPage={dataResult?.length}
                setRowsPerPage={setRowsPerPage}
                count={count}
                selectedRow1={selectedRow1}
                tableApiFunc={getWorkOrderViewListing}
                defaultParams={defaultParams}
                tableClass={"h-60"}
                actionsRequired={true}
                renderActions={RenderActions1}
                rowBackgroundColor={rowBackgroundColor1}
                renderTableHeader={renderTableHeader}
                removeHeaders={[
                  "resultDetails",
                  "IsApproved",
                  "IsDelivered",
                  "IsCancelled",
                  "PatientId",
                  "IsSchedule",
                  "OrderId",
                  "Opd_Ipd_External_Id",
                  "Opd_Ipd_External",
                  "resultDetails",
                  "Bill No",
                  "Receipt No",
                ]}
              />
            ) : (
              <div className="justify-center items-center font-semibold h-60 w-full flex border-2 border-gray-300 rounded">
                <p>No Records Found...</p>
              </div>
            )}
          </>
        ) : (
          <div className="justify-center items-center font-semibold h-60 w-full flex border-2 border-gray-300 rounded">
            <LoadingSpinner />
          </div>
        )}
      </div>
      {!testLoadingSpinner ? (
        <>
          {testsArray !== undefined && testsArray?.length > 0 ? (
            <CommonDynamicTable
              data={testsArray}
              setSelectedTestId={setSelectedTestId}
              selectedTestId={selectedTestId}
              actionsRequired={true}
              renderActions={RenderActions2}
              highlightRow={false}
              tableClass={"h-60"}
              handleSelectedRow={handleSelectedRow2}
              rowBackgroundColor={rowBackgroundColor2}
              removeHeaders={[
                "Test Code",
                "Test Cost",
                "First Level",
                "Second Level",
                "Third Level",
                "IsCompleted",
                "IsDelivered",
                "IsPrinted",
                "HasWordProcessor",
                "RadiologistId",
                "FlimId",
                "NoOfFlims",
                "MicrobiologistId",
                "Radiologist1Id",
                "Radiologist2Id",
                "ThirdLevelDescription",
                "TemplateId",
                "FirstLevelId",
                "SecondLevelId",
                "ThirdLevelId",
                "Number",
                "IsOutSourced",
                "AgencyId",
                "PrintTestName",
                "isProcedureStart",
                "IsProcedureEnd",
                "Opd_Ipd_External",
                "Opd_Ipd_External_Id",
                "OrderId",
                "NonPathTestId",
                "IsApproved",
                "ChargeId",
                "Id",
                "TariffServiceId",
                "OrderDtlsId",
                "DoctorId",
                "FirstLevel",
                "SecondLevel",
                "ThirdLevel",
                "NoOfContrast",
                "CriticalFinding",
                "TimeOfInform",
                "GenderId",
                "Gender",
                "AbnormalId",
                "Abnormal",
                "FirstLevelDescription",
                "SecondLevelDescription",
                "IsAnc",
                "PatientId",
                "filepath",
              ]}
            />
          ) : (
            <div className="justify-center items-center font-semibold h-60 w-full flex border-2 border-gray-300 rounded">
              <p>No Records Found...</p>
            </div>
          )}
        </>
      ) : (
        <div className="justify-center items-center font-semibold h-60 w-full flex border-2 border-gray-300 rounded">
          <LoadingSpinner />
        </div>
      )}
      <div>
        <div className="grid grid-cols-2 gap-2 my-1">
          <div className="w-full">
            <TextField
              name="provisionalRemark"
              aria-label="minimum height"
              multiline
              maxRows={4}
              minRows={2}
              placeholder="Provisional Diagnosis / Remark"
              fullWidth
              {...register("provisionalRemark")}
            />
          </div>

          <div className="flex gap-2 justify-end">
            <>
              {/* {Actions.map((action, i) => (
                <>
                  {action.isAction === false &&
                  action.action === "procedure" ? ( */}
              <div
                className="grid "
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                {selectedTestId?.isProcedureStart === true &&
                selectedTestId?.IsProcedureEnd === false ? (
                  <div className="grid gap-2">
                    <CommonButton
                      type="button"
                      label="Cancel Procedure"
                      className="bg-customGreen text-white"
                      onClick={(e) => {
                        setProcedureConfirmation(true);
                        setForStatus(e.target.innerHTML);
                      }}
                    />
                    <CommonButton
                      type="button"
                      label="End Procedure"
                      className="bg-customRed text-white"
                      onClick={(e) => {
                        setProcedureConfirmation(true);
                        setForStatus(e.target.innerHTML);
                      }}
                    />
                  </div>
                ) : (selectedTestId?.isProcedureStart === null ||
                    selectedTestId?.isProcedureStart === false) &&
                  selectedTestId?.IsProcedureEnd === false ? (
                  <CommonButton
                    type="button"
                    label="Start Procedure"
                    className="bg-customGreen text-white"
                    disabled={!selectedTestId}
                    onClick={(e) => {
                      setProcedureConfirmation(true);
                      setForStatus(e.target.innerHTML);
                    }}
                  />
                ) : (
                  ""
                )}
              </div>
              {/* ) : null}
                </>
              ))} */}
              {userActions.map((action, i) => (
                <>
                  {action.isAction === false && action.action === "Create" ? (
                    <div>
                      <CommonButton
                        type="button"
                        label="Add"
                        className="bg-customGreen text-white"
                        onClick={() => {
                          if (
                            selectedTestId &&
                            selectedTestId !== null &&
                            selectedTestId?.isProcedureStart === true
                          ) {
                            handleOpen();
                            fetchPatientInfo(selectedTestId);
                          } else if (
                            selectedTestId !== null &&
                            selectedTestId?.isProcedureStart === null
                          ) {
                            warningAlert("Please Start Procedure...!");
                          } else if (selectedTestId === null) {
                            warningAlert("Please Select Procedure...!");
                          }
                        }}
                      />
                    </div>
                  ) : null}
                </>
              ))}
              <div>
                <CommonButton
                  type="button"
                  label="Generate Report"
                  className="bg-customGreen text-white"
                  onClick={() => {
                    if (selectedTestId?.IsCompleted === true) {
                      setOpenGenerateReport(true);
                    } else {
                      warningAlert("Please select test...!");
                    }
                  }}
                  disabled={
                    selectedTestId === null ||
                    selectedTestId === undefined ||
                    selectedTestId?.IsCompleted === false
                  }
                />
              </div>
            </>
          </div>
        </div>
      </div>
      <RadioWorkOrderViewModal
        selectedTestId={selectedTestId}
        setSelectedTestId={setSelectedTestId}
        setCheckBoxIndex={setCheckBoxIndex}
        setSelectedRow1={setSelectedRow1}
        selectedRow1={selectedRow1}
        patientInfo={patientInfo}
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        openBackdrop={openBackdrop}
        setOpenBackdrop={setOpenBackdrop}
        setTestsArray={setTestsArray}
        setForStatus={setForStatus}
        listOfTests={listOfTests}
        userActions={userActions}
        menuId={location?.state?.menuId}
        isView={isView}
        setIsView={setIsView}
        getPrintDetails={getPrintDetails}
        setIsWhatsApp={setIsWhatsApp}
        setIsEmail={setIsEmail}
      />
      <RadiologyQueueModal
        openQueueModal={openQueueModal}
        setOpenQueueModal={setOpenQueueModal}
        getUnit={getUnit}
      />
      <ConfirmationModal
        confirmationOpen={procedureConfirmation}
        confirmationHandleClose={() => {
          setProcedureConfirmation(false);
        }}
        confirmationSubmitFunc={startProcedure}
        confirmationLabel="Confirmation"
        confirmationMsg={
          forStatus === "Start Procedure"
            ? "Are you sure want to start the procedure ?"
            : "Are you sure want to end the procedure ?"
        }
        confirmationButtonMsg="Yes"
      />
      <ConfirmationModal
        confirmationOpen={cancelTestConfirmation}
        confirmationHandleClose={() => {
          setCancelTestConfirmation(false);
        }}
        confirmationSubmitFunc={forCancelTest}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure to cancel selected test ?"
        confirmationButtonMsg="Yes"
      />
      <ConfirmationModal
        confirmationOpen={cancelOrderConfirmation}
        confirmationHandleClose={() => {
          setCancelOrderConfirmation(false);
        }}
        confirmationSubmitFunc={forCancelOrder}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure to cancel selected work order ?"
        confirmationButtonMsg="Yes"
      />
      <ConfirmationModal
        confirmationOpen={openGenerateReport}
        confirmationHandleClose={() => {
          setOpenGenerateReport(false);
        }}
        confirmationSubmitFunc={generateRadioReport}
        confirmationLabel="Confirmation"
        confirmationMsg="Are you sure to generate selected report ?"
        confirmationButtonMsg="Yes"
      />
      <ConfirmationModal
        confirmationOpen={messageConfirmation}
        confirmationHandleClose={() => {
          setMessageConfirmation(false);
        }}
        confirmationSubmitFunc={isWhatsApp ? whatsAppPrint : sendToEmailPrint}
        confirmationLabel="Confirmation"
        confirmationMsg={`Are you sure want to send report on ${
          isWhatsApp ? "whatsapp" : "email"
        } ?`}
        confirmationButtonMsg="Yes"
      />
      {/*  {openPrintModal ? (
        <CommonPrintModal
          open={openPrintModal}
          setOpen={setOpenPrintModal}
          handleOpen={handleOpenPrintModal}
          handleClose={handleClosePrintModal}
          urlforPrint={urlforPrint}
        />
      ) : null}
      */}
      <Modal open={appointmentModalFlag}>
        <Box sx={ModalStyle}>
          <CancelPresentationIconButton
            onClick={() => {
              setAppointmentModaFlag(false);
              setNavigateData(null);
            }}
          />
          <AppointmentPage
            navigateData={navigateData}
            setNavigateData={setNavigateData}
            setAppointmentModaFlag={setAppointmentModaFlag}
          />
        </Box>
      </Modal>
      {messageConfirmation && (
        <div id="radiologyPrint">
          <RadiologyWorkOrderPrint
            selectedRow={selectedTestId}
            setOpenBackdrop={setOpenBackdrop}
            dataResult={printDetails}
            isPrint={isPrint}
            setIsPrint={setIsPrint}
            isWhatsApp={isWhatsApp}
            isEmail={isEmail}
            images={images}
            selectedRowIndex={selectedRowIndex}
            setSelectedRowIndex={setSelectedRowIndex}
          />
        </div>
      )}
      {messageConfirmation && (
        <div className="mt-96">
          <div id="radiologyPrintHeader">
            <thead
              className={`${
                isWhatsApp === false && isEmail === false ? "w-[100%]" : ""
              }`}
              style={{
                "-fs-table-paginate": "paginate",
              }}
            >
              {isWhatsApp === true || isEmail === true ? (
                <div className="flex items-center text-center h-16">
                  {(isWhatsApp === true || isEmail === true) && (
                    <div
                      className={`flex justify-start ${
                        isWhatsApp === true || isEmail === true ? "w-10" : ""
                      } `}
                    >
                      <RadioNABHLogo />
                    </div>
                  )}
                  <div className="text-center w-full">
                    <h1 className="text-[12px] font-semibold">
                      Inamdar Multispeciality Hospital
                    </h1>
                    <h1 className="text-[12px] font-semibold">
                      Sr. No 15, Fatimanagar, Behind Kpct Mall Pune - 411 040
                    </h1>
                    <h1 className="text-[12px] font-semibold">
                      Phone No : 020-66812222/44
                    </h1>
                  </div>
                  {(isWhatsApp === true || isEmail === true) && (
                    <div
                      className={`flex justify-end ${
                        isWhatsApp === true || isEmail === true ? "w-16" : ""
                      } `}
                    >
                      <InamdarHospiLogo />
                    </div>
                  )}
                </div>
              ) : (
                <div className="mt-32"></div>
              )}

              <hr className="border-t-[1px] border-black" />
              <div className="p-1 grid grid-cols-2">
                <div className="flex items-center">
                  <div className="text-[14px] font-semibold w-16">Date</div>
                  <div className="text-[14px]">:&nbsp;{printDetails?.Date}</div>
                </div>
                <div className="flex items-center">
                  <div className="text-[14px] font-semibold w-36">
                    Report Date & Time
                  </div>
                  <div className="text-[14px]">
                    :&nbsp;{printDetails?.ReportDate} {printDetails?.ReportTime}
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="text-[14px] font-semibold w-16">MRNo</div>
                  <div className="text-[14px]">:&nbsp;{printDetails?.MRNo}</div>
                </div>
                <div className="flex items-center">
                  <div className="text-[14px] font-semibold w-36">
                    Printed Date & Time
                  </div>
                  <div className="text-[14px]">
                    :&nbsp;{format(new Date(), "dd/MM/yyyy hh:mm aa")}
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="text-[14px] font-semibold w-16">Name</div>
                  <div className="text-[14px]">
                    :&nbsp;{printDetails?.PatientName}
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="text-[14px] font-semibold w-36">
                    Consultant
                  </div>
                  <div className="text-[14px]">
                    :&nbsp;{printDetails?.Consultant}
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="text-[14px] font-semibold w-16">Sex</div>
                  <div className="text-[14px]">
                    :&nbsp;{printDetails?.Gender}
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="text-[14px] font-semibold w-36">
                    Advised By
                  </div>
                  <div className="text-[14px]">
                    :&nbsp;{printDetails?.AdvisedBy}
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="text-[14px] font-semibold w-16">Bed No</div>
                  <div className="text-[14px]">
                    :&nbsp;{printDetails?.BedNo}
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="text-[14px] font-semibold w-36">Ward</div>
                  <div className="text-[14px]">:&nbsp;{printDetails?.Ward}</div>
                </div>
              </div>

              <div className="space-y-1">
                <hr className="border-t-[1px] border-black" />
                <div className="grid grid-cols-3 w-full -mt-4 text-[15px]">
                  {!selectedTestId?.IsCompleted ? (
                    <div className="text-customRed">Provisional Report</div>
                  ) : (
                    <div></div>
                  )}
                  <div className="col-span-2 font-semibold ml-10">
                    {printDetails?.TestName}
                  </div>
                </div>
                <hr className="border-t-[1px] border-black" />
              </div>
            </thead>
          </div>
        </div>
      )}
      <ANCSchedulerModal
        openANCSchedulerModal={openANCSchedulerModal}
        setOpenANCSchedulerModal={setOpenANCSchedulerModal}
        selectedTestId={selectedTestId}
        selectedRow1={selectedRow1}
        setSelectedTestId={setSelectedTestId}
        privilege={privilege}
        setPrivilege={setPrivilege}
        menuId={location?.state?.menuId}
        openBackdrop={openBackdrop}
        setOpenBackdrop={setOpenBackdrop}
      />
      <UploadFileViewModal
        fileSrc={viewPdfSrc}
        openImageModal={openViewPdf}
        setOpenImageModal={setOpenViewPdf}
      />
      {openPrintModal && renderPrint()}
      <LIMSHistoryModal
        openLIMSHistoryModal={openLIMSHistoryModal}
        setOpenLIMSHistoryModal={setOpenLIMSHistoryModal}
        radioHistoryData={radioHistoryData}
        patientInfo={selectedRow1}
        LIMSType={LIMSType}
      />
      <CommonBackDrop openBackdrop={openBackdrop} />
    </form>
  );
}

export default RadiologyWorkOrderView;
