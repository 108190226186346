import authHeader from "../../../../authentication/authservices/auth-header";
import apiClient from "../../../../http-common";

//drug name table
export const fetchDrugNameTable = (drugListObj) => {
  return apiClient.post(
    `/inventory/inPatientIssue/itemDetailsSearch`,
    drugListObj,
    {
      headers: authHeader(),
    }
  );
};

//Alternate drug name table
export const fetchAlternateDrugNameTable = (altDrugObj) => {
  return apiClient.post(
    `/inventory/inPatientIssue/itemDetailListByGenericId`,
    altDrugObj,
    {
      headers: authHeader(),
    }
  );
};

//Item Kit List
export const fetchKitList = (searchString) => {
  return apiClient.get(
    `/inventory/inPatientIssue/itemKitList/${searchString}`,
    {
      headers: authHeader(),
    }
  );
};

export const fetchKitListItems = (kitId, storeId) => {
  return apiClient.get(
    `/inventory/inPatientIssue/kitItems/${kitId}/${storeId}`,
    {
      headers: authHeader(),
    }
  );
};

//Item patient List for listing page
export const fetchPatientList = (searchString) => {
  return apiClient.get(`/patientInfo/admissionAutoComplete/${searchString}`, {
    headers: authHeader(),
  });
};

//Item patient Details
export const fetchPatientDetails = (patientId) => {
  return apiClient.get(`/patientInfo/transfer/${patientId}`, {
    headers: authHeader(),
  });
};

//Item List for listing page
export const fetchItemList = (searchItem) => {
  return apiClient.get(`/inventory/inPatientIssue/itemList/${searchItem}`, {
    headers: authHeader(),
  });
};

//Item List for listing page
export const fetchIpdIssueDetails = (ipdIssueId) => {
  return apiClient.get(
    `/inventory/inPatientIssue/ipdIssueDetailsList/${ipdIssueId}`,
    {
      headers: authHeader(),
    }
  );
};

//Item Table List for listing page
export const fetchItemListTableData = (IpdIssuelistObj) => {
  return apiClient.post(
    `/inventory/inPatientIssue/ipdIssueList`,
    IpdIssuelistObj,
    {
      headers: authHeader(),
    }
  );
};

//fetch Store Name
export const fetchStore = (cashCounterId) => {
  return apiClient.get(`/inventory/inPatientIssue/storeName/${cashCounterId}`, {
    headers: authHeader(),
  });
};

//fetch Batch Number
export const fetchBatchNumber = (BatchSelObj) => {
  return apiClient.post(
    `/inventory/inPatientIssue/itemBatchNumber`,
    BatchSelObj,
    {
      headers: authHeader(),
    }
  );
};

export const fetchBatchNumberForGrn = (itemId, storeId) => {
  return apiClient.get(`/grn/batchNumberForGRN/${itemId}/${storeId}`, {
    headers: authHeader(),
  });
};

//post finalobj
export const postInPatientIssueData = (finalObj) => {
  return apiClient.post(`/inventory/inPatientIssue/saveInvIPDIssue`, finalObj, {
    headers: authHeader(),
  });
};

//get indent
export const fetchIndentList = (indentObj) => {
  return apiClient.post(
    `/inventory/inPatientIssue/getPatientIndent`,
    indentObj,
    {
      headers: authHeader(),
    }
  );
};

//get indent items
export const fetchIndentItems = (storeId, indentId) => {
  return apiClient.get(
    `/inventory/inPatientIssue/patientIndentListOfItems/${storeId}/${indentId} `,
    {
      headers: authHeader(),
    }
  );
};

//autocomplete against selected indent
export const fetchPatientIndentListSearch = (autoCopmleteObj) => {
  return apiClient.post(
    `/inventory/inPatientIssue/getPatientIndentListSearch`,
    autoCopmleteObj,
    {
      headers: authHeader(),
    }
  );
};
//patient info against selected indent
export const fetchSelectedIndentPatientInfo = (patientId) => {
  return apiClient.get(`/patientInfo/transfer/${patientId}`, {
    headers: authHeader(),
  });
};

export const fetchItemKitData = (indentId, storeId) => {
  return apiClient.get(`/patientIndent/kitItems/${indentId}/${storeId}`, {
    headers: authHeader(),
  });
};

export const fetchPrintGetIndent = (indentId) => {
  return apiClient.get(
    `/reports/inventory/patientStoreIndent?indentId=${indentId}&patient=${false}`,
    {
      responseType: "blob",
      headers: authHeader(),
    }
  );
};

export const fetchPrintInPatientIssue = (ipdIssueId, storeId) => {
  return apiClient.get(
    `/reports/nursing/inPatientIssue/${ipdIssueId}/${storeId}`,
    {
      responseType: "blob",
      headers: authHeader(),
    }
  );
};

export const InPatientIssuePrintData = (ipdIssueId, Storeld) => {
  return apiClient.get(
    `/reports/nursing/inPatientIssue/${ipdIssueId}/${Storeld}`,
    {
      headers: authHeader(),
    }
  );
};
