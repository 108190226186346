import authHeader from "../../../../authentication/authservices/auth-header";
import apiClient from "../../../../http-common";

//IPD issue list
export const fetchIpdIssueListTable = (ipdIssueListObj) => {
  return apiClient.post(`/patientIndent/indentList`, ipdIssueListObj, {
    headers: authHeader(),
  });
};

//IPD issue list
export const fetchIndentItemDetailsList = (indentId) => {
  return apiClient.get(`/patientIndent/indentItemDetailsList/${indentId}`, {
    headers: authHeader(),
  });
};

// ordered Drug List
export const fetchOrderedDrugList = (admissionId) => {
  return apiClient.get(`/drugAdministrator/orderedDrugList/${admissionId}`, {
    headers: authHeader(),
  });
};

// Prescribed Drug List
export const fetchPrescribedDrugList = (prescriptionId) => {
  return apiClient.get(`/drugAdministrator/drugList/${prescriptionId}`, {
    headers: authHeader(),
  });
};

//drug name table
export const fetchDrugNameTable = (drugListObj) => {
  return apiClient.post(`/patientIndent/itemDetailsSearch`, drugListObj, {
    headers: authHeader(),
  });
};

//Alternate drug name table
export const fetchAlternateDrugNameTable = (altDrugObj) => {
  return apiClient.post(
    `/inventory/inPatientIssue/itemDetailListByGenericId`,
    altDrugObj,
    {
      headers: authHeader(),
    }
  );
};

//Alternate drug name table
export const postPatientIndent = (altDrugObj) => {
  return apiClient.post(`/patientIndent/savePatientIndent`, altDrugObj, {
    headers: authHeader(),
  });
};

//Alternate drug name table
export const fetchHospitalBalanceQty = (itemId) => {
  return apiClient.get(`/patientIndent/storeBalanceQty/${itemId}`, {
    headers: authHeader(),
  });
};

export const fetchPrintPatientIndentList = (indentId, patient) => {
  return apiClient.get(
    `/reports/inventory/patientStoreIndent?indentId=${indentId}&patient=${patient}`,
    {
      responseType: "blob",
      headers: authHeader(),
    }
  );
};
// /api/misc/referenceDoctor/a
export const fetchPrescribedBy = (searchString) => {
  return apiClient.get(`/misc/referenceDoctor/${searchString}`, {
    headers: authHeader(),
  });
};
//http://192.168.0.131:5002/api/patientIndent/getIndentNoAutoComplete
export const autoSearchIndent = (obj) => {
  return apiClient.post(`patientIndent/getIndentNoAutoComplete`, obj, {
    headers: authHeader(),
  });
};
