import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import CommonDynamicTableNew from "../../../common/components/CommonTable/CommonDynamicTableNew";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import {
  fetchPatientInfoAutoComplete,
  fetchVentilatorBundleDtls,
  fetchVentilatorBundleList,
  saveVentilatorBundle,
} from "../../services/ventilatorBundleServices/VentilatorBundleServices";
import CautiBundleChecklistTable from "./CautiBundleChecklistTable";
import CentralLineBundleChecklistTable from "./CentralLineBundleChecklistTable";
import VentilatorBubdleChecklistTable from "./VentilatorBubdleChecklistTable";
import { getPatientInfoData } from "../../services/cliinicalChartServices/ClinicalCareChartService";
import InputArea from "../../../common/components/FormFields/InputArea";

const defaultDataResult1 = [
  {
    "Day No": "",
    1: {},
    2: {},
    3: {},
    4: {},
    5: {},
    6: {},
    7: {},
  },
  {
    "Day No": "Head Of Te Bed @ 30",
    1: { M: false, E: false, N: false },
    2: { M: false, E: false, N: false },
    3: { M: false, E: false, N: false },
    4: { M: false, E: false, N: false },
    5: { M: false, E: false, N: false },
    6: { M: false, E: false, N: false },
    7: { M: false, E: false, N: false },
  },
  {
    "Day No": "Daily Sedative interruption daily assessment of readiness",
    1: { M: false, E: false, N: false },
    2: { M: false, E: false, N: false },
    3: { M: false, E: false, N: false },
    4: { M: false, E: false, N: false },
    5: { M: false, E: false, N: false },
    6: { M: false, E: false, N: false },
    7: { M: false, E: false, N: false },
  },
  {
    "Day No": "PUD Prophylaxis",
    1: { M: false, E: false, N: false },
    2: { M: false, E: false, N: false },
    3: { M: false, E: false, N: false },
    4: { M: false, E: false, N: false },
    5: { M: false, E: false, N: false },
    6: { M: false, E: false, N: false },
    7: { M: false, E: false, N: false },
  },
  {
    "Day No": "DVT Prophylaxis",
    1: { M: false, E: false, N: false },
    2: { M: false, E: false, N: false },
    3: { M: false, E: false, N: false },
    4: { M: false, E: false, N: false },
    5: { M: false, E: false, N: false },
    6: { M: false, E: false, N: false },
    7: { M: false, E: false, N: false },
  },
  {
    "Day No": "Weaning Protocol",
    1: { M: false, E: false, N: false },
    2: { M: false, E: false, N: false },
    3: { M: false, E: false, N: false },
    4: { M: false, E: false, N: false },
    5: { M: false, E: false, N: false },
    6: { M: false, E: false, N: false },
    7: { M: false, E: false, N: false },
  },
  {
    "Day No": "Oral Care",
    1: { M: false, E: false, N: false },
    2: { M: false, E: false, N: false },
    3: { M: false, E: false, N: false },
    4: { M: false, E: false, N: false },
    5: { M: false, E: false, N: false },
    6: { M: false, E: false, N: false },
    7: { M: false, E: false, N: false },
  },
  {
    "Day No": "FIO2",
    1: { M: false, E: false, N: false },
    2: { M: false, E: false, N: false },
    3: { M: false, E: false, N: false },
    4: { M: false, E: false, N: false },
    5: { M: false, E: false, N: false },
    6: { M: false, E: false, N: false },
    7: { M: false, E: false, N: false },
  },
  {
    "Day No": "PEER",
    1: { M: false, E: false, N: false },
    2: { M: false, E: false, N: false },
    3: { M: false, E: false, N: false },
    4: { M: false, E: false, N: false },
    5: { M: false, E: false, N: false },
    6: { M: false, E: false, N: false },
    7: { M: false, E: false, N: false },
  },
];
const defaultDataResult2 = [
  {
    "Day No": "",
    1: {},
    2: {},
    3: {},
    4: {},
    5: {},
    6: {},
    7: {},
  },
  {
    "Day No": "Catheter Property Secured",
    1: { M: false, E: false, N: false },
    2: { M: false, E: false, N: false },
    3: { M: false, E: false, N: false },
    4: { M: false, E: false, N: false },
    5: { M: false, E: false, N: false },
    6: { M: false, E: false, N: false },
    7: { M: false, E: false, N: false },
  },
  {
    "Day No": "Collection Bag Below The Level Of Bladder",
    1: { M: false, E: false, N: false },
    2: { M: false, E: false, N: false },
    3: { M: false, E: false, N: false },
    4: { M: false, E: false, N: false },
    5: { M: false, E: false, N: false },
    6: { M: false, E: false, N: false },
    7: { M: false, E: false, N: false },
  },
  {
    "Day No": "Unobstructed Urine Flow",
    1: { M: false, E: false, N: false },
    2: { M: false, E: false, N: false },
    3: { M: false, E: false, N: false },
    4: { M: false, E: false, N: false },
    5: { M: false, E: false, N: false },
    6: { M: false, E: false, N: false },
    7: { M: false, E: false, N: false },
  },
  {
    "Day No": "Empty Collection Bag Regularly",
    1: { M: false, E: false, N: false },
    2: { M: false, E: false, N: false },
    3: { M: false, E: false, N: false },
    4: { M: false, E: false, N: false },
    5: { M: false, E: false, N: false },
    6: { M: false, E: false, N: false },
    7: { M: false, E: false, N: false },
  },
  {
    "Day No": "Maintain A sterline continuosly closed drainage system",
    1: { M: false, E: false, N: false },
    2: { M: false, E: false, N: false },
    3: { M: false, E: false, N: false },
    4: { M: false, E: false, N: false },
    5: { M: false, E: false, N: false },
    6: { M: false, E: false, N: false },
    7: { M: false, E: false, N: false },
  },
  {
    "Day No": "Daily Perianal Care",
    1: { M: false, E: false, N: false },
    2: { M: false, E: false, N: false },
    3: { M: false, E: false, N: false },
    4: { M: false, E: false, N: false },
    5: { M: false, E: false, N: false },
    6: { M: false, E: false, N: false },
    7: { M: false, E: false, N: false },
  },
  {
    "Day No": "Screening Patients For Signs And Systems",
    1: { M: false, E: false, N: false },
    2: { M: false, E: false, N: false },
    3: { M: false, E: false, N: false },
    4: { M: false, E: false, N: false },
    5: { M: false, E: false, N: false },
    6: { M: false, E: false, N: false },
    7: { M: false, E: false, N: false },
  },
  {
    "Day No": "Culture sent Yes/No (If Required)",
    1: { M: false, E: false, N: false },
    2: { M: false, E: false, N: false },
    3: { M: false, E: false, N: false },
    4: { M: false, E: false, N: false },
    5: { M: false, E: false, N: false },
    6: { M: false, E: false, N: false },
    7: { M: false, E: false, N: false },
  },
];
const defaultDataResult3 = [
  {
    "Day No": "",
    1: {},
    2: {},
    3: {},
    4: {},
    5: {},
    6: {},
    7: {},
  },
  {
    "Day No": "Hand Hygeine",
    1: { M: false, E: false, N: false },
    2: { M: false, E: false, N: false },
    3: { M: false, E: false, N: false },
    4: { M: false, E: false, N: false },
    5: { M: false, E: false, N: false },
    6: { M: false, E: false, N: false },
    7: { M: false, E: false, N: false },
  },
  {
    "Day No": "Maximal Barrier Precautions",
    1: { M: false, E: false, N: false },
    2: { M: false, E: false, N: false },
    3: { M: false, E: false, N: false },
    4: { M: false, E: false, N: false },
    5: { M: false, E: false, N: false },
    6: { M: false, E: false, N: false },
    7: { M: false, E: false, N: false },
  },
  {
    "Day No": "Clorthexidine Skin antisepsis",
    1: { M: false, E: false, N: false },
    2: { M: false, E: false, N: false },
    3: { M: false, E: false, N: false },
    4: { M: false, E: false, N: false },
    5: { M: false, E: false, N: false },
    6: { M: false, E: false, N: false },
    7: { M: false, E: false, N: false },
  },
  {
    "Day No": "Optimal Cleateral Site Cleaning port with Chorhexidine",
    1: { M: false, E: false, N: false },
    2: { M: false, E: false, N: false },
    3: { M: false, E: false, N: false },
    4: { M: false, E: false, N: false },
    5: { M: false, E: false, N: false },
    6: { M: false, E: false, N: false },
    7: { M: false, E: false, N: false },
  },
  {
    "Day No":
      "Daily Revives of Central Line Necessity with Promt Removal Of Unneceesary",
    1: { M: false, E: false, N: false },
    2: { M: false, E: false, N: false },
    3: { M: false, E: false, N: false },
    4: { M: false, E: false, N: false },
    5: { M: false, E: false, N: false },
    6: { M: false, E: false, N: false },
    7: { M: false, E: false, N: false },
  },
  // {
  //   "Day No": "Daily Perianal Care",
  //   1: { M: false, E: false, N: false },
  //   2: { M: false, E: false, N: false },
  //   3: { M: false, E: false, N: false },
  //   4: { M: false, E: false, N: false },
  //   5: { M: false, E: false, N: false },
  //   6: { M: false, E: false, N: false },
  //   7: { M: false, E: false, N: false },
  // },
  {
    "Day No": "Screening Patients For Signs And Systems",
    1: { M: false, E: false, N: false },
    2: { M: false, E: false, N: false },
    3: { M: false, E: false, N: false },
    4: { M: false, E: false, N: false },
    5: { M: false, E: false, N: false },
    6: { M: false, E: false, N: false },
    7: { M: false, E: false, N: false },
  },
  {
    "Day No": "Change Of IV Withine 24 hrs",
    1: { M: false, E: false, N: false },
    2: { M: false, E: false, N: false },
    3: { M: false, E: false, N: false },
    4: { M: false, E: false, N: false },
    5: { M: false, E: false, N: false },
    6: { M: false, E: false, N: false },
    7: { M: false, E: false, N: false },
  },
  {
    "Day No": "Culture sent Yes/No (If Required)",
    1: { M: false, E: false, N: false },
    2: { M: false, E: false, N: false },
    3: { M: false, E: false, N: false },
    4: { M: false, E: false, N: false },
    5: { M: false, E: false, N: false },
    6: { M: false, E: false, N: false },
    7: { M: false, E: false, N: false },
  },
];

const VentilatorBundle = () => {
  //
  let location = useLocation();
  
  //
  const [userActions, setUserActions] = useState([]);
  const [privilege, setPrivilege] = React.useState("");

  //
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [ventilatorBundleList, setVentilatorBundleList] = useState([]);

  const [checkListTabledata, setCheckListTabledata] = useState([]);
  const [cautiListTabledata, setCautiListTabledata] = useState([]);
  const [centralLineTabledata, setCentralLineTabledata] = useState([]);
  //
  const [vapIdForFnlObj, setVapIdForFnlObj] = useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      patientSearch: "",
      address: "",
      remarkDtls: "",
    },
  });

  const { control, watch, register, setValue } = methods;

  const Remark = watch("remarkDtls");

  ///get User Action
  useEffect(() => {
    location?.state?.menuId &&
      fetchUserActionsByMenuId(location?.state?.menuId)
        .then((response) => response.data)
        .then((res) => {
          setUserActions(res.result);
        });

    setCheckListTabledata(structuredClone(defaultDataResult1));
    setCautiListTabledata(structuredClone(defaultDataResult2));
    setCentralLineTabledata(structuredClone(defaultDataResult3));
  }, []);

  const getPatientInfoAutoComplete = (serchString) => {
    serchString &&
      fetchPatientInfoAutoComplete(serchString)
        .then((response) => response.data)
        .then((res) => {
          setAutocompleteData(res.result);
        });
  };
  const handleSelectedPatient = (ptInfo) => {
    ptInfo?.AdmissionId &&
      getPatientInfoData(ptInfo?.AdmissionId)
        .then((response) => response.data)
        .then((res) => {
          setAutocompleteData(res.result);
          setSelectedPatient(res.result);
          setValue("address", res.result.Address);
        });
  };

  const getVentilatorBundleList = (admissionId) => {
    admissionId &&
      fetchVentilatorBundleList(admissionId)
        .then((response) => response.data)
        .then((res) => {
          setVentilatorBundleList(res.result);
        });
  };

  const getVentilatorBundleDtls = (vapId) => {
    ////////
    setVapIdForFnlObj(vapId);
    vapId &&
      fetchVentilatorBundleDtls(vapId)
        .then((response) => response.data)
        .then((res) => {
          //
          setValue("remarkDtls", res?.result?.remark);
          ///////////////////////

          ///////////////////////////////////////////////////////////
          const patchdata1 = res?.result?.ventilatorBundleDetailsDtoList;
          const patchdata2 = res?.result?.ventilatorBundleCautiDtoList;
          const patchdata3 = res?.result?.ventilatorBundleCentralDtoList;

          function convertKeys(dataArray, keyMap) {
            return dataArray.map((item) => {
              const newItem = {};
              for (const key in item) {
                if (key in keyMap) {
                  newItem[keyMap[key]] = item[key];
                } else {
                  newItem[key] = item[key];
                }
              }
              return newItem;
            });
          }

          //////patch for ventilator Bundle Details
          const keyMap1 = {
            Headbed: "Head Of Te Bed @ 30",
            dailly_sedative:
              "Daily Sedative interruption daily assessment of readiness",
            PUD: "PUD Prophylaxis",
            DVT: "DVT Prophylaxis",
            Weanin: "Weaning Protocol",
            Oral: "Oral Care",
            FIO2: "FIO2",
            PEEP: "PEER",
          };
          // Call the function to convert keys
          const convertedPatchdata1 = convertKeys(patchdata1, keyMap1);
          let temp1 = [...checkListTabledata];
          for (let item of temp1) {
            convertedPatchdata1.map((key) => {
              if (key[item["Day No"]]) {
                if (item[Number(key["dy"])]) {
                  item[Number(key["dy"])][key["dy_Time"]] = true;
                }
              } else {
                if (item[Number(key["dy"])]) {
                  item[Number(key["dy"])][key["dy_Time"]] = false;
                }
              }
            });
          }
          setCheckListTabledata(temp1);
          //////////////ventilator Bundle Cauti
          const keyMap2 = {
            Catheter: "Catheter Property Secured",
            collection: "Collection Bag Below The Level Of Bladder",
            culture: "Culture sent Yes/No (If Required)",
            dailyperianal: "Daily Perianal Care",
            emptycollection: "Empty Collection Bag Regularly",
            maintain: "Maintain A sterline continuosly closed drainage system",
            screening: "Screening Patients For Signs And Systems",
            unobstruct: "Unobstructed Urine Flow",
          };
          const convertedPatchdata2 = convertKeys(patchdata2, keyMap2);
          let temp2 = [...cautiListTabledata];
          for (let item of temp2) {
            convertedPatchdata2.map((key) => {
              if (key[item["Day No"]]) {
                if (item[Number(key["dy"])]) {
                  item[Number(key["dy"])][key["dy_Time"]] = true;
                }
              }
            });
          }
          setCautiListTabledata([...temp2]);

          //////////////ventilator Bundle Central
          const keyMap3 = {
            changeiv: "Change Of IV Withine 24 hrs",
            chl_skin: "Clorthexidine Skin antisepsis",
            culture: "Culture sent Yes/No (If Required)",
            dailyreview:
              "Daily Revives of Central Line Necessity with Promt Removal Of Unneceesary",
            Hand: "Hand Hygeine",
            maximal: "Maximal Barrier Precautions",
            optimal: "Optimal Cleateral Site Cleaning port with Chorhexidine",
            screening: "Screening Patients For Signs And Systems",
          };
          const convertedPatchdata3 = convertKeys(patchdata3, keyMap3);
          let temp3 = [...centralLineTabledata];
          for (let item of temp3) {
            convertedPatchdata3.map((key) => {
              if (key[item["Day No"]]) {
                if (item[Number(key["dy"])]) {
                  item[Number(key["dy"])][key["dy_Time"]] = true;
                }
              }
            });
          }
          setCentralLineTabledata([...temp3]);
        });
  };

  //
  const handleSelectedRow = (row) => {
    ///
    setCheckListTabledata(structuredClone(defaultDataResult1));
    setCautiListTabledata(structuredClone(defaultDataResult2));
    setCentralLineTabledata(structuredClone(defaultDataResult3));
    ///

    getVentilatorBundleDtls(row?.vapId);
  };

  /////
  function mergeAndReplace(objects) {
    const merged = {};

    for (const obj of objects) {
      const key = `${obj.dy}_${obj.dyTime}`;

      if (!merged[key]) {
        merged[key] = { ...obj };
      } else {
        for (const prop in obj) {
          if (obj.hasOwnProperty(prop) && obj[prop] === true) {
            merged[key][prop] = true;
          }
        }
      }
    }

    return Object.values(merged);
  }
  //
  const onsubmit = () => {
    
    if (selectedPatient !== null) {
      setOpenConfirmationModal(true);
    } else {
      warningAlert("Please Select Patient...");
    }
  };

  const handleFormSubmit = () => {
    // setOpenBackdrop(true);
    setOpenConfirmationModal(false);

    //
    // Function to convert "one" to "1", "two" to "2", etc.
    function convertToNumber(key) {
      const keyMap = {
        one: "1",
        two: "2",
        three: "3",
        four: "4",
        five: "5",
        six: "6",
        seven: "7",
      };

      return keyMap[key] || key;
    }

    ////// 1.ventilatorBundleDetailsDtoList
    const temp1 = [...checkListTabledata];

    let arr1 = [];
    for (let item of temp1) {
      

      let key = item["Day No"];
      let one = item["1"];
      let two = item["2"];
      let three = item["3"];
      let four = item["4"];
      let five = item["5"];
      let six = item["6"];
      let seven = item["7"];

      arr1.push({ key, one, two, three, four, five, six, seven });
    }

    
    const filteredData1 = arr1.filter((item) => {
      return Object.values(item).some((obj) =>
        Object.values(obj).some((val) => val === true)
      );
    });
    //

    

    const commonDate = new Date();

    const restructuredData1 = [];

    filteredData1.forEach((item) => {
      for (const key in item) {
        if (key !== "key" && (item[key].M || item[key].N || item[key].E)) {
          if (item[key].M) {
            const newObj = createObject1(item.key, key, "M");
            restructuredData1.push(newObj);
          }
          if (item[key].N) {
            const newObj = createObject1(item.key, key, "N");
            restructuredData1.push(newObj);
          }
          if (item[key].E) {
            const newObj = createObject1(item.key, key, "E");
            restructuredData1.push(newObj);
          }
        }
      }
    });

    

    // Function to create an object
    function createObject1(key, dy, dyTime) {
      return {
        date: commonDate,
        "Head Of Te Bed @ 30": key === "Head Of Te Bed @ 30",
        "Daily Sedative interruption daily assessment of readiness":
          key === "Daily Sedative interruption daily assessment of readiness",
        "PUD Prophylaxis": key === "PUD Prophylaxis",
        "DVT Prophylaxis": key === "DVT Prophylaxis",
        "Weaning Protocol": key === "Weaning Protocol",
        "Oral Care": key === "Oral Care",
        FIO2: key === "FIO2",
        PEER: key === "PEER",
        dy: dy,
        dyTime: dyTime,
        id: 0,
      };
    }

    const mergedDataRestructuredData1 = mergeAndReplace(restructuredData1);

    let ventilatorBundleDetailsDtoList = [];

    for (let object of mergedDataRestructuredData1) {
      let obj = {
        dailly_sedative:
          object["Daily Sedative interruption daily assessment of readiness"],
        date: object.date,
        dvt: object["DVT Prophylaxis"],
        dy: Number(convertToNumber(object.dy)),
        dyTime: object.dyTime,
        fiO2: object.FIO2,
        headbed: object["Head Of Te Bed @ 30"],
        id: 0,
        oral: object["Oral Care"],
        peep: object.PEER,
        pud: object["PUD Prophylaxis"],
        weanin: object["Weaning Protocol"],
      };
      ventilatorBundleDetailsDtoList.push(obj);
    }

    /////////// 2.ventilatorBundleCautiDtoList
    const temp2 = [...cautiListTabledata];

    let arr2 = [];
    for (let item of temp2) {
      

      let key = item["Day No"];
      let one = item["1"];
      let two = item["2"];
      let three = item["3"];
      let four = item["4"];
      let five = item["5"];
      let six = item["6"];
      let seven = item["7"];

      arr2.push({ key, one, two, three, four, five, six, seven });
    }

    
    const filteredData2 = arr2.filter((item) => {
      return Object.values(item).some((obj) =>
        Object.values(obj).some((val) => val === true)
      );
    });
    //

    

    const restructuredData2 = [];

    filteredData2.forEach((item) => {
      for (const key in item) {
        if (key !== "key" && (item[key].M || item[key].N || item[key].E)) {
          if (item[key].M) {
            const newObj = createObject2(item.key, key, "M");
            restructuredData2.push(newObj);
          }
          if (item[key].N) {
            const newObj = createObject2(item.key, key, "N");
            restructuredData2.push(newObj);
          }
          if (item[key].E) {
            const newObj = createObject2(item.key, key, "E");
            restructuredData2.push(newObj);
          }
        }
      }
    });

    

    // Function to create an object
    function createObject2(key, dy, dyTime) {
      return {
        date: commonDate,
        "Catheter Property Secured": key === "Catheter Property Secured",
        "Collection Bag Below The Level Of Bladder":
          key === "Collection Bag Below The Level Of Bladder",
        "Unobstructed Urine Flow": key === "Unobstructed Urine Flow",
        "Empty Collection Bag Regularly":
          key === "Empty Collection Bag Regularly",
        "Maintain A sterline continuosly closed drainage system":
          key === "Maintain A sterline continuosly closed drainage system",
        "Daily Perianal Care": key === "Daily Perianal Care",
        "Screening Patients For Signs And Systems":
          key === "Screening Patients For Signs And Systems",
        "Culture sent Yes/No (If Required)":
          key === "Culture sent Yes/No (If Required)",
        dy: dy,
        dyTime: dyTime,
        id: 0,
      };
    }

    const mergedDataRestructuredData2 = mergeAndReplace(restructuredData2);

    let ventilatorBundleCautiDtoList = [];

    for (let object of mergedDataRestructuredData2) {
      let obj = {
        catheter: object["Catheter Property Secured"],
        collection: object["Collection Bag Below The Level Of Bladder"],
        culture: object["Culture sent Yes/No (If Required)"],
        dailyPerianal: object["Daily Perianal Care"],
        date: object.date,
        dy: Number(convertToNumber(object.dy)),
        dyTime: object.dyTime,
        emptyCollection: object["Empty Collection Bag Regularly"],
        id: 0,
        maintain:
          object["Maintain A sterline continuosly closed drainage system"],
        screening: object["Screening Patients For Signs And Systems"],
        unObstruct: object["Unobstructed Urine Flow"],
      };
      ventilatorBundleCautiDtoList.push(obj);
    }

    /////////// 3.ventilatorBundleCautiDtoList
    const temp3 = [...centralLineTabledata];

    let arr3 = [];
    for (let item of temp3) {
      

      let key = item["Day No"];
      let one = item["1"];
      let two = item["2"];
      let three = item["3"];
      let four = item["4"];
      let five = item["5"];
      let six = item["6"];
      let seven = item["7"];

      arr3.push({ key, one, two, three, four, five, six, seven });
    }

    
    const filteredData3 = arr3.filter((item) => {
      return Object.values(item).some((obj) =>
        Object.values(obj).some((val) => val === true)
      );
    });
    //

    

    const restructuredData3 = [];

    filteredData3.forEach((item) => {
      for (const key in item) {
        if (key !== "key" && (item[key].M || item[key].N || item[key].E)) {
          if (item[key].M) {
            const newObj = createObject3(item.key, key, "M");
            restructuredData3.push(newObj);
          }
          if (item[key].N) {
            const newObj = createObject3(item.key, key, "N");
            restructuredData3.push(newObj);
          }
          if (item[key].E) {
            const newObj = createObject3(item.key, key, "E");
            restructuredData3.push(newObj);
          }
        }
      }
    });

    

    // Function to create an object
    function createObject3(key, dy, dyTime) {
      return {
        date: commonDate,
        "Hand Hygeine": key === "Hand Hygeine",
        "Maximal Barrier Precautions": key === "Maximal Barrier Precautions",
        "Clorthexidine Skin antisepsis":
          key === "Clorthexidine Skin antisepsis",
        "Optimal Cleateral Site Cleaning port with Chorhexidine":
          key === "Optimal Cleateral Site Cleaning port with Chorhexidine",
        "Daily Revives of Central Line Necessity with Promt Removal Of Unneceesary":
          key ===
          "Daily Revives of Central Line Necessity with Promt Removal Of Unneceesary",
        "Daily Perianal Care": key === "Daily Perianal Care",
        "Screening Patients For Signs And Systems":
          key === "Screening Patients For Signs And Systems",
        "Change Of IV Withine 24 hrs": key === "Change Of IV Withine 24 hrs",
        "Culture sent Yes/No (If Required)":
          key === "Culture sent Yes/No (If Required)",
        dy: dy,
        dyTime: dyTime,
        id: 0,
      };
    }

    const mergedDataRestructuredData3 = mergeAndReplace(restructuredData3);

    let ventilatorBundleCentralDtoList = [];

    for (let object of mergedDataRestructuredData3) {
      let obj = {
        changeIv: object["Change Of IV Withine 24 hrs"],
        chl_skin: object["Clorthexidine Skin antisepsis"],
        culture: object["Culture sent Yes/No (If Required)"],
        dailyReview:
          object[
            "Daily Revives of Central Line Necessity with Promt Removal Of Unneceesary"
          ],
        date: "2023-09-02T04:19:15.257Z",
        dy: Number(convertToNumber(object.dy)),
        dyTime: object.dyTime,

        hand: object["Hand Hygeine"],
        id: 0,
        maximal: object["Maximal Barrier Precautions"],
        optimal:
          object["Optimal Cleateral Site Cleaning port with Chorhexidine"],
        screening: object["Screening Patients For Signs And Systems"],
      };
      ventilatorBundleCentralDtoList.push(obj);
    }

    //////////

    const finalObj = {
      admissionId: selectedPatient?.AdmissionId,
      menuId: location?.state?.menuId,
      privilege: privilege,
      remark: Remark,
      vapId: vapIdForFnlObj ? vapIdForFnlObj : 0,
      ventilatorBundleCautiDtoList: ventilatorBundleCautiDtoList,
      ventilatorBundleCentralDtoList: ventilatorBundleCentralDtoList,
      ventilatorBundleDetailsDtoList: ventilatorBundleDetailsDtoList,
    };

    saveVentilatorBundle(finalObj)
      .then((response) => response.data)
      .then((res) => {
        
        if (res.statusCode === 200) {
          successAlert(res.message);
          setOpenBackdrop(false);
          setOpenConfirmationModal(false);
          ///
          setCheckListTabledata(structuredClone(defaultDataResult1));
          setCautiListTabledata(structuredClone(defaultDataResult2));
          setCentralLineTabledata(structuredClone(defaultDataResult3));
          //
          getVentilatorBundleList(selectedPatient?.AdmissionId);
          setValue("remarkDtls", "");
          // setSelectedPatient("");
          // setVentilatorBundleList([]);
          // methods.reset();
        }
      })
      .catch((res) => {
        errorAlert(res.message);
        setOpenConfirmationModal(false);
        setOpenBackdrop(false);
      });
  };

  // 

  useEffect(() => {
    
   
  }, [checkListTabledata, cautiListTabledata, centralLineTabledata]);

  return (
    <div className="mt-12">
      {/* //heading// */}
      <div className="text-center text-black font-bold text-xl ">
        Care Bundle Checklist
      </div>
      {/* search */}
      <div className=" py-2 grid grid-cols-3">
        <div className="md:col-span-2 lg:col-span-1">
          <SearchDropdown
            control={control}
            placeholder="Search By Patient Name / MR.No./ Mob No."
            dataArray={autocompleteData}
            name="patientSearch"
            searchIcon={true}
            isClearable={true}
            handleInputChange={getPatientInfoAutoComplete}
            //   isDisabled={isDisableSearch}
            inputRef={{
              ...register("patientSearch", {
                onChange: (e) => {
                  
                  if (e.target.value !== null) {
                    handleSelectedPatient(e.target.value);

                    getVentilatorBundleList(e?.target?.value?.AdmissionId);
                  } else {
                    ///
                    setCheckListTabledata(structuredClone(defaultDataResult1));
                    setCautiListTabledata(structuredClone(defaultDataResult2));
                    setCentralLineTabledata(
                      structuredClone(defaultDataResult3)
                    );
                    //
                    setSelectedPatient(null);
                    setVentilatorBundleList([]);
                    methods.reset();
                  }
                },
              }),
            }}
          />
        </div>
      </div>

      {/* patientInfo */}
      <fieldset
        className="border border-gray-300 col-span-3 w-full
               text-left lg:px-2 md:px-5 rounded bg-gray-100 p-2  "
      >
        <div className="py-2 grid  grid-cols-2 2xl:grid-cols-4 gap-2 p-3 ">
          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
            <span className=" font-semibold w-28">Patient Name</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                {selectedPatient && selectedPatient.PatientName}
              </span>
            </div>
          </div>

          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
            <span className=" font-semibold w-28">MR. No.</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                {selectedPatient && selectedPatient.MRNo}
              </span>
            </div>
          </div>

          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
            <span className=" font-semibold w-28">IPD No.</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                {selectedPatient && selectedPatient.IpdNo}
              </span>
            </div>
          </div>

          <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm ">
            <span className=" font-semibold w-28">Age</span>
            <div className="flex space-x-2 items-center">
              <span>:</span>
              <span className="text-gray-700 font-normal">
                {selectedPatient && selectedPatient.AgeYear}
              </span>
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset
        disabled={selectedPatient !== null ? false : true}
        onClick={() => {
          selectedPatient === null && warningAlert("Please Select Patiient...");
        }}
      >
        <div className="grid grid-cols-2 gap-3 mt-3">
          <div className=" ">
            <InputArea
              control={control}
              name="address"
              label="Address"
              placeholder="Address"
            />
          </div>
          <div className=" ">
            <InputArea
              control={control}
              name="remarkDtls"
              label="Remark"
              placeholder="Remark"
            />
           
          </div>
        </div>

        <div>
          {ventilatorBundleList.length > 0 ? (
            <CommonDynamicTableNew
              dataResult={ventilatorBundleList}
              handleSelectedRow={handleSelectedRow}
              removeHeaders={["vapId", "Remark"]}
            />
          ) : (
            <h3 className="flex justify-center mt-20 font-bold text-gray-600">
              No Records Found...
            </h3>
          )}
        </div>

        <div className=" text-black font-bold text-base ">
          Ventilator Bundle Checklist
        </div>

        <div>
          {checkListTabledata.length > 0 && (
            <VentilatorBubdleChecklistTable
              dataResult={checkListTabledata}
              setDataResult={setCheckListTabledata}
            />
          )}
        </div>

        <div className=" text-black font-bold text-base ">
          Cauti Bundle Checklist
        </div>

        <div>
          {cautiListTabledata.length > 0 && (
            <CautiBundleChecklistTable
              dataResult={cautiListTabledata}
              setDataResult={setCautiListTabledata}
            />
          )}
        </div>

        <div className=" text-black font-bold text-base ">
          Central line Bundle Checklist
        </div>
        <div>
          {centralLineTabledata.length > 0 && (
            <CentralLineBundleChecklistTable
              dataResult={centralLineTabledata}
              setDataResult={setCentralLineTabledata}
            />
          )}
        </div>
      </fieldset>

      <div className="md:col-span-1 lg:col-span-2 flex justify-end">
        {userActions.map((obj) => (
          <>
            {!obj.isAction && obj.action === "Create" ? (
              <>
                <div className="flex justify-end gap-2 mb-3">
                  <div>
                    <CommonButton
                      label="Reset"
                      className="border border-customRed text-customRed"
                      onClick={() => {
                        ///
                        setCheckListTabledata(
                          structuredClone(defaultDataResult1)
                        );
                        setCautiListTabledata(
                          structuredClone(defaultDataResult2)
                        );
                        setCentralLineTabledata(
                          structuredClone(defaultDataResult3)
                        );
                        // //

                        setSelectedPatient(null);
                        setVentilatorBundleList([]);
                        methods.reset();
                      }}
                    />
                  </div>
                  <div>
                    <CommonButton
                      label="New"
                      className="bg-customOrange text-white"
                      onClick={() => {
                        ///
                        setCheckListTabledata(
                          structuredClone(defaultDataResult1)
                        );
                        setCautiListTabledata(
                          structuredClone(defaultDataResult2)
                        );
                        setCentralLineTabledata(
                          structuredClone(defaultDataResult3)
                        );
                      }}
                    />
                  </div>
                  <div>
                    <CommonButton
                      onClick={() => {
                        onsubmit();
                        setPrivilege(obj?.action);
                      }}
                      label="Save"
                      className="bg-customGreen text-white"
                    />
                  </div>
                </div>
              </>
            ) : null}
          </>
        ))}
      </div>

      {/* backdrop */}
      <CommonBackDrop openBackdrop={openBackdrop} />
      {/* //Confirmation Modal// */}
      <ConfirmationModal
        confirmationOpen={openConfirmationModal}
        confirmationHandleClose={() => {
          setOpenConfirmationModal(false);
        }}
        confirmationSubmitFunc={() => handleFormSubmit()}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </div>
  );
};

export default VentilatorBundle;
