import React, { useEffect, useRef } from "react";
import { InamdarHospiLogo } from "../../../assets/printAssets/InmadarHospiLogo";
import { GeneratePrint } from "../../../common/components/printModal/GeneratePrint";

export default function NarcoticDrugPrint(props) {
  const [printCount, setPrintCount] = React.useState(0);
  const printRef = useRef();
  useEffect(() => {
    if (props.editRow && props.listing.length > 0 && printCount === 0) {
      props.listing && printRef && GeneratePrint(printRef);
    }
  }, [props.listing, props.editRow, printCount]);

  window.addEventListener("beforeprint", (event) => {
    setPrintCount(printCount + 1);
    props.setListing([]);
    props.setEditRow();
  });

  return (
    <div ref={printRef} className="ml-20 mr-14">
      <div className="w-full grid grid-cols-12 mt-12 items-center text-black">
        <div className="col-span-10">
          <h6 className="font-bold text-xl  text-center py-2 ">
            NARCOTIC DRUG & PSHYCHOTROPIC SUBSTANCES PRESCRIPTION (NDPS- II)
          </h6>
        </div>

        <div className=" flex justify-end gap-2  py-2 col-span-2">
          <InamdarHospiLogo />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-2 text-start ml-1 -pr-4  border-t-[1px]  border-b-[1px] border-black py-3">
        <div className="flex items-center">
          <p className="text-lg font-semibold gap-4 w-28">Patient Name</p>
          <p className="text-lg w-4">:</p>
          <p className="text-lg">{props.editRow?.PatientName} </p>
        </div>

        <div className="flex items-center">
          <p className="text-lg font-semibold gap-4 w-28">MR No</p>
          <p className="text-lg w-4">:</p>
          <p className="text-lg">{props.editRow?.MRNo} </p>
        </div>

        <div className="flex items-center">
          <p className="text-lg font-semibold gap-4 w-28">Age</p>
          <p className="text-lg w-4">:</p>
          <p className="text-lg">{props.editRow?.Age} </p>
        </div>
        <div className="flex items-center">
          <p className="text-lg font-semibold gap-4 w-28">Gender</p>
          <p className="text-lg w-4">:</p>
          <p className="text-lg">{props.editRow?.Gender} </p>
        </div>
        <div className="flex items-center">
          <p className="text-lg font-semibold gap-4 w-28">Patient Address</p>
          <p className="text-lg w-4">:</p>
          <p className="text-lg">{props.editRow?.Address} </p>
        </div>
      </div>
      <div className="w-full flex py-2 space-x-3">
        <div className="w-[10%] font-semibold text-lg">Diagnosis : </div>

        <div className="w-[90%] text-lg">{props.editRow?.Diagnosis}</div>
      </div>
      <div className="w-full flex my-3">
        <div className="w-[5%] font-semibold text-lg">Note-</div>
        <div className="w-[90%] font-semibold text-lg">
          AS PER GAZETTE OF INDIA NO.285 EXTRAORDINARY-II (SECTION-3) PG NO- 21,
          CLAUSE 52G IT IS MANDATORY THAT THE PRESCRIPTION SHALL BE IN WRITING,
          DATED AND SIGNED BY THE PRACTITIONER WITH HIS FULL NAME, ADDRESS AND
          REGISTRATION NUMBER.
        </div>
      </div>
      <div>
        <table className="w-full text-lg  ">
          <thead className=" border-t-[1px] border-black border-b-[1px] ">
            <tr className="border border-black text-start ">
              <th className="border border-black w-5/12">Drug Name</th>
              <th className="border border-black w-2/12">Frequency</th>
              <th className="border border-black w-1/12">Qty</th>
              <th className="border border-black w-2/12">Duration</th>
              <th className="border border-black w-2/12">Dose</th>
            </tr>
          </thead>
          <tbody className="text-center pt-[3px]">
            {/* {data.map((row) => (
              <tr key={row.id}>
                {Object.values(row).map((value, index) => (
                  <td key={index}>{value}</td>
                  // {
                  //     // value.Oral
                  // }
                  
                ))}
              </tr>
            ))} */}

            {props.listing?.map((row) => (
              <tr key={row.id} className="pt-[3px] border border-black ">
                <td className="border border-black text-left py-2 w-5/12">
                  <input
                    type="checkbox"
                    name="IsMLC"
                    checked={row.IsChecked}
                    className="mx-3 mt-1"
                  />

                  {row["Drug Name"]}
                </td>
                <td className="border border-black w-2/12">{row.Frequency}</td>

                <td className="border border-black w-1/12">{row.Qty}</td>
                <td className="border border-black w-2/12">{row.Duration}</td>
                <td className="border border-black w-2/12">{row.Dose}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="w-full flex py-2 pt-2 mt-3">
        <div className="w-[25%] font-semibold text-lg">Dr. Full Name:</div>
        <div className="w-[75%] text-lg border-b border-dotted border-black"></div>
      </div>
      <div className="w-full flex py-2">
        <div className="w-[25%] font-semibold text-lg">
          Dr. Registration No:
        </div>
        <div className="w-[75%] text-lg border-b border-dotted border-black"></div>
      </div>
      <div className="w-full flex py-2">
        <div className="w-[25%] font-semibold text-lg">Dr. Signature:</div>
        <div className="w-[75%] text-lg border-b border-dotted border-black"></div>
      </div>
      <div className="w-full flex py-2">
        <div className="w-[25%] font-semibold text-lg">Date:</div>
        <div className="w-[90%] text-lg">___/___/___</div>
      </div>
      <div className="w-full flex py-2">
        <div className="w-[38%] font-semibold text-lg">
          Dr. Qualification (MBBS/MD/MS/DNB) :
        </div>
        <div className="w-[90%] text-lg">
          __________________________________________________________________________________________
        </div>
      </div>
      <div className="w-[300px] h-[500px] border border-black p-20 mt-4">
        <h1 className="font-semiold text-center"> Dr.Stamp</h1>
      </div>
    </div>
  );
}
