import { yupResolver } from "@hookform/resolvers/yup";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as React from "react";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import ResetButton from "../../../common/components/Buttons/ResetButton";
import CommonBackDrop from "../../../common/components/CommonBackDrop/CommonBackDrop";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import InputField from "../../../common/components/FormFields/InputField";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import DatePickerFieldNew from "../../../common/components/FormFields/DatePickerFieldNew";
import {
  deleteAlert,
  errorAlert,
  successAlert,
} from "../../../common/components/Toasts/CustomToasts";
import { fetchUserActionsByMenuId } from "../../../commonServices/functionalityServices/FunctionalityServices";
import // fetchSearchDropDown,

// getBedTransferPatientInfo,
"../../../commonServices/patientInfoServices/PatientInfoServices";
import IPDBilling from "../../../ipd/components/bill/IPDBilling";
import {
  convertToPackage,
  deleteService,
  getAutoSearchPatientList,
  getDoctorList,
  getPatientData,
  saveServices,
  searchService,
} from "../../services/charges/IPDCharges";
import AddedServiceTable from "./AddedServiceTable";
import ConvertToCharge from "./ConvertToCharge";
import ConvertToPackage from "./ConvertToPackage";
import CommonTable from "./IPDChargesTable";
import { format, isAfter } from "date-fns";
const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflowY: "scroll",
  bgcolor: "background.paper",
  boxShadow: 24,
  width: "90%",
  height: "95%",
  border: "1px solid gray",
};

export default function IPDCharge(props) {
  let location = useLocation();

  //

  const [serviceList, setServiceList] = React.useState();
  // yup Schema validation for form fields
  const schema = yup.object().shape({
    searchServiceList: yup
      .object()
      .nullable()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .required("Please Select Service"),
    qty: yup
      .string()
      .required("Required")
      .matches(/^0*[1-9]\d*$/, "Invalid Value"),

    rate: yup
      .string()
      .required("Required")
      .matches(/^(\d*\.)?\d+$/, "Invalid Value"),

    discountPercent: yup
      .string()
      .nullable()
      .when("searchServiceList", (searchServiceList) => {
        if (searchServiceList !== null) {
          if (searchServiceList.discountApplicable === true) {
            return yup
              .string()
              .nullable()
              .matches(/^100(\.0{1,8})?|\d{1,2}(\.\d{1,8})?$/, "Invalid")
              .required("Required");
          }
        }
      }),
    discountAmount: yup
      .string()
      .nullable()
      .when("searchServiceList", (searchServiceList) => {
        if (searchServiceList !== null) {
          if (
            searchServiceList.discountApplicable !== null &&
            searchServiceList.discountApplicable === true
          ) {
            return yup.string().nullable().required("Required");
          }
        }
      }),
    doctor: yup
      .object()
      .nullable()
      .when("searchServiceList", (searchServiceList) => {
        if (searchServiceList !== null) {
          if (
            searchServiceList.DoctorShare !== null &&
            searchServiceList.DoctorShare === true
          ) {
            return yup
              .object()
              .nullable()
              .shape({
                value: yup.string(),
                label: yup.string(),
              })
              .required("Required");
          }
        }
      }),
  });

  let defaultValues = {
    fromDate: new Date(),
    toDate: new Date(),
    fromDateFilter: null,
    toDateFilter: null,
    searchServiceList: null,
    qty: "",
    rate: "",
    discountPercent: 0,
    discountAmount: 0,
    doctor: null,
    admissionUnderPackage: false,
  };

  const {
    control,
    handleSubmit,
    reset,
    register,
    formState: { errors },
    setValue,
    watch,
    resetField,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const {
    control: control1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    formState: { errors1 },
    setValue: setValue1,
    watch: watch1,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      appliedDiscountPercent: 0,
      appliedDiscountAmount: 0,
    },
  });
  //
  const [userActions, setUserActions] = useState([]);
  //
  const [patientList, setPatientList] = React.useState([]);
  const [admissionId, setAdmissionId] = React.useState(null);
  const [patientId, setPatientId] = React.useState(null);
  const [bedCategoryId, setbedCategoryId] = React.useState(null);
  const [tariffId, setTariffId] = React.useState(null);
  const [visitId, setVisitId] = React.useState(null);
  const [selectedPatient, setSelectedPatient] = React.useState();
  const [addedServices, setAddedSevices] = React.useState([]);
  const [savedServices, setSavedSevices] = React.useState([]);
  const [dataId, setDataId] = useState(null);
  const [actions, setActions] = React.useState(["Edit", "Delete"]);
  const [isDisable, setIsDisable] = React.useState(false);
  const [previousCharges, setPreviousCharges] = React.useState([]);
  const [style, setStyle] = React.useState("col-span-5");
  const [isDoctorShareApplicable, setIsDoctorShareApplicable] = React.useState(
    null
  );
  const [doctors, setDoctors] = useState();
  const [isDiscountApplicable, setIsDiscountApplicable] = React.useState(null);
  const [index, setIndex] = React.useState(null);
  const [status, setStatus] = React.useState(null);
  const [rowData, setRowData] = React.useState(null);
  const [openCovertToPackage, setOpenCovertToPackage] = React.useState(false);
  const [openCovertToCharge, setOpenCovertToCharge] = React.useState(false);
  const [category, setCategory] = React.useState("All");
  const [totalPayable, setTotalPayable] = React.useState(0);
  const [previousChargesAction, setPreviousChargesAction] = React.useState(
    false
  );
  const [selected, setSelected] = React.useState([]);
  //Confirmation Modal useState
  const [openConfirmation, setOpenConfirmation] = useState(false);
  //From and To Date useState
  const [openFromDate, setOpenFromDate] = useState(false);
  const [openToDate, setOpenToDate] = useState(false);
  const [isByDate, setIsByDate] = useState(false);
  const [isEmergencyFlag, setIsEmergencyFlag] = useState(false);
  const [openIpdBill, setopenIpdBill] = useState(false);

  const [isCurrentAdmitted, setIsCurrentAdmitted] = useState(true);

  //consolidate useState
  // const [isConsolidate, setIsConsolidate]= useState(false)

  //Extra useState value
  const [free, setFree] = useState(0);
  const [isPackage, setIsPackage] = useState(false);
  const [isEmergency, setIsEmergency] = useState(false);
  //useState to Search Previous Charges
  const [previousChargesDropdown, setPreviousChargesDropdown] = React.useState(
    []
  );
  const [previousChargesList, setPreviousChargesList] = React.useState([]);
  const [searchedPreviousCharges, setSearchedPreviousCharges] = React.useState(
    false
  );

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [classId, setClassId] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [minFromDate, setMinFromDate] = useState(null);
  const [minToDate, setMinToDate] = useState(null);

  const [groupId, setGroupId] = useState(null);
  const [checkDuplicate, setCheckDuplicate] = useState(false);
  const [ipdChargesWindow, setIpdChargesWindow] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [billMenuId, setBillMenuId] = useState(null);
  const [privilege, setPrivilege] = useState("");
  const [checkIsPackage, setCheckIsPackage] = useState(false);
  //

  let fromDateVal = watch("fromDate");
  let fromDateFilter_selected = watch("fromDateFilter");
  let toDateFilter_selected = watch("toDateFilter");

  // Hanmant Changes
  let ipdChargesSearchVal = watch("ipdChargesSearch");

  let admissionIdValue;

  const handlOpenIpdBill = () => {
    setopenIpdBill(true);
  };

  const handlCloseIpdBill = () => setopenIpdBill(false);

  const handlCloseIpdBillAfterFinalSave = () => {
    setAdmissionId(null);
    setPatientId(null);
    setSelectedPatient(null);
    setopenIpdBill(false);
  };

  //API for Patient Search
  const handlePatientSearch = (e) => {
    if (e.length > 0) {
      setSearchedPreviousCharges(false);
      getAutoSearchPatientList(e, isCurrentAdmitted)
        .then((response) => {
          setPatientList(response.data.result);
        })
        .catch((response) => {});
    } else {
      setAddedSevices([]);
      setSavedSevices([]);
      setValue("fromDate", new Date());
      setValue("toDate", new Date());
    }
  };

  useEffect(() => {
    isAfter(fromDateFilter_selected, new Date(1900, 1, 1)) &&
      isAfter(toDateFilter_selected, new Date(1900, 1, 1)) &&
      handlePatientInfo();
  }, [fromDateFilter_selected, toDateFilter_selected]);

  //API For Get Patient Info and Previous Services
  const handlePatientInfo = () => {
    const listObj = {
      admissionId: admissionId,
      category: category,
      consolidate: false,
      fromDate:
        (!!fromDateFilter_selected &&
          format(fromDateFilter_selected, "yyyy-MM-dd'T'HH:mm:ss.000'Z'")) ||
        null,
      patientId: patientId,
      toDate:
        (!!toDateFilter_selected &&
          format(toDateFilter_selected, "yyyy-MM-dd'T'HH:mm:ss.000'Z'")) ||
        null,
    };

    if (admissionId !== null && searchedPreviousCharges === false) {
      getPatientData(listObj)
        .then((response) => {
          // props.setCompanyId(response.data.result.CompanyId);
          setSelectedPatient(response.data.result);
          setPreviousCharges(response.data.result.list);
          setPreviousChargesList(response.data.result.list);
          setbedCategoryId(response.data.result.BedId);
          setTariffId(response.data.result.TariffId);
          setVisitId(response.data.result?.getPatientDetails?.visitId);
        })
        .catch((response) => {});
    } else {
      setSelectedPatient(null);
      // setPreviousCharges([]);
    }
  };

  //Handle Min From Date
  const handleMinFromDate = () => {
    if (selectedPatient) {
      let currentDate = String(new Date().getDate()).padStart(2, "0");
      let currentMonth = String(new Date().getMonth() + 1).padStart(2, "0");
      let currentYear = new Date().getFullYear();
      let fullDate =
        currentDate + "-" + currentMonth + "-" + currentYear.toString();

      let minFromDateValue = selectedPatient.admissionDate.split("-");
      if (fullDate == selectedPatient.admissionDate) {
        let currentDateData = new Date();
        setMinFromDate(currentDateData);
      } else {
        setMinFromDate(
          new Date(
            minFromDateValue[2],
            minFromDateValue[1] - 1,
            minFromDateValue[0]
          )
        );
      }
    }
  };

  useEffect(() => {
    handleMinFromDate();
    if (fromDateVal) {
      setMinToDate(fromDateVal);
    }
  }, [selectedPatient]);

  //Calculate total Payable Amount
  let totalPay = 0;
  const handleTotalPayableAmount = () => {
    previousCharges.map((item) => {
      totalPay = totalPay + item["TotalAmount"];
    });

    setTotalPayable(totalPay.toFixed(2));
  };
  useEffect(() => {
    if (
      location.state &&
      location.state !== null &&
      location.state?.admissionId
    ) {
      setAdmissionId(location.state?.admissionId);
      setbedCategoryId(location.state.bedCategory);
      setTariffId(location.state.tariff);
      setPatientId(location.state?.patientId);
    }
    if (
      admissionId &&
      admissionId !== null &&
      searchedPreviousCharges === false
    ) {
      handlePatientInfo();
    }
  }, [admissionId, category]);

  useEffect(() => {
    if (previousCharges.length > 0) {
      handleTotalPayableAmount();
    }
    if (
      props.admissionId &&
      props.admissionId != null &&
      props.patientId &&
      props.patientId != null
    ) {
      setAdmissionId(props?.admissionId);
      setPatientId(props?.patientId);
    }
  }, [previousCharges.length, props]);

  // calculate discount Amount
  let getRate = watch("rate");
  let getDiscountPercent = parseFloat(watch("discountPercent"));
  let getDiscountAmount = parseFloat(watch("discountAmount"));
  let getQuantity = watch("qty");
  let discountAmount, totalAmount;
  let serviceRate = parseFloat(getRate);
  let serviceQuantity = parseInt(getQuantity);
  let serviceDiscountPer = parseFloat(getDiscountPercent);
  let serviceDiscountAmt = parseFloat(getDiscountAmount);

  useEffect(() => {
    // if (getDiscountPercent && typeof getDiscountAmount === "number") {
    if (
      isDiscountApplicable !== null &&
      isDiscountApplicable === true &&
      getDiscountPercent !== 0 &&
      typeof getDiscountPercent === "number" &&
      typeof serviceDiscountPer === "number"
    ) {
      totalAmount = parseFloat(serviceRate * serviceQuantity);
      let priceAfterDiscount =
        totalAmount - (totalAmount * parseFloat(serviceDiscountPer)) / 100;
      discountAmount = parseFloat(totalAmount - priceAfterDiscount).toFixed(1);

      setValue("discountAmount", discountAmount);
    }
  }, [getRate, getDiscountPercent, getQuantity]);

  useEffect(() => {
    if (typeof serviceDiscountPer === "number") {
      totalAmount = parseFloat(serviceRate * serviceQuantity);
      let getDisPrice = totalAmount - serviceDiscountAmt;
      let totalValue = parseFloat(totalAmount) - parseFloat(getDisPrice);
      let discountInDecimal = totalValue / totalAmount;
      let discountInPercent = (discountInDecimal * 100).toFixed(1);
      setValue("discountPercent", parseFloat(discountInPercent));
    }
  }, [getDiscountAmount, getRate, getQuantity]);

  //API For Search Service
  const handleChange = (e) => {
    let unitkey = JSON.parse(localStorage.getItem("userInfo"));
    if (e.length > 0 && selectedPatient) {
      let finalSearchObj = {
        description: e,
        tariffId: tariffId,
        unitId: unitkey.unitId,
        groupId: 0,
        classId: selectedPatient.classId,
        companyId: selectedPatient.CompanyId,
        applicableTo: 1,
        isEmergency: isEmergencyFlag,
      };
      searchService(finalSearchObj)
        .then((response) => {
          //
          setServiceList(response.data.result);
          // setActions(response.data.actions);
        })
        .catch((response) => {});
    }
  };

  // API to Get Data Based on Selected Service
  const handleSelectedService = (data) => {
    // getSelectedServiceDetails(
    //   data.isPackaged,
    //   bedCategoryId,
    //   data.value,
    //   tariffId
    // )
    //   .then((response) => {
    //
    //     setIsDoctorShareApplicable(
    //       response.data.result[0].doctorShareApplicable
    //     );
    //     setIsDiscountApplicable(response.data.result[0].Concession);
    //     setValue("discountPercent", response.data.result[0].discountPercent);
    //   })
    //   .catch((res) => {
    //
    //   });
  };

  //API for Doctor List
  const handleDoctorList = (searchString) => {
    if (searchString.length > 0) {
      getDoctorList(searchString)
        .then((response) => {
          setDoctors(response.data.result);
        })
        .catch((res) => {});
    }
  };

  //API to Save Added Services
  const handleAddedService = () => {
    handleCloseSaveModal();
    setIsByDate(false);
    setIsEmergencyFlag(false);
    setOpenBackdrop(true);
    saveServices(
      savedServices,
      location?.state?.menuId ? location?.state?.menuId : props.menuId,
      privilege
    )
      .then((response) => {
        if (response.status === 200) {
          setOpenSaveModal(false);
          successAlert(response.data.message);
          handlePatientInfo();
          setAddedSevices([]);
          setOpenBackdrop(false);

          setSavedSevices([]);
          setOpenBackdrop(false);
          setValue("fromDate", new Date());
          setValue("toDate", new Date());
          setCheckDuplicate(false);
        } else {
          errorAlert(response.data.error);
          setOpenBackdrop(false);
        }
      })
      .catch((res) => {
        errorAlert(res.data.result);
        setOpenBackdrop(false);
      });
  };

  //Edit Charge
  function editCharge(chargeDetails, index) {
    console.log("editChargeeditChargeeditChargeeditCharge",chargeDetails, index);
    setSavedSevices([]);
    setPreviousChargesAction(previousChargesAction);
    let serviceObj;
    setDataId(index);
    let serviceData = chargeDetails["Service Description"];

    let substring = serviceData.split("|");
    serviceObj = {
      label: substring[1],
      // serviceName: substring[1],
      ServiceCode: substring[0],
      value: chargeDetails.serviceId,
      ServiceId: chargeDetails.serviceId,
      // discountApplicable: chargeDetails.discountApplicable,
    };
    if (chargeDetails.discountApplicable === false) {
      setIsDiscountApplicable(false);
    } else {
      setIsDiscountApplicable(true);
    }

    let myObj = {
      searchServiceList: serviceObj,
      qty: chargeDetails.Quantity,
      rate: chargeDetails.Rate,
      
      // discountPercent: chargeDetails["Discount Per"],
      // discountAmount: chargeDetails["Discount Amount"],
    };
    reset(myObj);
    setValue("discountAmount", chargeDetails["DiscountAmt"], {
      shouldValidate: true,
    });
    setValue("discountPercent", chargeDetails["DiscountPerc"], {
      shouldValidate: true,
    });
    setValue("doctor",{id:chargeDetails["Doctor Name"],value:chargeDetails["Doctor Name"],label:chargeDetails["Doctor Name"]})
  }

  //Delete Charge
  function deleteCharge(index, status, rowData) {
    setIndex(index);
    setStatus(status);
    setRowData(rowData);
    setOpenConfirmation(true);
  }
  const handleDeleteRecord = () => {
    let roleObj = JSON.parse(localStorage.getItem("userInfo"));
    if (status === true) {
      let finalDeleteObj = {
        discountAmount: rowData["Discount Amount"],
        discountPercent: rowData["Discount Per"],
        serviceListDto: [
          {
            billInfoId: rowData.billInfoId,
            discountAmount: rowData["Discount Amount"],
            discountPercent: rowData["Discount Per"],
            isCancelled: true,
            quantity: rowData["Quantity"],
            rate: rowData["Rate"],
            services: rowData.serviceId,
          },
        ],
        visitId: visitId,
        unitId: roleObj.units[0].id,
      };
      handlePrevDelete(finalDeleteObj);
    } else {
      addedServices.splice(index, 1);
      setAddedSevices([...addedServices]);
      savedServices.splice(index, 1);
      setSavedSevices([...savedServices]);
      setOpenConfirmation(false);
    }
  };
  const handlePrevDelete = (deleteData) => {
    // updateCharges(deleteData)
    //   .then((response) => {
    //
    //     if (response.status === 200) {
    //       successAlert(response.data.message);
    //       handlePatientInfo();
    //       setOpenConfirmation(false);
    //     }
    //   })
    //   .catch((res) => {
    //
    //     errorAlert(res.data.message);
    //   });
  };

  let serviceName,
    serviceCode,
    doctorName,
    doctorId,
    serviceId,
    discountApplicable;
  //Add Charges
  function onSubmit(data) {
    data.searchServiceList.serviceName !== null
      ? (serviceName = data.searchServiceList.label)
      : (serviceName = null);
    data.searchServiceList.ServiceCode !== null
      ? (serviceCode = data.searchServiceList.ServiceCode)
      : (serviceCode = null);
    data.searchServiceList.ServiceCode !== null
      ? (serviceId = data.searchServiceList.ServiceId)
      : (serviceId = null);
    discountApplicable = isDiscountApplicable;

    data.doctor && data.doctor !== null
      ? (doctorName = data.doctor.label)
      : (doctorName = null);
    data.doctor && data.doctor !== null
      ? (doctorId = data.doctor.id)
      : (doctorId = 0);

    let totalAmt = parseFloat(data.qty) * parseFloat(data.rate);

    let discountInPercent, discountAmountAvailable;

    // //if isDiscountApplicable Flag not given
    // discountInPercent = parseInt(data.discountPercent);
    // discountAmountAvailable = parseInt(data.discountAmount);

    let netAmt;
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
console.log("data.discountPercent",data.discountPercent);

    if (isDiscountApplicable === true) {
      if (isNaN(data.discountPercent) || data.discountPercent === "NaN") {
        discountInPercent = 0;
      } else {
        discountInPercent = parseInt(data.discountPercent);
      }
      if (
        isNaN(parseInt(data.discountAmount)) ||
        data.discountAmount === "NaN"
      ) {
        discountAmountAvailable = 0;
      } else {
        discountAmountAvailable = parseInt(data.discountAmount);
      }
    } else {
      discountInPercent = 0;
      discountAmountAvailable = 0;
    }

    netAmt = totalAmt - discountAmountAvailable;

    if (previousChargesAction === true && dataId !== null) {
      let discountAmountValue, discountPercentValue;
      if (
        data.discountAmount === null ||
        data.discountAmount === "" ||
        data.discountAmount === "NaN" ||
        isNaN(data.discountAmount)
      ) {
        discountAmountValue = 0;
      } else {
        discountAmountValue = parseFloat(data.discountAmount);
      }
      if (
        data.discountPercent === null ||
        data.discountPercent === "" ||
        data.discountPercent === "NaN" ||
        isNaN(data.discountPercent)
      ) {
        discountPercentValue = 0;
      } else {
        discountPercentValue = parseFloat(data.discountPercent);
      }

      let updateObj = {
        discountAmount: parseFloat(discountAmountValue),
        discountPercent: parseFloat(discountPercentValue),
        serviceListDto: [
          {
            billInfoId: dataId,
            discountAmount: parseFloat(discountAmountValue),
            discountPercent: parseFloat(discountPercentValue),
            isCancelled: false,
            quantity: parseInt(data.qty),
            rate: parseFloat(data.rate),
            services: parseInt(serviceId),
          },
        ],
        unitId: userInfo.unitId,
        // visitId: visitId,
      };

      // updateCharges(updateObj)
      //   .then((response) => {
      //
      //     if (response.status === 200) {
      //       successAlert(response.data.message);
      //       handlePatientInfo();
      //       reset(defaultValues);
      //       setValue("discountAmount", 0);
      //     }
      //   })
      //   .catch((res) => {
      //
      //     errorAlert(res.data.message);
      //   });
    } else {
      //Object To Add Data to Table
      let finalObj = {
        "Service Description": serviceCode + " | " + serviceName,
        // "From Date": data.fromDate,
        // "To Date": data.toDate,
        Quantity: parseInt(data.qty),
        doctorId: doctorId,
        serviceId: parseInt(serviceId),
        Rate: parseFloat(data.rate),
        "Total Amt": totalAmt,
        "Discount Per": discountInPercent,
        "Discount Amount": discountAmountAvailable,
        "Net Amt": netAmt,
        "Doctor Name": doctorName,
        discountApplicable: discountApplicable,
      };
      let concessionValue;
      if (isDiscountApplicable === false) {
        concessionValue = 0;
      } else {
        concessionValue = 1;
      }

      if (dataId !== null) {
        addedServices[dataId] = finalObj;
        //Object Required to Save Added Services
        let userInfo = JSON.parse(localStorage.getItem("userInfo"));

        let savedService = {
          doctorId: doctorId,
          quantity: parseInt(data.qty),
          classId: 0,
          // services: serviceId,
          // discountAmount: discountAmountAvailable,
          // discountPercent: discountInPercent,
          rate: parseFloat(data.rate),
          // visitId: visitId,
          unitId: userInfo.unitId,
          //extra values
          addedBy: userInfo.userId,
          updatedBy: userInfo.userId,
          opd_Ipd_External: 1,
          opd_Ipd_External_Id: 0,
          tariffId: tariffId,
          serviceId: serviceId,
          totalAmount: totalAmt,
          netAmount: netAmt,
          concession: discountAmountAvailable,
          discount: discountAmountAvailable,
          staffFree: free,
          isPackageService: isPackage,
          emergency: isEmergency,
          opd_Ipd_External_Id: admissionId,
          hospShareAmount: parseFloat(data.rate),
          doctorShareAmount: 0,
          chargeIdPackage: 0,
          sponsorType: true,
          classId: classId,
          companyId: companyId,
          fromDate: data.fromDate,
          toDate: data.toDate,
          byDate: isByDate,
          groupId: groupId,
        };
        savedServices[dataId] = savedService;
      } else {
        let obj = addedServices.find(
          (o) => o["Service Description"] === serviceCode + " | " + serviceName
        );
        if (typeof obj === "undefined") {
          addedServices.push(finalObj);
          //Object Required to Save Added Services
          let userInfo = JSON.parse(localStorage.getItem("userInfo"));

          let savedService = {
            doctorId: doctorId,
            quantity: parseInt(data.qty),
            classId: 0,
            // services: serviceId,
            // discountAmount: discountAmountAvailable,
            // discountPercent: discountInPercent,
            rate: parseFloat(data.rate),
            // visitId: visitId,
            unitId: userInfo.unitId,
            //extra values
            addedBy: userInfo.userId,
            updatedBy: userInfo.userId,
            opd_Ipd_External: 1,
            opd_Ipd_External_Id: 0,
            tariffId: tariffId,
            serviceId: serviceId,
            totalAmount: totalAmt,
            netAmount: netAmt,
            concession: discountAmountAvailable,
            discount: discountAmountAvailable,
            staffFree: free,
            isPackageService: isPackage,
            emergency: isEmergency,
            opd_Ipd_External_Id: admissionId,
            hospShareAmount: 0,
            doctorShareAmount: 0,
            chargeIdPackage: 0,
            sponsorType: true,
            classId: classId,
            companyId: companyId,
            fromDate: data.fromDate,
            toDate: data.toDate,
            byDate: isByDate,
            groupId: groupId,
          };
          savedServices.push(savedService);
          setCheckDuplicate(false);
        } else {
          setCheckDuplicate(true);
        }
      }
    }

    setDataId(null);
    reset(defaultValues);
    setValue("fromDate", data.fromDate);
    setValue("toDate", data.toDate);
  }

  //Function to get Applied Discount Value
  function appliedDiscount(discountData) {
    // handleDiscount(discountData);
    reset1();
  }

  //Check Discount Percent or Discount Amount is Given
  // const handleDiscount = (discountValue) => {
  //
  //   if (discountValue.appliedDiscountAmount !== 0) {
  //     calculateDiscountBasedOnAmount(discountValue.appliedDiscountAmount);
  //   } else {
  //     calculateDiscountBasedOnPercentage(discountValue.appliedDiscountPercent);
  //   }
  // };

  //Calculate Discount Based On Amount
  // const calculateDiscountBasedOnAmount = (discount) => {
  //   let numberOfServices = 0;
  //   previousCharges.map((item, index) => {
  //     if (item.discountApplicable === true) {
  //       numberOfServices = numberOfServices + 1;
  //     }
  //   });
  //   let discountAmountPerService = (
  //     parseFloat(discount) / numberOfServices
  //   ).toFixed(2);

  //   previousCharges.map((item, index) => {
  //     if (item.discountApplicable === true) {
  //       let getDisPrice = item["Total Amount"] - discountAmountPerService;
  //       let totalValue =
  //         parseFloat(item["Total Amount"]) - parseFloat(getDisPrice);
  //       let discountInDecimal = totalValue / item["Total Amount"];
  //       let discountInPercent = discountInDecimal * 100;
  //       item["Discount Amount"] = discountAmountPerService;
  //       item["Discount Per"] = discountInPercent.toFixed(2);
  //       item["Net Amount"] = parseFloat(
  //         item["Total Amount"] - discountAmountPerService
  //       );
  //     }
  //   });
  //   handleTotalPayableAmount();
  // };

  //Calculate Discount Based On Percentage
  // const calculateDiscountBasedOnPercentage = (discount) => {
  //   let discountAmt;
  //   previousCharges.map((item, index) => {
  //     if (item.discountApplicable === true) {
  //       let priceAfterDiscount =
  //         item["Total Amount"] - (item["Total Amount"] * discount) / 100;
  //       discountAmt = (item["Total Amount"] - priceAfterDiscount).toFixed(2);
  //       item["Discount Amount"] = discountAmt;
  //       item["Discount Per"] = discount;
  //       item["Net Amount"] = parseFloat(item["Total Amount"] - discountAmt);
  //     }
  //   });
  //   handleTotalPayableAmount();
  // };

  //Convert API's
  //Convert Company To Patient
  // const handleConvertCompanyToPatient = () => {
  //   let selectBillInfoId = [];
  //   selected.map((item) => {
  //     let billInfoList = item.billInfoId;
  //     selectBillInfoId.push(billInfoList);
  //   });
  //   if (selectBillInfoId.length > 0) {
  //     // convertCompanyToPatient(selectBillInfoId)
  //     //   .then((response) => {
  //     //
  //     //     if (response.status === 200) {
  //     //       successAlert(response.data.message);
  //     //       handlePatientInfo();
  //     //     }
  //     //   })
  //     //   .catch((res) => {
  //     //
  //     //     errorAlert(res.data.message);
  //     //   });
  //   }
  // };

  const handleIsByDate = (e) => {
    setIsByDate(e.target.checked);
  };

  const handleIsEmergency = (e) => {
    setIsEmergencyFlag(e.target.checked);
  };

  const handleIsCurrentAdmitted = (e) => {
    setIsCurrentAdmitted(e.target.checked);
  };
  //Previous Service Search

  const handlePreviousListChange = (e) => {
    let searchedService = previousChargesList.filter((name) =>
      name["Description"].match(new RegExp(e, "i"))
    );
    let searchedServiceData = [];
    searchedService.map((row, index) => {
      let dropdownObject = {
        label: row["Description"],
        value: index,
      };
      searchedServiceData.push(dropdownObject);
    });
    setPreviousChargesDropdown(searchedServiceData);
  };

  const handleSearchedPreviousList = (searchKey) => {
    let searchedServiceList = previousCharges.filter((name) =>
      name["Description"].match(new RegExp(searchKey, "i"))
    );

    setPreviousCharges(searchedServiceList);
  };

  //Convert to Charge
  const handleConvertToCharge = () => {
    let selectBillInfoId = [];
    selected.map((item) => {
      selectBillInfoId.push(item.ChargeId);
    });

    let finalObj = {
      admissionId: admissionId,
      charges: selectBillInfoId,
      packageId: null,
      pkgChargeId: null,
      menuId:
        props?.from === "Billing" ? props.menuId : location?.state?.menuId,
      privilege: privilege,
      tariffId: selectedPatient?.TariffId,
    };

    if (selectBillInfoId.length > 0) {
      convertToPackage(finalObj)
        .then((response) => {
          if (response.status === 200) {
            successAlert(response.data.message);
            handlePatientInfo();
          }
        })
        .catch((res) => {
          errorAlert(res.data.message);
        });
    }
  };

  //Delete Service
  const handleDeleteService = () => {
    setOpenBackdrop(true);

    let deletedServiceId = [];
    selected.map((item) => {
      deletedServiceId.push(item.ChargeId);
    });

    let deleteObj = {
      admissionId: admissionId,
      chargeId: deletedServiceId,
      tariffId: tariffId,
      menuId: props.menuId ? props.menuId : location?.state?.menuId,
      privilege: privilege,
    };

    deleteService(deleteObj)
      .then((response) => {
        setOpenModal(false);
        if (response.data.statusCode === 200) {
          setOpenBackdrop(false);
          deleteAlert(response.data.message);
          handlePatientInfo();
          setSelected([]);
        }
      })
      .catch((res) => {
        setOpenBackdrop(false);
      });
  };

  const handleSaveService = () => {
    //setOpenBackdrop(true);
    setOpenSaveModal(true);
  };

  const handleCloseSaveModal = () => {
    setOpenSaveModal(false);
    setOpenBackdrop(false);
  };

  useEffect(() => {
    //

    if (props["from"]) {
      if (props.from === "Billing") {
        fetchUserActionsByMenuId(props.menuId)
          .then((response) => response.data)
          .then((res) => {
            setUserActions(res.result);
          });
        setAdmissionId(props.admissionId);
        setPatientId(props.patientId);
      }
    } else {
      location?.state?.menuId &&
        fetchUserActionsByMenuId(location?.state?.menuId)
          .then((response) => response.data)
          .then((res) => {
            setUserActions(res.result);
          });

      if (location?.state?.billParams) {
        setAdmissionId(location?.state?.billParams?.admissionId);
        setPatientId(location?.state?.billParams?.patientId);
      }
    }

    // location?.state?.menuId &&
    //   fetchUserActionsByMenuId(location?.state?.menuId)
    //     .then((response) => response.data)
    //     .then((res) => {
    //       setUserActions(res.result);
    //     });

    // if (props?.from === "Billing") {
    //   setAdmissionId(props.admissionId);
    //   setPatientId(props.patientId);
    // } else {
    //   if (location.state.billParams) {
    //     setAdmissionId(location.state.billParams.admissionId);
    //     setPatientId(location.state.billParams.patientId);
    //   }
    // }
  }, []);

  return (
    <>
      <div className="pt-20 ml-2">
        <div className="grid">
          <div className="text-center text-black font-bold text-xl mr-8 my-auto">
            IPD Charges
          </div>
          {(props.admissionId && props.patientId) ||
          (location.state && location.state) === null ? null : (
            <div className="flex justify-between items-center">
              <div className="flex space-x-2 w-10/12 lg:w-6/12">
                <div className="w-[90%]">
                  <SearchDropdown
                    control={control}
                    placeholder="Search By Patient Name/MR No./Mobile No."
                    dataArray={patientList}
                    name="ipdChargesSearch"
                    searchIcon={true}
                    isClearable={true}
                    handleInputChange={handlePatientSearch}
                    inputRef={{
                      ...register("ipdChargesSearch", {
                        onChange: (e) => {
                          if (e.target.value !== null) {
                            setAdmissionId(e.target.value.id);
                            setPatientId(e.target.value.PatientId);
                          } else {
                            setAdmissionId(null);
                            setPatientId(null);
                            setSelectedPatient(null);
                            // setPreviousCharges([]);
                            setopenIpdBill();
                          }
                        },
                      }),
                    }}
                  />
                </div>
                <div>
                  <FormControlLabel
                    label={
                      <span className="text-sm whitespace-nowrap">
                        Current Admitted
                      </span>
                    }
                    control={
                      <Checkbox
                        checked={isCurrentAdmitted}
                        onChange={handleIsCurrentAdmitted}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                  />
                </div>
              </div>

              <div>
                {/* {ipdChargesSearchVal !== undefined ? (
                  <CommonButton
                    label="IPD Bill"
                    className="bg-customBlue text-white"
                    onClick={() => {
                      setIsByDate(false);
                      reset();
                      setopenIpdBill(true);
                    }}
                  />
                ) : null} */}

                {userActions.map((obj) => (
                  <>
                    {!obj.isAction && obj.action === "Bill" && selectedPatient && (
                      <>
                        <CommonButton
                          label="IPD Bill"
                          className="bg-customBlue text-white"
                          onClick={() => {
                            setBillMenuId(obj.menuId);
                            setIsByDate(false);
                            reset();
                            setopenIpdBill(true);
                          }}
                        />
                      </>
                    )}
                  </>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="w-full  mt-2 md:rounded-md">
          {/* //Patient Info// */}
          <div className="">
            {admissionId !== null ? (
              <fieldset
                className="border border-gray-300 col-span-3 w-full lg:py-0
               text-left lg:px-2 md:p-2 rounded bg-gray-100"
              >
                <div className=" grid  lg:grid-cols-4 2xl:grid-cols-4 gap-y-1 gap-x-2 px-3 ">
                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-2 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">Patient Name</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {selectedPatient && selectedPatient.patientName}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">MR No.</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {selectedPatient && selectedPatient.mrNo}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">ADM Date</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {selectedPatient && selectedPatient.admissionDate}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">IPD No.</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {selectedPatient && selectedPatient.IpdNo}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">Gender</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {selectedPatient && selectedPatient.gender}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">Age</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {selectedPatient && selectedPatient.AgeYear}
                      </span>
                    </div>
                  </div>
                  {/* <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                  <span className=" font-semibold w-28">Department</span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {selectedPatient && selectedPatient.department}
                    </span>
                  </div>
                </div> */}
                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">Doctor</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {selectedPatient && selectedPatient.doctor}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">Ward</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {selectedPatient && selectedPatient.Ward}
                      </span>
                    </div>
                  </div>
                  {/* <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                  <span className=" font-semibold w-28">Room No.</span>
                  <div className="flex space-x-2 items-center">
                    <span>:</span>
                    <span className="text-gray-700 font-normal">
                      {selectedPatient && selectedPatient.roomNumber}
                    </span>
                  </div>
                </div> */}
                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">Bed Category</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {selectedPatient && selectedPatient.BedCategory}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">Bed No.</span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {selectedPatient && selectedPatient.BedNo}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 w-full col-span-2 xl:col-span-1 font-semibold text-sm py-1">
                    <span className=" font-semibold w-28">
                      Patient Category
                    </span>
                    <div className="flex space-x-2 items-center">
                      <span>:</span>
                      <span className="text-gray-700 font-normal">
                        {selectedPatient && selectedPatient.PatientCategory}
                      </span>
                    </div>
                  </div>
                </div>
              </fieldset>
            ) : (
              ""
            )}
          </div>

          {/* //Previous Charges// */}
          {admissionId !== null ? (
            <div className="h-[24.3rem] border rounded-md w-full overflow-hidden mt-2 px-5 bg-white">
              <div className="text-sm font-semibold text-black  my-auto">
                <label>Prev Charges</label>
              </div>
              <div className="grid grid-cols-5 lg:grid-cols-9 gap-2 mt-2">
                {/* //Search Charges// */}
                <div className="col-span-4 lg:col-span-2 ">
                  <SearchDropdown
                    name="searchlistofcharges"
                    control={control}
                    searchIcon={true}
                    isSearchable={true}
                    isClearable={true}
                    dataArray={previousChargesDropdown}
                    handleInputChange={handlePreviousListChange}
                    placeholder="Search Services"
                    inputRef={{
                      ...register("searchlistofcharges", {
                        onChange: (e) => {
                          if (e.target.value !== null) {
                            setSearchedPreviousCharges(true);
                            handleSearchedPreviousList(e.target.value.label);
                            // setPreviousCharges(searchedService)
                          } else {
                            setSearchedPreviousCharges(false);
                            setPreviousCharges(previousChargesList);
                          }
                        },
                      }),
                    }}
                  />
                </div>

                {/* // date filters//  */}
                <div className="grid grid-cols-2 col-span-2 gap-3">
                  {/* from Date */}
                  <div className="md:col-span-2 lg:col-span-1">
                    <DatePickerFieldNew
                      control={control}
                      name="fromDateFilter"
                      label="From Date"
                      value={new Date()}
                      disableFuture={true}
                      disablePast={false}
                      inputFormat="dd-MM-yyyy"
                    />
                  </div>

                  {/* to Date */}
                  <div className="md:col-span-2 lg:col-span-1">
                    <DatePickerFieldNew
                      control={control}
                      name="toDateFilter"
                      label="To Date"
                      value={new Date()}
                      disableFuture={true}
                      disablePast={false}
                      inputFormat="dd-MM-yyyy"
                    />
                  </div>
                </div>

                {/* // Radio Buttons to Select Charges//  */}
                <div className="text-sm col-span-3 lg:col-span-3">
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={category}
                      onChange={(e) => {
                        setCategory(e.target.value);
                        setSelected([]);
                      }}
                    >
                      <FormControlLabel
                        value="All"
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="body2">All Charges</Typography>
                        }
                      />
                      {/* <FormControlLabel
                        value="Patient"
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="body2">
                            Patient Charges
                          </Typography>
                        }
                      /> */}

                      {/* <FormControlLabel
                        value="Company"
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="body2">
                            Company Charges
                          </Typography>
                        }
                      /> */}
                      <FormControlLabel
                        value="Package"
                        control={<Radio size="small" />}
                        label={<Typography variant="body2">Package</Typography>}
                      />
                    </RadioGroup>
                  </FormControl>

                  <CommonButton
                    label="Reset"
                    className="border border-customRed text-customRed"
                    onClick={() => {
                      setValue("fromDateFilter", null);
                      setValue("toDateFilter", null);
                      setValue("searchlistofcharges", null);
                      setSearchedPreviousCharges(false);
                      handlePatientInfo();
                    }}
                  />
                </div>

                {/* //Convert Modals Buttons// */}
                {selected.length > 0 ? (
                  <div className="flex justify-end col-span-2 lg:col-span-2">
                    {userActions.map((obj) => (
                      <>
                        {!obj.isAction && (
                          <>
                            {obj.action === "Convert To Package" &&
                              category.toLowerCase() === "all" &&
                              checkIsPackage === false &&
                              selectedPatient?.IsBillGenerated === false && (
                                <>
                                  <CommonButton
                                    className=" bg-customBlue text-white"
                                    onClick={() => {
                                      setOpenCovertToPackage(true);
                                      setPrivilege(obj.action);
                                    }}
                                    label="Convert To Package"
                                  />
                                </>
                              )}
                            {obj.action === "Move To Charges" &&
                              category.toLowerCase() === "package" && (
                                <>
                                  <CommonButton
                                    className=" bg-customBlue text-white"
                                    onClick={() => {
                                      setPrivilege(obj.action);
                                      handleConvertToCharge();
                                    }}
                                    label="Move To Charges"
                                  />
                                </>
                              )}
                            {obj.action === "Delete" &&
                              selectedPatient?.IsBillGenerated === false && (
                                <>
                                  <Tooltip title="Delete" arrow>
                                    <button
                                      className="text-red-500 mr-3 h-8"
                                      onClick={() => {
                                        setPrivilege(obj.action);
                                        setOpenModal(true);
                                      }}
                                    >
                                      <DeleteOutlineOutlinedIcon />
                                    </button>
                                  </Tooltip>
                                </>
                              )}
                          </>
                        )}
                      </>
                    ))}
                  </div>
                ) : (
                  ""
                )}
                {/* <button
                  className="h-8 px-3 text-xs font-medium  bg-customGreen text-white rounded"
                  onClick={() => {
                    setOpenCovertToPackage(true);
                  }}
                >
                  Convert To Package
                </button>
                <button
                  className="h-8 px-3 text-xs mx-2 font-medium  bg-customGreen text-white rounded"
                  onClick={() => {
                    setOpenCovertToCharge(true);
                  }}
                >
                  Convert To Charge
                </button> */}
              </div>
              {/* //Previous Visits Table// */}
              <div className="h-[17.9rem]">
                <div className="grid grid-cols-5 gap-2 items-center">
                  {previousCharges.length > 0 ? (
                    <div className={style}>
                      <CommonTable
                        data={previousCharges}
                        // actions={actions}
                        charges={category}
                        editCharge={editCharge}
                        deleteCharge={deleteCharge}
                        setPreviousChargesAction={setPreviousChargesAction}
                        setSelected={setSelected}
                        selected={selected}
                        setCheckIsPackage={setCheckIsPackage}
                      />
                    </div>
                  ) : (
                    <div className="my-8 w-full col-span-5">
                      <p className="w-full text-center">No Records Found</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex my-2">
                {/* //Payable Amount// */}
                <div className="flex items-center ">
                  <p className="font-semibold text-sm mr-2">Payable Amount :</p>
                  <p>
                    <span>₹</span> {totalPayable}
                  </p>
                </div>

                {/* //Apply Total Discount Form// */}
                {category.toLowerCase() === "patient" ? (
                  <div className="ml-10">
                    <form onSubmit={handleSubmit1(appliedDiscount)}>
                      <div className="flex space-x-2">
                        <div className="w-28">
                          <InputField
                            name="appliedDiscountPercent"
                            variant="outlined"
                            label="Dis %"
                            // error={errors1.appliedDiscountPercent}
                            control={control1}
                          />
                        </div>
                        <div className="w-28">
                          <InputField
                            name="appliedDiscountAmount"
                            variant="outlined"
                            label="Dis Amt"
                            // error={errors1.appliedDiscountAmount}
                            control={control1}
                          />
                        </div>
                        <button className="h-10 px-3  bg-customBlue  text-white rounded text-sm font-medium">
                          Apply
                        </button>
                      </div>
                    </form>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}

          {/* //Add Charges// */}
          {userActions.map((obj) => (
            <>
              {!obj.isAction && obj.action === "Create" && (
                <>
                  {admissionId !== null &&
                  selectedPatient &&
                  // selectedPatient.Discharged === false &&
                  selectedPatient.isBilled === false ? (
                    <div className="border rounded-md w-full mt-2 px-5 mb-10 bg-white py-4 overflow-visible">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="grid grid-cols-10 gap-2">
                          <div className="col-span-2 lg:col-span-1 text-base text-black font-Poppins flex justify-center my-auto">
                            <label>Add Charges</label>
                          </div>
                          {/* //In Emergency // */}
                          <div className="col-span-2 lg:col-span-1">
                            <FormControlLabel
                              label={<span className="text-sm">Emergency</span>}
                              control={
                                <Checkbox
                                  checked={isEmergencyFlag}
                                  onChange={handleIsEmergency}
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              }
                            />
                          </div>
                          {/* //Is By Date// */}
                          <div className="col-span-2 lg:col-span-1">
                            <FormControlLabel
                              label={<span className="text-sm">By Date</span>}
                              control={
                                <Checkbox
                                  checked={isByDate}
                                  onChange={handleIsByDate}
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              }
                            />
                          </div>
                          {/* //From Date// */}
                          {isByDate === true ? (
                            <div className="col-span-4 lg:col-span-2">
                              <Controller
                                control={control}
                                rules={{
                                  required: true,
                                }}
                                render={({ field }) => (
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <DesktopDatePicker
                                      open={openFromDate}
                                      onOpen={() => setOpenFromDate(true)}
                                      onClose={() => setOpenFromDate(false)}
                                      inputProps={{ readOnly: true }}
                                      renderInput={(props) => (
                                        <TextField
                                          {...props}
                                          type="date"
                                          variant="outlined"
                                          label="From Date"
                                          name="fromDate"
                                          InputLabelProps={{ shrink: true }}
                                          fullWidth
                                          size="small"
                                          // error={errors.dob}
                                          // disabled={true}
                                          readOnly={true}
                                          onClick={(e) => setOpenFromDate(true)}
                                        />
                                      )}
                                      PopperProps={{ placement: "auto-end" }}
                                      inputFormat="dd/MM/yyyy"
                                      disableFuture
                                      {...field}
                                    />
                                  </LocalizationProvider>
                                )}
                                name="fromDate"
                              />
                            </div>
                          ) : null}
                          {/* //To Date// */}
                          {isByDate === true ? (
                            <div className="col-span-4 lg:col-span-2">
                              <Controller
                                control={control}
                                rules={{
                                  required: true,
                                }}
                                render={({ field }) => (
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <DesktopDatePicker
                                      open={openToDate}
                                      onOpen={() => setOpenToDate(true)}
                                      onClose={() => setOpenToDate(false)}
                                      inputProps={{ readOnly: true }}
                                      renderInput={(props) => (
                                        <TextField
                                          {...props}
                                          type="date"
                                          variant="outlined"
                                          label="To Date"
                                          name="toDate"
                                          InputLabelProps={{ shrink: true }}
                                          fullWidth
                                          size="small"
                                          readOnly={true}
                                          onClick={(e) => setOpenToDate(true)}
                                        />
                                      )}
                                      PopperProps={{ placement: "auto-end" }}
                                      minDate={new Date(minToDate)}
                                      inputFormat="dd/MM/yyyy"
                                      disableFuture
                                      {...field}
                                    />
                                  </LocalizationProvider>
                                )}
                                name="toDate"
                              />
                            </div>
                          ) : null}
                          {/* //Search Services// */}
                          <div className="col-span-4 lg:col-span-2">
                            <SearchDropdown
                              name="searchServiceList"
                              control={control}
                              searchIcon={true}
                              isSearchable={true}
                              isClearable={true}
                              placeholder="Search Services *"
                              error={errors.searchServiceList}
                              dataArray={serviceList}
                              handleInputChange={handleChange}
                              inputRef={{
                                ...register("searchServiceList", {
                                  onChange: (e) => {
                                    if (e.target.value !== null) {
                                      handleSelectedService(e.target.value);
                                      setValue("rate", e.target.value.Rate, {
                                        shouldValidate: true,
                                      });
                                      setValue("qty", 1, {
                                        shouldValidate: true,
                                      });
                                      setClassId(e.target.value.ClassId);
                                      setGroupId(e.target.value.GroupId);
                                      setCompanyId(e.target.value.CompId);
                                      setIsDoctorShareApplicable(
                                        e.target.value.DoctorShare
                                      );
                                      setIsDiscountApplicable(
                                        e.target.value.Concession
                                      );
                                      if (e.target.value.Free === false) {
                                        setFree(0);
                                      } else {
                                        setFree(1);
                                      }
                                      // setIsPackage(e.target.value.Healthplan_Package);
                                      setIsPackage(e.target.value.IsPackage);
                                      setIsEmergency(e.target.value.Emergency);
                                    } else {
                                      setValue("rate", 0, {
                                        shouldValidate: true,
                                      });
                                      setValue("qty", 0);
                                      setValue("doctor", null);
                                    }
                                  },
                                }),
                              }}
                            />
                          </div>
                          {/* //Quantity// */}
                          <div className="col-span-2 lg:col-span-1">
                            <InputField
                              name="qty"
                              variant="outlined"
                              label="Qty *"
                              error={errors.qty}
                              control={control}
                            />
                          </div>
                          {/* //Rate// */}
                          <div className="col-span-2 lg:col-span-1">
                            <InputField
                              name="rate"
                              variant="outlined"
                              label="Rate*"
                              error={errors.rate}
                              control={control}
                            />
                          </div>
                          {/* //Discount %// */}
                          {isDiscountApplicable === true ? (
                            <div className="col-span-2 lg:col-span-1 ">
                              <InputField
                                type="number"
                                name="discountPercent"
                                variant="outlined"
                                label="Dis %"
                                error={errors.discountPercent}
                                control={control}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          {/* //Discount Amount// */}
                          {isDiscountApplicable === true ? (
                            <div className="col-span-2 lg:col-span-1">
                              <InputField
                                type="number"
                                name="discountAmount"
                                variant="outlined"
                                label="Dis Amt"
                                error={errors.discountAmount}
                                control={control}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          {/* //Doctors Dropdown// */}
                          {isDoctorShareApplicable === true ? (
                            <div className="col-span-4 lg:col-span-2">
                              <SearchDropdown
                                name="doctor"
                                control={control}
                                placeholder="Doctor *"
                                dataArray={doctors}
                                error={errors.doctor}
                                handleInputChange={handleDoctorList}
                                isSearchable={true}
                                isClearable={true}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          {/* <div className="col-span-2 text-xs">
                    <CheckBoxField
                      control={control}
                      name="admissionUnderPackage"
                      label="Admission Under Package"
                    />
                  </div> */}
                        </div>
                        <div className="w-full flex justify-end">
                          {dataId !== null ? (
                            <div className=" flex space-x-2">
                              <CommonButton
                                label="Update"
                                type={"submit"}
                                className="bg-customGreen text-white"
                              />
                              <CommonButton
                                label="Cancel"
                                className="border border-customRed text-customRed"
                                onClick={() => {
                                  reset(defaultValues);
                                  setValue("discountAmount", 0);
                                  setPreviousChargesAction(false);
                                  setDataId(null);
                                }}
                              />
                            </div>
                          ) : (
                            <CommonButton
                              type={"submit"}
                              label="Add"
                              className="bg-green-600 text-white"
                              onClick={() => {
                                setPreviousChargesAction(false);
                              }}
                            />
                          )}
                        </div>
                      </form>
                      <div>
                        {checkDuplicate === true ? (
                          <p className="text-sm text-red-500">
                            Record Already Present
                          </p>
                        ) : (
                          ""
                        )}
                      </div>

                      {/* //Added Service Table// */}
                      {addedServices && addedServices.length > 0 ? (
                        <div className="">
                          <AddedServiceTable
                            data={addedServices}
                            actions={actions}
                            editCharge={editCharge}
                            deleteCharge={deleteCharge}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="flex justify-end items-center">
                        {/* <div className="flex gap-4">
                <div className="flex space-x-3 items-center ">
                  <h1>Total Advance</h1>
                  <label className="border rounded-md px-4 py-1">
                    ₹&nbsp; 67654
                  </label>
                </div>
                <div className="flex items-center space-x-2">
                  <h1>Total Amount</h1>
                  <label className="border rounded-md px-4 py-1">
                    ₹&nbsp; 67654
                  </label>
                </div>
              </div> */}
                        {addedServices && addedServices.length > 0 ? (
                          <div className="flex justify-end items-center space-x-2 my-1">
                            <ResetButton
                              onClick={() => {
                                setAddedSevices([]);
                              }}
                            />
                            {/* <CancelButton /> */}
                            {/* <BillButton /> */}
                            {/* <SaveButton /> */}

                            {userActions.map((obj) => (
                              <>
                                {!obj.isAction && (
                                  <>
                                    {obj.action === "Create" && (
                                      <>
                                        <CommonButton
                                          className=" bg-green-600 text-white"
                                          onClick={() => {
                                            setPrivilege(obj.action);
                                            handleSaveService();
                                          }}
                                          label="Save"
                                        />
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            ))}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}
            </>
          ))}
        </div>
      </div>
      <CommonBackDrop openBackdrop={openBackdrop} />

      {/* //Convert to Package// */}
      <Modal
        open={openCovertToPackage}
        // onClose={() => {
        //   setOpenCovertToPackage(false);
        // }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <ConvertToPackage
            setOpenCovertToPackage={setOpenCovertToPackage}
            selected={selected}
            admissionId={admissionId}
            handlePatientInfo={handlePatientInfo}
            menuId={
              props?.from === "Billing" ? props.menuId : location?.state?.menuId
            }
            privilege={privilege}
            tariffId={selectedPatient?.TariffId}
          />
        </Box>
      </Modal>
      {/* //Convert To Charge// */}
      <Modal
        open={openCovertToCharge}
        // onClose={() => {
        //   setOpenCovertToCharge(false);
        // }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <ConvertToCharge
            setOpenCovertToCharge={setOpenCovertToCharge}
            menuId={location?.state?.menuId}
            privilege={privilege}
          />
        </Box>
      </Modal>
      {/* //Delete Confirmation Modal// */}
      <Modal
        open={openConfirmation}
        onClose={() => {
          setOpenConfirmation(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #848211",
            borderRadius: "0.5rem",
            boxShadow: 24,
            p: 4,
            width: "46%",
            height: "auto",
          }}
        >
          <p className="my-3 tracking-wide font-bold text-lg text-center ">
            Please Confirm to Delete Record
          </p>

          <div className="flex justify-center space-x-3">
            <button
              className="h-9 px-3 border border-red-700 text-red-700 rounded text-base font-medium"
              onClick={() => {
                setOpenConfirmation(false);
              }}
            >
              Cancel
            </button>
            <button
              className="h-9 px-3  bg-customGreen text-white rounded text-base font-medium"
              onClick={() => handleDeleteRecord()}
            >
              Confirm
            </button>
          </div>
        </Box>
      </Modal>
      <div className=" bg-white px-8">
        <Modal
          open={openIpdBill}
          // onClose={() => {
          //   handleCloseCharge();
          // }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyle}>
            <div className="grid grid-cols-1 md:grid-cols-1  w-full">
              <CancelPresentationIconButton
                onClick={() => {
                  handlCloseIpdBill();
                }}
                style={{
                  paddingLeft: 10,
                }}
              />
            </div>
            <IPDBilling
              open={openIpdBill}
              admissionId={selectedPatient && selectedPatient.AdmissionId}
              patientId={selectedPatient && selectedPatient.PatientId}
              advanceAmount={
                selectedPatient && selectedPatient.PatientAdvanceAmount
              }
              handlCloseIpdBill={handlCloseIpdBill}
              setIpdChargesWindow={setIpdChargesWindow}
              ipdChargesWindow={ipdChargesWindow}
              handlCloseIpdBillAfterFinalSave={handlCloseIpdBillAfterFinalSave}
              from="IpdCharges"
              menuId={billMenuId}
            />
          </Box>
        </Modal>
      </div>
      {/* //Confirmation to Delete Previous Services */}
      <ConfirmationModal
        confirmationOpen={openModal}
        confirmationHandleClose={() => setOpenModal(false)}
        confirmationSubmitFunc={() => handleDeleteService()}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
      {/* //Confirmation to Save Added Services */}
      <ConfirmationModal
        confirmationOpen={openSaveModal}
        confirmationHandleClose={() => handleCloseSaveModal()}
        confirmationSubmitFunc={() => handleAddedService()}
        confirmationLabel="Confirmation "
        confirmationMsg="Are You Sure ?"
        confirmationButtonMsg="Proceed"
      />
    </>
  );
}
