import React, { useEffect, useRef } from "react";
import { InamdarHospiLogo } from "../../assets/printAssets/InmadarHospiLogo";
import { GeneratePrint } from "../../common/components/printModal/GeneratePrint";
import { OtConsentPrintData } from "../services/otConsentServices/OtConsentServices ";

export default function OtConsentPrint(props) {
  let printRef = useRef(null);

  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    OtConsentPrintData(props?.otConsentId)
      .then((response) => response.data)
      .then((res) => {
        setData(res.result);
      });
  }, [props?.otConsentId]);

  const DynamicHTMLRenderer = ({ htmlContent }) => {
    return (
      <div
        className="break-words"
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />
    );
  };

  useEffect(() => {
    data !== null && printRef && GeneratePrint(printRef);
  }, [data]);
  return (
    <div ref={printRef}>
      <table className="w-full">
        <thead
          style={{
            "-fs-table-paginate": "paginate",
          }}
        >
          <div>
            <div className=" justify-end flex ">
              <div>
                <InamdarHospiLogo />
              </div>
            </div>
            <div className=" border-t-[1px] font-semibold text-[15px] border-black text-center">
              OT Conset
            </div>
            <div className=" grid grid-cols-[20%_5%_25%_20%_5%_25%] border-b-[1px] border-t-[1px] p-1 border-black ">
              <div className=" text-[13px] font-semibold  ">PatientName</div>
              <div className=" text-[13px] ">:</div>
              <div className=" text-[13px] whitespace-nowrap ">
                {data?.PatientName}
              </div>
              <div className=" text-[13px] font-semibold ">MR No</div>
              <div className=" text-[13px] ">:</div>
              <div className=" text-[13px] ">{data?.MRNo}</div>
              <div className=" text-[13px] font-semibold ">Gender</div>
              <div className=" text-[13px] ">:</div>
              <div className=" text-[13px] ">{data?.Gender}</div>
              <div className=" text-[13px] font-semibold ">Consent Date</div>
              <div className=" text-[13px] ">:</div>
              <div className=" text-[13px] ">{data?.ConsentDate}</div>
              <div className=" text-[13px]  font-semibold">Admission Date</div>
              <div className=" text-[13px] ">:</div>
              <div className=" text-[13px] ">{data?.AdmissionDate}</div>
              <div className=" text-[13px] font-semibold ">Adviced By</div>
              <div className=" text-[13px] ">:</div>
              <div className=" text-[13px] ">
                {data?.AdvicedBy ? data?.AdvicedBy : "-"}
              </div>
              <div className=" text-[13px] font-semibold ">Age</div>
              <div className=" text-[13px] ">:</div>
              <div className=" text-[13px] ">{data?.AgeYear}</div>
              <div className=" text-[13px] font-semibold ">DOB</div>
              <div className=" text-[13px] ">:</div>
              <div className=" text-[13px] ">{data?.dob}</div>
            </div>
            <div className=" border-b-[1px] font-semibold p-1 text-[13px] border-black text-center">
              {data?.TemplateName}
            </div>
          </div>
        </thead>
        <tbody>
          <tr>
            {/*<div
              className="pt-5 text-wrap flex flex-wrap  "
              // dangerouslySetInnerHTML={{ __html: data?.TempalteDescription }}
            >
              <h1> {data?.TempalteDescription}</h1>
        </div>*/}
            <DynamicHTMLRenderer htmlContent={data?.TempalteDescription} />
          </tr>
        </tbody>
      </table>
    </div>
  );
}
