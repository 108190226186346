import React from "react";

export const DeleteIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3485_10997)">
        <path
          d="M16 9V19H8V9H16ZM14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM18 7H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7Z"
          fill="#EF4444"
        />
      </g>
      <defs>
        <clipPath id="clip0_3485_10997">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export const DeleteDisableIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3485_10997)">
        <path
          d="M16 9V19H8V9H16ZM14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM18 7H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7Z"
          fill="#D7D7D7"
        />
      </g>
      <defs>
        <clipPath id="clip0_3485_10997">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

//Black Cross Icon
export const EditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M6.2253 20.25H2.75V16.9011C2.75 16.8338 2.77719 16.7693 2.8254 16.7222L13.1918 6.60669L16.7502 10.0791L6.39989 20.1789C6.3532 20.2245 6.29054 20.25 6.2253 20.25Z"
        stroke="#007EA9"
        stroke-width="1.5"
      />
      <path
        d="M12.1406 20.25H21.2498V17.75H14.6616L12.1406 20.25Z"
        fill="#007EA9"
        stroke="#007EA9"
        stroke-width="1.5"
      />
      <path
        d="M17.8241 9.03109L19.5609 7.33607C19.7411 7.16027 19.7411 6.88578 19.5609 6.70999L16.6703 3.88934C16.4799 3.70354 16.1665 3.70357 15.9761 3.88935L14.2656 5.55874L17.8241 9.03109Z"
        fill="#007EA9"
        stroke="#007EA9"
        stroke-width="1.5"
      />
    </svg>
  );
};

export const DisableEditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M6.2253 20.25H2.75V16.9011C2.75 16.8338 2.77719 16.7693 2.8254 16.7222L13.1918 6.60669L16.7502 10.0791L6.39989 20.1789C6.3532 20.2245 6.29054 20.25 6.2253 20.25Z"
        stroke="#D7D7D7"
        stroke-width="1.5"
      />
      <path
        d="M12.1406 20.25H21.2498V17.75H14.6616L12.1406 20.25Z"
        fill="#D7D7D7"
        stroke="#D7D7D7"
        stroke-width="1.5"
      />
      <path
        d="M17.8241 9.03109L19.5609 7.33607C19.7411 7.16027 19.7411 6.88578 19.5609 6.70999L16.6703 3.88934C16.4799 3.70354 16.1665 3.70357 15.9761 3.88935L14.2656 5.55874L17.8241 9.03109Z"
        fill="#D7D7D7"
        stroke="#D7D7D7"
        stroke-width="1.5"
      />
    </svg>
  );
};

export const CommonCrossIcon = () => {
  return (
    <div>
      <svg
        width="17"
        height="17"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.9425 5L8 6.9425L6.0575 5L5 6.0575L6.9425 8L5 9.9425L6.0575 11L8 9.0575L9.9425 11L11 9.9425L9.0575 8L11 6.0575L9.9425 5ZM8 0.5C3.8525 0.5 0.5 3.8525 0.5 8C0.5 12.1475 3.8525 15.5 8 15.5C12.1475 15.5 15.5 12.1475 15.5 8C15.5 3.8525 12.1475 0.5 8 0.5ZM8 14C4.6925 14 2 11.3075 2 8C2 4.6925 4.6925 2 8 2C11.3075 2 14 4.6925 14 8C14 11.3075 11.3075 14 8 14Z"
          fill="#232323"
        />
      </svg>
    </div>
  );
};

//Gray Cross Icon
export const CommonCrossDisableIcon = () => {
  return (
    <div>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.9425 5L8 6.9425L6.0575 5L5 6.0575L6.9425 8L5 9.9425L6.0575 11L8 9.0575L9.9425 11L11 9.9425L9.0575 8L11 6.0575L9.9425 5ZM8 0.5C3.8525 0.5 0.5 3.8525 0.5 8C0.5 12.1475 3.8525 15.5 8 15.5C12.1475 15.5 15.5 12.1475 15.5 8C15.5 3.8525 12.1475 0.5 8 0.5ZM8 14C4.6925 14 2 11.3075 2 8C2 4.6925 4.6925 2 8 2C11.3075 2 14 4.6925 14 8C14 11.3075 11.3075 14 8 14Z"
          fill="#D7D7D7"
        />
      </svg>
    </div>
  );
};

export const TransferToWardIcon = () => {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.31741 5.55982C8.26401 5.46128 8.32822 5.27844 9.31961 2.68573C10.3693 0.204275 10.5003 0.123393 10.6084 0.056923C10.6876 0.00817832 10.769 -0.0100308 10.8541 0.00528266L10.983 0.021558L11.7258 1.39254L11.8151 1.35353C11.8632 1.3359 12.0987 1.23879 12.3342 1.14168C13.5709 0.623687 14.8583 0.30547 15.9113 0.254864C16.4537 0.227779 17.3971 0.356057 17.8139 0.513733C17.8856 0.540871 17.9571 0.561178 18.0377 0.584068C18.4254 0.694169 19.0235 0.864035 20.8697 2.14144C21.1218 2.55446 20.9434 2.81555 20.87 2.92312C20.8549 2.94523 20.8442 2.96085 20.8426 2.96998C20.8426 2.96998 20.5664 3.16812 20.4139 3.05494C19.3442 2.64671 17.4117 2.43163 15.7164 2.92138C14.3182 3.32531 13.8956 3.86608 13.5639 4.29052C13.5143 4.35392 13.4668 4.41473 13.4183 4.4721L13.7715 5.16821L14.1272 5.82467C14.1272 5.82467 13.8607 6.36126 11.1507 6.07422C8.53439 5.79709 8.36918 5.65538 8.31741 5.55982Z"
        fill="#0B83A5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.24017 4.83667C3.94596 4.11631 5.08983 4.11671 5.79509 4.83755L6.65755 5.71901C7.36291 6.43991 7.36253 7.60831 6.65669 8.32872L6.48019 8.50887L6.65573 8.68936C6.68337 8.71779 6.72088 8.7338 6.75993 8.7338H16.7998C17.8407 8.7338 18.6844 9.60293 18.6844 10.6751C18.6844 11.5885 18.0721 12.3545 17.2472 12.5614V14.0848H19.1635V15.0636H16.0519C16.3625 15.3745 16.5551 15.8078 16.5551 16.2871C16.5551 17.2331 15.8044 18 14.8783 18C13.9523 18 13.2015 17.2331 13.2015 16.2871C13.2015 15.8078 13.3942 15.3745 13.7048 15.0636H5.4587C5.76929 15.3745 5.96198 15.8078 5.96198 16.2871C5.96198 17.2331 5.21125 18 4.28517 18C3.3591 18 2.60837 17.2331 2.60837 16.2871C2.60837 15.8078 2.80107 15.3745 3.11165 15.0636H0V14.0848H1.43726V8.86907L1.0382 8.45866C0.292718 7.69195 0.292718 6.44764 1.0382 5.68094C1.54341 5.16139 2.26123 4.99469 2.90284 5.18096L3.24017 4.83667ZM5.97945 7.63631L5.80496 7.8144L3.74949 5.70047L3.9174 5.52908C4.24889 5.19075 4.78618 5.19095 5.11742 5.52952L5.97988 6.41098C6.31107 6.74946 6.31088 7.29803 5.97945 7.63631ZM2.39544 14.0848H16.289V12.6164H5.30456C5.16136 12.6164 5.02405 12.5579 4.92272 12.4537L2.39544 9.85449V14.0848Z"
        fill="#0B83A5"
      />
    </svg>
  );
};

export const TransferToWardDisableIcon = () => {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.31741 5.55982C8.26401 5.46128 8.32822 5.27844 9.31961 2.68573C10.3693 0.204275 10.5003 0.123393 10.6084 0.056923C10.6876 0.00817832 10.769 -0.0100308 10.8541 0.00528266L10.983 0.021558L11.7258 1.39254L11.8151 1.35353C11.8632 1.3359 12.0987 1.23879 12.3342 1.14168C13.5709 0.623687 14.8583 0.30547 15.9113 0.254864C16.4537 0.227779 17.3971 0.356057 17.8139 0.513733C17.8856 0.540871 17.9571 0.561178 18.0377 0.584068C18.4254 0.694169 19.0235 0.864035 20.8697 2.14144C21.1218 2.55446 20.9434 2.81555 20.87 2.92312C20.8549 2.94523 20.8442 2.96085 20.8426 2.96998C20.8426 2.96998 20.5664 3.16812 20.4139 3.05494C19.3442 2.64671 17.4117 2.43163 15.7164 2.92138C14.3182 3.32531 13.8956 3.86608 13.5639 4.29052C13.5143 4.35392 13.4668 4.41473 13.4183 4.4721L13.7715 5.16821L14.1272 5.82467C14.1272 5.82467 13.8607 6.36126 11.1507 6.07422C8.53439 5.79709 8.36918 5.65538 8.31741 5.55982Z"
        fill="#D7D7D7"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.24017 4.83667C3.94596 4.11631 5.08983 4.11671 5.79509 4.83755L6.65755 5.71901C7.36291 6.43991 7.36253 7.60831 6.65669 8.32872L6.48019 8.50887L6.65573 8.68936C6.68337 8.71779 6.72088 8.7338 6.75993 8.7338H16.7998C17.8407 8.7338 18.6844 9.60293 18.6844 10.6751C18.6844 11.5885 18.0721 12.3545 17.2472 12.5614V14.0848H19.1635V15.0636H16.0519C16.3625 15.3745 16.5551 15.8078 16.5551 16.2871C16.5551 17.2331 15.8044 18 14.8783 18C13.9523 18 13.2015 17.2331 13.2015 16.2871C13.2015 15.8078 13.3942 15.3745 13.7048 15.0636H5.4587C5.76929 15.3745 5.96198 15.8078 5.96198 16.2871C5.96198 17.2331 5.21125 18 4.28517 18C3.3591 18 2.60837 17.2331 2.60837 16.2871C2.60837 15.8078 2.80107 15.3745 3.11165 15.0636H0V14.0848H1.43726V8.86907L1.0382 8.45866C0.292718 7.69195 0.292718 6.44764 1.0382 5.68094C1.54341 5.16139 2.26123 4.99469 2.90284 5.18096L3.24017 4.83667ZM5.97945 7.63631L5.80496 7.8144L3.74949 5.70047L3.9174 5.52908C4.24889 5.19075 4.78618 5.19095 5.11742 5.52952L5.97988 6.41098C6.31107 6.74946 6.31088 7.29803 5.97945 7.63631ZM2.39544 14.0848H16.289V12.6164H5.30456C5.16136 12.6164 5.02405 12.5579 4.92272 12.4537L2.39544 9.85449V14.0848Z"
        fill="#D7D7D7"
      />
    </svg>
  );
};

export const ViewIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C13.38 9.5 14.5 10.62 14.5 12C14.5 13.38 13.38 14.5 12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 10.62 10.62 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z"
        fill="#073763"
      />
      <path
        d="M16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12Z"
        fill="white"
      />
      <path
        d="M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z"
        fill="white"
      />
      <path
        d="M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z"
        fill="#073763"
      />
    </svg>
  );
};

export const PrintIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3777_12340)">
        <path
          d="M19 8H5C3.34 8 2 9.34 2 11V17H6V21H18V17H22V11C22 9.34 20.66 8 19 8ZM16 19H8V14H16V19ZM19 12C18.45 12 18 11.55 18 11C18 10.45 18.45 10 19 10C19.55 10 20 10.45 20 11C20 11.55 19.55 12 19 12ZM18 3H6V7H18V3Z"
          fill="#E3902F"
        />
      </g>
      <defs>
        <clipPath id="clip0_3777_12340">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const RescheduleIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 16.666V4.99935C17.5 4.08018 16.7525 3.33268 15.8333 3.33268H14.1667V1.66602H12.5V3.33268H7.5V1.66602H5.83333V3.33268H4.16667C3.2475 3.33268 2.5 4.08018 2.5 4.99935V16.666C2.5 17.5852 3.2475 18.3327 4.16667 18.3327H15.8333C16.7525 18.3327 17.5 17.5852 17.5 16.666ZM7.5 14.9993H5.83333V13.3327H7.5V14.9993ZM7.5 11.666H5.83333V9.99935H7.5V11.666ZM10.8333 14.9993H9.16667V13.3327H10.8333V14.9993ZM10.8333 11.666H9.16667V9.99935H10.8333V11.666ZM14.1667 14.9993H12.5V13.3327H14.1667V14.9993ZM14.1667 11.666H12.5V9.99935H14.1667V11.666ZM15.8333 7.49935H4.16667V5.83268H15.8333V7.49935Z"
        fill="#0E7DE4"
      />
    </svg>
  );
};

export const UploadIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_8956_3328)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4 4H10L12 6H20C21.1 6 22 6.9 22 8V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18L2.01 6C2.01 4.9 2.9 4 4 4ZM13.7143 12.2941V15.1176H10.2857V12.2941H8L12 9L16 12.2941H13.7143ZM16 17V16.0588H8V17H16Z"
          fill="#073763"
        />
      </g>
      <defs>
        <clipPath id="clip0_8956_3328">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const NextIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle
        cx="12"
        cy="12"
        r="12"
        transform="rotate(-180 12 12)"
        fill="#0B83A5"
      />
      <path
        d="M10 17L14.3382 11.9768C14.7024 11.5552 14.6517 10.9172 14.2255 10.5583L10 7"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};

export const PreviousIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle cx="12" cy="12" r="12" fill="#0B83A5" />
      <path
        d="M14 7L9.66182 12.0232C9.29765 12.4448 9.34833 13.0828 9.77451 13.4417L14 17"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};

export const TrendChartIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 17.7498L10.1111 10.1664C10.4152 9.79076 11.033 9.95606 11.0978 10.4304L12.1573 15.3795C12.2226 15.8565 12.8458 16.0199 13.1476 15.6391L20.1111 8"
        stroke="#0C8CE9"
      />
      <path
        d="M14.2221 16.2917C14.2221 17.1892 13.4759 17.9167 12.5555 17.9167C11.635 17.9167 10.8888 17.1892 10.8888 16.2917C10.8888 15.3943 11.635 14.6668 12.5555 14.6668C13.4759 14.6668 14.2221 15.3943 14.2221 16.2917Z"
        fill="#D90B0B"
      />
      <path
        d="M21.9999 7.62497C21.9999 8.52241 21.2537 9.24993 20.3332 9.24993C19.4128 9.24993 18.6666 8.52241 18.6666 7.62497C18.6666 6.72752 19.4128 6 20.3332 6C21.2537 6 21.9999 6.72752 21.9999 7.62497Z"
        fill="#D90B0B"
      />
      <path
        d="M5.88889 17.4675C5.88889 18.3649 5.1427 19.0925 4.22222 19.0925C3.30175 19.0925 2.55556 18.3649 2.55556 17.4675C2.55556 16.5701 3.30175 15.8425 4.22222 15.8425C5.1427 15.8425 5.88889 16.5701 5.88889 17.4675Z"
        fill="#D90B0B"
      />
    </svg>
  );
};

export const DetailedReportIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3134_3871)">
        <path
          d="M18.8438 0.46875H7.03125C6.51324 0.46875 6.09375 0.888245 6.09375 1.40625V22.5938C6.09375 23.1118 6.51324 23.5312 7.03125 23.5312H22.5938C23.1118 23.5312 23.5312 23.1118 23.5312 22.5938V5.15625L18.8438 0.46875Z"
          fill="#F2F2FC"
        />
        <path
          d="M23.5312 5.15625H18.8438V0.46875L23.5312 5.15625Z"
          fill="#BFE7F2"
        />
        <path
          d="M20.5781 12.9375C21.0961 12.9375 21.5156 13.357 21.5156 13.875C21.5156 14.393 21.0961 14.8125 20.5781 14.8125C20.0601 14.8125 19.6406 14.393 19.6406 13.875C19.6406 13.357 20.0601 12.9375 20.5781 12.9375Z"
          fill="#FF6961"
        />
        <path
          d="M15.75 18.5625C16.268 18.5625 16.6875 18.982 16.6875 19.5C16.6875 20.018 16.268 20.4375 15.75 20.4375C15.232 20.4375 14.8125 20.018 14.8125 19.5C14.8125 18.9825 15.2325 18.5625 15.75 18.5625Z"
          fill="#FFD685"
        />
        <path
          d="M12.4688 14.8125C12.9868 14.8125 13.4062 15.232 13.4062 15.75C13.4062 16.2675 12.9862 16.6875 12.4688 16.6875C11.9513 16.6875 11.5312 16.2675 11.5312 15.75C11.5312 15.232 11.9507 14.8125 12.4688 14.8125Z"
          fill="#65A8D1"
        />
        <path
          d="M9.84375 7.96875H5.15625V3.28125C5.47742 3.28125 5.79089 3.31366 6.09375 3.375C8.23315 3.80914 9.84375 5.70099 9.84375 7.96875Z"
          fill="#FFD685"
        />
        <path
          d="M5.15625 7.96875H9.84375C9.84375 10.5504 7.75195 12.6562 5.15625 12.6562C2.56732 12.6562 0.46875 10.5577 0.46875 7.96875C0.46875 5.37982 2.56732 3.28125 5.15625 3.28125V7.96875Z"
          fill="#65A8D1"
        />
        <path
          d="M9.84375 19.5C9.84375 20.018 9.42426 20.4375 8.90625 20.4375C8.38824 20.4375 7.96875 20.018 7.96875 19.5C7.96875 18.982 8.38824 18.5625 8.90625 18.5625C9.42371 18.5625 9.84375 18.9825 9.84375 19.5Z"
          fill="#70CCC9"
        />
        <path
          d="M11.7188 8.4375C11.9775 8.4375 12.1875 8.22748 12.1875 7.96875C12.1875 7.71002 11.9775 7.5 11.7188 7.5C11.46 7.5 11.25 7.71002 11.25 7.96875C11.25 8.22748 11.46 8.4375 11.7188 8.4375Z"
          fill="black"
        />
        <path
          d="M11.7188 10.3125C11.9775 10.3125 12.1875 10.1025 12.1875 9.84375C12.1875 9.58502 11.9775 9.375 11.7188 9.375C11.46 9.375 11.25 9.58502 11.25 9.84375C11.25 10.1025 11.46 10.3125 11.7188 10.3125Z"
          fill="black"
        />
        <path
          d="M23.8627 4.82483L19.1752 0.137329C19.0922 0.0541992 18.9717 0 18.8438 0H7.03125C6.2558 0 5.625 0.630798 5.625 1.40625V2.83411C5.46936 2.82001 5.31281 2.8125 5.15625 2.8125C2.31317 2.8125 0 5.12567 0 7.96875C0 10.8118 2.31317 13.125 5.15625 13.125C5.31281 13.125 5.46936 13.1175 5.625 13.1034V22.5938C5.625 23.3692 6.2558 24 7.03125 24H22.5938C23.3692 24 24 23.3692 24 22.5938V5.15625C24 5.02826 23.9467 4.90887 23.8627 4.82483ZM19.3125 1.60034L22.3997 4.6875H19.3125V1.60034ZM5.625 3.77618C7.60437 3.99463 9.13147 5.57281 9.3479 7.5H5.625V3.77618ZM0.9375 7.96875C0.9375 5.80096 2.58105 4.01019 4.6875 3.77618V7.96875C4.6875 8.22766 4.89734 8.43695 5.15625 8.43695H9.3479C9.11243 10.5339 7.33832 12.1875 5.15625 12.1875C2.83008 12.1875 0.9375 10.2949 0.9375 7.96875ZM22.5938 23.0625H7.03125C6.77271 23.0625 6.5625 22.8523 6.5625 22.5938V12.9298C8.75684 12.3087 10.3125 10.2817 10.3125 7.96875C10.3125 5.65576 8.75684 3.62878 6.5625 3.00769V1.40625C6.5625 1.14771 6.77271 0.9375 7.03125 0.9375H18.375V5.15625C18.375 5.41516 18.5848 5.625 18.8438 5.625H23.0625V22.5938C23.0625 22.8523 22.8523 23.0625 22.5938 23.0625Z"
          fill="black"
        />
        <path
          d="M12 3.75H16.9688C17.2277 3.75 17.4375 3.54016 17.4375 3.28125C17.4375 3.02234 17.2277 2.8125 16.9688 2.8125H12C11.7411 2.8125 11.5312 3.02234 11.5312 3.28125C11.5312 3.54016 11.7411 3.75 12 3.75Z"
          fill="black"
        />
        <path
          d="M12 5.625H16.9688C17.2277 5.625 17.4375 5.41516 17.4375 5.15625C17.4375 4.89734 17.2277 4.6875 16.9688 4.6875H12C11.7411 4.6875 11.5312 4.89734 11.5312 5.15625C11.5312 5.41516 11.7411 5.625 12 5.625Z"
          fill="black"
        />
        <path
          d="M5.15625 10.3125C3.86389 10.3125 2.8125 9.26111 2.8125 7.96875C2.8125 7.70984 2.60266 7.5 2.34375 7.5C2.08484 7.5 1.875 7.70984 1.875 7.96875C1.875 9.77802 3.34698 11.25 5.15625 11.25C5.41516 11.25 5.625 11.0402 5.625 10.7812C5.625 10.5223 5.41516 10.3125 5.15625 10.3125Z"
          fill="black"
        />
        <path
          d="M20.5781 12.4688C19.8027 12.4688 19.1719 13.0995 19.1719 13.875C19.1719 14.1656 19.2605 14.436 19.4121 14.6603L16.157 18.154C16.0281 18.115 15.8915 18.0938 15.75 18.0938C15.5522 18.0938 15.3609 18.1348 15.1851 18.2119L13.719 16.3943C13.8208 16.1975 13.875 15.978 13.875 15.75C13.875 14.9745 13.2442 14.3438 12.4688 14.3438C11.6933 14.3438 11.0625 14.9745 11.0625 15.75C11.0625 15.969 11.1127 16.1801 11.2068 16.3709L9.4975 18.2236C9.31458 18.1386 9.11389 18.0938 8.90625 18.0938C8.1308 18.0938 7.5 18.7245 7.5 19.5C7.5 20.2755 8.1308 20.9062 8.90625 20.9062C9.6817 20.9062 10.3125 20.2755 10.3125 19.5C10.3125 19.281 10.2625 19.0699 10.1682 18.8791L11.8775 17.0264C12.0604 17.1114 12.2611 17.1562 12.4688 17.1562C12.6665 17.1562 12.8578 17.1152 13.0336 17.0381L14.4998 18.8557C14.3979 19.0525 14.3438 19.272 14.3438 19.5C14.3438 20.2755 14.9745 20.9062 15.75 20.9062C16.5255 20.9062 17.1562 20.2755 17.1562 19.5C17.1562 19.2094 17.0676 18.939 16.916 18.7147L20.1711 15.221C20.3 15.26 20.4366 15.2812 20.5781 15.2812C21.3536 15.2812 21.9844 14.6505 21.9844 13.875C21.9844 13.0995 21.3536 12.4688 20.5781 12.4688ZM8.90625 19.9688C8.64771 19.9688 8.4375 19.7585 8.4375 19.5C8.4375 19.2415 8.64771 19.0312 8.90625 19.0312C9.16498 19.0312 9.375 19.2411 9.375 19.5C9.375 19.7585 9.16479 19.9688 8.90625 19.9688ZM12 15.75C12 15.4915 12.2102 15.2812 12.4688 15.2812C12.7273 15.2812 12.9375 15.4915 12.9375 15.75C12.9375 16.0087 12.7277 16.2188 12.4688 16.2188C12.2098 16.2188 12 16.0087 12 15.75ZM15.75 19.9688C15.4915 19.9688 15.2812 19.7585 15.2812 19.5C15.2812 19.2413 15.4911 19.0312 15.75 19.0312C16.0085 19.0312 16.2188 19.2415 16.2188 19.5C16.2188 19.7585 16.0085 19.9688 15.75 19.9688ZM20.5781 14.3438C20.3196 14.3438 20.1094 14.1335 20.1094 13.875C20.1094 13.6165 20.3196 13.4062 20.5781 13.4062C20.8367 13.4062 21.0469 13.6165 21.0469 13.875C21.0469 14.1335 20.8367 14.3438 20.5781 14.3438Z"
          fill="black"
        />
        <path
          d="M13.125 7.96875C13.125 8.22766 13.3348 8.4375 13.5938 8.4375H20.7188C20.9777 8.4375 21.1875 8.22766 21.1875 7.96875C21.1875 7.70984 20.9777 7.5 20.7188 7.5H13.5938C13.3348 7.5 13.125 7.70984 13.125 7.96875Z"
          fill="black"
        />
        <path
          d="M20.7188 9.375H13.5938C13.3348 9.375 13.125 9.58484 13.125 9.84375C13.125 10.1027 13.3348 10.3125 13.5938 10.3125H20.7188C20.9777 10.3125 21.1875 10.1027 21.1875 9.84375C21.1875 9.58484 20.9777 9.375 20.7188 9.375Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_3134_3871">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const EmailEnableIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.70293 6.584L10.5147 11.6154C11.3975 12.2675 12.6025 12.2675 13.4853 11.6154L20.2971 6.584L19.7029 5.77964L12.8912 10.811C12.3615 11.2023 11.6385 11.2023 11.1088 10.811L4.29707 5.77964L3.70293 6.584ZM5 5.5H19C19.8284 5.5 20.5 6.17157 20.5 7V16C20.5 16.8284 19.8284 17.5 19 17.5H5C4.17157 17.5 3.5 16.8284 3.5 16V7C3.5 6.17157 4.17157 5.5 5 5.5Z"
        stroke="#073763"
      />
    </svg>
  );
};

export const EmailDisableIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.70293 6.584L10.5147 11.6154C11.3975 12.2675 12.6025 12.2675 13.4853 11.6154L20.2971 6.584L19.7029 5.77964L12.8912 10.811C12.3615 11.2023 11.6385 11.2023 11.1088 10.811L4.29707 5.77964L3.70293 6.584ZM5 5.5H19C19.8284 5.5 20.5 6.17157 20.5 7V16C20.5 16.8284 19.8284 17.5 19 17.5H5C4.17157 17.5 3.5 16.8284 3.5 16V7C3.5 6.17157 4.17157 5.5 5 5.5Z"
        stroke="#D7D7D7"
      />
    </svg>
  );
};
