import SampleCollection from "../../../http-common";
import authHeader from "../../../authentication/authservices/auth-header";

export const saveSampleCollection = async (saveFinalObj) => {
  return SampleCollection.post(
    `/pathSampleCollection/saveSampleCollection`,
    saveFinalObj,
    {
      headers: authHeader(),
    }
  );
};

export const saveOutSourceSample = async (saveOutsourceObj) => {
  return SampleCollection.post(
    `/pathSampleCollection/saveOutSourceSample`,
    saveOutsourceObj,
    {
      headers: authHeader(),
    }
  );
};

export const searchPatientList = (patientSearch) => {
  return SampleCollection.post(
    `/pathSampleCollection/sampleCollectionPatientList`,
    patientSearch,
    {
      headers: authHeader(),
    }
  );
};

export const searchPatientTestList = (patientSearch) => {
  return SampleCollection.post(
    `/pathSampleCollection/sampleCollectionPatientTest`,
    patientSearch,
    {
      headers: authHeader(),
    }
  );
};

export const saveColorTubeTemperature = (postColorTube) => {
  return SampleCollection.post(
    `/pathSampleCollection/saveColorTubeTemperature`,
    postColorTube,
    {
      headers: authHeader(),
    }
  );
};

export const autoSerachPatient = (searchObj) => {
  return SampleCollection.post(
    `/pathSampleCollection/sampleCollectionOrderListSearch`,
    searchObj,
    {
      headers: authHeader(),
    }
  );
};

export const getSampleCategoryList = () => {
  return SampleCollection.get(`/pathSampleCollection/sampleCategory`, {
    headers: authHeader(),
  });
};

export const getUnitList = () => {
  return SampleCollection.get(`/misc/unit`, {
    headers: authHeader(),
  });
};

export const getCompany = () => {
  return SampleCollection.get(`/misc/company`, {
    headers: authHeader(),
  });
};

export const colourTubeTemperature = (orderDtlsId) => {
  return SampleCollection.get(
    `/pathSampleCollection/colourTubeTemperature/${orderDtlsId}`,
    {
      headers: authHeader(),
    }
  );
};

export const fetchSampleCollectionBarcode = (orderId) => {
  return SampleCollection.get(`/barcode/sampleBarcode?orderId=${orderId}`, {
    headers: authHeader(),
    responseType: "blob",
  });
};
