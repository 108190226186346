import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import { styled, useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { QueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import "../../App.css";
import PatientAdvance from "../../billing/components/PatientAdvance";
import IPDPatientAdvance from "../../ipd/components/advance/PatientAdvance";

import AppointmentPage from "../../opd/components/AppointmentBooking/AppointmentPage";
import PatientEngagement from "../../PatientEngagement/components/PatientEngagement";
import PatientOrderManagementDetails from "../../PatientEngagement/components/PatientOrderManagementDetails";

import Fade from "@mui/material/Fade";
import Tooltip from "@mui/material/Tooltip";
import {
  redirect,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import CompanySettlement from "../../billing/components/billsettlement/company/CompanySettlement";
import OPDConsessionManager from "../../billing/components/billsettlement/concessionmanager/OPDConcessionManager";
import SelfBillSettlement from "../../billing/components/billsettlement/self/SelfBillSettlement";
import OPDBilling from "../../billing/components/opdbilling/OPDBilling";
import IPDBilling from "../../ipd/components/bill/IPDBilling";
import Discharge from "../../ipd/components/discharge/Discharge";
import IPDRefundOfBill from "../../ipd/components/refund/bill/IPDRefundOfBill";
import Login from "../../login/components/Login";
import VentilatorBundle from "../../nursingStation/components/ventilatorBundle/VentilatorBundle";
import RefundOfAdvance from "../../opd/components/refund/advance/RefundOfAdvance";
import RefundOfBill from "../../opd/components/refund/bill/RefundOfBill";
import PatientRegistrationForm from "../../opd/components/registration/PatientRegistrationForm";
import PatientVisitList from "../../opd/components/visitlist/PatientVisitList";

import PatientAdmissionDetails from "../../ipd/components/admission/patientAdmissionDetails/AdmissionDetailsForm";
// import InamdarHospiLogo"
import InamdarHospiLogo from "../../assets/icons/InamdarHospiLogo.png";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import EmergencyModal from "../../emergency/components/registration/Emergency";
import AdmissionListing from "../../ipd/components/admission/admissionlisting/AdmissionList";
import BedAllowModal from "../../ipd/components/bedallocation/BedAllowModal";
import IPDCharges from "../../ipd/components/charges/IPDCharges";
import IPDRefundOfAdvance from "../../ipd/components/refund/advance/IPDRefundOfAdvance";
// import BedAllowModal from "../../ipd/components/bedallowcation/BedAllocation"
// import DischargeSummary from "../../ipd/components/dischargeSummary/DischargeSummary";
import EtuRegister from "../../emergency/components/etuRegister/EtuRegister";
import OPDCasePaperRegister from "../../emergency/components/opdcasepaperregister/OPDCasePaperRegister";
import PathReportDelivery from "../../Investigation/Pathology/Components/PathReportDelivery";
import PathWorkOrderGeneration from "../../Investigation/Pathology/Components/PathWorkOrderGeneration";
import SampleCollection from "../../Investigation/Pathology/Components/sampleCollection/SampleCollection";
import WorkOrderStatus from "../../Investigation/Pathology/Components/WorkOrderStatus";
import WorkOrderView from "../../Investigation/Pathology/Components/workOrderView/WorkOrderView";
import RadiologyWorkOrderView from "../../Investigation/Radiology/Components/radiologyWorkOrderView/RadiologyWorkOrderView";
import RadioReportDelivery from "../../Investigation/Radiology/Components/RadioReportDelivery";
import RadioWorkOrderGeneration from "../../Investigation/Radiology/Components/RadioWorkOrderGeneration";
import BedRelease from "../../ipd/components/bedRelease/BedRelease";
import BedTransfer from "../../ipd/components/bedtransfer/BedTransfer";
import IPDCompanySettlement from "../../ipd/components/billsettlement/company/IPDCompanySettlement";
import IPDConcessionManager from "../../ipd/components/billsettlement/concessionmanager/IPDConcessionManager";
import IPDSelfBillSettlement from "../../ipd/components/billsettlement/self/IPDSelfBillSettlement";
import CarePlan from "../../ipd/components/carePlan/CarePlan";
import EtuCaseSheet from "../../ipd/components/ETUCaseSheet/EtuCaseSheet";
//nursing station
import DrugAdminstratorOrder from "../../ipd/components/drugadministratororder/DrugAdministratorOrder";
import ClinicalCareChart from "../../nursingStation/components/clinicalcarechart/ClinicalCareChart";
import InPatientIssue from "../../nursingStation/components/inPatientIssue/InPatientIssue";
import NursingInPatientReturn from "../../nursingStation/components/inPatientReturn/InPatientReturn";
// import MaterialConsumptionNursing from "../../nursingStation/components/materialConsumption/MaterialConsumptionNursing";

import InventoryMaterialConsumption from "../../inventory/components/generalStore/InventoryMaterialConsumption/InventoryMaterialConsumption";
import DrugAdministrationChart from "../../nursingStation/components/drugadministratorchart/DrugAdministrationChart";
import NursingMaterialConsumption from "../../nursingStation/components/NursingStationMaterialConsumption/NursingMaterialConsumption";

import DietChartDetails from "../../nursingStation/components/diet/dietchartdetails/DietChartDetails";
import DietOrder from "../../nursingStation/components/diet/DietOrder";
import NursingTakeOverDetails from "../../nursingStation/components/nursingsupervisorreport/nursingreport/NursingTakeOverDetails";
import NursingSupervisor from "../../nursingStation/components/nursingsupervisorreport/nursingSupervisor/PatientCount";

//inventory/generalstore
import PatientIndent from "../../inventory/components/common/patientIndent/PatientIndent";
import StoreIndent from "../../inventory/components/common/storeIndent/StoreIndent";
import ExpiryAdjustment from "../../inventory/components/generalStore/expiryAdjustment/ExpiryAdjustment";
import StoreGRN from "../../inventory/components/generalStore/grn/StoreGRN";
import GRNReturn from "../../inventory/components/generalStore/grnReturn/GRNReturn";
import GRNReturnMultiple from "../../inventory/components/generalStore/grnReturnMultiple/GRNReturnMultiple";
import GsIssueToDept from "../../inventory/components/generalStore/issueToDepartment/GsIssueToDept";
import MRPAdjustment from "../../inventory/components/generalStore/mrpAdjustment/MRPAdjustment";
import POMultiple from "../../inventory/components/generalStore/poMultiple/POMultiple";
import PurchaseOrder from "../../inventory/components/generalStore/purchaseOrder/PurchaseOrder";
import GsReturnFromDept from "../../inventory/components/generalStore/returnFromDept/GsReturnFromDept";
import StockAdjustment from "../../inventory/components/generalStore/stockAdjustment/StockAdjustment";
import WorkOrder from "../../inventory/components/generalStore/workOrder/WorkOrder";
import WorkOrderGRN from "../../inventory/components/generalStore/workOrderGRN/workOrderGRN";

//inventory/pharmacy
import PharmacySelfSettlement from "../../inventory/components/pharmacy/billSettlement/selfSettlement/PharmacySelfSettlement";
import ExpiredItemReturn from "../../inventory/components/pharmacy/expiredItemsReturn/ExpiredItemsReturn";
import PharmacyExpiryAdjustment from "../../inventory/components/pharmacy/expiryAdjustment/ExpiryAdjustment";
import PharmacyInPatientIssue from "../../inventory/components/pharmacy/inPatientIssue/InPatientIssuePharmacy";
import PharmacyInPatientReturn from "../../inventory/components/pharmacy/inPatientReturn/PharmacyInPatientReturn";
import InPatientReturnApproval from "../../inventory/components/pharmacy/inPatientReturnApproval/InPatientReturnApproval";
import PharmacyIssueToDept from "../../inventory/components/pharmacy/issueToDepartment/PharmacIssueToDept";
import PharmacyMRPAdjustment from "../../inventory/components/pharmacy/mrpAdjustment/MRPAdjustment";
import PharmacyReturnFromDept from "../../inventory/components/pharmacy/returnFromDept/PharmacyReturnFromDept";
import PharmacyStockAdjustment from "../../inventory/components/pharmacy/stockAdjustment/StockAdjustment";
import StoreStockMinMax from "../../inventory/components/pharmacy/storeStockMinMax/StoreStockMinMax";

import OtConsent from "../../operationTheater/components/otConsent/OtConsent ";
import PostOpInstructions from "../../operationTheater/components/postOpInstructions/PostOpInstructions";
import PreOpInstruction from "../../operationTheater/components/preOpInstructions/PreOpInstruction";

////task manager
import ChangePassword from "../../administration/components/changePassword/ChangePassword";
import DocItLogo from "../../assets/icons/DocItLogo.png";
import BillEstimation from "../../billing/components/billestimation/BillEstimation";
import NIHStrokeScaleScoringSheet from "../../emergency/components/NIHStrokeScaleScoringSheet/NIHStrokeScaleScoringSheet";
import VerifyGrn from "../../inventory/components/generalStore/verifyGrn/VerifyGrn";
import WorkOrderMultiple from "../../inventory/components/generalStore/workOrderMultiple/WorkOrderMultiple";
import PharmacyCompanySettlement from "../../inventory/components/pharmacy/pharmacyCompanySettlement/PharmacyCompanySettlement";
import Enquiry from "../../inventory/components/purchase/enquiry/Enquiry";
import PartySettlement from "../../inventory/components/purchase/partySettlement/PartySettlement";
import PurchaseApproval from "../../inventory/components/purchase/purchaseapproval/PurchaseApproval";
import Quotation from "../../inventory/components/purchase/quotation/Quotation";
import SupplierAdvance from "../../inventory/components/purchase/supplierAdvance/SupplierAdvance";
import DischargeSummaryTabWise from "../../ipd/components/dischargeSummary/dischargeSummaryTabwise/DischargeSummaryTabWise";
import PathologyTemplateMaster from "../../masters/components/pathology/PathologyTemplateMaster";
import RadiologyTemplateMaster from "../../masters/components/radiology/RadiologyTemplateMaster";
// import QRGenerate from "../../masters/components/feedbackmasters/qrgenerate/QRGenerate";
import AnswerReviewCreation from "../../masters/components/feeddbackmasters/answerreview/AnswerReviewCreation";
import FeedBackQuestionSetCreation from "../../masters/components/feeddbackmasters/feedback/FeedbackQuestionSetCreation";
import DietetianModule from "../../nursingStation/components/diet/dietorder/dietetianmodule/DietetianModule";
import DivaTool from "../../nursingStation/components/divatool/DivaTool";
import Certificate from "../../opd/components/AppointmentBooking/certificate/Certificate";
import OfficePermissions from "../../operationTheater/components/officePermissions/OfficePermissions";
import OtDetails from "../../operationTheater/components/otDetails/otDetailsForm/OtDetails";
import OtDetailsList from "../../operationTheater/components/otDetails/otDetailsListing/OtDetailsList";
import OtReservation from "../../operationTheater/components/otReservation/OtReservation";
import CounterSales from "../../Pharmacy/counterSale/CounterSales";
import CounterSalesReturn from "../../Pharmacy/counterSalesReturn/CounterSalesReturn";
import BmiEgfrCalculator from "../../taskManager/components/bmiEgfrCalculator/BmiEgfrCalculator";
import CodeForms from "../../taskManager/components/codeForms/CodeForms";
import ComplaintManagement from "../../taskManager/components/ComplaintManagement/ComplaintManagement";
import FumigationChart from "../../taskManager/components/fumigationChart/FumigationChart";
import IncidentReporting from "../../taskManager/components/incidentReporting/IncidentReporting";
import MessegingConsole from "../../taskManager/components/messegingConsole/MessegingConsole";
import PatientClearence from "../../taskManager/components/patientClearence/PatientClearence";
import PendingAntibioticForm from "../../taskManager/components/pendingAntibioticForm/PendingAntibioticForm";
import DashboardPage from "./MainDashBoard/DashboardPage";

import GrnReturnList from "../../inventory/components/generalStore/GrnReturnlist/GrnReturnList";
import PatientTransfer from "../../ipd/components/patientTransfer/PatientTransfer";
import OtConcentMaster from "../../masters/components/operationTheater/OtConcentMaster";
import CodeBlueForm from "../../nursingStation/components/codeBlueForm/CodeBlueForm";
import SampleCollectionForColourTube from "../../nursingStation/components/sampleCollectionForColourTube/SampleCollectionForColourTube";
import OtDetailsIssuedItems from "../../operationTheater/components/otDetails/issudItems/OtDetailsIssuedItems";
import PendingImplantPatientList from "../../operationTheater/components/pendingImplantList/PendingImplantPatientList";

import DoctorSchedule from "../../masters/components/doctor/DoctorSchedule";
import AssignFuntionalities from "../../UserManagement/AssignFuctinalities/AssignFuntionalities";
import Role from "../../UserManagement/roles/Role";
import Users from "../../UserManagement/users/Users";

import BloodIssueAcceptance from "../../bloodBank/components/bloodIssueAcceptance/BloodIssueAcceptance";
import BloodIssueNote from "../../bloodBank/components/bloodIssueNote/BloodIssueNote";
import BloodRequisitionAcceptance from "../../bloodBank/components/bloodRequisitionAcceptance/BloodRequisitionAcceptance";
import BloodRequisitionNote from "../../bloodBank/components/bloodRequisitionNote/BloodRequisitionNote";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ContraVoucher from "../../accounting/components/contravoucher/ContraVoucher";
import MiscellaneousExpense from "../../accounting/components/miscellaneousExpense/MiscellaneousExpense";
import MiscellaneousIncome from "../../accounting/components/miscellaneousIncome/MiscellaneousIncome";
import MultipleVoucher from "../../accounting/components/multipleVoucher/MultipleVoucher";
import AutoCharges from "../../administration/components/autoCharges/AutoCharges";
import ConfigurationForm from "../../administration/components/configurationForm/ConfigurationForm";
import PatientDataMerge from "../../administration/components/patientDataMerge/PatientDataMerge";
import ReceiptCancellation from "../../administration/components/receiptCancellation/ReceiptCancellation";
import ScreenSaver from "../../administration/components/screenSaver/ScreenSaver";
import authHeader from "../../authentication/authservices/auth-header";
import EventBus from "../../authentication/EventBus";
import { logout } from "../../authentication/slices/auth";
import { baseUrl } from "../../http-common";
import GRNAmendments from "../../inventory/components/purchase/GRNAmendments/GRNAmendments";
import GeneralAssessment from "../../Investigation/Physiotheropy/Components/generalassessment/GeneralAssessment";
import PhysioWorkOrderGeneration from "../../Investigation/Physiotheropy/Components/PhysioWorkOrderGeneration";
import PhysioWorkOrderView from "../../Investigation/Physiotheropy/Components/physioworkorderview/PhysioWorkOrderView";
import NarcoticDrug from "../../ipd/components/narcoticdrugForm/NarcoticDrug";
import ServiceMasterForm from "../../masters/components/billing/ServiceMaster";
import ServiceMasterListing from "../../masters/components/billing/ServiceMasterListing";
import BioMedicalWasteManagement from "../../miscleneousServicesModule/components/bioMedicalWasteManagement/BioMedicalWasteManagement";
import AdmittedPatients from "../../nursingStation/components/admittedPatients/AdmittedPatients";
import Charges from "../../nursingStation/components/charges/Charges";
import MrdChecklist from "../../nursingStation/components/mrdChecklist/MrdChecklist";
import NeedleStickInjury from "../../nursingStation/components/needleStickInjury/NeedleStickInjury";
import Todayscallingdashboard from "../../postDischarge/TodaysCallingDashboard";
import FeedBackReport from "../../reports/feedbackReports/FeedBackReport";
import { store } from "../../store";
import ContactManagement from "../../taskManager/components/contactManagement/ContactManagement";
import HrDms from "../../taskManager/components/hrDms/HrDms";
import InventoryStock from "../../taskManager/components/inventoryStock/InventoryStock";
import IPDFileResgister from "../../taskManager/components/ipdFileResgister/IPDFileResgister";
import NotificationManagement from "../../taskManager/components/notificationManagement/NotificationManagement";
import PatientDocumentSystem from "../../taskManager/components/patientDocumentSystem/PatientDocumentSystem";
import PushTool from "../../taskManager/components/pushTool/PushTool";
import ShowItemListBelowStockLevel from "../../taskManager/components/showItemListBelowStockLevel/ShowItemListBelowStockLevel";
import StaffVsPatientRatio from "../../taskManager/components/staffVsPatientRatio/StaffVsPatientRatio";
import Privilege from "../../UserManagement/privilegetofuctionality/Privilege";
import { BellIcon } from "../../UserManagement/users/NotificationToast/BellIcon";
import { NotificationToast } from "../../UserManagement/users/NotificationToast/NotificationToast";
import DateTimeMismatchErrorPage from "./DateTimeMismatchErrorPage";
import NewMenuList from "./NewMenuList";
import PageNotFound from "./PageNotFound";

// feedback
import EmployeeFeedback from "../../feedback/EmployeeFeedback";
import AssignTemplate from "../../masters/components/feeddbackmasters/templateAssignment/AssignnTemplate";
import FeedBackDashbord from "./MainDashBoard/feedbackdashbord/FeedBackDashbord";
//

import { addSeconds, format, isValid } from "date-fns";
import { BedAllowIcon } from "../../assets/icons/CustomIcons";
import {
  setReUpdateComponent,
  updateDateTime,
} from "../../authentication/slices/dateTimeSlice";
import { warningAlert } from "../../common/components/Toasts/CustomToasts";
import {
  fetchCurrentDateTime,
  restrictSamePatientToMultiUserActivity,
} from "../../login/services/LoginServices.js";
import TestCreationMaster from "../../masters/components/pathology/Test/TestCreationMaster.js";
import PrinterSettings from "../../settings/componets/printerSettings/PrinterSettings";
import TimoutLoginModal from "./TimoutLoginModal";

//
const FnImg = (imgPath) => <img src={imgPath} alt="icon" />;
//
const drawerWidth = 280;
// const drawerHeight =auto;

const openedMixin = (theme) => ({
  width: drawerWidth,
  height: "100%",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(9)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1350,
  height: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

let timeStamp;
let logoutTimer;

export default function Dashboard() {
  var token = JSON.parse(localStorage.getItem("userInfo"));
  let navigate = useNavigate();
  const dispatch = useDispatch();
  let path = useLocation();
  const { user: currentUser } = useSelector((state) => state.auth);
  //
  console.log("path", path);
  //
  const [dashboardMenus, setDashboardMenus] = useState([]);
  const [openTimoutLoginModal, setOpenTimoutLoginModal] = React.useState(false);

  //

  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [isLogin, setIsLogin] = useState(null);
  //
  const [time, setTime] = useState();
  const [displayDate, setDisplayDate] = useState();
  // const [timeStamp, setTimeStamp] = useState(
  //   currentUser?.userInfo?.currentDateTime
  //     ? new Date(currentUser.userInfo.currentDateTime)
  //     : new Date()
  // );
  //
  const [openModal, setOpenModal] = useState(false);
  const [updateComponent, setUpdateComponent] = useState(false);
  const [openBed, setOpenBed] = useState(false);
  const handleModalClose = () => setOpenModal(false);
  const handleCloseBed = () => setOpenBed(false);

  const loggedUser = JSON.parse(localStorage.getItem("userInfo"));
  const [isValidLink, setIsValidLink] = useState(false);
  const [routerLinks, setRouterLinks] = React.useState([]);
  const [spinner, setSpinner] = React.useState(true);
  //
  const [isOpenFav, setIsOpenFav] = useState(true);
  const [favourites, setFavourites] = React.useState([]);
  const [latestDateTime, setLatestDateTime] = React.useState();
  const [dateTimeError, setDateTimeError] = useState(false);

  //
  //Code By Omkar
  useEffect(() => {
    if (loggedUser?.token) {
      const eventSource = new EventSource(
        `${baseUrl}/whatsappSms/register?token=${
          loggedUser && loggedUser.token
        }&clientId=${loggedUser && loggedUser.userId.toString()}`,
        {
          headers: authHeader(),
        }
      );

      eventSource.addEventListener(`message`, (event) => {
        const newData = event.data;
        if (loggedUser && loggedUser.token) {
          notificationContent(newData);
        }
        // const audio = new Audio(notificationSound);
      });

      // Event listener for SSE errors
      eventSource.addEventListener("error", (error) => {
        console.error("SSE Error:", error);
      });

      return () => {
        eventSource.close();
      };
    }
  }, [loggedUser]);

  // useEffect(() => {
  //   const sessionStorageKey = "isReloaded";

  //   if (!sessionStorage.getItem(sessionStorageKey)) {
  //     handleLogoutModalSubmit();
  //     localStorage.clear();
  //     sessionStorage.setItem(sessionStorageKey, "true");
  //   }

  //   return () => {
  //     sessionStorage.removeItem(sessionStorageKey);
  //   };
  // }, []);

  //################ handelling push notifications ##############

  let gotMessage = {
    from: "abc",
    message: "Hello From Server!!!",
    title: null,
    isRead: false,
  };

  //function to push nnotifications on UI
  const notificationContent = (object) => {
    let message = JSON.parse(object);

    if (loggedUser && loggedUser?.token) {
      return NotificationToast(
        <div className="text-slate-800 bg-[#e3caf0] flex gap-2 h-[45px] hover:h-[90px] hover:max-h-[110px] overflow-hidden hover:overflow-auto ease-in-out duration-300">
          <div className="flex items-center">
            <BellIcon />
          </div>
          <div className="grid ">
            <div className=" font-semibold">{message?.title}</div>
            <div className=" text-sm text-gray-500 ">{message?.message}</div>
          </div>
        </div>
      );
    }
  };
  //################ handelling push notifications ##############

  //

  // //////Time greetings
  const TimeGreetings = () => {
    var myDate = new Date();
    var hrs = myDate.getHours();

    var greet;

    if (hrs < 12) greet = "Good Morning";
    else if (hrs >= 12 && hrs <= 17) greet = "Good Afternoon";
    else if (hrs >= 17 && hrs <= 24) greet = "Good Evening";
    return greet;
  };

  //////////^^

  const queryClient = new QueryClient();
  const handleDrawerOpen = () => {
    setOpen(true);
    setCollapsed(false);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setCollapsed(true);
  };

  const handleLogOut = () => {
    setOpenModal(true);
  };

  const handleLogoutModalSubmit = () => {
    console.log("handleLogoutModalSubmit");
    // localStorage.clear();
    // setIsLogin(false);
    // setOpenModal(false);
    // navigate("/");
    // code by Omkar
    logOut();
    localStorage.removeItem("username");
    setIsLogin(false);
    toast.dismiss();
    setOpenModal(false);
    redirect("/");
    setDashboardMenus([]);
    //
    checkSimilarTransaction();
    setOpenTimoutLoginModal(false);

    //
  };

  const handleOpenBed = () => {
    setOpenBed(true);
  };
  ///// this is for set

  useEffect(() => {
    if (isLogin !== null || isLogin === false) {
      let token = localStorage.getItem("userInfo");
      //
      if (token !== null) {
        setIsLogin(true);
      } else {
        setIsLogin(false);
      }
    }
  }, [isLogin]);

  //#############################

  useEffect(() => {
    handleCurrentDateTime();
  }, []);

  const handleCurrentDateTime = () => {
    fetchCurrentDateTime()
      .then((response) => response.data)
      .then((res) => {
        const currentDate = new Date(res).valueOf();
        localStorage.setItem("dateTime", Number(currentDate));
        localStorage.setItem("dateTimeString", new Date(res));
        setLatestDateTime(res);
        timeStamp = new Date(res);
      });
  };

  const resetLogoutTimer = () => {
    clearTimeout(logoutTimer);
    logoutTimer = setTimeout(() => {
      warningAlert("User logged out due to inactivity.");

      setOpenTimoutLoginModal(true);
      //
    }, 60 * 60 * 1000);
  };

  const handleActivity = () => {
    resetLogoutTimer();
  };

  useEffect(() => {
    resetLogoutTimer();

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);
    window.addEventListener("touchstart", handleActivity);

    return () => {
      clearTimeout(logoutTimer);
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      window.removeEventListener("touchstart", handleActivity);
    };
  }, []);

  const handleVisibilityChange = () => {
    if (document.hidden) {
      console.log("Tab is Inactive x");
    } else {
      handleCurrentDateTime();
      console.log("Tab is Active =");
    }
  };
  const handleRefresh = (event) => {
    openTimoutLoginModal && handleLogoutModalSubmit();
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleRefresh);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      window.removeEventListener("beforeunload", handleRefresh);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [openTimoutLoginModal]);

  useEffect(() => {
    handleDateTime();
    const datetimer = setInterval(() => {
      handleDateTime();
      timeStamp = addSeconds(timeStamp, 1);
    }, 1000);
    return () => {
      clearInterval(datetimer);
    };
  }, [latestDateTime]);

  function handleDateTime() {
    let today = timeStamp;
    let valid = isValid(today);
    //
    const currentTime = valid && !!today && format(today, "hh:mm a");
    const date = valid && !!today && format(today, "dd-MM-yyyy");

    const currentDate = new Date(latestDateTime).valueOf();

    //
    setTime(currentTime);
    setDisplayDate(date);
    //
    if (today) {
      const difference = Math.abs(new Date() - today);
      if (difference > 35 * 60 * 1000) {
        setDateTimeError(true);
      } else {
        setDateTimeError(false);
      }
    }

    //
    dispatch(updateDateTime({ currentDate, currentTime }));

    ///
    if (
      today?.getHours() === 0 &&
      today?.getMinutes() === 0 &&
      today?.getSeconds() === 0
    ) {
      handleCurrentDateTime();
      setUpdateComponent(true);
      dispatch(setReUpdateComponent(true));
      console.log("updateComponent");
    }
    if (
      today?.getHours() === 0 &&
      today?.getMinutes() === 0 &&
      today?.getSeconds() === 3
    ) {
      setUpdateComponent(false);
      dispatch(setReUpdateComponent(false));
    }
    ///
  }

  // useEffect(() => {
  //   const datetimer = setInterval(() => {
  //     currentTimeRef.current = addSeconds(currentTimeRef.current, 1);

  //     handleDateTime(currentTimeRef.current);
  //   }, 1000);

  //   return () => clearInterval(datetimer);
  // }, [currentUser]); // Only run the effect when currentUser changes

  // const handleDateTime = (currentTime) => {
  //   let today = new Date(currentTime);
  //   let day = today.getDate();
  //   let month = today.toLocaleString("default", { month: "long" });
  //   let year = today.getFullYear();
  //   setDisplayDate(day + " " + month + " " + year);
  //   let hours = today.getHours();
  //   let minutes = today.getMinutes();
  //   let sec = today.getSeconds();
  //   let ampm = hours >= 12 ? "PM" : "AM";
  //   hours = hours % 12;
  //   hours = hours ? hours : 12;
  //   minutes = minutes < 10 ? "0" + minutes : minutes;
  //   setTime(hours + ":" + minutes + " " + ampm);

  //   const time = `${hours}:${minutes}:${sec} ${ampm}`;
  //   const date = `${day} ${month} ${year}`;

  //   const dateTimeMilliseconds = today.getTime();

  //   dispatch(updateDateTime({ date, time, dateTimeMilliseconds }));

  //   console.log("current time:", date, time);

  //   setDisplayDate(date);
  //   setTime(time);

  //   dispatch(
  //     updateDateTime({
  //       date: date,
  //       time: time,
  //       dateTimeMilliseconds: new Date(currentTime).getTime(),
  //     })
  //   );

  //   //Handle component updates at midnight
  //   if (
  //     today.getHours() === 0 &&
  //     today.getMinutes() === 0 &&
  //     today.getSeconds() === 0
  //   ) {
  //     setUpdateComponent(true);
  //     dispatch(setReUpdateComponent(true));
  //   }
  //   if (
  //     today.getHours() === 0 &&
  //     today.getMinutes() === 0 &&
  //     today.getSeconds() === 3
  //   ) {
  //     setUpdateComponent(false);
  //     dispatch(setReUpdateComponent(false));
  //   }
  // };
  //Code By Omkar

  const logOut = React.useCallback(() => {
    dispatch(logout());
    setOpen(false);
    setCollapsed(true);
    navigate("/dashboard/dashboard");
    localStorage.clear();
    sessionStorage.clear();
    window.history.replaceState(null, "", "/dashboard/dashboard");
  }, [dispatch]);

  //Check Local Storage
  useEffect(() => {
    if (currentUser) {
      //
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [currentUser, logOut]);

  const state = store.getState();
  // Restrict Direct Access to URL
  useEffect(() => {
    //
    if (state?.auth?.routeList?.length > 0) {
      let obj = state.auth.routeList.find((o) => o === path.pathname);
      if (typeof obj !== "undefined") {
        setIsValidLink(true);
      } else {
        setIsValidLink(false);
      }
      setSpinner(false);
    }
  }, [state.auth.routeList, path]);

  //Handling multiple tab logout
  window.addEventListener("click", (event) => {
    let tokenStatus = localStorage.getItem("userInfo");
    handleRedirect(tokenStatus);
  });

  const handleRedirect = (tokenDetails) => {
    if (tokenDetails === null) {
      setIsLogin(false);
    }
  };

  // *********************** Restrict similar patient for multiUser ***************************
  useEffect(() => {
    checkSimilarTransaction();
  }, [path]);

  const checkSimilarTransaction = () => {
    let obj = {
      id: null,
      isInsert: false,
    };

    restrictSamePatientToMultiUserActivity(obj)
      .then((response) => response.data)
      .then((res) => {
        localStorage.setItem("refreshObj", JSON.stringify({}));
      })

      .catch((res) => {
        warningAlert(res.response.data.message);
      });
  };

  // ***********************^^^^^ similar patient ^^^^^*************************** //

  console.log("isLogin", isLogin);
  return (
    <>
      {/* <BrowserRouter> */}
      {isLogin !== null ? (
        !isLogin ? (
          <Login setIsLogin={setIsLogin} isLogin={isLogin} />
        ) : (
          <Box sx={{ display: "flex", flexGrow: 1 }}>
            <CssBaseline />

            <AppBar
              position="fixed"
              open={open}
              sx={{ backgroundColor: "#1e3a8a" }}
            >
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    marginRight: 5,
                    ...(open && { display: "none" }),
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{
                    flexGrow: 1,
                    ////to hide name if drawer open
                    ...(open && { visibility: "hidden" }),
                  }}
                >
                  {/* Inamdar Hospital */}
                  <img
                    className="h-10 rounded-sm cursor-pointer "
                    src={InamdarHospiLogo}
                    alt="hospital Logo"
                    onClick={() => {
                      navigate("/dashboard/dashboard");
                    }}
                  />
                </Typography>
                {token?.bedAllocationId && (
                  <div className="flex items-center gap-3">
                    <div className="mt-3">
                      <BedAllowIcon
                        title={"Bed Allocation"}
                        onClick={() => {
                          handleOpenBed();
                        }}
                      />
                    </div>
                    {/*<Tooltip
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 600 }}
                      title="Bed"
                      placement="top"
                      style={
                        {
                          // marginTop: -10,
                        }
                      }
                    >
                      <div className="grid text-center p-2">
                        <IconButton onClick={handleOpenBed}>
                          <img
                            className="h-5 rounded-sm hover:cursor-pointer "
                            src={bed}
                            alt="bed Logo"
                          />
                        </IconButton>
                      </div>
                    </Tooltip>*/}

                    <span className="  -mt-2">
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        color="#FFFFFF"
                        style={{
                          height: 30,
                          marginTop: 15,
                          // marginRight: 1,
                          // marginLeft: 1,
                        }}
                      />
                    </span>
                  </div>
                )}
                <div className=" text-center p-2 text-xs hidden lg:block">
                  <span>{token?.storeName}</span>
                </div>
                <span className="hidden lg:block -mt-2">
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    color="#FFFFFF"
                    style={{
                      height: 30,
                      marginTop: 15,
                      marginRight: 10,
                      marginLeft: 10,
                    }}
                  />
                </span>
                <div className=" text-center p-2 text-xs hidden lg:grid">
                  <span>
                    {TimeGreetings()}
                    <span className="text-color-white">!</span>
                  </span>
                  <span>{token?.userName}</span>
                  {/* <span>[ {token.storeName} ]</span> */}
                </div>
                <span className="hidden lg:block -mt-2">
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    color="#FFFFFF"
                    style={{
                      height: 30,
                      marginTop: 15,
                      marginRight: 10,
                      marginLeft: 10,
                    }}
                  />
                </span>
                {/* /////Date/Time//////// */}
                <div className=" text-center p-2 text-xs hidden lg:grid">
                  <span>
                    {/* <CalendarMonthOutlinedIcon/>    displayDate.toLocaleTimeString() */}
                    {displayDate}
                  </span>
                  <span>
                    {/* <AccessTimeOutlinedIcon/> */}
                    {time}
                  </span>
                </div>
                <span className="hidden lg:block -mt-2">
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    color="#FFFFFF"
                    style={{
                      height: 30,
                      marginTop: 15,
                      marginRight: 1,
                      marginLeft: 10,
                    }}
                  />
                </span>
                <Tooltip
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}
                  title="Logout"
                  placement="top"
                  style={
                    {
                      // marginTop: -10,
                    }
                  }
                >
                  <IconButton color="inherit" onClick={handleLogOut}>
                    <LogoutIcon />
                  </IconButton>
                </Tooltip>
                <span className=" -mt-2">
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    color="#FFFFFF"
                    style={{
                      height: 30,
                      marginTop: 15,
                      marginRight: 10,
                      marginLeft: 1,
                    }}
                  />
                </span>
                <span>
                  <img
                    className="h-6 rounded-sm hover:cursor-pointer "
                    src={DocItLogo}
                    alt="DocIt Logo"
                  />
                </span>

                <span
                  className="absolute z-10 top-[70px] right-0 mx-auto mr-4  flex flex-row-reverse gap-3 shadow-favShadow
                    rounded bg-white items-center "
                  // onMouseEnter={() => setIsOpenFav(true)}
                  // onMouseLeave={() => setIsOpenFav(true)}
                >
                  {favourites.length > 0 && isOpenFav ? (
                    <div className="text-stone-600 -ml-2 mr-1 -mt-1">
                      <Tooltip title={"Hide Favourite"}>
                        <ArrowForwardIosIcon
                          fontSize="small"
                          onClick={() => setIsOpenFav((prev) => !prev)}
                        />
                      </Tooltip>
                    </div>
                  ) : (
                    <div
                      className={`text-pink-500 hover:bg-indigo-200 rounded flex items-center p-2 ${
                        isOpenFav && "animate-none"
                      }`}
                    >
                      <Tooltip title={"Show Favourite"}>
                        {/* <Icon sx={{margin:1}}> */}
                        <BookmarksIcon
                          onClick={() => setIsOpenFav((prev) => !prev)}
                          fontSize="small"
                        />
                        {/* </Icon> */}
                      </Tooltip>
                    </div>
                  )}
                  {isOpenFav && (
                    <>
                      {favourites &&
                        favourites.map((item, index) => (
                          <>
                            <span className=" -mt-1">
                              <Divider
                                orientation="vertical"
                                variant="middle"
                                flexItem
                                color="#FFFFFF"
                                style={{
                                  height: 20,
                                  marginTop: 15,
                                  marginLeft: 2,
                                  marginRight: 2,
                                }}
                              />
                            </span>
                            <div
                              className={` ${
                                index === favourites.length - 1
                                  ? "ml-2 -mr-2"
                                  : "-mx-2"
                              } rounded h-5 w-5 text-center hover:bg-indigo-200 hover:p-[1px] `}
                            >
                              <Tooltip title={item.label}>
                                <img
                                  src={`${baseUrl}/file/${item.iconPath}`}
                                  alt="icon"
                                  onClick={() =>
                                    navigate(item.key, {
                                      state: { menuId: item.menuId },
                                    })
                                  }
                                />
                              </Tooltip>
                            </div>
                          </>
                        ))}
                    </>
                  )}
                </span>
              </Toolbar>
            </AppBar>

            <Drawer variant="permanent" open={open}>
              <div className="sticky top-0 z-50">
                <DrawerHeader sx={{ backgroundColor: "#1e3a8a" }}>
                  <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    sx={{
                      flexGrow: 1,
                      cursor: "pointer",

                      color: "#FFFFFF",
                      margin: "0px 0px 0px 20px ",
                    }}
                    onClick={(e) => {
                      navigate("/", {});
                    }}
                  >
                    {/* Inamdar Hospital */}
                    <img
                      className="h-10 rounded-sm hover:cursor-pointer "
                      src={InamdarHospiLogo}
                      alt="hospital Logo"
                    />
                  </Typography>

                  <IconButton
                    onClick={handleDrawerClose}
                    sx={{ color: "#FFFFFF" }}
                  >
                    {theme.direction === "rtl" ? (
                      <ChevronRightIcon />
                    ) : (
                      <CloseTwoToneIcon />
                    )}
                  </IconButton>
                </DrawerHeader>
              </div>

              <Divider />

              <List sx={{ backgroundColor: "#f8fafc" }}>
                {/*<MenuList
                  collapsed={collapsed}
                  setRouterLinks={setRouterLinks}
                    />*/}
                <NewMenuList
                  collapsed={collapsed}
                  setRouterLinks={setRouterLinks}
                  setFavourites={setFavourites}
                  //
                  dashboardMenus={dashboardMenus}
                  setDashboardMenus={setDashboardMenus}
                  //
                  time={time}
                />
              </List>
            </Drawer>
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                paddingY: 5,
                paddingX: 2,
                backgroundColor: "#F8FAFC",
                height: "100vh",
              }}
            >
              {/* //Code By Omkar */}
              {spinner ? (
                <>
                  <div class="flex flex-col justify-center items-center h-screen">
                    <div class="relative inline-flex">
                      <div class="w-8 h-8 bg-customBlue rounded-full"></div>
                      <div class="w-8 h-8 bg-customBlue rounded-full absolute top-0 left-0 animate-ping"></div>
                      <div class="w-8 h-8 bg-customBlue rounded-full absolute top-0 left-0 animate-pulse"></div>
                    </div>
                    <p className="text-customBlue my-2 font-semibold ml-4">
                      Loading...
                    </p>
                  </div>
                </>
              ) : dateTimeError ? (
                <DateTimeMismatchErrorPage />
              ) : isValidLink === false ? (
                <PageNotFound />
              ) : (
                <>
                  {/* // */}
                  <Routes>
                    <Route path="/feedBack" element={<EmployeeFeedback />} />

                    {/* ///wel-come dash board// */}
                    {/* <Route path="/" element={<Welcome />} /> */}
                    <Route path="/dashboard">
                      <Route
                        path="/dashboard/dashboard"
                        element={<DashboardPage />}
                      />
                      <Route
                        path="/dashboard/feedbackDashboard"
                        element={<FeedBackDashbord />}
                      />
                    </Route>

                    {/* ///////////////////////// Billing ////////////////// */}
                    <Route
                      //path="/PatientAdvance"
                      path="/opd/advance"
                      element={<PatientAdvance />}
                    />

                    {/* ///////////////////////// OPD ////////////////// */}

                    <Route
                      //path="/PatientRegistrationForm"
                      path="/opd/registration"
                      element={<PatientRegistrationForm />}
                    />

                    <Route
                      path="opd/visitDetails"
                      element={
                        <PatientVisitList updateComponent={updateComponent} />
                      }
                    />

                    <Route
                      Exact
                      //path="/PatientOrderManagementDetails"
                      path="/opd/patientEngagementOrder"
                      element={<PatientOrderManagementDetails />}
                    />

                    <Route
                      Exact
                      //path="/PatientEngagement"
                      path="/opd/patientEngagementRegistration"
                      element={<PatientEngagement />}
                    />

                    <Route
                      Exact
                      //path="/AppointmentPage"
                      path="/opd/phoneAppointments"
                      element={<AppointmentPage />}
                    />

                    <Route
                      Exact
                      path="/opd/refund/bill"
                      element={<RefundOfBill />}
                    />

                    <Route
                      Exact
                      // path="/OPDBilling"
                      path="/opd/bill"
                      element={<OPDBilling />}
                    />

                    <Route
                      Exact
                      //path="/CompanySettlement"
                      path="/opd/billsettlementCompany"
                      element={<CompanySettlement />}
                    />

                    <Route
                      Exact
                      //path="/SelfBillSettlement"
                      path="/opd/billsettlementSelf"
                      element={<SelfBillSettlement />}
                    />

                    <Route
                      Exact
                      //path="/OPDConsessionManager"
                      path="/opd/billsettlementConcessionManager"
                      element={<OPDConsessionManager />}
                    />

                    {/* ///////////////////////// Refund ////////////////// */}
                    <Route
                      Exact
                      //path="/RefundOfBill"
                      path="/opd/refundOfBill"
                      element={<RefundOfBill />}
                    />

                    <Route
                      Exact
                      //path="/RefundOfAdvance"
                      path="/opd/refund/advance"
                      element={<RefundOfAdvance />}
                    />

                    <Route
                      Exact
                      path="/opd/certificate"
                      element={<Certificate />}
                    />

                    <Route
                      Exact
                      path="/opd/billEstimation"
                      element={<BillEstimation />}
                    />

                    <Route
                      Exact
                      //path="/AdmissionList"
                      path="/ipd/admission"
                      element={
                        <AdmissionListing updateComponent={updateComponent} />
                      }
                    />

                    <Route
                      exact
                      //path="/Discharge"
                      path="/ipd/discharge"
                      element={<Discharge />}
                    />

                    {/* <Route
                    exact
                    //path="/Discharge"
                    path="/ipd/dischargeSummary"
                    element={<DischargeSummary />}
                  /> */}

                    {/* $$$$$$$$$$$$$$$$$$$$$ */}
                    <Route
                      exact
                      //path="/Discharge"
                      path="/ipd/dischargeSummary"
                      element={<DischargeSummaryTabWise />}
                    />
                    {/* $$$$$$$$$$$$$$$$$$$$$ */}

                    <Route
                      exact
                      //path="/Discharge"
                      path="/ipd/etuCaseSheet"
                      element={<EtuCaseSheet />}
                    />

                    <Route
                      Exact
                      //path="/BedTransfer"
                      path="/ipd/bedTransfer"
                      element={<BedTransfer />}
                    />

                    <Route
                      Exact
                      path="/admission/PatientAdmissionDetails"
                      element={<PatientAdmissionDetails />}
                    />

                    <Route
                      Exact
                      // path="/IPDCharges"
                      path="/ipd/charges"
                      element={<IPDCharges />}
                    />

                    <Route Exact path="/ipd/bill" element={<IPDBilling />} />

                    <Route
                      Exact
                      path="/ipd/selfSettlement"
                      element={<IPDSelfBillSettlement />}
                    />

                    <Route
                      Exact
                      path="/ipd/companySettlement"
                      element={<IPDCompanySettlement />}
                    />

                    <Route
                      Exact
                      path="/ipd/concessionManager"
                      element={<IPDConcessionManager />}
                    />

                    <Route
                      Exact
                      path="/ipd/advance"
                      element={<IPDPatientAdvance />}
                    />

                    <Route
                      Exact
                      path="/ipd/refundOfAdvance"
                      element={<IPDRefundOfAdvance />}
                    />

                    <Route
                      Exact
                      path="/ipd/refundOfBill"
                      element={<IPDRefundOfBill />}
                    />

                    <Route
                      Exact
                      path="/ipd/drugAdministratorOrder"
                      element={<DrugAdminstratorOrder />}
                    />

                    <Route
                      Exact
                      path="/ipd/bedRelease"
                      element={<BedRelease />}
                    />

                    <Route
                      Exact
                      path="/ipd/patientTrasfer"
                      element={<PatientTransfer />}
                    />

                    <Route
                      Exact
                      path="/ipd/carePlan"
                      element={<CarePlan updateComponent={updateComponent} />}
                    />

                    {/* Emergency */}

                    <Route
                      Exact
                      //path="/Emergency"
                      path="/emergency/registration"
                      element={
                        <EmergencyModal updateComponent={updateComponent} />
                      }
                    />

                    <Route
                      Exact
                      path="/emergency/nihStrokeScaleScoringSheet"
                      element={<NIHStrokeScaleScoringSheet />}
                    />

                    <Route
                      exact
                      path="/emergency/etuRegisters"
                      element={
                        <EtuRegister updateComponent={updateComponent} />
                      }
                    />

                    <Route
                      exact
                      path="/emergency/opdCasePaperRegister"
                      element={<OPDCasePaperRegister />}
                    />

                    {/* Investigation */}
                    <Route
                      path="/investigations/pathologyWorkOrderGeneration"
                      element={<PathWorkOrderGeneration />}
                    />

                    <Route
                      path="/investigations/pathologySampleCollection"
                      element={
                        <SampleCollection updateComponent={updateComponent} />
                      }
                    />

                    <Route
                      path="/investigations/pathologyReportDelivery"
                      element={<PathReportDelivery />}
                    />

                    <Route
                      path="/investigations/pathologyWorkOrderView"
                      element={
                        <WorkOrderView updateComponent={updateComponent} />
                      }
                    />

                    <Route
                      path="/investigations/pathologyWorkOrderStatus"
                      element={<WorkOrderStatus />}
                    />

                    <Route
                      path="/investigations/PhysiotheropyWorkOrderGeneration"
                      element={<PhysioWorkOrderGeneration />}
                    />

                    <Route
                      Exact
                      path="/investigations/PhysiotheropyWorkOrderView"
                      element={
                        <PhysioWorkOrderView
                          updateComponent={updateComponent}
                        />
                      }
                    />
                    <Route
                      Exact
                      path="/investigations/generalAssessment"
                      element={<GeneralAssessment />}
                    />

                    <Route
                      path="/investigations/radiologyWorkOrderGeneration"
                      element={<RadioWorkOrderGeneration />}
                    />

                    <Route
                      path="/investigations/radiologyWorkOrderView"
                      element={
                        <RadiologyWorkOrderView
                          updateComponent={updateComponent}
                        />
                      }
                    />

                    <Route
                      path="/investigations/radiologyReportDelivery"
                      element={<RadioReportDelivery />}
                    />

                    {/* ///////////////// Nursing Station ////////////////// */}
                    <Route
                      Exact
                      path="/nursing/admittedPatients"
                      element={
                        <AdmittedPatients updateComponent={updateComponent} />
                      }
                    />

                    <Route
                      path="/nursing/inPatientIssue"
                      element={<InPatientIssue />}
                    />

                    <Route
                      path="/nursing/inPatientReturn"
                      element={<NursingInPatientReturn />}
                    />

                    <Route
                      path="/nursing/clinicalCareChart"
                      element={<ClinicalCareChart />}
                    />

                    <Route
                      Exact
                      path="/nursing/drugAdministratorChart"
                      element={<DrugAdministrationChart />}
                    />

                    <Route
                      Exact
                      path="/nursing/dietetianModule"
                      element={<DietetianModule />}
                    />

                    <Route
                      Exact
                      path="/nursing/dietChartDetails"
                      element={<DietChartDetails />}
                    />

                    <Route
                      Exact
                      // path="/IPDCharges"
                      path="/nursing/charges"
                      element={<Charges />}
                      // element={<IPDCharges />}
                    />

                    <Route
                      Exact
                      path="/nursing/dietOrder"
                      element={<DietOrder />}
                    />

                    <Route
                      Exact
                      path="/nursing/nursingSupervisor"
                      element={<NursingSupervisor />}
                    />

                    <Route
                      Exact
                      path="/nursing/nursingNightReport"
                      element={<NursingTakeOverDetails />}
                    />

                    <Route
                      Exact
                      path="nursingStation/codeBlueForm"
                      element={<CodeBlueForm />}
                    />

                    <Route
                      Exact
                      path="/nursingStation/sampleCollectionForColorTube"
                      element={<SampleCollectionForColourTube />}
                    />

                    <Route
                      Exact
                      path="/nursing/divaToolScore"
                      element={<DivaTool />}
                    />

                    <Route
                      Exact
                      path="/nursing/materialConsumption"
                      element={<NursingMaterialConsumption />}
                    />

                    <Route
                      Exact
                      path="/nursing/ventilatorBundle"
                      element={<VentilatorBundle />}
                    />

                    <Route
                      Exact
                      path="/nursingStation/mrdCheckList"
                      element={<MrdChecklist />}
                    />

                    <Route
                      Exact
                      path="/nursing/needleStickInjuryForm"
                      element={<NeedleStickInjury />}
                    />

                    <Route
                      Exact
                      path="/inventory/generalStore/materialConsumption"
                      element={<InventoryMaterialConsumption />}
                    />

                    {/* ///////////////// Inventory ////////////////// */}
                    {/* GeneralStore */}
                    <Route
                      Exact
                      path="/inventory/generalStore/purchaseOrder"
                      element={<PurchaseOrder />}
                    />

                    <Route
                      Exact
                      path="/inventory/generalStore/workOrder"
                      element={<WorkOrder />}
                    />

                    <Route
                      Exact
                      path="/inventory/generalStore/storeGRN"
                      element={<StoreGRN />}
                    />

                    <Route
                      Exact
                      path="/inventory/generalStore/storeGRNReturn"
                      element={<GRNReturn />}
                    />

                    <Route
                      Exact
                      path="/inventory/generalStore/grnReturnMultiple"
                      element={<GRNReturnMultiple />}
                    />

                    <Route
                      Exact
                      path="/inventory/generalStore/patientIndent"
                      element={<PatientIndent />}
                    />
                    <Route
                      Exact
                      path="/inventory/generalStore/storeIndent"
                      element={<StoreIndent />}
                    />

                    <Route
                      Exact
                      path="/inventory/generalStore/workOrderGRN"
                      element={<WorkOrderGRN />}
                    />

                    <Route
                      Exact
                      path="/inventory/generalStore/poMultiple"
                      element={<POMultiple />}
                    />

                    <Route
                      Exact
                      path="/inventory/generalStore/issueToDepartment"
                      element={<GsIssueToDept />}
                    />

                    <Route
                      Exact
                      path="/inventory/generalStore/returnFromDepartment"
                      element={<GsReturnFromDept />}
                    />

                    <Route
                      Exact
                      path="/inventory/generalStore/stockAdjustment"
                      element={<StockAdjustment />}
                    />

                    <Route
                      Exact
                      path="/inventory/generalStore/mrpAdjustment"
                      element={<MRPAdjustment />}
                    />

                    <Route
                      Exact
                      path="/inventory/generalStore/expiryAdjustment"
                      element={<ExpiryAdjustment />}
                    />

                    <Route
                      Exact
                      path="/inventory/generalStore/workOrderGRN"
                      element={<WorkOrderGRN />}
                    />

                    <Route
                      Exact
                      path="/inventory/generalStore/poMultiple"
                      element={<POMultiple />}
                    />

                    <Route
                      Exact
                      path="/inventory/generalStore/workOrderMultiple"
                      element={<WorkOrderMultiple />}
                    />

                    {/* pharmacy */}
                    <Route
                      Exact
                      path="/inventory/pharmacy/storeIndent"
                      element={<StoreIndent />}
                    />

                    <Route
                      Exact
                      path="/inventory/pharmacy/issueToDepartment"
                      element={<PharmacyIssueToDept />}
                    />

                    <Route
                      Exact
                      path="/inventory/pharmacy/returnFromDepartment"
                      element={<PharmacyReturnFromDept />}
                    />

                    <Route
                      path="/inventory/pharmacy/inPatientIssue"
                      element={<PharmacyInPatientIssue />}
                    />

                    <Route
                      Exact
                      path="/inventory/pharmacy/stockAdjustment"
                      element={<PharmacyStockAdjustment />}
                    />

                    <Route
                      Exact
                      path="/inventory/pharmacy/mrpAdjustment"
                      element={<PharmacyMRPAdjustment />}
                    />

                    <Route
                      Exact
                      path="/inventory/pharmacy/expiryAdjustment"
                      element={<PharmacyExpiryAdjustment />}
                    />

                    <Route
                      Exact
                      path="/inventory/pharmacy/selfSettlement"
                      element={<PharmacySelfSettlement />}
                    />

                    <Route
                      Exact
                      path="/inventory/pharmacy/companySettlement"
                      element={<PharmacyCompanySettlement />}
                    />

                    <Route
                      Exact
                      path="/inventory/pharmacy/inPatientReturn"
                      element={<PharmacyInPatientReturn />}
                    />

                    {/* Counter Sales */}
                    <Route
                      Exact
                      path="/inventory/pharmacy/counterSale"
                      element={<CounterSales />}
                    />

                    {/* Counter Sales Return */}
                    <Route
                      Exact
                      path="/inventory/pharmacy/counterSaleReturn"
                      element={<CounterSalesReturn />}
                    />
                    {/* /route visible only for administrator */}
                    <Route
                      Exact
                      path="inventory/pharmacy/inPatientReturnApproval"
                      element={<InPatientReturnApproval />}
                    />

                    <Route
                      Exact
                      path="/inventory/pharmacy/itemReturn"
                      element={<ExpiredItemReturn />}
                    />

                    <Route
                      Exact
                      path="/inventory/pharmacy/storeInventory(min/max)"
                      element={<StoreStockMinMax />}
                    />

                    <Route
                      exact
                      path="/reports/queryList/grnList"
                      element={<VerifyGrn />}
                    />
                    <Route
                      Exact
                      path="/reports/queryList/grnReturnList"
                      element={<GrnReturnList />}
                    />
                    <Route
                      Exact
                      path="/purchase/enquiry"
                      element={<Enquiry />}
                    />

                    <Route
                      Exact
                      path="/purchase/partySettlement"
                      element={<PartySettlement />}
                    />

                    <Route
                      Exact
                      path="/purchase/quotation"
                      element={<Quotation />}
                    />

                    <Route
                      Exact
                      path="/purchase/purchaseApprovals"
                      element={<PurchaseApproval />}
                    />

                    <Route
                      Exact
                      path="/purchase/supplierAdvance"
                      element={<SupplierAdvance />}
                    />
                    <Route
                      Exact
                      path="/purchase/amendment"
                      element={<GRNAmendments />}
                    />
                    {/* /////// Operation Theater////// */}
                    <Route
                      Exact
                      path="/operationTheater/consent"
                      element={<OtConsent />}
                    />

                    <Route
                      Exact
                      path="/operationTheater/preOpIntructions"
                      element={<PreOpInstruction />}
                    />

                    <Route
                      Exact
                      path="/operationTheater/postOpIntructions"
                      element={<PostOpInstructions />}
                    />

                    <Route
                      Exact
                      path="/operationTheater/reservation"
                      element={<OtReservation />}
                    />

                    <Route
                      Exact
                      path="/operationTheater/Details"
                      element={<OtDetailsList />} /// 1] connected as main route in menus
                    />

                    <Route
                      Exact
                      path="/operationTheater/Details/create"
                      element={<OtDetails />} /// 2] connected As New Form On Ot-Details Listing
                    />

                    <Route
                      Exact
                      path="/operationTheater/Details/IssuedItems"
                      element={<OtDetailsIssuedItems />} /// 3] Reletes to issued items in ot details which available on Ot-Details listing
                    />

                    <Route
                      Exact
                      path="/operationTheater/pendingImplantsPatientDetails"
                      element={<PendingImplantPatientList />}
                    />

                    <Route
                      Exact
                      path="/operationTheater/officePermission"
                      element={<OfficePermissions />}
                    />

                    {/* Pharmacy */}

                    {/* Masters */}
                    <Route
                      path="/investigation/pathology/test"
                      element={<TestCreationMaster />}
                    />
                    <Route
                      Exact
                      path="/masters/investigation/radiology/templates"
                      element={<RadiologyTemplateMaster />}
                    />

                    <Route
                      Exact
                      path="/investigation/pathology/templates"
                      element={<PathologyTemplateMaster />}
                    />

                    <Route
                      Exact
                      path="/masters/opTheater/ConsentTemplate"
                      element={<OtConcentMaster />}
                    />

                    <Route
                      Exact
                      path="/masters/doctor/DoctorSchedule"
                      element={<DoctorSchedule />}
                    />

                    <Route
                      path="/master/feedBackQuestions"
                      element={<FeedBackQuestionSetCreation />}
                    />

                    <Route
                      path="/reports/feedBackReports"
                      element={<FeedBackReport />}
                    />

                    <Route
                      path="/master/assignTemplate"
                      element={<AssignTemplate />}
                    />
                    <Route
                      path="/master/answerReview"
                      element={<AnswerReviewCreation />}
                    />

                    <Route
                      path="/billing/serviceMaster"
                      element={<ServiceMasterListing />}
                    />

                    <Route
                      path="/master/billing/serviceForm"
                      element={<ServiceMasterForm />}
                    />

                    {/* User Management */}
                    <Route
                      Exact
                      path="/userManagement/assignFunctionality"
                      element={<AssignFuntionalities />}
                    />
                    <Route
                      Exact
                      path="/userManagement/role"
                      element={<Role />}
                    />
                    <Route
                      Exact
                      path="/userManagement/manageSystemMenus"
                      element={<Privilege />}
                    />

                    <Route
                      Exact
                      path="/userManagement/manageUser"
                      element={<Users />}
                    />

                    {/* Blood Bank */}

                    <Route
                      Exact
                      path="/bloodBank/bloodRequisitionNote"
                      element={<BloodRequisitionNote />}
                    />

                    <Route
                      Exact
                      path="/bloodBank/bloodRequisitionAcceptance"
                      element={<BloodRequisitionAcceptance />}
                    />

                    <Route
                      Exact
                      path="/bloodBank/bloodIssueNote"
                      element={<BloodIssueNote />}
                    />

                    <Route
                      Exact
                      path="/bloodBank/bloodIssueAcceptance"
                      element={<BloodIssueAcceptance />}
                    />

                    {/* ///////////////////////// task manager ////////////////// */}
                    <Route
                      Exact
                      path="/taskManager/patientClearanceForm"
                      element={<PatientClearence />}
                    />

                    <Route
                      Exact
                      path="/taskManager/incidentReporting"
                      element={<IncidentReporting />}
                    />

                    <Route
                      Exact
                      path="/clinical/codeForm"
                      element={<CodeForms />}
                    />

                    <Route
                      Exact
                      path="/taskManager/complaintManagement"
                      element={<ComplaintManagement />}
                    />

                    <Route
                      Exact
                      path="/taskManager/trainingCard"
                      // element={<TraningCard />}
                    />

                    <Route
                      Exact
                      path="/taskManager/fumigationChart"
                      element={<FumigationChart />}
                    />

                    <Route
                      Exact
                      path="/administration/changePassword"
                      element={<ChangePassword setIsLogin={setIsLogin} />}
                    />

                    <Route
                      Exact
                      path="/taskManager/bmiandEGFRCalculator"
                      element={<BmiEgfrCalculator />}
                    />

                    <Route
                      Exact
                      path="/taskManager/pendingAntibioticForm"
                      element={<PendingAntibioticForm />}
                    />

                    <Route
                      Exact
                      path="/taskManager/messagingConsole"
                      element={<MessegingConsole />}
                    />

                    <Route
                      Exact
                      path="/administration/configuration"
                      element={<ConfigurationForm />}
                    />

                    <Route
                      Exact
                      path="/administration/screenSaver"
                      element={<ScreenSaver />}
                    />

                    <Route
                      Exact
                      path="/nursing/fileLinking"
                      // element={<FileLinking />}
                    />

                    <Route
                      Exact
                      path="/taskManager/staffVsPatientRatio"
                      element={<StaffVsPatientRatio />}
                    />

                    <Route
                      Exact
                      path="/taskManager/inventoryStock"
                      element={<InventoryStock />}
                    />

                    <Route
                      Exact
                      path="/taskManager/itemListBelowStockLevel"
                      element={<ShowItemListBelowStockLevel />}
                    />

                    <Route
                      Exact
                      path="/taskManager/ipdFileRegister"
                      element={<IPDFileResgister />}
                    />

                    <Route
                      Exact
                      path="/administration/autoCharges"
                      element={<AutoCharges />}
                    />

                    <Route
                      Exact
                      path="/administration/patientDataMerge"
                      element={<PatientDataMerge />}
                    />

                    <Route
                      Exact
                      path="/administration/receiptCancellation"
                      element={<ReceiptCancellation />}
                    />

                    <Route
                      Exact
                      path="/miscServices/bioMedicalWasteManagement"
                      element={<BioMedicalWasteManagement />}
                    />

                    <Route
                      Exact
                      path="/accounting/miscIncome"
                      element={<MiscellaneousIncome />}
                    />

                    <Route
                      Exact
                      path="/accounting/miscExpense"
                      element={<MiscellaneousExpense />}
                    />

                    <Route
                      Exact
                      path="/accounting/contraVoucher"
                      element={<ContraVoucher />}
                    />

                    <Route
                      Exact
                      path="/accounting/multipleVoucher"
                      element={<MultipleVoucher />}
                    />

                    <Route
                      Exact
                      path="/ipd/narcoticDrugForm"
                      element={<NarcoticDrug />}
                    />

                    <Route
                      Exact
                      path="/taskManager/contactManagement"
                      element={<ContactManagement />}
                    />

                    <Route
                      Exact
                      path="/taskManager/hrDms"
                      element={<HrDms />}
                    />

                    <Route
                      Exact
                      path="/taskManager/dms"
                      element={<PatientDocumentSystem />}
                    />

                    <Route
                      Exact
                      path="/taskManager/pushTool"
                      element={<PushTool />}
                    />

                    <Route
                      Exact
                      path="/taskManager/notificationManagement"
                      element={<NotificationManagement />}
                    />

                    <Route
                      Exact
                      path="/postDischarge/callManagement"
                      element={<Todayscallingdashboard />}
                    />

                    <Route
                      Exact
                      path="/settings/printerSettings"
                      element={<PrinterSettings />}
                    />
                  </Routes>
                </>
              )}
            </Box>
            {/* ///Bed Allocation Modal */}

            {openBed ? (
              <BedAllowModal
                handleOpen={handleOpenBed}
                handleCloseBed={handleCloseBed}
                open={openBed}
                setOpen={setOpenBed}
              />
            ) : null}

            {openTimoutLoginModal && (
              <TimoutLoginModal
                open={openTimoutLoginModal}
                setOpen={setOpenTimoutLoginModal}
                handleOpen={() => setOpenTimoutLoginModal(true)}
                handleClose={() => setOpenTimoutLoginModal(false)}
                handleLogoutModalSubmit={handleLogoutModalSubmit}
              />
            )}

            {/* //Confirmation Modal// */}
            <ConfirmationModal
              confirmationOpen={openModal}
              confirmationHandleClose={handleModalClose}
              confirmationSubmitFunc={() => handleLogoutModalSubmit()}
              confirmationLabel="Logout Confirmation "
              confirmationMsg="Are you sure ?"
              confirmationButtonMsg="Proceed"
            />
          </Box>
        )
      ) : (
        ""
      )}
    </>
  );
}

