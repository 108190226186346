import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Box, Modal, Tooltip } from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { PrintOnIcon } from "../../../assets/icons/CustomIcons";
import CancelPresentationIconButton from "../../../common/components/Buttons/CancelPresentationIconButton";
import CommonButton from "../../../common/components/Buttons/CommonButton";
import CommonDynamicTablePaginationNew from "../../../common/components/CommonTable/CommonDynamicTablePaginationNew";
import DatePickerField from "../../../common/components/FormFields/DatePickerFieldNew";
import RadioField from "../../../common/components/FormFields/RadioField";
import SearchBar from "../../../common/components/FormFields/SearchBar";
import {
  NarcoticDrugGetById,
  narcoticDrugFormList,
  patientInfo,
} from "../../services/narcoticDrugServices/NarcoticDrugServices";
import NarcoticDrugPrint from "./NarcoticDrugPrint";

export const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
 // height: "60%",
  bgcolor: "background.paper",
  border: "1px solid gray",
  borderRadius: 1,
  boxShadow: 20,
};
const departmentType = [
  {
    id: 0,
    label: "OPD",
    value: 0,
  },
  {
    id: 1,
    label: "IPD",
    value: 1,
  },
];
export default function NarcoticDrugListing(props) {
  const [dataResult, setDataResult] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [count, setCount] = useState();
  const [options, setOptions] = React.useState([]);
  const [searchString, setSearchString] = React.useState("");
  const [searchBar, setSearchBar] = React.useState("");
  const [searchId, setSearchId] = React.useState(null);
  const [openPainAssPrint, setOpenPainAssPrint] = useState(false);
  const [editRow, setEditRow] = useState();
  const [listing, setListing] = useState([]);

  // const [fromToDate, setFromToDate] = useState(new Date());
  // const [toDates, SetToDate] = useState(new Date());

  const defaultValues = {
    departmentTypes: 1,
    fromDate: new Date(),
    toDate: new Date(),
  };
  const { control, watch, setValue } = useForm({
    defaultValues,
  });

  let fromDate = watch("fromDate");

  let toDate = watch("toDate");
  let departmentTypesWatch = watch("departmentTypes");
  //
  // format(Fri Dec 01 2023 00:00:00 GMT+0530, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
  const populateTable = (forPagination) => {
    let searchObj = {
      page: !forPagination ? 0 : page,
      fromDate:
        fromDate !== undefined
          ? format(fromDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
          : null,
      patientId: searchString?.id,
      toDate:
        toDate !== undefined
          ? format(toDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
          : null,
      size: rowsPerPage,
      opdIpd: Number(departmentTypesWatch),
    };
    narcoticDrugFormList(searchObj)
      .then((response) => response.data)
      .then((res) => {
        if (forPagination) {
          setDataResult((prevData) => [...prevData, ...res.result]);
        } else {
          setDataResult(res.result);
          setPage(0);
        }
        setCount(res.count);
      });
    // .catch(() => {
    //   showSpinner(false);
    //   showRecordWarning(true);
    // });
  };
  useEffect(() => {
    populateTable()
  }, [])
  


  //Autosearch API Function
  const handleChange = (autoSearchString) => {
    if (autoSearchString !== "") {
      setSearchBar(autoSearchString);
      patientInfo(autoSearchString, departmentTypesWatch)
        .then((response) => response.data)
        .then((res) => {
          setOptions(res.result);
        });
    } else if (autoSearchString === "") {
      setSearchBar(null);
    }
  };

  const searchFilde = (value) => {
    if (value === null) {
      setSearchString("");
      setSearchId(null);
    } else {
      setSearchString(value);
      setSearchId(value.Id);
    }
  };

  const editData = (row) => {
    NarcoticDrugGetById(row.NarcoticDrugFormId).then((response) => {
      setEditRow(response.data.result);

      setListing(response.data.result?.NarcoticDrugDetails);
    });
  };

  const renderPrint = (component) => {
    setTimeout(() => {
      // if(tabValue ===0){
      // setOpenPainAssPrint(false);}else if(tabValue === 1){
      //   setOpenVitalPrint(false)
      // }
      setOpenPainAssPrint(false);
    }, 500);

    return (
      <div className="hidden">
        <NarcoticDrugPrint
          editRow={editRow}
          listing={listing}
          setListing={setListing}
          setEditRow={setEditRow}
        />
      </div>
    );
  };
  const RenderActions = (row, index) => {
    return (
      <div className="flex ">
        <div className="flex gap-1">
          {props.userActions.map((actions, i) => (
            <>
              {actions.isAction === true ? (
                <>
                  <div className="flex gap-2 cursor-pointer" key={i}>
                    {actions.action === "Edit" ? (
                      <Tooltip title="Edit">
                        <div value="click" className="text-blue-500 mr-1 mt-1">
                          <DriveFileRenameOutlineIcon
                            onClick={(e) => {
                              props.handleClose();
                              props.editData(row);
                              props.setEdit(true);
                              props.setPrivilege(actions.action);
                            }}
                          />
                        </div>
                      </Tooltip>
                    ) : null}
                  </div>
                </>
              ) : null}
            </>
          ))}
          <Tooltip title="Print">
            <button
              type="button"
              onClick={() => {
                editData(row);
                setOpenPainAssPrint(true);
              }}
            >
              <PrintOnIcon />
            </button>
          </Tooltip>
        </div>
      </div>
    );
  };

  return (
    <div className="py-4">
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <CancelPresentationIconButton
            onClick={() => {
              //   reset(defaultValues);
              props.handleClose();
              //   props.setEdit(false);
            }}
          />
          <div className="mx-4 my-3">
            <h1 className=" font-semibold text-[16px] py-2">
              Narcotic Drug And Pshychotropic Substances Prescrition Listing
            </h1>
            <div className="w-full flex gap-3">
              <div className="flex w-[70%] gap-1">
                <div className="w-96">
                  <SearchBar
                    type="button"
                    name="SearchableSelect"
                    placeholder="Search By Patient Name / MR No"
                    searchIcon={true}
                    isClearable={true}
                    handleInputChange={handleChange}
                    dataArray={options}
                    onChange={searchFilde}
                  />
                </div>
                <div className="mt-2 whitespace-nowrap w-[25%] ml-4">
                  <RadioField
                    control={control}
                    name="departmentTypes"
                    dataArray={departmentType}
                  />
                </div>
                <div>
                  <DatePickerField
                    control={control}
                    name="fromDate"
                    label="from Date"
                    value={new Date()}
                    onChange={(newValue) => {
                      setValue("fromDate", newValue);
                    }}
                    disablePast={false}
                    disableFuture={false}
                    inputFormat="dd-MM-yyyy"
                  />
                </div>
                <div>
                  <DatePickerField
                    control={control}
                    name="toDate"
                    label="To Date"
                    disableFuture
                    value={new Date()}
                    onChange={(newValue) => {
                      setValue("toDate", newValue);
                    }}
                    inputFormat="dd/MM/yyyy"
                  />
                </div>
              </div>

              <div className="">
                <CommonButton
                  className="bg-customBlue text-white"
                  type="button"
                  searchIcon={true}
                  onClick={() => {
                    populateTable();
                  }}
                />
              </div>
            </div>

            <div className="">
              {dataResult.length > 0 ? (
                <CommonDynamicTablePaginationNew
                  dataResult={dataResult}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  count={count}
                  tableClass={"h-96"}
                  renderActions={RenderActions}
                  removeHeaders={["NarcoticDrugFormId", "Age", "Gender"]}
                  populateTable={populateTable}
                  actionsRequired={true}
                  highlightRow={false}
                  // handleStatusChange={handleStatusChange}
                />
              ) : null}
            </div>
          </div>
          {openPainAssPrint && renderPrint()}
        </Box>
      </Modal>
    </div>
  );
}
