import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Divider } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Receive from "../../../assets/admissionAssets/Receive.svg";
import {
  BedReleaseBed,
  BedTransferBed,
  ChargesAsPerI,
  DischargeI,
  IPDIconBed,
  ReserveBedIcon,
  UnReserveBedIcon,
} from "../../../assets/icons/CustomIcons";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import { warningAlert } from "../../../common/components/Toasts/CustomToasts";
import { baseUrl } from "../../../http-common";
import InitiatDischargeMessage from "../admission/admissionlisting/InitiatDischargeModal";
import ReceivePatientModal from "../admission/admissionlisting/ReceivePatientModal";
import { BedAllocationModalContext } from "./BedAllowModal";
import BedReserveModal from "./BedReserveModal";
import BedUnReserveModal from "./BedUnReserveModal";
import ChargesAsPerModal from "./ChargesAsPerModal";
import SecondaryBedAllocationModal from "./SecondaryBedAllocationModal";
import SecondaryBedReleaseModal from "./SecondaryBedReleaseModal";
import bed from "./assets/bed.svg";
import InfoIcon from "./assets/infoIcon.png";

function BedImageView(props) {
  let token = JSON.parse(localStorage.getItem("userInfo"));
  const { userActions, getBedListData } = useContext(BedAllocationModalContext);
  const [privilege, setPrivilege] = useState();
  const [menuId, setMenuId] = useState();
  const [functionalityId, setFunctionalityId] = useState(null);

  //state variable to open the confirmation modal for POST request
  const [openModal, setOpenModal] = React.useState(false);
  const [bedData, setBedData] = React.useState([]);
  const [bedDetails, setBedDetails] = React.useState();
  //
  const [openChargesAsPeModal, setOpenChargesAsPeModal] = useState(false);
  const handleOpenChargesAsPeModal = () => setOpenChargesAsPeModal(true);
  const handleCloseChargesAsPeModal = () => setOpenChargesAsPeModal(false);
  const [openSecondaryBedAllocationModal, setOpenSecondaryBedAllocationModal] =
    useState(false);
  const [openSecondaryBedReleaseModal, setOpenSecondaryBedReleaseModal] =
    useState(false);

  const [openBedReserveModal, setOpenBedReserveModal] = useState(false);

  const [openBedUnReserveModal, setOpenBedUnReserveModal] = useState(false);
  //
  const handleOpenSecondaryBedAllocationModal = () =>
    setOpenSecondaryBedAllocationModal(true);
  const handleCloseSecondaryBedAllocationModal = () =>
    setOpenSecondaryBedAllocationModal(false);
  //
  const handleOpenSecondaryBedReleaseModal = () =>
    setOpenSecondaryBedReleaseModal(true);
  const handleCloseSecondaryBedReleaseModal = () =>
    setOpenSecondaryBedReleaseModal(false);
  //
  const handleOpenBedReserveModal = () => setOpenBedReserveModal(true);
  const handleCloseBedReserveModal = () => setOpenBedReserveModal(false);
  //
  const handleOpenBedUnReserveModal = () => setOpenBedUnReserveModal(true);
  const handleCloseBedUnReserveModal = () => setOpenBedUnReserveModal(false);
  //
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //initiate discharge modal
  const [openInitiateDischargeModal, setOpenInitiateDischargeModal] =
    React.useState(false);
  const handleOpenInitiateDischargeModal = () =>
    setOpenInitiateDischargeModal(true);
  const handleCloseInitiateDischargeModal = () =>
    setOpenInitiateDischargeModal(false);

  console.log("bedDetails", bedDetails);
  //Receive modal
  const [openReceivePatientModal, setOpenReceivePatientModal] = useState(false);
  const handleOpenReceivePatientModal = () => setOpenReceivePatientModal(true);
  const handleCloseReceivePatientModal = () =>
    setOpenReceivePatientModal(false);

  //

  useEffect(() => {
    if (props.bedListData !== null) {
      setBedData(props.bedListData);
    }
    handleRouteAddition();
  }, [props]);

  //state variable to open the confirmation modal for bed add request
  const handelOpenModal = (selectedData) => {
    // setOpenModal(!selectedData.Occupied);
    setBedDetails(selectedData);
    if (selectedData.Occupied) {
      const createObject = userActions.find((item) => item.action === "Create");

      navigate(createObject.menuKey, {
        state: {
          bedDetails: bedDetails,
          menuId: createObject.menuId,
          // previousMenuId: 261, //need dynamicaly
          privilege: "Create",
          isEdit: true,
          row: {
            AdmissionId: selectedData?.AdmissionId,
            PatientId: selectedData?.PatientId,
          },
        },
      });
      props.handleCloseBed();
    } else {
      setOpenModal(true);
    }
  };

  //state variable to close the confirmation modal for bed  request
  const handleCloseModal = () => {
    if (openModal) {
      setOpenModal(false);
    }
  };
  //event listener function for the Add button on the modal form
  //Code Added By Omkar

  const handleRouteAddition = (userActions) => {
    userActions?.map((item) => {
      if (item.menuKey) {
        // dispatch(routeAdded(item.menuKey));
      }
    });
  };
  //

  //event listener function for the Add button on the modal form
  function addRecord(bedDetails) {
    const createObject = userActions.find((item) => item.action === "Create");

    navigate(createObject.menuKey, {
      state: {
        bedDetails: bedDetails,
        menuId: createObject.menuId,
        // previousMenuId: 261, //need dynamicaly
        privilege: "Create",
      },
    });
    setOpenModal(false);
    props.handleCloseBed();
  }

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip
      placement="left-start"
      arrow
      {...props}
      classes={{ popper: className }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "black",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 320,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
      borderRadius: "10px",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: "black",
    },
  }));

  const HtmlTooltipActions = styled(({ className, ...props }) => (
    <Tooltip
      placement="right"
      arrow
      {...props}
      classes={{ popper: className }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      color: "f0f0f0",
      // maxWidth: 320,
      fontSize: theme.typography.pxToRem(13),
      borderRadius: "4px",
      background: "#D0DAFF",
      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    },
  }));

  return (
    <div className="overflow-hidden w-full ">
      <div className="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-7 2xl:grid-cols-8 gap-4 px-5  mt-2 w-full">
        {props.bedListData.map((item, index) => {
          return (
            <div className="w-full">
              <div className="w-full" type="button">
                <div className="bg-white border shadow rounded-lg w-full transform transition duration-200 hover:scale-110   z-50">
                  <div className="flex px-1 items-center justify-between font-medium space-x-1 w-full pt-2">
                    <div className="flex space-x-2 items-center">
                      <p
                        className={`${
                          item.Occupied === true
                            ? "w-3 h-3 rounded-full bg-red-600"
                            : "w-3 h-3 rounded-full bg-green-600"
                        } `}
                      ></p>

                      <div className="border border-customBlue rounded flex space-x-2 text-xs text-customBlue px-2">
                        <img src={bed} />
                        <span className="border-l border-customBlue"> </span>
                        <p>{item.BedNo}</p>
                      </div>
                    </div>
                    <div className="flex items-center gap-1">
                      {item?.ptInfo !== null ? (
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <div className="grid gap-2 text-sm bg-black p-2 rounded-md">
                                <div className="gap-2 text-sm">
                                  <div className="flex">
                                    <span className="w-[115px] text-[#0b83a5]">
                                      Patient Name
                                    </span>
                                    <span className="text-[#0b83a5]">
                                      &nbsp;:&nbsp;
                                    </span>
                                    <span className="text-white">
                                      {item?.ptInfo !== null
                                        ? (item?.ptInfo &&
                                            item?.ptInfo["Patient Name"]) ||
                                          item?.ptInfo?.PatientName
                                        : ""}
                                    </span>
                                  </div>
                                  {(!item?.isBooked ||
                                    (item?.isBooked && !!item.AdmissionId)) && (
                                    <>
                                      <div className="flex">
                                        <span className="w-[115px] text-[#0b83a5]">
                                          MR No
                                        </span>
                                        <span className="text-[#0b83a5]">
                                          &nbsp;:&nbsp;
                                        </span>
                                        <span className="text-white">
                                          {item?.ptInfo !== null
                                            ? item?.ptInfo["MRNo"]
                                            : ""}
                                        </span>
                                      </div>
                                      <div className="flex">
                                        <span className="w-[115px] text-[#0b83a5]">
                                          Gender
                                        </span>
                                        <span className="text-[#0b83a5]">
                                          &nbsp;:&nbsp;
                                        </span>
                                        <span className="text-white">
                                          {item?.ptInfo !== null
                                            ? item?.ptInfo["Gender"]
                                            : ""}
                                        </span>
                                      </div>
                                      <div className="flex">
                                        <span className="w-[115px] text-[#0b83a5]">
                                          Age
                                        </span>
                                        <span className="text-[#0b83a5]">
                                          &nbsp;:&nbsp;
                                        </span>
                                        <span className="text-white">
                                          {item?.ptInfo !== null
                                            ? item?.ptInfo["Age"]
                                            : ""}
                                        </span>
                                      </div>
                                    </>
                                  )}
                                  <div className="flex">
                                    <span className="w-[115px] text-[#0b83a5]">
                                      Admission Date
                                    </span>
                                    <span className="text-[#0b83a5]">
                                      &nbsp;:&nbsp;
                                    </span>
                                    <span className="text-white">
                                      {item?.ptInfo !== null
                                        ? item?.ptInfo["Admission Date"] ||
                                          item?.ptInfo["AdmissionDate"]
                                        : ""}
                                    </span>
                                  </div>
                                  <div className="flex">
                                    <span className="w-[115px] text-[#0b83a5]">
                                      Admitted Under
                                    </span>
                                    <span className="text-[#0b83a5]">
                                      &nbsp;:&nbsp;
                                    </span>
                                    <span className="text-white">
                                      {item?.ptInfo !== null
                                        ? item?.ptInfo["Admitted Under"] ||
                                          item?.ptInfo["DoctorName"]
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          }
                        >
                          <img src={InfoIcon} className="h-[17px] w-[17px]" />
                        </HtmlTooltip>
                      ) : (
                        <HtmlTooltip
                          title={
                            <>
                              <div className="gap-2 text-sm">
                                <div className="flex">
                                  <span className="text-white">
                                    Bed Is Not Allocated !
                                  </span>
                                </div>
                              </div>
                            </>
                          }
                        >
                          <img src={InfoIcon} className="h-[17px] w-[17px]" />
                        </HtmlTooltip>
                      )}

                      <div className="w-[24px] h-[29px]">
                        <HtmlTooltipActions
                          title={
                            <>
                              <div className="grid mt-1 ">
                                {userActions.map((obj) => {
                                  return (
                                    <>
                                      {!obj.isAction &&
                                      obj.action === "Create" &&
                                      !item.isSecondaryAllocated &&
                                      !item.isBooked ? (
                                        <button
                                          className="flex items-center rounded hover:bg-[#b5bbd8] px-1 text-black"
                                          onClick={() => {
                                            handelOpenModal(item, index);
                                          }}
                                        >
                                          <h1 className="flex items-center space-x-2">
                                            <span className="pt-2">
                                              <IPDIconBed />
                                            </span>
                                            <span>
                                              <Divider
                                                orientation="vertical"
                                                variant="middle"
                                                flexItem
                                                color="#39314F"
                                                style={{
                                                  height: 20,
                                                }}
                                              />
                                            </span>

                                            <label className="font-semibold">
                                              Admission
                                            </label>
                                          </h1>
                                        </button>
                                      ) : null}

                                      {!obj.isAction &&
                                      obj.action === "Reserve Bed" &&
                                      !item.isSecondaryAllocated &&
                                      !item.isBooked ? (
                                        <button
                                          className="flex items-center rounded hover:bg-[#b5bbd8] px-1 text-black"
                                          onClick={() => {
                                            handleOpenBedReserveModal();
                                            setPrivilege(obj.action);
                                            setMenuId(obj.menuId);
                                            setBedDetails(item);
                                          }}
                                        >
                                          <h1 className="flex items-center space-x-2">
                                            <span className="pt-2">
                                              <ReserveBedIcon />
                                            </span>
                                            <span>
                                              <Divider
                                                orientation="vertical"
                                                variant="middle"
                                                flexItem
                                                color="#39314F"
                                                style={{
                                                  height: 20,
                                                }}
                                              />
                                            </span>

                                            <label className="font-semibold">
                                              Reserve Bed
                                            </label>
                                          </h1>
                                        </button>
                                      ) : null}

                                      {!obj.isAction
                                        ? obj.action === "UnReserve Bed" &&
                                          !item.isSecondaryAllocated &&
                                          item.isBooked && (
                                            <button
                                              className="flex items-center rounded hover:bg-[#b5bbd8] px-1 text-black"
                                              onClick={() => {
                                                handleOpenBedUnReserveModal();
                                                setPrivilege(obj.action);
                                                setMenuId(obj.menuId);
                                                setBedDetails(item);
                                              }}
                                            >
                                              <h1 className="flex items-center space-x-2">
                                                <span className="pt-2">
                                                  <UnReserveBedIcon />
                                                </span>
                                                <span>
                                                  <Divider
                                                    orientation="vertical"
                                                    variant="middle"
                                                    flexItem
                                                    color="#39314F"
                                                    style={{
                                                      height: 20,
                                                    }}
                                                  />
                                                </span>

                                                <label className="font-semibold">
                                                  UnReserve Bed
                                                </label>
                                              </h1>
                                            </button>
                                          )
                                        : null}

                                      {!obj.isAction &&
                                      obj.action === "Release Secondary Bed" &&
                                      item.isSecondaryAllocated ? (
                                        <button
                                          className="flex items-center rounded hover:bg-[#b5bbd8] px-1 text-black"
                                          onClick={() => {
                                            handleOpenSecondaryBedReleaseModal();
                                            setPrivilege(obj.action);
                                            setMenuId(obj.menuId);
                                            setBedDetails(item);
                                          }}
                                        >
                                          <h1 className="flex items-center space-x-2">
                                            <span className="pt-2">
                                              <IPDIconBed />
                                            </span>
                                            <span>
                                              <Divider
                                                orientation="vertical"
                                                variant="middle"
                                                flexItem
                                                color="#39314F"
                                                style={{
                                                  height: 20,
                                                }}
                                              />
                                            </span>

                                            <label className="font-semibold">
                                              Release Secondary Bed
                                            </label>
                                          </h1>
                                        </button>
                                      ) : null}

                                      {!obj.isAction &&
                                      obj.action === "Release Bed" &&
                                      item.Discharged &&
                                      !item.isSecondaryAllocated ? (
                                        <button
                                          className="flex items-center rounded hover:bg-[#b5bbd8] px-1 text-black"
                                          onClick={() => {
                                            navigate("/ipd/bedRelease", {
                                              state: {
                                                menuId: 265,
                                              },
                                            });
                                            props.handleCloseBed();
                                          }}
                                        >
                                          <h1 className="flex items-center space-x-2">
                                            <span className="pt-2">
                                              <BedReleaseBed />
                                            </span>
                                            <span>
                                              <Divider
                                                orientation="vertical"
                                                variant="middle"
                                                flexItem
                                                color="#39314F"
                                                style={{
                                                  height: 20,
                                                }}
                                              />
                                            </span>

                                            <label className="font-semibold">
                                              Bed Release
                                            </label>
                                          </h1>
                                        </button>
                                      ) : null}

                                      {item?.isrecievebed !== null &&
                                      item?.isrecievebed &&
                                      item?.AdmissionId !== null &&
                                      !item.Discharged &&
                                      !item.isSecondaryAllocated ? (
                                        <>
                                          {!obj.isAction &&
                                            obj.action === "Transfer Bed" && (
                                              <button
                                                className="flex items-center rounded hover:bg-[#b5bbd8] px-1 text-black"
                                                onClick={() => {
                                                  navigate(obj.menuKey, {
                                                    state: {
                                                      menuId: obj.menuId,
                                                      params: {
                                                        PatientId:
                                                          item.PatientId,
                                                        item: item,
                                                      },
                                                      DisableSearch: true,
                                                    },
                                                  });
                                                  props.handleCloseBed();
                                                }}
                                              >
                                                <h1 className="flex items-center space-x-2">
                                                  <span className="pt-2">
                                                    <BedTransferBed />
                                                  </span>
                                                  <span>
                                                    <Divider
                                                      orientation="vertical"
                                                      variant="middle"
                                                      flexItem
                                                      color="#39314F"
                                                      style={{
                                                        height: 20,
                                                      }}
                                                    />
                                                  </span>

                                                  <label className="font-semibold">
                                                    Bed Transfer
                                                  </label>
                                                </h1>
                                              </button>
                                            )}

                                          {!obj.isAction &&
                                            obj.action ===
                                              "Discharge Initiate" && (
                                              <button
                                                className="flex items-center rounded hover:bg-[#b5bbd8] px-1 text-black"
                                                onClick={() => {
                                                  setPrivilege(obj.action);
                                                  setMenuId(obj.menuId);
                                                  handleOpenInitiateDischargeModal();
                                                  setBedDetails(item);
                                                }}
                                              >
                                                <h1 className="flex items-center space-x-2">
                                                  <span className="pt-2">
                                                    <DischargeI />
                                                  </span>
                                                  <span>
                                                    <Divider
                                                      orientation="vertical"
                                                      variant="middle"
                                                      flexItem
                                                      color="#39314F"
                                                      style={{
                                                        height: 20,
                                                      }}
                                                    />
                                                  </span>

                                                  <label className="font-semibold">
                                                    Discharge Adv. By
                                                  </label>
                                                </h1>
                                              </button>
                                            )}

                                          {!obj.isAction &&
                                            obj.action === "Charges" && (
                                              <button
                                                className="flex items-center rounded hover:bg-[#b5bbd8] px-1 text-black"
                                                onClick={() => {
                                                  setPrivilege(obj.action);
                                                  setMenuId(obj.menuId);
                                                  handleOpenChargesAsPeModal();
                                                  setBedDetails(item);
                                                }}
                                              >
                                                <h1 className="flex items-center space-x-2">
                                                  <span className="pt-2">
                                                    <ChargesAsPerI />
                                                  </span>
                                                  <span>
                                                    <Divider
                                                      orientation="vertical"
                                                      variant="middle"
                                                      flexItem
                                                      color="#39314F"
                                                      style={{
                                                        height: 20,
                                                      }}
                                                    />
                                                  </span>

                                                  <label className="font-semibold">
                                                    Charges As Per
                                                  </label>
                                                </h1>
                                              </button>
                                            )}
                                          {!obj.isAction &&
                                            obj.action ===
                                              "Secondary Bed Allocation" && (
                                              <button
                                                className="flex items-center rounded hover:bg-[#b5bbd8] px-1 text-black"
                                                onClick={() => {
                                                  setPrivilege(obj.action);
                                                  setMenuId(obj.menuId);
                                                  setFunctionalityId(
                                                    obj.functionalityId
                                                  );
                                                  handleOpenSecondaryBedAllocationModal();
                                                  setBedDetails(item);
                                                }}
                                              >
                                                <h1 className="flex items-center space-x-2">
                                                  <span className="pt-2">
                                                    <IPDIconBed />
                                                  </span>
                                                  <span>
                                                    <Divider
                                                      orientation="vertical"
                                                      variant="middle"
                                                      flexItem
                                                      color="#39314F"
                                                      style={{
                                                        height: 20,
                                                      }}
                                                    />
                                                  </span>

                                                  <label className="font-semibold">
                                                    Add Secondary Bed
                                                  </label>
                                                </h1>
                                              </button>
                                            )}
                                        </>
                                      ) : null}

                                      {!obj.isAction &&
                                      item?.isrecievebed !== null &&
                                      !item?.isrecievebed &&
                                      item?.AdmissionId !== null &&
                                      obj.action === "Receive Patient" &&
                                      !item.isSecondaryAllocated ? (
                                        <button
                                          className="flex items-center rounded hover:bg-[#b5bbd8] px-1 text-black"
                                          onClick={() => {
                                            setPrivilege(obj.action);
                                            setMenuId(obj.menuId);

                                            if (item?.isTransferToWard === 1) {
                                              handleOpenReceivePatientModal();
                                            } else if (
                                              item?.isTransferToWard === 0
                                            ) {
                                              warningAlert(
                                                "Please Initiate Transfer To Ward"
                                              );
                                            }

                                            // handleOpenReceivePatientModal();

                                            setBedDetails(item);
                                          }}
                                        >
                                          <h1 className="flex items-center space-x-2">
                                            <span className="">
                                              <img
                                                src={Receive}
                                                alt="reciveimg"
                                                className="h-6"
                                              />
                                            </span>
                                            <span>
                                              <Divider
                                                orientation="vertical"
                                                variant="middle"
                                                flexItem
                                                color="#39314F"
                                                style={{
                                                  height: 20,
                                                }}
                                              />
                                            </span>

                                            <label className="font-semibold">
                                              Receive Patient
                                            </label>
                                          </h1>
                                        </button>
                                      ) : null}
                                    </>
                                  );
                                })}
                              </div>
                            </>
                          }
                        >
                          <MoreVertIcon className="w-3 h-3 text-[#073763]" />
                        </HtmlTooltipActions>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center ">
                    <div>
                      <button className="">
                        <img
                          style={{ height: "100px" }}
                          src={`${baseUrl}/file/${item.ImgPath}`}
                        />
                      </button>
                    </div>
                  </div>
                  {!!item?.AdmissionId &&
                    // !item.isSecondaryAllocated &&
                    item.isBooked && (
                      <div className="text-white text-xs -mt-[16px] bg-red-600 px-2 animate-pulse ">
                        RESERVED
                      </div>
                    )}
                  <div className=" py-1 px-1 bg-gray-200  text-xs text-blue-900 leading-2 ">
                    <div className="flex space-x-2  ">
                      <div className="flex space-x-2">
                        {/* <h2 className="text-blue-500"> Class :</h2> */}

                        <p>{item.Class}</p>
                      </div>
                    </div>
                    <div className="flex space-x-2 ">
                      <div className="flex space-x-2 ">
                        {/* <h1 className="text-blue-500">Room :</h1> */}
                        {/* <h1>{item.roomNo.label}</h1> */}
                        <p>{item.Ward}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {/* //Receive Patient Modal// */}
      {openReceivePatientModal ? (
        <ReceivePatientModal
          open={openReceivePatientModal}
          setOpen={setOpenReceivePatientModal}
          handleOpen={handleOpenReceivePatientModal}
          handleClose={handleCloseReceivePatientModal}
          selectedRow={{
            AdmissionId: bedDetails?.AdmissionId,
            BedId: bedDetails?.BedId,
            GenderId:
              bedDetails?.ptInfo !== null && bedDetails?.ptInfo?.GenderId,
            isTransferToWard: bedDetails?.isTransferToWard,
            isrecievebed: bedDetails?.isrecievebed,
          }}
          // handleList={handleList}
          // setReplaceSelectedRow={setReplaceSelectedRow}
          // updateRowOfAdmTbl={updateRowOfAdmTbl}
          menuId={menuId}
          privilege={privilege}
          getBedListData={getBedListData}
        />
      ) : null}

      {/* openInitiateDischargeModal */}
      {openInitiateDischargeModal ? (
        <InitiatDischargeMessage
          open={openInitiateDischargeModal}
          setOpen={setOpenInitiateDischargeModal}
          handleOpen={handleOpenInitiateDischargeModal}
          handleClose={handleCloseInitiateDischargeModal}
          selectedRowData={{
            MRNo: bedDetails?.ptInfo?.MRNo,
            PatientName:
              bedDetails?.ptInfo !== null && bedDetails?.ptInfo["Patient Name"],
            bedNo: bedDetails?.BedNo,
            BedId: bedDetails?.BedId,
            AdmissionId: bedDetails?.AdmissionId,
            MRNo: bedDetails?.ptInfo?.MRNo,
          }}
          // handleList={handleList}
          // setPage={setPage}
          // updateRowOfAdmTbl={updateRowOfAdmTbl}
          menuId={menuId}
          privilege={privilege}
          getBedListData={getBedListData}
        />
      ) : null}

      {openChargesAsPeModal && (
        <ChargesAsPerModal
          open={openChargesAsPeModal}
          setOpen={setOpenChargesAsPeModal}
          handleOpen={handleOpenChargesAsPeModal}
          handleClose={handleCloseChargesAsPeModal}
          menuId={menuId}
          privilege={privilege}
          getBedListData={getBedListData}
          AdmissionId={bedDetails?.AdmissionId}
          bedDetails={bedDetails}
        />
      )}

      {openSecondaryBedAllocationModal && (
        <SecondaryBedAllocationModal
          open={openSecondaryBedAllocationModal}
          setOpen={setOpenSecondaryBedAllocationModal}
          handleOpen={handleOpenSecondaryBedAllocationModal}
          handleClose={handleCloseSecondaryBedAllocationModal}
          functionalityId={functionalityId}
          menuId={token?.bedAllocationId}
          privilege={privilege}
          getBedListData={getBedListData}
          AdmissionId={bedDetails?.AdmissionId}
          bedDetails={bedDetails}
        />
      )}

      {openSecondaryBedReleaseModal && (
        <SecondaryBedReleaseModal
          open={openSecondaryBedReleaseModal}
          setOpen={setOpenSecondaryBedReleaseModal}
          handleOpen={handleOpenSecondaryBedReleaseModal}
          handleClose={handleCloseSecondaryBedReleaseModal}
          // menuId={menuId}
          menuId={token?.bedAllocationId}
          privilege={privilege}
          getBedListData={getBedListData}
          AdmissionId={bedDetails?.AdmissionId}
          bedDetails={bedDetails}
        />
      )}

      {openBedReserveModal && (
        <BedReserveModal
          open={openBedReserveModal}
          setOpen={setOpenBedReserveModal}
          handleOpen={handleOpenBedReserveModal}
          handleClose={handleCloseBedReserveModal}
          // menuId={menuId}
          menuId={token?.bedAllocationId}
          privilege={privilege}
          getBedListData={getBedListData}
          AdmissionId={bedDetails?.AdmissionId}
          bedDetails={bedDetails}
        />
      )}

      {openBedUnReserveModal && (
        <BedUnReserveModal
          open={openBedUnReserveModal}
          setOpen={setOpenBedUnReserveModal}
          handleOpen={handleOpenBedUnReserveModal}
          handleClose={handleCloseBedUnReserveModal}
          // menuId={menuId}
          menuId={token?.bedAllocationId}
          privilege={privilege}
          getBedListData={getBedListData}
          AdmissionId={bedDetails?.AdmissionId}
          bedDetails={bedDetails}
        />
      )}

      <ConfirmationModal
        confirmationOpen={openModal}
        confirmationHandleClose={handleCloseModal}
        confirmationSubmitFunc={() => addRecord(bedDetails)}
        confirmationLabel="Confirmation"
        confirmationMsg="Click to add this bed ?"
        confirmationButtonMsg="Add"
      />
    </div>
  );
}

export default BedImageView;
