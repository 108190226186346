import React, { useEffect } from "react";
import * as yup from "yup";
import DropdownField from "../../../common/components/FormFields/DropdownField";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InputField from "../../../common/components/FormFields/InputField";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Controller, useForm } from "react-hook-form";
import { TextField, Tooltip } from "@mui/material";
import { areIntervalsOverlapping, format, isBefore, isEqual } from "date-fns";
import { yupResolver } from "@hookform/resolvers/yup";
import { fetchDepartment } from "../../../commonServices/miscellaneousServices/MiscServices";
import { useState } from "react";
import {
  fetchDepartmentOfDoctor,
  fetchShifts,
} from "../../services/doctor/DoctorScheduleServices";
import SearchDropdown from "../../../common/components/FormFields/searchDropdown";
import CheckBoxField from "../../../common/components/FormFields/CheckBoxField";
import { warningAlert } from "../../../common/components/Toasts/CustomToasts";

const schema = yup.object().shape({
  check: yup.boolean(),
  slotFromTime: yup.date().nullable().required(),
  slotToTime: yup.date().nullable().required(),
  slotsInMin: yup.string().nullable().required(),
  ///shift//
  shift: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Required"),
      label: yup.string().required("Required"),
    })
    .required("Required"),
  ///department//
  department: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required("Required"),
      label: yup.string().required("Required"),
    })
    .required("Required"),
});

const AddSlotDetails = (props) => {
  const {
    selectedSlotDetails,
    setSelectedSlotDetails,
    selectedWeekDaysIds,
    setSelectedWeekDaysIds,
    departments,
  } = props;

  const [shifts, setShifts] = useState([]);
  // const [departments, setDepartments] = useState([]);
  const [customError, setCustomError] = useState(false);

  const defaultValues = {
    slotFromTime: null,
    slotToTime: null,
    slotsInMin: "",
    shift: null,
    department: null,
    slotStatus: true,
  };
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    getValues,
    register,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });
  const fromTime = watch("slotFromTime");
  const toTime = watch("slotToTime");

  useEffect(() => {
    if (toTime?.$d && fromTime?.$d) {
      if (isBefore(toTime.$d, fromTime.$d) || isEqual(fromTime.$d, toTime.$d)) {
        setCustomError(true);
        warningAlert("Please Select Valid Time Slot...");
      } else {
        setCustomError(false);
      }
    }
  }, [fromTime, toTime]);

  const handleAddCreateSlotData = (data) => {
    if (!customError) {
      let newSelctedSlots = [...selectedSlotDetails];
      const slotDtlsObj = {
        Shift: data.shift?.label,
        shift_id: data.shift?.id,
        Department: data.department?.label,
        departmentId: data.department?.id,
        isActive: data.slotStatus,
        fromTime: new Date(data.slotFromTime),
        "From Time": format(new Date(data.slotFromTime), "HH:mm"),
        toTime: new Date(data.slotToTime),
        "To Time": format(new Date(data.slotToTime), "HH:mm"),
        "Slots In Minutes": data?.slotsInMin,
      };

      selectedWeekDaysIds.length < 1 &&
        warningAlert("Please Select WeekDay To Create Slot...");

      const mergedData = selectedWeekDaysIds.map((weekDaysId) => ({
        ...weekDaysId,
        ...slotDtlsObj,
      }));
      ///

      mergedData.forEach((newSlot) => {
        const existingSlotsForSameWeekday = selectedSlotDetails.filter(
          (existingSlot) => existingSlot.weekDaysId === newSlot.weekDaysId
        );

        const newSlotInterval = {
          start: new Date(newSlot.fromTime),
          end: new Date(newSlot.toTime),
        };

        const isOverlapping = existingSlotsForSameWeekday.some(
          (existingSlot) => {
            const existingSlotInterval = {
              start: new Date(existingSlot.fromTime),
              end: new Date(existingSlot.toTime),
            };

            return areIntervalsOverlapping(
              newSlotInterval,
              existingSlotInterval
            );
          }
        );

        if (!isOverlapping) {
          newSelctedSlots.push(newSlot);
          setSelectedSlotDetails(newSelctedSlots);
          //
          setSelectedWeekDaysIds([]);
          reset();
          // setValue("slotFromTime", "");
          // setValue("slotToTime", "");
        } else {
          warningAlert("Slot Already Exist For This WeekDay...");
        }
      });
    } else {
      warningAlert("Please Select Valid Time Range...");
    }
  };

  // sorting slot table according weekdays
  useEffect(() => {
    //  Sort by selectedWeekDays and fromTime
    const sortedSlots = [...selectedSlotDetails].sort((a, b) => {
      const weekIdA = selectedWeekDaysIds.findIndex(
        (item) => item.weekDaysId === a.weekDaysId
      );
      const weekIdB = selectedWeekDaysIds.findIndex(
        (item) => item.weekDaysId === b.weekDaysId
      );

      if (weekIdA !== weekIdB) {
        return weekIdA - weekIdB;
      }

      return new Date(a.fromTime) - new Date(b.fromTime);
    });

    // Separate sorted and unsorted data
    const sortedData = [];
    const unsortedData = [];

    sortedSlots.forEach((slot) => {
      if (
        selectedWeekDaysIds.some((item) => item.weekDaysId === slot.weekDaysId)
      ) {
        sortedData.push(slot);
      } else {
        unsortedData.push(slot);
      }
    });

    // Combine sorted and unsorted data
    const finalArr = [...sortedData, ...unsortedData];

    setSelectedSlotDetails(finalArr);
  }, [selectedWeekDaysIds]);

  useEffect(() => {
    getUnit();
  }, []);

  const getUnit = () => {
    fetchShifts()
      .then((response) => response.data)
      .then((res) => {
        //
        setShifts(res.result);
      });
  };

  useEffect(() => {
    if (departments.length === 1) {
      setValue("department", departments[0]);
    } else if (departments.length === 0) {
      setValue("department", null);
    }
  }, [departments]);

  return (
    <>
      <form>
        <div className="grid grid-cols-6 gap-3">
          <div className="lg:col-span-1 md:col-span-2">
            <DropdownField
              control={control}
              name="shift"
              label="Shift"
              dataArray={shifts}
              isSearchable={false}
              isDisabled={false}
              isClearable={false}
              placeholder="Shift *"
              error={errors.shift}
              inputRef={{
                ...register("shift", {
                  onChange: (e) => {},
                }),
              }}
            />
          </div>

          <div className="lg:col-span-1 md:col-span-2">
            <DropdownField
              control={control}
              name="department"
              label="Department"
              dataArray={departments}
              isSearchable={false}
              isDisabled={false}
              isClearable={false}
              placeholder="Department *"
              error={errors.shift}
            />
          </div>

          <div className="lg:col-span-1 md:col-span-2">
            <Controller
              control={control}
              name="slotFromTime"
              render={({ field: { value, onChange } }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopTimePicker
                    label="From Time"
                    value={value}
                    onChange={onChange}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        error={errors.slotFromTime}
                        // helperText={errors.startTime?.message}
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                        InputLabelProps={{
                          style: {
                            fontSize: "14px",
                            position: "absolute",
                            top: "-2px",
                          },
                        }}
                        sx={{
                          svg: {
                            color: "#1e3a8a",
                            height: 22,
                            width: "100%",
                            marginRight: "16px",
                          },
                          backgroundColor: "white",
                          overflow: "visible",

                          "& .MuiOutlinedInput-root": {
                            "& .MuiOutlinedInput-input": {
                              // border: 0,
                              fontSize: 14,
                              height: "18px",
                              // width: "100%",

                              borderColor: "  ",
                              overflow: "hidden",
                            },
                            "& .MuiFormLabel-root": {
                              fontSize: "14px",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </div>

          <div className="lg:col-span-1 md:col-span-2">
            <Controller
              control={control}
              name="slotToTime"
              render={({ field: { value, onChange } }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopTimePicker
                    label="To Time"
                    value={value}
                    onChange={onChange}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        error={errors.slotToTime}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                        InputLabelProps={{
                          style: {
                            fontSize: "14px",
                            position: "absolute",
                            top: "-2px",
                          },
                        }}
                        sx={{
                          svg: {
                            color: "#1e3a8a",
                            height: 22,
                            width: "100%",
                            marginRight: "16px",
                          },
                          backgroundColor: "white",
                          overflow: "visible",

                          "& .MuiOutlinedInput-root": {
                            "& .MuiOutlinedInput-input": {
                              // border: 0,
                              fontSize: 14,
                              height: "18px",
                              width: "100%",

                              borderColor: "  ",
                              overflow: "hidden",
                            },
                            "& .MuiFormLabel-root": {
                              fontSize: "14px",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </div>

          <div>
            <InputField
              name="slotsInMin"
              variant="outlined"
              label="Slot In Min"
              error={errors.slotsInMin}
              control={control}
              inputProps={{ maxLength: 100 }}
              // disabled={true}
            />
          </div>

          <div className="grid md:col-span-3 lg:col-span-1 grid-cols-2 items-center">
            <div>
              <CheckBoxField
                control={control}
                name="slotStatus"
                label="Is Active"
              />
            </div>
            <div className="justify-end">
              <Tooltip title="Add">
                <AddCircleIcon
                  sx={{ color: "#1e3a8a" }}
                  onClick={handleSubmit(handleAddCreateSlotData)}
                />
              </Tooltip>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddSlotDetails;
