//imports from the react js library
import React from "react";

//imports from the mui library
import { Modal, Box } from "@mui/material";

//importing the CancelPresentationIconButton from common/components
import CancelPresentationIconButton from "../../common/components/Buttons/CancelPresentationIconButton";

//importing the BatchInfoTable
import BatchInfoTable from "./common/BatchInfoTable";

//defining the object specifying the BatchInfoModal's style.
const BatchInfoModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  overflowY: "auto",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 20,
  p: 4,
};

export default function BatchInfoModal(props) {
  return (
    <div className="">
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={BatchInfoModalStyle} className="">
          <div className="grid grid-cols-1 md:grid-cols-1  w-full ">
            <CancelPresentationIconButton
              onClick={() => {
                props.handleClose();
                props.setDrugBatchInfo(null);
                props.functionSetvalue()
                props.setSelectedRow("")
                props.findNext()
                
              }}
            />
            <div className="row">
              <fieldset className="border border-gray-300 text-left px-3 lg:px-4  rounded mt-1 lg:m-2 ">
                <legend className="px-2 ml-3 lg:ml-0 font-bold text-gray-600">
                  Item Info
                </legend>
                <div>
                  <div className="flex justify-between text-gray-600 font-semibold">
                    <div className="flex space-x-3 ml-2">
                      <label>Item Description &nbsp; :</label>
                      <p>{props.drugItemInfo.pharmacyItem}</p>
                    </div>
                    <div className="flex space-x-3">
                      <label>Item Code &nbsp; :</label>
                      <p>{props.drugItemInfo.code}</p>
                    </div>
                  </div>
                  <BatchInfoTable
                    data={props.drugBatchInfo}
                    open={props.open}
                    selectedRow={props.selectedRow}
                    setSelectedRow={props.setSelectedRow}
                    handleClose={props.handleClose}
                    indexVal={props.indexVal}
                    setIsFocusedBatchModal={props?.setIsFocusedBatchModal}
                  />
                </div>
              </fieldset>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
