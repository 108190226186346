import React, { useContext } from "react";
import DropdownField from "../../../../common/components/FormFields/DropdownField";
import { useFormContext } from "react-hook-form";
import JoditEditor from "jodit-react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { fetchOtTemplates } from "../../../services/otDetailsServices/OtDetailsServices";
import { OtDetailsContext } from "./OtDetails";

const SurgeryNotes = () => {
  const { templateContent, setTemplateContent } = useContext(OtDetailsContext);
  const [templets, setTemplets] = useState();
  const { control, watch ,register} = useFormContext();


  ////
  let editor = useRef(null);
  let templete = watch("templateName");
  let writerContent = "";

  const config = {
    readonly: false,
    height: 340,
    allowResizeY: false,
    statusbar: false,
    buttons: [
      "bold",
      "underline",
      "strikethrough",
      "italic",
      "image",
      "indent",
      "outdent",
      "fontsize",
      "paragraph",
      "brush",
      "|",
      "align",
      "ul",
      "ol",
      "table",
      "hr",
      "symbol",
      "eraser",
      "copyformat",
      "superscript",
      "subscript",
      "undo",
      "redo",
      "find",
      "preview",
      "print",
    ],
    imageDefaultWidth: 100,
    uploader: {
      insertImageAsBase64URI: true,
    },
  };
  ///

  useEffect(() => {
    fetchOtTemplates()
      .then((response) => response.data)
      .then((res) => {
        //
        setTemplets(res.result);
      });
  }, []);

  //
  return (
    <>
      <div className=" text-gray-700 font-semibold whitespace-nowrap">
        Surgery Notes
      </div>
      <div className="grid grid-cols-6 mt-3">
        <div>
          <DropdownField
            control={control}
            name="templateName"
            placeholder="Select Template"
            isSearchable={false}
            dataArray={templets}
            inputRef={{
              ...register("templateName", {
                onChange: (e) => {
                  setTemplateContent(e.target.value.value);
                },
              }),
            }}          />
        </div>
      </div>
      {/* div for JoditEditor component */}
      <div className="mt-2">
        <JoditEditor
          ref={editor}
          value={!!templateContent ? templateContent : ""}
          config={config}
          // onChange={(newContent) => {
          //   writerContent = newContent;
          // }}

          onBlur={(newContent) => {
            //writerContent = newContent;
            if (newContent !== "") {
              setTemplateContent(newContent);
            }
          }}
        />
      </div>
    </>
  );
};

export default SurgeryNotes;
